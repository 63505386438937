import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Icon } from 'components';

/**
 *
 */
export default class Button extends React.PureComponent {
  static propTypes = {
    sm:         PropTypes.bool,
    lg:         PropTypes.bool,
    far:        PropTypes.bool,
    fas:        PropTypes.bool,
    fab:        PropTypes.bool,
    type:       PropTypes.string,
    icon:       PropTypes.string,
    theme:      PropTypes.string,
    active:     PropTypes.bool,
    disabled:   PropTypes.bool,
    isSpinning: PropTypes.bool,
    className:  PropTypes.string,
    children:   PropTypes.node,
    onClick:    PropTypes.func
  };

  static defaultProps = {
    sm:         false,
    lg:         false,
    far:        false,
    fas:        false,
    fab:        false,
    active:     false,
    disabled:   false,
    isSpinning: false,
    type:       'button',
    theme:      '',
    icon:       '',
    className:  '',
    children:   '',
    onClick:    () => {}
  };

  /**
   * @returns {*}
   */
  render() {
    const {
      sm,
      lg,
      far,
      fas,
      fab,
      icon,
      type,
      theme,
      active,
      disabled,
      className,
      children,
      onClick,
      isSpinning,
      ...props
    } = this.props;

    const classes = classNames(className, {
      'btn-editor':                theme === '',
      [`btn-editor btn-${theme}`]: theme !== '',
      'btn-editor-sm':             sm,
      'btn-editor-lg':             lg,
      'btn-editor-active':         active
    });

    if (icon) {
      return (
        <button type={type} className={classes} onClick={onClick} disabled={disabled} {...props}>
          <Icon name={icon} far={far} fas={fas} fab={fab} isSpinning={isSpinning} />
          {children && (
            <span className="btn-editor-label">
              {children}
            </span>
          )}
        </button>
      );
    }

    return (
      <button type={type} className={classes} onClick={onClick} disabled={disabled} {...props}>
        {children}
      </button>
    );
  }
}
