/**
 * @param {number} index
 * @param {string} alt1
 * @param {string} alt2
 * @returns {string}
 */
export function stringRoundRobin(index, alt1, alt2) {
  if (index % 2 === 0) {
    return alt1;
  }
  return alt2;
}

/**
 * @param {string|Array} pieces
 */
export function stringSpaceCommas(pieces) {
  if (!pieces) {
    return null;
  }
  let newPieces = pieces;
  if (Array.isArray(newPieces)) {
    newPieces = newPieces.join(', ');
  }

  return newPieces.replace(/,/g, ', ');
}

/**
 * @param {string} str
 */
export function stringUcWords(str) {
  return str
    .replace(/^(.)|\s+(.)/g, ($1) => {
      return $1.toUpperCase();
    });
}

/**
 * @param {string} str
 * @returns {string}
 */
export function stringEncodeURI(str) {
  return encodeURIComponent(str).replace(/%20/g, '+');
}

/**
 * @param {string} str
 * @returns {string}
 */
export function stringDecodeURI(str) {
  return decodeURIComponent(str).replace(/\+/g, ' ');
}

/**
 * @param {number} count
 * @param {string} singular
 * @param {string} plural
 * @returns {string}
 */
export function stringPluralize(count, singular, plural) {
  return count === 1 ? singular : plural;
}

/**
 * @param {string} string
 * @returns {string}
 */
export function stringEscapeRegExp(string) {
  return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
}

/**
 * @param {string} str
 * @returns {string}
 */
export function stringSlugify(str) {
  return str.replace(/[^a-z0-9_-]/gi, '').toLowerCase();
}

/**
 * @param {string} str
 * @param {number} maxLength
 * @param {boolean} reverse
 * @param {boolean} middle
 * @returns {string}
 */
export function stringTruc(str, maxLength, reverse = false, middle = false) {
  if (str.length < maxLength) {
    return str;
  }
  if (reverse) {
    return `… ${str.substr(-Math.abs(maxLength - 2))}`;
  }
  if (middle) {
    const pad   = Math.round(maxLength - 4) / 2;
    const start = str.substr(0, pad);
    const end   = str.substr(str.length - pad);

    return [start, ' … ', end].join('');
  }

  return `${str.substr(0, maxLength - 3)} …`;
}

/**
 * @param {string} prefix
 * @returns {string}
 */
export function stringUnique(prefix = '-') {
  return `${prefix}${Math.random().toString(36).substr(2, 9)}`;
}

/**
 * @param {string} input
 * @param {string} allowed
 * @returns {*}
 */
export function stringStripTags(input, allowed = '') {
  allowed = (((allowed || '') + '')
    .toLowerCase()
    .match(/<[a-z][a-z0-9]*>/g) || [])
    .join('');
  const tags = /<\/?([a-z][a-z0-9]*)\b[^>]*>/gi;
  const commentsAndPhpTags = /<!--[\s\S]*?-->|<\?(?:php)?[\s\S]*?\?>/gi;

  return input.replace(commentsAndPhpTags, '')
    .replace(tags, function($0, $1) {
      return allowed.indexOf('<' + $1.toLowerCase() + '>') > -1 ? $0 : '';
    });
}

/**
 * @param {number} length
 * @returns {string}
 */
export function stringUid(length) {
  let result             = '';
  const characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }

  return result;
}

/**
 * @param {RegExp} regex
 * @param {string} value
 * @returns {Array}
 */
export function stringMatchAll(regex, value) {
  let match;
  const matches = [];
  while ((match = regex.exec(value)) !== null) { // eslint-disable-line
    matches.push(match);
  }

  return matches;
}

/**
 * @param {number} fileSizeInBytes
 * @returns {string}
 */
export function stringHumanBytes(fileSizeInBytes) {
  let i = -1;
  const byteUnits = [' KB', ' MB', ' GB', ' TB', 'PB', 'EB', 'ZB', 'YB'];
  do {
    fileSizeInBytes /= 1024;
    i += 1;
  } while (fileSizeInBytes > 1024);

  return Math.max(fileSizeInBytes, 0.1).toFixed(1) + byteUnits[i];
}

/**
 * @param {string} str
 * @param {string} chars
 * @returns {string}
 */
export function stringTrim(str, chars) {
  if (chars === undefined) {
    chars = "\s";
  }

  str = str.replace(new RegExp(`^[${chars}]+`), '');
  return str.replace(new RegExp(`[${chars}]+$`), '');
}

/**
 * @param {string} path
 * @param {string} matchedTerm
 * @returns {string}
 */
export const stringHighlight = (path, matchedTerm) => {
  if (!matchedTerm) {
    return path;
  }

  return path.replace(new RegExp(matchedTerm, 'i'), (match) => {
    return `<span class="highlight-text">${match}</span>`;
  });
};

export default {
  roundRobin:   stringRoundRobin,
  spaceCommas:  stringSpaceCommas,
  ucWords:      stringUcWords,
  encodeURI:    stringEncodeURI,
  decodeURI:    stringDecodeURI,
  pluralize:    stringPluralize,
  escapeRegExp: stringEscapeRegExp,
  highlight:    stringHighlight,
  slugify:      stringSlugify,
  trunc:        stringTruc,
  trim:         stringTrim,
  unique:       stringUnique,
  stripTags:    stringStripTags,
  uid:          stringUid,
  matchAll:     stringMatchAll,
  humanBytes:   stringHumanBytes
};
