let googleFonts = [
  {
    "family":"Roboto",
    "variants":[
      "100",
      "100italic",
      "300",
      "300italic",
      "regular",
      "italic",
      "500",
      "500italic",
      "700",
      "700italic",
      "900",
      "900italic"
    ],
    "subsets":[
      "cyrillic",
      "cyrillic-ext",
      "greek",
      "greek-ext",
      "latin",
      "latin-ext",
      "vietnamese"
    ],
    "version":"v20",
    "lastModified":"2019-07-24",
    "files":{
      "100":"http://fonts.gstatic.com/s/roboto/v20/KFOkCnqEu92Fr1MmgWxPKTM1K9nz.ttf",
      "300":"http://fonts.gstatic.com/s/roboto/v20/KFOlCnqEu92Fr1MmSU5vAx05IsDqlA.ttf",
      "500":"http://fonts.gstatic.com/s/roboto/v20/KFOlCnqEu92Fr1MmEU9vAx05IsDqlA.ttf",
      "700":"http://fonts.gstatic.com/s/roboto/v20/KFOlCnqEu92Fr1MmWUlvAx05IsDqlA.ttf",
      "900":"http://fonts.gstatic.com/s/roboto/v20/KFOlCnqEu92Fr1MmYUtvAx05IsDqlA.ttf",
      "100italic":"http://fonts.gstatic.com/s/roboto/v20/KFOiCnqEu92Fr1Mu51QrIzcXLsnzjYk.ttf",
      "300italic":"http://fonts.gstatic.com/s/roboto/v20/KFOjCnqEu92Fr1Mu51TjARc9AMX6lJBP.ttf",
      "regular":"http://fonts.gstatic.com/s/roboto/v20/KFOmCnqEu92Fr1Me5WZLCzYlKw.ttf",
      "italic":"http://fonts.gstatic.com/s/roboto/v20/KFOkCnqEu92Fr1Mu52xPKTM1K9nz.ttf",
      "500italic":"http://fonts.gstatic.com/s/roboto/v20/KFOjCnqEu92Fr1Mu51S7ABc9AMX6lJBP.ttf",
      "700italic":"http://fonts.gstatic.com/s/roboto/v20/KFOjCnqEu92Fr1Mu51TzBhc9AMX6lJBP.ttf",
      "900italic":"http://fonts.gstatic.com/s/roboto/v20/KFOjCnqEu92Fr1Mu51TLBBc9AMX6lJBP.ttf"
    },
    "category":"sans-serif",
    "kind":"webfonts#webfont"
  },
  {
    "family":"Open Sans",
    "variants":[
      "300",
      "300italic",
      "regular",
      "italic",
      "600",
      "600italic",
      "700",
      "700italic",
      "800",
      "800italic"
    ],
    "subsets":[
      "cyrillic",
      "cyrillic-ext",
      "greek",
      "greek-ext",
      "latin",
      "latin-ext",
      "vietnamese"
    ],
    "version":"v18",
    "lastModified":"2020-09-15",
    "files":{
      "300":"http://fonts.gstatic.com/s/opensans/v18/mem5YaGs126MiZpBA-UN_r8-VeJoCqeDjg.ttf",
      "600":"http://fonts.gstatic.com/s/opensans/v18/mem5YaGs126MiZpBA-UNirk-VeJoCqeDjg.ttf",
      "700":"http://fonts.gstatic.com/s/opensans/v18/mem5YaGs126MiZpBA-UN7rg-VeJoCqeDjg.ttf",
      "800":"http://fonts.gstatic.com/s/opensans/v18/mem5YaGs126MiZpBA-UN8rs-VeJoCqeDjg.ttf",
      "300italic":"http://fonts.gstatic.com/s/opensans/v18/memnYaGs126MiZpBA-UFUKWyV-hsKKKTjrPW.ttf",
      "regular":"http://fonts.gstatic.com/s/opensans/v18/mem8YaGs126MiZpBA-U1UpcaXcl0Aw.ttf",
      "italic":"http://fonts.gstatic.com/s/opensans/v18/mem6YaGs126MiZpBA-UFUJ0ef8xkA76a.ttf",
      "600italic":"http://fonts.gstatic.com/s/opensans/v18/memnYaGs126MiZpBA-UFUKXGUehsKKKTjrPW.ttf",
      "700italic":"http://fonts.gstatic.com/s/opensans/v18/memnYaGs126MiZpBA-UFUKWiUOhsKKKTjrPW.ttf",
      "800italic":"http://fonts.gstatic.com/s/opensans/v18/memnYaGs126MiZpBA-UFUKW-U-hsKKKTjrPW.ttf"
    },
    "category":"sans-serif",
    "kind":"webfonts#webfont"
  },
  {
    "family":"Noto Sans JP",
    "variants":[
      "100",
      "300",
      "regular",
      "500",
      "700",
      "900"
    ],
    "subsets":[
      "japanese",
      "latin"
    ],
    "version":"v27",
    "lastModified":"2020-08-26",
    "files":{
      "100":"http://fonts.gstatic.com/s/notosansjp/v27/-F6ofjtqLzI2JPCgQBnw7HFQoggM-FNthvIU.otf",
      "300":"http://fonts.gstatic.com/s/notosansjp/v27/-F6pfjtqLzI2JPCgQBnw7HFQaioq1H1hj-sNFQ.otf",
      "500":"http://fonts.gstatic.com/s/notosansjp/v27/-F6pfjtqLzI2JPCgQBnw7HFQMisq1H1hj-sNFQ.otf",
      "700":"http://fonts.gstatic.com/s/notosansjp/v27/-F6pfjtqLzI2JPCgQBnw7HFQei0q1H1hj-sNFQ.otf",
      "900":"http://fonts.gstatic.com/s/notosansjp/v27/-F6pfjtqLzI2JPCgQBnw7HFQQi8q1H1hj-sNFQ.otf",
      "regular":"http://fonts.gstatic.com/s/notosansjp/v27/-F62fjtqLzI2JPCgQBnw7HFowAIO2lZ9hg.otf"
    },
    "category":"sans-serif",
    "kind":"webfonts#webfont"
  },
  {
    "family":"Lato",
    "variants":[
      "100",
      "100italic",
      "300",
      "300italic",
      "regular",
      "italic",
      "700",
      "700italic",
      "900",
      "900italic"
    ],
    "subsets":[
      "latin",
      "latin-ext"
    ],
    "version":"v17",
    "lastModified":"2020-09-15",
    "files":{
      "100":"http://fonts.gstatic.com/s/lato/v17/S6u8w4BMUTPHh30wWyWrFCbw7A.ttf",
      "300":"http://fonts.gstatic.com/s/lato/v17/S6u9w4BMUTPHh7USew-FGC_p9dw.ttf",
      "700":"http://fonts.gstatic.com/s/lato/v17/S6u9w4BMUTPHh6UVew-FGC_p9dw.ttf",
      "900":"http://fonts.gstatic.com/s/lato/v17/S6u9w4BMUTPHh50Xew-FGC_p9dw.ttf",
      "100italic":"http://fonts.gstatic.com/s/lato/v17/S6u-w4BMUTPHjxsIPy-vNiPg7MU0.ttf",
      "300italic":"http://fonts.gstatic.com/s/lato/v17/S6u_w4BMUTPHjxsI9w2PHA3s5dwt7w.ttf",
      "regular":"http://fonts.gstatic.com/s/lato/v17/S6uyw4BMUTPHvxk6XweuBCY.ttf",
      "italic":"http://fonts.gstatic.com/s/lato/v17/S6u8w4BMUTPHjxswWyWrFCbw7A.ttf",
      "700italic":"http://fonts.gstatic.com/s/lato/v17/S6u_w4BMUTPHjxsI5wqPHA3s5dwt7w.ttf",
      "900italic":"http://fonts.gstatic.com/s/lato/v17/S6u_w4BMUTPHjxsI3wiPHA3s5dwt7w.ttf"
    },
    "category":"sans-serif",
    "kind":"webfonts#webfont"
  },
  {
    "family":"Montserrat",
    "variants":[
      "100",
      "100italic",
      "200",
      "200italic",
      "300",
      "300italic",
      "regular",
      "italic",
      "500",
      "500italic",
      "600",
      "600italic",
      "700",
      "700italic",
      "800",
      "800italic",
      "900",
      "900italic"
    ],
    "subsets":[
      "cyrillic",
      "cyrillic-ext",
      "latin",
      "latin-ext",
      "vietnamese"
    ],
    "version":"v15",
    "lastModified":"2020-09-15",
    "files":{
      "100":"http://fonts.gstatic.com/s/montserrat/v15/JTUQjIg1_i6t8kCHKm45_QphziTn89dtpQ.ttf",
      "200":"http://fonts.gstatic.com/s/montserrat/v15/JTURjIg1_i6t8kCHKm45_aZA7g7J_950vCo.ttf",
      "300":"http://fonts.gstatic.com/s/montserrat/v15/JTURjIg1_i6t8kCHKm45_cJD7g7J_950vCo.ttf",
      "500":"http://fonts.gstatic.com/s/montserrat/v15/JTURjIg1_i6t8kCHKm45_ZpC7g7J_950vCo.ttf",
      "600":"http://fonts.gstatic.com/s/montserrat/v15/JTURjIg1_i6t8kCHKm45_bZF7g7J_950vCo.ttf",
      "700":"http://fonts.gstatic.com/s/montserrat/v15/JTURjIg1_i6t8kCHKm45_dJE7g7J_950vCo.ttf",
      "800":"http://fonts.gstatic.com/s/montserrat/v15/JTURjIg1_i6t8kCHKm45_c5H7g7J_950vCo.ttf",
      "900":"http://fonts.gstatic.com/s/montserrat/v15/JTURjIg1_i6t8kCHKm45_epG7g7J_950vCo.ttf",
      "100italic":"http://fonts.gstatic.com/s/montserrat/v15/JTUOjIg1_i6t8kCHKm459WxZqi7j0dJ9pTOi.ttf",
      "200italic":"http://fonts.gstatic.com/s/montserrat/v15/JTUPjIg1_i6t8kCHKm459WxZBg_D-_xxrCq7qg.ttf",
      "300italic":"http://fonts.gstatic.com/s/montserrat/v15/JTUPjIg1_i6t8kCHKm459WxZYgzD-_xxrCq7qg.ttf",
      "regular":"http://fonts.gstatic.com/s/montserrat/v15/JTUSjIg1_i6t8kCHKm45xW5rygbi49c.ttf",
      "italic":"http://fonts.gstatic.com/s/montserrat/v15/JTUQjIg1_i6t8kCHKm459WxhziTn89dtpQ.ttf",
      "500italic":"http://fonts.gstatic.com/s/montserrat/v15/JTUPjIg1_i6t8kCHKm459WxZOg3D-_xxrCq7qg.ttf",
      "600italic":"http://fonts.gstatic.com/s/montserrat/v15/JTUPjIg1_i6t8kCHKm459WxZFgrD-_xxrCq7qg.ttf",
      "700italic":"http://fonts.gstatic.com/s/montserrat/v15/JTUPjIg1_i6t8kCHKm459WxZcgvD-_xxrCq7qg.ttf",
      "800italic":"http://fonts.gstatic.com/s/montserrat/v15/JTUPjIg1_i6t8kCHKm459WxZbgjD-_xxrCq7qg.ttf",
      "900italic":"http://fonts.gstatic.com/s/montserrat/v15/JTUPjIg1_i6t8kCHKm459WxZSgnD-_xxrCq7qg.ttf"
    },
    "category":"sans-serif",
    "kind":"webfonts#webfont"
  },
  {
    "family":"Roboto Condensed",
    "variants":[
      "300",
      "300italic",
      "regular",
      "italic",
      "700",
      "700italic"
    ],
    "subsets":[
      "cyrillic",
      "cyrillic-ext",
      "greek",
      "greek-ext",
      "latin",
      "latin-ext",
      "vietnamese"
    ],
    "version":"v19",
    "lastModified":"2020-09-15",
    "files":{
      "300":"http://fonts.gstatic.com/s/robotocondensed/v19/ieVi2ZhZI2eCN5jzbjEETS9weq8-33mZKCMSbvtdYyQ.ttf",
      "700":"http://fonts.gstatic.com/s/robotocondensed/v19/ieVi2ZhZI2eCN5jzbjEETS9weq8-32meKCMSbvtdYyQ.ttf",
      "300italic":"http://fonts.gstatic.com/s/robotocondensed/v19/ieVg2ZhZI2eCN5jzbjEETS9weq8-19eDpCEYatlYcyRi4A.ttf",
      "regular":"http://fonts.gstatic.com/s/robotocondensed/v19/ieVl2ZhZI2eCN5jzbjEETS9weq8-59WxDCs5cvI.ttf",
      "italic":"http://fonts.gstatic.com/s/robotocondensed/v19/ieVj2ZhZI2eCN5jzbjEETS9weq8-19e7CAk8YvJEeg.ttf",
      "700italic":"http://fonts.gstatic.com/s/robotocondensed/v19/ieVg2ZhZI2eCN5jzbjEETS9weq8-19eDtCYYatlYcyRi4A.ttf"
    },
    "category":"sans-serif",
    "kind":"webfonts#webfont"
  },
  {
    "family":"Source Sans Pro",
    "variants":[
      "200",
      "200italic",
      "300",
      "300italic",
      "regular",
      "italic",
      "600",
      "600italic",
      "700",
      "700italic",
      "900",
      "900italic"
    ],
    "subsets":[
      "cyrillic",
      "cyrillic-ext",
      "greek",
      "greek-ext",
      "latin",
      "latin-ext",
      "vietnamese"
    ],
    "version":"v14",
    "lastModified":"2020-09-15",
    "files":{
      "200":"http://fonts.gstatic.com/s/sourcesanspro/v14/6xKydSBYKcSV-LCoeQqfX1RYOo3i94_AkB1v_8CGxg.ttf",
      "300":"http://fonts.gstatic.com/s/sourcesanspro/v14/6xKydSBYKcSV-LCoeQqfX1RYOo3ik4zAkB1v_8CGxg.ttf",
      "600":"http://fonts.gstatic.com/s/sourcesanspro/v14/6xKydSBYKcSV-LCoeQqfX1RYOo3i54rAkB1v_8CGxg.ttf",
      "700":"http://fonts.gstatic.com/s/sourcesanspro/v14/6xKydSBYKcSV-LCoeQqfX1RYOo3ig4vAkB1v_8CGxg.ttf",
      "900":"http://fonts.gstatic.com/s/sourcesanspro/v14/6xKydSBYKcSV-LCoeQqfX1RYOo3iu4nAkB1v_8CGxg.ttf",
      "200italic":"http://fonts.gstatic.com/s/sourcesanspro/v14/6xKwdSBYKcSV-LCoeQqfX1RYOo3qPZYokRdr3cWWxg40.ttf",
      "300italic":"http://fonts.gstatic.com/s/sourcesanspro/v14/6xKwdSBYKcSV-LCoeQqfX1RYOo3qPZZMkhdr3cWWxg40.ttf",
      "regular":"http://fonts.gstatic.com/s/sourcesanspro/v14/6xK3dSBYKcSV-LCoeQqfX1RYOo3aP6TkmDZz9g.ttf",
      "italic":"http://fonts.gstatic.com/s/sourcesanspro/v14/6xK1dSBYKcSV-LCoeQqfX1RYOo3qPa7gujNj9tmf.ttf",
      "600italic":"http://fonts.gstatic.com/s/sourcesanspro/v14/6xKwdSBYKcSV-LCoeQqfX1RYOo3qPZY4lBdr3cWWxg40.ttf",
      "700italic":"http://fonts.gstatic.com/s/sourcesanspro/v14/6xKwdSBYKcSV-LCoeQqfX1RYOo3qPZZclRdr3cWWxg40.ttf",
      "900italic":"http://fonts.gstatic.com/s/sourcesanspro/v14/6xKwdSBYKcSV-LCoeQqfX1RYOo3qPZZklxdr3cWWxg40.ttf"
    },
    "category":"sans-serif",
    "kind":"webfonts#webfont"
  },
  {
    "family":"Oswald",
    "variants":[
      "200",
      "300",
      "regular",
      "500",
      "600",
      "700"
    ],
    "subsets":[
      "cyrillic",
      "cyrillic-ext",
      "latin",
      "latin-ext",
      "vietnamese"
    ],
    "version":"v35",
    "lastModified":"2020-07-13",
    "files":{
      "200":"http://fonts.gstatic.com/s/oswald/v35/TK3_WkUHHAIjg75cFRf3bXL8LICs13FvgUFoZAaRliE.ttf",
      "300":"http://fonts.gstatic.com/s/oswald/v35/TK3_WkUHHAIjg75cFRf3bXL8LICs169vgUFoZAaRliE.ttf",
      "500":"http://fonts.gstatic.com/s/oswald/v35/TK3_WkUHHAIjg75cFRf3bXL8LICs18NvgUFoZAaRliE.ttf",
      "600":"http://fonts.gstatic.com/s/oswald/v35/TK3_WkUHHAIjg75cFRf3bXL8LICs1y9ogUFoZAaRliE.ttf",
      "700":"http://fonts.gstatic.com/s/oswald/v35/TK3_WkUHHAIjg75cFRf3bXL8LICs1xZogUFoZAaRliE.ttf",
      "regular":"http://fonts.gstatic.com/s/oswald/v35/TK3_WkUHHAIjg75cFRf3bXL8LICs1_FvgUFoZAaRliE.ttf"
    },
    "category":"sans-serif",
    "kind":"webfonts#webfont"
  },
  {
    "family":"Poppins",
    "variants":[
      "100",
      "100italic",
      "200",
      "200italic",
      "300",
      "300italic",
      "regular",
      "italic",
      "500",
      "500italic",
      "600",
      "600italic",
      "700",
      "700italic",
      "800",
      "800italic",
      "900",
      "900italic"
    ],
    "subsets":[
      "devanagari",
      "latin",
      "latin-ext"
    ],
    "version":"v13",
    "lastModified":"2020-09-15",
    "files":{
      "100":"http://fonts.gstatic.com/s/poppins/v13/pxiGyp8kv8JHgFVrLPTed3FBGPaTSQ.ttf",
      "200":"http://fonts.gstatic.com/s/poppins/v13/pxiByp8kv8JHgFVrLFj_V1tvFP-KUEg.ttf",
      "300":"http://fonts.gstatic.com/s/poppins/v13/pxiByp8kv8JHgFVrLDz8V1tvFP-KUEg.ttf",
      "500":"http://fonts.gstatic.com/s/poppins/v13/pxiByp8kv8JHgFVrLGT9V1tvFP-KUEg.ttf",
      "600":"http://fonts.gstatic.com/s/poppins/v13/pxiByp8kv8JHgFVrLEj6V1tvFP-KUEg.ttf",
      "700":"http://fonts.gstatic.com/s/poppins/v13/pxiByp8kv8JHgFVrLCz7V1tvFP-KUEg.ttf",
      "800":"http://fonts.gstatic.com/s/poppins/v13/pxiByp8kv8JHgFVrLDD4V1tvFP-KUEg.ttf",
      "900":"http://fonts.gstatic.com/s/poppins/v13/pxiByp8kv8JHgFVrLBT5V1tvFP-KUEg.ttf",
      "100italic":"http://fonts.gstatic.com/s/poppins/v13/pxiAyp8kv8JHgFVrJJLmE3tFOvODSVFF.ttf",
      "200italic":"http://fonts.gstatic.com/s/poppins/v13/pxiDyp8kv8JHgFVrJJLmv1plEN2PQEhcqw.ttf",
      "300italic":"http://fonts.gstatic.com/s/poppins/v13/pxiDyp8kv8JHgFVrJJLm21llEN2PQEhcqw.ttf",
      "regular":"http://fonts.gstatic.com/s/poppins/v13/pxiEyp8kv8JHgFVrFJDUc1NECPY.ttf",
      "italic":"http://fonts.gstatic.com/s/poppins/v13/pxiGyp8kv8JHgFVrJJLed3FBGPaTSQ.ttf",
      "500italic":"http://fonts.gstatic.com/s/poppins/v13/pxiDyp8kv8JHgFVrJJLmg1hlEN2PQEhcqw.ttf",
      "600italic":"http://fonts.gstatic.com/s/poppins/v13/pxiDyp8kv8JHgFVrJJLmr19lEN2PQEhcqw.ttf",
      "700italic":"http://fonts.gstatic.com/s/poppins/v13/pxiDyp8kv8JHgFVrJJLmy15lEN2PQEhcqw.ttf",
      "800italic":"http://fonts.gstatic.com/s/poppins/v13/pxiDyp8kv8JHgFVrJJLm111lEN2PQEhcqw.ttf",
      "900italic":"http://fonts.gstatic.com/s/poppins/v13/pxiDyp8kv8JHgFVrJJLm81xlEN2PQEhcqw.ttf"
    },
    "category":"sans-serif",
    "kind":"webfonts#webfont"
  },
  {
    "family":"Raleway",
    "variants":[
      "100",
      "200",
      "300",
      "regular",
      "500",
      "600",
      "700",
      "800",
      "900",
      "100italic",
      "200italic",
      "300italic",
      "italic",
      "500italic",
      "600italic",
      "700italic",
      "800italic",
      "900italic"
    ],
    "subsets":[
      "cyrillic",
      "cyrillic-ext",
      "latin",
      "latin-ext",
      "vietnamese"
    ],
    "version":"v18",
    "lastModified":"2020-09-30",
    "files":{
      "100":"http://fonts.gstatic.com/s/raleway/v18/1Ptxg8zYS_SKggPN4iEgvnHyvveLxVvao4CPNLA3JC9c.ttf",
      "200":"http://fonts.gstatic.com/s/raleway/v18/1Ptxg8zYS_SKggPN4iEgvnHyvveLxVtaooCPNLA3JC9c.ttf",
      "300":"http://fonts.gstatic.com/s/raleway/v18/1Ptxg8zYS_SKggPN4iEgvnHyvveLxVuEooCPNLA3JC9c.ttf",
      "500":"http://fonts.gstatic.com/s/raleway/v18/1Ptxg8zYS_SKggPN4iEgvnHyvveLxVvoooCPNLA3JC9c.ttf",
      "600":"http://fonts.gstatic.com/s/raleway/v18/1Ptxg8zYS_SKggPN4iEgvnHyvveLxVsEpYCPNLA3JC9c.ttf",
      "700":"http://fonts.gstatic.com/s/raleway/v18/1Ptxg8zYS_SKggPN4iEgvnHyvveLxVs9pYCPNLA3JC9c.ttf",
      "800":"http://fonts.gstatic.com/s/raleway/v18/1Ptxg8zYS_SKggPN4iEgvnHyvveLxVtapYCPNLA3JC9c.ttf",
      "900":"http://fonts.gstatic.com/s/raleway/v18/1Ptxg8zYS_SKggPN4iEgvnHyvveLxVtzpYCPNLA3JC9c.ttf",
      "regular":"http://fonts.gstatic.com/s/raleway/v18/1Ptxg8zYS_SKggPN4iEgvnHyvveLxVvaooCPNLA3JC9c.ttf",
      "100italic":"http://fonts.gstatic.com/s/raleway/v18/1Pt_g8zYS_SKggPNyCgSQamb1W0lwk4S4WjNPrQVIT9c2c8.ttf",
      "200italic":"http://fonts.gstatic.com/s/raleway/v18/1Pt_g8zYS_SKggPNyCgSQamb1W0lwk4S4ejMPrQVIT9c2c8.ttf",
      "300italic":"http://fonts.gstatic.com/s/raleway/v18/1Pt_g8zYS_SKggPNyCgSQamb1W0lwk4S4TbMPrQVIT9c2c8.ttf",
      "italic":"http://fonts.gstatic.com/s/raleway/v18/1Pt_g8zYS_SKggPNyCgSQamb1W0lwk4S4WjMPrQVIT9c2c8.ttf",
      "500italic":"http://fonts.gstatic.com/s/raleway/v18/1Pt_g8zYS_SKggPNyCgSQamb1W0lwk4S4VrMPrQVIT9c2c8.ttf",
      "600italic":"http://fonts.gstatic.com/s/raleway/v18/1Pt_g8zYS_SKggPNyCgSQamb1W0lwk4S4bbLPrQVIT9c2c8.ttf",
      "700italic":"http://fonts.gstatic.com/s/raleway/v18/1Pt_g8zYS_SKggPNyCgSQamb1W0lwk4S4Y_LPrQVIT9c2c8.ttf",
      "800italic":"http://fonts.gstatic.com/s/raleway/v18/1Pt_g8zYS_SKggPNyCgSQamb1W0lwk4S4ejLPrQVIT9c2c8.ttf",
      "900italic":"http://fonts.gstatic.com/s/raleway/v18/1Pt_g8zYS_SKggPNyCgSQamb1W0lwk4S4cHLPrQVIT9c2c8.ttf"
    },
    "category":"sans-serif",
    "kind":"webfonts#webfont"
  },
  {
    "family":"Roboto Mono",
    "variants":[
      "100",
      "200",
      "300",
      "regular",
      "500",
      "600",
      "700",
      "100italic",
      "200italic",
      "300italic",
      "italic",
      "500italic",
      "600italic",
      "700italic"
    ],
    "subsets":[
      "cyrillic",
      "cyrillic-ext",
      "greek",
      "latin",
      "latin-ext",
      "vietnamese"
    ],
    "version":"v12",
    "lastModified":"2020-07-13",
    "files":{
      "100":"http://fonts.gstatic.com/s/robotomono/v12/L0xuDF4xlVMF-BfR8bXMIhJHg45mwgGEFl0_3vuPQ--5Ip2sSQ.ttf",
      "200":"http://fonts.gstatic.com/s/robotomono/v12/L0xuDF4xlVMF-BfR8bXMIhJHg45mwgGEFl0_XvqPQ--5Ip2sSQ.ttf",
      "300":"http://fonts.gstatic.com/s/robotomono/v12/L0xuDF4xlVMF-BfR8bXMIhJHg45mwgGEFl0_gPqPQ--5Ip2sSQ.ttf",
      "500":"http://fonts.gstatic.com/s/robotomono/v12/L0xuDF4xlVMF-BfR8bXMIhJHg45mwgGEFl0_7PqPQ--5Ip2sSQ.ttf",
      "600":"http://fonts.gstatic.com/s/robotomono/v12/L0xuDF4xlVMF-BfR8bXMIhJHg45mwgGEFl0_AP2PQ--5Ip2sSQ.ttf",
      "700":"http://fonts.gstatic.com/s/robotomono/v12/L0xuDF4xlVMF-BfR8bXMIhJHg45mwgGEFl0_Of2PQ--5Ip2sSQ.ttf",
      "regular":"http://fonts.gstatic.com/s/robotomono/v12/L0xuDF4xlVMF-BfR8bXMIhJHg45mwgGEFl0_3vqPQ--5Ip2sSQ.ttf",
      "100italic":"http://fonts.gstatic.com/s/robotomono/v12/L0xoDF4xlVMF-BfR8bXMIjhOsXG-q2oeuFoqFrlnAeW9AJi8SZwt.ttf",
      "200italic":"http://fonts.gstatic.com/s/robotomono/v12/L0xoDF4xlVMF-BfR8bXMIjhOsXG-q2oeuFoqFrnnAOW9AJi8SZwt.ttf",
      "300italic":"http://fonts.gstatic.com/s/robotomono/v12/L0xoDF4xlVMF-BfR8bXMIjhOsXG-q2oeuFoqFrk5AOW9AJi8SZwt.ttf",
      "italic":"http://fonts.gstatic.com/s/robotomono/v12/L0xoDF4xlVMF-BfR8bXMIjhOsXG-q2oeuFoqFrlnAOW9AJi8SZwt.ttf",
      "500italic":"http://fonts.gstatic.com/s/robotomono/v12/L0xoDF4xlVMF-BfR8bXMIjhOsXG-q2oeuFoqFrlVAOW9AJi8SZwt.ttf",
      "600italic":"http://fonts.gstatic.com/s/robotomono/v12/L0xoDF4xlVMF-BfR8bXMIjhOsXG-q2oeuFoqFrm5B-W9AJi8SZwt.ttf",
      "700italic":"http://fonts.gstatic.com/s/robotomono/v12/L0xoDF4xlVMF-BfR8bXMIjhOsXG-q2oeuFoqFrmAB-W9AJi8SZwt.ttf"
    },
    "category":"monospace",
    "kind":"webfonts#webfont"
  },
  {
    "family":"Noto Sans",
    "variants":[
      "regular",
      "italic",
      "700",
      "700italic"
    ],
    "subsets":[
      "cyrillic",
      "cyrillic-ext",
      "devanagari",
      "greek",
      "greek-ext",
      "latin",
      "latin-ext",
      "vietnamese"
    ],
    "version":"v11",
    "lastModified":"2020-09-25",
    "files":{
      "700":"http://fonts.gstatic.com/s/notosans/v11/o-0NIpQlx3QUlC5A4PNjXhFlY9aA5Wl6PQ.ttf",
      "regular":"http://fonts.gstatic.com/s/notosans/v11/o-0IIpQlx3QUlC5A4PNb4j5Ba_2c7A.ttf",
      "italic":"http://fonts.gstatic.com/s/notosans/v11/o-0OIpQlx3QUlC5A4PNr4DRFSfiM7HBj.ttf",
      "700italic":"http://fonts.gstatic.com/s/notosans/v11/o-0TIpQlx3QUlC5A4PNr4Az5ZtyEx2xqPaif.ttf"
    },
    "category":"sans-serif",
    "kind":"webfonts#webfont"
  },
  {
    "family":"PT Sans",
    "variants":[
      "regular",
      "italic",
      "700",
      "700italic"
    ],
    "subsets":[
      "cyrillic",
      "cyrillic-ext",
      "latin",
      "latin-ext"
    ],
    "version":"v12",
    "lastModified":"2020-09-15",
    "files":{
      "700":"http://fonts.gstatic.com/s/ptsans/v12/jizfRExUiTo99u79B_mh4OmnLD0Z4zM.ttf",
      "regular":"http://fonts.gstatic.com/s/ptsans/v12/jizaRExUiTo99u79P0WOxOGMMDQ.ttf",
      "italic":"http://fonts.gstatic.com/s/ptsans/v12/jizYRExUiTo99u79D0eEwMOJIDQA-g.ttf",
      "700italic":"http://fonts.gstatic.com/s/ptsans/v12/jizdRExUiTo99u79D0e8fOytKB8c8zMrig.ttf"
    },
    "category":"sans-serif",
    "kind":"webfonts#webfont"
  },
  {
    "family":"Roboto Slab",
    "variants":[
      "100",
      "200",
      "300",
      "regular",
      "500",
      "600",
      "700",
      "800",
      "900"
    ],
    "subsets":[
      "cyrillic",
      "cyrillic-ext",
      "greek",
      "greek-ext",
      "latin",
      "latin-ext",
      "vietnamese"
    ],
    "version":"v12",
    "lastModified":"2020-06-26",
    "files":{
      "100":"http://fonts.gstatic.com/s/robotoslab/v12/BngbUXZYTXPIvIBgJJSb6s3BzlRRfKOFbvjojIWWaG5iddG-1A.ttf",
      "200":"http://fonts.gstatic.com/s/robotoslab/v12/BngbUXZYTXPIvIBgJJSb6s3BzlRRfKOFbvjoDISWaG5iddG-1A.ttf",
      "300":"http://fonts.gstatic.com/s/robotoslab/v12/BngbUXZYTXPIvIBgJJSb6s3BzlRRfKOFbvjo0oSWaG5iddG-1A.ttf",
      "500":"http://fonts.gstatic.com/s/robotoslab/v12/BngbUXZYTXPIvIBgJJSb6s3BzlRRfKOFbvjovoSWaG5iddG-1A.ttf",
      "600":"http://fonts.gstatic.com/s/robotoslab/v12/BngbUXZYTXPIvIBgJJSb6s3BzlRRfKOFbvjoUoOWaG5iddG-1A.ttf",
      "700":"http://fonts.gstatic.com/s/robotoslab/v12/BngbUXZYTXPIvIBgJJSb6s3BzlRRfKOFbvjoa4OWaG5iddG-1A.ttf",
      "800":"http://fonts.gstatic.com/s/robotoslab/v12/BngbUXZYTXPIvIBgJJSb6s3BzlRRfKOFbvjoDIOWaG5iddG-1A.ttf",
      "900":"http://fonts.gstatic.com/s/robotoslab/v12/BngbUXZYTXPIvIBgJJSb6s3BzlRRfKOFbvjoJYOWaG5iddG-1A.ttf",
      "regular":"http://fonts.gstatic.com/s/robotoslab/v12/BngbUXZYTXPIvIBgJJSb6s3BzlRRfKOFbvjojISWaG5iddG-1A.ttf"
    },
    "category":"serif",
    "kind":"webfonts#webfont"
  },
  {
    "family":"Merriweather",
    "variants":[
      "300",
      "300italic",
      "regular",
      "italic",
      "700",
      "700italic",
      "900",
      "900italic"
    ],
    "subsets":[
      "cyrillic",
      "cyrillic-ext",
      "latin",
      "latin-ext",
      "vietnamese"
    ],
    "version":"v22",
    "lastModified":"2020-09-10",
    "files":{
      "300":"http://fonts.gstatic.com/s/merriweather/v22/u-4n0qyriQwlOrhSvowK_l521wRpX837pvjxPA.ttf",
      "700":"http://fonts.gstatic.com/s/merriweather/v22/u-4n0qyriQwlOrhSvowK_l52xwNpX837pvjxPA.ttf",
      "900":"http://fonts.gstatic.com/s/merriweather/v22/u-4n0qyriQwlOrhSvowK_l52_wFpX837pvjxPA.ttf",
      "300italic":"http://fonts.gstatic.com/s/merriweather/v22/u-4l0qyriQwlOrhSvowK_l5-eR7lXcf_hP3hPGWH.ttf",
      "regular":"http://fonts.gstatic.com/s/merriweather/v22/u-440qyriQwlOrhSvowK_l5OeyxNV-bnrw.ttf",
      "italic":"http://fonts.gstatic.com/s/merriweather/v22/u-4m0qyriQwlOrhSvowK_l5-eSZJdeP3r-Ho.ttf",
      "700italic":"http://fonts.gstatic.com/s/merriweather/v22/u-4l0qyriQwlOrhSvowK_l5-eR71Wsf_hP3hPGWH.ttf",
      "900italic":"http://fonts.gstatic.com/s/merriweather/v22/u-4l0qyriQwlOrhSvowK_l5-eR7NWMf_hP3hPGWH.ttf"
    },
    "category":"serif",
    "kind":"webfonts#webfont"
  },
  {
    "family":"Ubuntu",
    "variants":[
      "300",
      "300italic",
      "regular",
      "italic",
      "500",
      "500italic",
      "700",
      "700italic"
    ],
    "subsets":[
      "cyrillic",
      "cyrillic-ext",
      "greek",
      "greek-ext",
      "latin",
      "latin-ext"
    ],
    "version":"v15",
    "lastModified":"2020-09-10",
    "files":{
      "300":"http://fonts.gstatic.com/s/ubuntu/v15/4iCv6KVjbNBYlgoC1CzTt2aMH4V_gg.ttf",
      "500":"http://fonts.gstatic.com/s/ubuntu/v15/4iCv6KVjbNBYlgoCjC3Tt2aMH4V_gg.ttf",
      "700":"http://fonts.gstatic.com/s/ubuntu/v15/4iCv6KVjbNBYlgoCxCvTt2aMH4V_gg.ttf",
      "300italic":"http://fonts.gstatic.com/s/ubuntu/v15/4iCp6KVjbNBYlgoKejZftWyIPYBvgpUI.ttf",
      "regular":"http://fonts.gstatic.com/s/ubuntu/v15/4iCs6KVjbNBYlgo6eAT3v02QFg.ttf",
      "italic":"http://fonts.gstatic.com/s/ubuntu/v15/4iCu6KVjbNBYlgoKeg7znUiAFpxm.ttf",
      "500italic":"http://fonts.gstatic.com/s/ubuntu/v15/4iCp6KVjbNBYlgoKejYHtGyIPYBvgpUI.ttf",
      "700italic":"http://fonts.gstatic.com/s/ubuntu/v15/4iCp6KVjbNBYlgoKejZPsmyIPYBvgpUI.ttf"
    },
    "category":"sans-serif",
    "kind":"webfonts#webfont"
  },
  {
    "family":"Playfair Display",
    "variants":[
      "regular",
      "500",
      "600",
      "700",
      "800",
      "900",
      "italic",
      "500italic",
      "600italic",
      "700italic",
      "800italic",
      "900italic"
    ],
    "subsets":[
      "cyrillic",
      "latin",
      "latin-ext",
      "vietnamese"
    ],
    "version":"v21",
    "lastModified":"2020-06-26",
    "files":{
      "500":"http://fonts.gstatic.com/s/playfairdisplay/v21/nuFvD-vYSZviVYUb_rj3ij__anPXJzDwcbmjWBN2PKd3vUDQZNLo_U2r.ttf",
      "600":"http://fonts.gstatic.com/s/playfairdisplay/v21/nuFvD-vYSZviVYUb_rj3ij__anPXJzDwcbmjWBN2PKebukDQZNLo_U2r.ttf",
      "700":"http://fonts.gstatic.com/s/playfairdisplay/v21/nuFvD-vYSZviVYUb_rj3ij__anPXJzDwcbmjWBN2PKeiukDQZNLo_U2r.ttf",
      "800":"http://fonts.gstatic.com/s/playfairdisplay/v21/nuFvD-vYSZviVYUb_rj3ij__anPXJzDwcbmjWBN2PKfFukDQZNLo_U2r.ttf",
      "900":"http://fonts.gstatic.com/s/playfairdisplay/v21/nuFvD-vYSZviVYUb_rj3ij__anPXJzDwcbmjWBN2PKfsukDQZNLo_U2r.ttf",
      "regular":"http://fonts.gstatic.com/s/playfairdisplay/v21/nuFvD-vYSZviVYUb_rj3ij__anPXJzDwcbmjWBN2PKdFvUDQZNLo_U2r.ttf",
      "italic":"http://fonts.gstatic.com/s/playfairdisplay/v21/nuFRD-vYSZviVYUb_rj3ij__anPXDTnCjmHKM4nYO7KN_qiTbtbK-F2rA0s.ttf",
      "500italic":"http://fonts.gstatic.com/s/playfairdisplay/v21/nuFRD-vYSZviVYUb_rj3ij__anPXDTnCjmHKM4nYO7KN_pqTbtbK-F2rA0s.ttf",
      "600italic":"http://fonts.gstatic.com/s/playfairdisplay/v21/nuFRD-vYSZviVYUb_rj3ij__anPXDTnCjmHKM4nYO7KN_naUbtbK-F2rA0s.ttf",
      "700italic":"http://fonts.gstatic.com/s/playfairdisplay/v21/nuFRD-vYSZviVYUb_rj3ij__anPXDTnCjmHKM4nYO7KN_k-UbtbK-F2rA0s.ttf",
      "800italic":"http://fonts.gstatic.com/s/playfairdisplay/v21/nuFRD-vYSZviVYUb_rj3ij__anPXDTnCjmHKM4nYO7KN_iiUbtbK-F2rA0s.ttf",
      "900italic":"http://fonts.gstatic.com/s/playfairdisplay/v21/nuFRD-vYSZviVYUb_rj3ij__anPXDTnCjmHKM4nYO7KN_gGUbtbK-F2rA0s.ttf"
    },
    "category":"serif",
    "kind":"webfonts#webfont"
  },
  {
    "family":"Nunito",
    "variants":[
      "200",
      "200italic",
      "300",
      "300italic",
      "regular",
      "italic",
      "600",
      "600italic",
      "700",
      "700italic",
      "800",
      "800italic",
      "900",
      "900italic"
    ],
    "subsets":[
      "cyrillic",
      "cyrillic-ext",
      "latin",
      "latin-ext",
      "vietnamese"
    ],
    "version":"v14",
    "lastModified":"2020-09-10",
    "files":{
      "200":"http://fonts.gstatic.com/s/nunito/v14/XRXW3I6Li01BKofA-sekZuHJeTsfDQ.ttf",
      "300":"http://fonts.gstatic.com/s/nunito/v14/XRXW3I6Li01BKofAnsSkZuHJeTsfDQ.ttf",
      "600":"http://fonts.gstatic.com/s/nunito/v14/XRXW3I6Li01BKofA6sKkZuHJeTsfDQ.ttf",
      "700":"http://fonts.gstatic.com/s/nunito/v14/XRXW3I6Li01BKofAjsOkZuHJeTsfDQ.ttf",
      "800":"http://fonts.gstatic.com/s/nunito/v14/XRXW3I6Li01BKofAksCkZuHJeTsfDQ.ttf",
      "900":"http://fonts.gstatic.com/s/nunito/v14/XRXW3I6Li01BKofAtsGkZuHJeTsfDQ.ttf",
      "200italic":"http://fonts.gstatic.com/s/nunito/v14/XRXQ3I6Li01BKofIMN5MZ-vNWz4PDWtj.ttf",
      "300italic":"http://fonts.gstatic.com/s/nunito/v14/XRXQ3I6Li01BKofIMN4oZOvNWz4PDWtj.ttf",
      "regular":"http://fonts.gstatic.com/s/nunito/v14/XRXV3I6Li01BKof4MuyAbsrVcA.ttf",
      "italic":"http://fonts.gstatic.com/s/nunito/v14/XRXX3I6Li01BKofIMOaETM_FcCIG.ttf",
      "600italic":"http://fonts.gstatic.com/s/nunito/v14/XRXQ3I6Li01BKofIMN5cYuvNWz4PDWtj.ttf",
      "700italic":"http://fonts.gstatic.com/s/nunito/v14/XRXQ3I6Li01BKofIMN44Y-vNWz4PDWtj.ttf",
      "800italic":"http://fonts.gstatic.com/s/nunito/v14/XRXQ3I6Li01BKofIMN4kYOvNWz4PDWtj.ttf",
      "900italic":"http://fonts.gstatic.com/s/nunito/v14/XRXQ3I6Li01BKofIMN4AYevNWz4PDWtj.ttf"
    },
    "category":"sans-serif",
    "kind":"webfonts#webfont"
  },
  {
    "family":"Open Sans Condensed",
    "variants":[
      "300",
      "300italic",
      "700"
    ],
    "subsets":[
      "cyrillic",
      "cyrillic-ext",
      "greek",
      "greek-ext",
      "latin",
      "latin-ext",
      "vietnamese"
    ],
    "version":"v15",
    "lastModified":"2020-09-10",
    "files":{
      "300":"http://fonts.gstatic.com/s/opensanscondensed/v15/z7NFdQDnbTkabZAIOl9il_O6KJj73e7Ff1GhPuLGRpWRyAs.ttf",
      "700":"http://fonts.gstatic.com/s/opensanscondensed/v15/z7NFdQDnbTkabZAIOl9il_O6KJj73e7Ff0GmPuLGRpWRyAs.ttf",
      "300italic":"http://fonts.gstatic.com/s/opensanscondensed/v15/z7NHdQDnbTkabZAIOl9il_O6KJj73e7Fd_-7suDMQreU2AsJSg.ttf"
    },
    "category":"sans-serif",
    "kind":"webfonts#webfont"
  },
  {
    "family":"Lora",
    "variants":[
      "regular",
      "500",
      "600",
      "700",
      "italic",
      "500italic",
      "600italic",
      "700italic"
    ],
    "subsets":[
      "cyrillic",
      "cyrillic-ext",
      "latin",
      "latin-ext",
      "vietnamese"
    ],
    "version":"v16",
    "lastModified":"2020-06-26",
    "files":{
      "500":"http://fonts.gstatic.com/s/lora/v16/0QI6MX1D_JOuGQbT0gvTJPa787wsuyJGmKxemMeZ.ttf",
      "600":"http://fonts.gstatic.com/s/lora/v16/0QI6MX1D_JOuGQbT0gvTJPa787zAvCJGmKxemMeZ.ttf",
      "700":"http://fonts.gstatic.com/s/lora/v16/0QI6MX1D_JOuGQbT0gvTJPa787z5vCJGmKxemMeZ.ttf",
      "regular":"http://fonts.gstatic.com/s/lora/v16/0QI6MX1D_JOuGQbT0gvTJPa787weuyJGmKxemMeZ.ttf",
      "italic":"http://fonts.gstatic.com/s/lora/v16/0QI8MX1D_JOuMw_hLdO6T2wV9KnW-MoFkqh8ndeZzZ0.ttf",
      "500italic":"http://fonts.gstatic.com/s/lora/v16/0QI8MX1D_JOuMw_hLdO6T2wV9KnW-PgFkqh8ndeZzZ0.ttf",
      "600italic":"http://fonts.gstatic.com/s/lora/v16/0QI8MX1D_JOuMw_hLdO6T2wV9KnW-BQCkqh8ndeZzZ0.ttf",
      "700italic":"http://fonts.gstatic.com/s/lora/v16/0QI8MX1D_JOuMw_hLdO6T2wV9KnW-C0Ckqh8ndeZzZ0.ttf"
    },
    "category":"serif",
    "kind":"webfonts#webfont"
  },
  {
    "family":"Work Sans",
    "variants":[
      "100",
      "200",
      "300",
      "regular",
      "500",
      "600",
      "700",
      "800",
      "900",
      "100italic",
      "200italic",
      "300italic",
      "italic",
      "500italic",
      "600italic",
      "700italic",
      "800italic",
      "900italic"
    ],
    "subsets":[
      "latin",
      "latin-ext",
      "vietnamese"
    ],
    "version":"v8",
    "lastModified":"2020-06-26",
    "files":{
      "100":"http://fonts.gstatic.com/s/worksans/v8/QGY_z_wNahGAdqQ43RhVcIgYT2Xz5u32K0nWNigDp6_cOyA.ttf",
      "200":"http://fonts.gstatic.com/s/worksans/v8/QGY_z_wNahGAdqQ43RhVcIgYT2Xz5u32K8nXNigDp6_cOyA.ttf",
      "300":"http://fonts.gstatic.com/s/worksans/v8/QGY_z_wNahGAdqQ43RhVcIgYT2Xz5u32KxfXNigDp6_cOyA.ttf",
      "500":"http://fonts.gstatic.com/s/worksans/v8/QGY_z_wNahGAdqQ43RhVcIgYT2Xz5u32K3vXNigDp6_cOyA.ttf",
      "600":"http://fonts.gstatic.com/s/worksans/v8/QGY_z_wNahGAdqQ43RhVcIgYT2Xz5u32K5fQNigDp6_cOyA.ttf",
      "700":"http://fonts.gstatic.com/s/worksans/v8/QGY_z_wNahGAdqQ43RhVcIgYT2Xz5u32K67QNigDp6_cOyA.ttf",
      "800":"http://fonts.gstatic.com/s/worksans/v8/QGY_z_wNahGAdqQ43RhVcIgYT2Xz5u32K8nQNigDp6_cOyA.ttf",
      "900":"http://fonts.gstatic.com/s/worksans/v8/QGY_z_wNahGAdqQ43RhVcIgYT2Xz5u32K-DQNigDp6_cOyA.ttf",
      "regular":"http://fonts.gstatic.com/s/worksans/v8/QGY_z_wNahGAdqQ43RhVcIgYT2Xz5u32K0nXNigDp6_cOyA.ttf",
      "100italic":"http://fonts.gstatic.com/s/worksans/v8/QGY9z_wNahGAdqQ43Rh_ebrnlwyYfEPxPoGU3moJo43ZKyDSQQ.ttf",
      "200italic":"http://fonts.gstatic.com/s/worksans/v8/QGY9z_wNahGAdqQ43Rh_ebrnlwyYfEPxPoGUXmsJo43ZKyDSQQ.ttf",
      "300italic":"http://fonts.gstatic.com/s/worksans/v8/QGY9z_wNahGAdqQ43Rh_ebrnlwyYfEPxPoGUgGsJo43ZKyDSQQ.ttf",
      "italic":"http://fonts.gstatic.com/s/worksans/v8/QGY9z_wNahGAdqQ43Rh_ebrnlwyYfEPxPoGU3msJo43ZKyDSQQ.ttf",
      "500italic":"http://fonts.gstatic.com/s/worksans/v8/QGY9z_wNahGAdqQ43Rh_ebrnlwyYfEPxPoGU7GsJo43ZKyDSQQ.ttf",
      "600italic":"http://fonts.gstatic.com/s/worksans/v8/QGY9z_wNahGAdqQ43Rh_ebrnlwyYfEPxPoGUAGwJo43ZKyDSQQ.ttf",
      "700italic":"http://fonts.gstatic.com/s/worksans/v8/QGY9z_wNahGAdqQ43Rh_ebrnlwyYfEPxPoGUOWwJo43ZKyDSQQ.ttf",
      "800italic":"http://fonts.gstatic.com/s/worksans/v8/QGY9z_wNahGAdqQ43Rh_ebrnlwyYfEPxPoGUXmwJo43ZKyDSQQ.ttf",
      "900italic":"http://fonts.gstatic.com/s/worksans/v8/QGY9z_wNahGAdqQ43Rh_ebrnlwyYfEPxPoGUd2wJo43ZKyDSQQ.ttf"
    },
    "category":"sans-serif",
    "kind":"webfonts#webfont"
  },
  {
    "family":"PT Serif",
    "variants":[
      "regular",
      "italic",
      "700",
      "700italic"
    ],
    "subsets":[
      "cyrillic",
      "cyrillic-ext",
      "latin",
      "latin-ext"
    ],
    "version":"v12",
    "lastModified":"2020-09-10",
    "files":{
      "700":"http://fonts.gstatic.com/s/ptserif/v12/EJRSQgYoZZY2vCFuvAnt65qVXSr3pNNB.ttf",
      "regular":"http://fonts.gstatic.com/s/ptserif/v12/EJRVQgYoZZY2vCFuvDFRxL6ddjb-.ttf",
      "italic":"http://fonts.gstatic.com/s/ptserif/v12/EJRTQgYoZZY2vCFuvAFTzrq_cyb-vco.ttf",
      "700italic":"http://fonts.gstatic.com/s/ptserif/v12/EJRQQgYoZZY2vCFuvAFT9gaQVy7VocNB6Iw.ttf"
    },
    "category":"serif",
    "kind":"webfonts#webfont"
  },
  {
    "family":"Rubik",
    "variants":[
      "300",
      "regular",
      "500",
      "600",
      "700",
      "800",
      "900",
      "300italic",
      "italic",
      "500italic",
      "600italic",
      "700italic",
      "800italic",
      "900italic"
    ],
    "subsets":[
      "cyrillic",
      "cyrillic-ext",
      "hebrew",
      "latin",
      "latin-ext"
    ],
    "version":"v11",
    "lastModified":"2020-09-29",
    "files":{
      "300":"http://fonts.gstatic.com/s/rubik/v11/iJWZBXyIfDnIV5PNhY1KTN7Z-Yh-WYi1UE80V4bVkA.ttf",
      "500":"http://fonts.gstatic.com/s/rubik/v11/iJWZBXyIfDnIV5PNhY1KTN7Z-Yh-NYi1UE80V4bVkA.ttf",
      "600":"http://fonts.gstatic.com/s/rubik/v11/iJWZBXyIfDnIV5PNhY1KTN7Z-Yh-2Y-1UE80V4bVkA.ttf",
      "700":"http://fonts.gstatic.com/s/rubik/v11/iJWZBXyIfDnIV5PNhY1KTN7Z-Yh-4I-1UE80V4bVkA.ttf",
      "800":"http://fonts.gstatic.com/s/rubik/v11/iJWZBXyIfDnIV5PNhY1KTN7Z-Yh-h4-1UE80V4bVkA.ttf",
      "900":"http://fonts.gstatic.com/s/rubik/v11/iJWZBXyIfDnIV5PNhY1KTN7Z-Yh-ro-1UE80V4bVkA.ttf",
      "regular":"http://fonts.gstatic.com/s/rubik/v11/iJWZBXyIfDnIV5PNhY1KTN7Z-Yh-B4i1UE80V4bVkA.ttf",
      "300italic":"http://fonts.gstatic.com/s/rubik/v11/iJWbBXyIfDnIV7nEt3KSJbVDV49rz8sDE0UwdYPFkJ1O.ttf",
      "italic":"http://fonts.gstatic.com/s/rubik/v11/iJWbBXyIfDnIV7nEt3KSJbVDV49rz8tdE0UwdYPFkJ1O.ttf",
      "500italic":"http://fonts.gstatic.com/s/rubik/v11/iJWbBXyIfDnIV7nEt3KSJbVDV49rz8tvE0UwdYPFkJ1O.ttf",
      "600italic":"http://fonts.gstatic.com/s/rubik/v11/iJWbBXyIfDnIV7nEt3KSJbVDV49rz8uDFEUwdYPFkJ1O.ttf",
      "700italic":"http://fonts.gstatic.com/s/rubik/v11/iJWbBXyIfDnIV7nEt3KSJbVDV49rz8u6FEUwdYPFkJ1O.ttf",
      "800italic":"http://fonts.gstatic.com/s/rubik/v11/iJWbBXyIfDnIV7nEt3KSJbVDV49rz8vdFEUwdYPFkJ1O.ttf",
      "900italic":"http://fonts.gstatic.com/s/rubik/v11/iJWbBXyIfDnIV7nEt3KSJbVDV49rz8v0FEUwdYPFkJ1O.ttf"
    },
    "category":"sans-serif",
    "kind":"webfonts#webfont"
  },
  {
    "family":"Noto Sans TC",
    "variants":[
      "100",
      "300",
      "regular",
      "500",
      "700",
      "900"
    ],
    "subsets":[
      "chinese-traditional",
      "latin"
    ],
    "version":"v10",
    "lastModified":"2020-03-05",
    "files":{
      "100":"http://fonts.gstatic.com/s/notosanstc/v10/-nFlOG829Oofr2wohFbTp9i9WyEJIfNZ1sjy.otf",
      "300":"http://fonts.gstatic.com/s/notosanstc/v10/-nFkOG829Oofr2wohFbTp9i9kwMvDd1V39Hr7g.otf",
      "500":"http://fonts.gstatic.com/s/notosanstc/v10/-nFkOG829Oofr2wohFbTp9i9ywIvDd1V39Hr7g.otf",
      "700":"http://fonts.gstatic.com/s/notosanstc/v10/-nFkOG829Oofr2wohFbTp9i9gwQvDd1V39Hr7g.otf",
      "900":"http://fonts.gstatic.com/s/notosanstc/v10/-nFkOG829Oofr2wohFbTp9i9uwYvDd1V39Hr7g.otf",
      "regular":"http://fonts.gstatic.com/s/notosanstc/v10/-nF7OG829Oofr2wohFbTp9iFOSsLA_ZJ1g.otf"
    },
    "category":"sans-serif",
    "kind":"webfonts#webfont"
  },
  {
    "family":"Mukta",
    "variants":[
      "200",
      "300",
      "regular",
      "500",
      "600",
      "700",
      "800"
    ],
    "subsets":[
      "devanagari",
      "latin",
      "latin-ext"
    ],
    "version":"v7",
    "lastModified":"2019-07-17",
    "files":{
      "200":"http://fonts.gstatic.com/s/mukta/v7/iJWHBXyXfDDVXbEOjFma-2HW7ZB_.ttf",
      "300":"http://fonts.gstatic.com/s/mukta/v7/iJWHBXyXfDDVXbFqj1ma-2HW7ZB_.ttf",
      "500":"http://fonts.gstatic.com/s/mukta/v7/iJWHBXyXfDDVXbEyjlma-2HW7ZB_.ttf",
      "600":"http://fonts.gstatic.com/s/mukta/v7/iJWHBXyXfDDVXbEeiVma-2HW7ZB_.ttf",
      "700":"http://fonts.gstatic.com/s/mukta/v7/iJWHBXyXfDDVXbF6iFma-2HW7ZB_.ttf",
      "800":"http://fonts.gstatic.com/s/mukta/v7/iJWHBXyXfDDVXbFmi1ma-2HW7ZB_.ttf",
      "regular":"http://fonts.gstatic.com/s/mukta/v7/iJWKBXyXfDDVXYnGp32S0H3f.ttf"
    },
    "category":"sans-serif",
    "kind":"webfonts#webfont"
  }
];

/**
 * @returns {Promise|Promise<void>}
 */
const fetchGoogleFonts = () => {
  if (googleFonts !== null) {
    return Promise.resolve(googleFonts);
  }

  return new Promise((resolve, reject) => {
    fetch('https://www.googleapis.com/webfonts/v1/webfonts?key=AIzaSyBOtBNepb6sGwCdvY41Z7KlmOnWKbhLPto&sort=POPULARITY')
      .then(resp => resp.json())
      .then((json) => {
        googleFonts = json.items.splice(0, 25);
        resolve(json);
      })
      .catch(reject);
  });
};

export default {
  fetchGoogleFonts
};
