import * as types from '@builder/actions/templateActions';
import { objects } from 'utils';

const initialState = objects.merge({
  templates: [],
  selected:  1
}, window.initialState.template);

/**
 * @param {*} state
 * @param {*} action
 */
const onLoad = (state, action) => {
  const templates = Array.from(action.templates);

  return {
    ...state,
    templates
  };
};

/**
 * @param {*} state
 * @param {*} action
 */
const onSelect = (state, action) => {
  return {
    ...state,
    selected: action.id
  };
};

const handlers = {
  [types.TEMPLATE_LOAD]:   onLoad,
  [types.TEMPLATE_SELECT]: onSelect
};

/**
 * @param {*} state
 * @param {*} action
 * @returns {*}
 */
export default function templateReducer(state = objects.clone(initialState), action = {}) {
  if (handlers[action.type]) {
    return handlers[action.type].call(null, state, action);
  }

  return state;
}
