import React from 'react';
import PropTypes from 'prop-types';

/**
 *
 */
class Label extends React.PureComponent {
  static propTypes = {
    htmlFor:   PropTypes.string.isRequired,
    required:  PropTypes.bool,
    children:  PropTypes.node,
    className: PropTypes.string
  };

  static defaultProps = {
    required:  false,
    children:  '',
    className: ''
  };

  /**
   * @returns {*}
   */
  render() {
    const { htmlFor, required, children, className, ...props } = this.props;

    if (!children) {
      return null;
    }

    return (
      <label htmlFor={htmlFor} className={className} {...props}> {/* eslint-disable-line */}
        {children}
        {required && (
          <span className="forms-required">*</span>
        )}
      </label>
    );
  }
}

export default Label;
