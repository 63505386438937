import { api, router, alerts, builder, arrays, objects } from 'utils';
import { commonValues } from '@builder/reducers/formsReducer';
import blockDependencies from '@builder/store/blockDependencies';
import { libraryCallFunction, libraryAddPixelByName } from './libraryActions';
import { loadTemplateStylesheet, appendBlockStyles } from './templateActions';
import { uiModal, uiToggleSection, uiChangePreviewSection, uiLeftSidebarTab, uiSlideUp } from './uiActions';
import { formError } from './formActions';

export const LISTICLE_BUSY                   = 'LISTICLE_BUSY';
export const LISTICLE_UNDO                   = 'LISTICLE_UNDO';
export const LISTICLE_INIT                   = 'LISTICLE_INIT';
export const LISTICLE_PUSH_STATE             = 'LISTICLE_PUSH_STATE';
export const LISTICLE_DROP                   = 'LISTICLE_DROP';
export const LISTICLE_SET_DRAGGING           = 'LISTICLE_SET_DRAGGING';
export const LISTICLE_DROP_HEADER            = 'LISTICLE_DROP_HEADER';
export const LISTICLE_INSERT_BLOCK           = 'LISTICLE_INSERT_BLOCK';
export const LISTICLE_SAVE                   = 'LISTICLE_SAVE';
export const LISTICLE_BLOCK_CLONE            = 'LISTICLE_BLOCK_CLONE';
export const LISTICLE_BLOCK_MOVE             = 'LISTICLE_BLOCK_MOVE';
export const LISTICLE_CHANGED                = 'LISTICLE_CHANGED';
export const LISTICLE_ADD_BUILDER_BLOCK      = 'LISTICLE_ADD_BUILDER_BLOCK';
export const LISTICLE_UPDATE_WIDGETS         = 'LISTICLE_UPDATE_WIDGETS';
export const LISTICLE_PREVIEW_COUNT          = 'LISTICLE_PREVIEW_COUNT';
export const LISTICLE_UPDATE_BLOCK           = 'LISTICLE_UPDATE_BLOCK';
export const LISTICLE_UPDATE_BLOCK_ALL       = 'LISTICLE_UPDATE_BLOCK_ALL';
export const LISTICLE_SET_TEMPLATE           = 'LISTICLE_SET_TEMPLATE';
export const LISTICLE_UPDATE_BLOCK_GROUP     = 'LISTICLE_UPDATE_BLOCK_GROUP';
export const LISTICLE_UPDATE_TEST_SETTINGS   = 'LISTICLE_UPDATE_TEST_SETTINGS';
export const LISTICLE_DELETE_BLOCK_GROUP     = 'LISTICLE_DELETE_BLOCK_GROUP';
export const LISTICLE_UPDATE_LISTICLE        = 'LISTICLE_UPDATE_LISTICLE';
export const LISTICLE_ACTIVATE_BLOCK         = 'LISTICLE_ACTIVATE_BLOCK';
export const LISTICLE_HOVER_BLOCK            = 'LISTICLE_HOVER_BLOCK';
export const LISTICLE_SELECT_BLOCK           = 'LISTICLE_SELECT_BLOCK';
export const LISTICLE_ADD_BLOCK_VARIATION    = 'LISTICLE_ADD_BLOCK_VARIATION';
export const LISTICLE_REMOVE_BLOCK_VARIATION = 'LISTICLE_REMOVE_BLOCK_VARIATION';
export const LISTICLE_UPDATE_EDITOR_STATE    = 'LISTICLE_UPDATE_EDITOR_STATE';
export const LISTICLE_UPDATE_HEADLINE_VALUE  = 'LISTICLE_UPDATE_HEADLINE_VALUE';
export const LISTICLE_UPDATE_OFFER           = 'LISTICLE_UPDATE_OFFER';
export const LISTICLE_REPLACE_OFFER          = 'LISTICLE_REPLACE_OFFER';
export const LISTICLE_SET_DEFAULT_BLOCK      = 'LISTICLE_SET_DEFAULT_BLOCK';
export const LISTICLE_TOGGLE_CANVAS_VIEW     = 'LISTICLE_TOGGLE_CANVAS_VIEW';
export const LISTICLE_UPDATE_PREVIEW_URL     = 'LISTICLE_UPDATE_PREVIEW_URL';
export const LISTICLE_SET_COMPACT_VIEW       = 'LISTICLE_SET_COMPACT_VIEW';
export const LISTICLE_SET_DESIGN_VIEW        = 'LISTICLE_SET_DESIGN_VIEW';
export const LISTICLE_INCREMENT_WORD_COUNT   = 'LISTICLE_INCREMENT_WORD_COUNT';
export const LISTICLE_SET_MARGIN_HOVERING    = 'LISTICLE_MARGIN_EDITOR_HOVERING';
export const LISTICLE_SET_FOOTER_EDITING     = 'LISTICLE_SET_FOOTER_EDITING';
export const LISTICLE_SET_HEADER_EDITING     = 'LISTICLE_SET_HEADER_EDITING';
export const LISTICLE_SET_HEADER_COL_EDITING = 'LISTICLE_SET_HEADER_COL_EDITING';
export const LISTICLE_UPDATE_HEADER          = 'LISTICLE_UPDATE_HEADER';
export const LISTICLE_SET_ACTIVE_COL_EDITING = 'LISTICLE_SET_ACTIVE_COL_EDITING';
export const LISTICLE_CLONE_STYLES_TO_ALL    = 'LISTICLE_CLONE_STYLES_TO_ALL';
export const LISTICLE_COPY_SELECTED_BLOCKS   = 'LISTICLE_COPY_SELECTED_BLOCKS';
export const LISTICLE_DELETE_SELECTED_BLOCKS = 'LISTICLE_DELETE_SELECTED_BLOCKS';
export const LISTICLE_UPDATE_SELECTED        = 'LISTICLE_UPDATE_SELECTED';
export const LISTICLE_CLONE_SELECTED         = 'LISTICLE_CLONE_SELECTED';
export const LISTICLE_GROUP_SELECTED         = 'LISTICLE_GROUP_SELECTED';
export const LISTICLE_MOVE_SELECTED          = 'LISTICLE_MOVE_SELECTED';
export const LISTICLE_UNGROUP_BLOCK          = 'LISTICLE_UNGROUP_BLOCK';
export const LISTICLE_ACTIVATE_BLOCK_STYLES  = 'LISTICLE_ACTIVATE_BLOCK_STYLES';
export const LISTICLE_IMPORT_LISTICLE        = 'LISTICLE_IMPORT_LISTICLE';
export const LISTICLE_IMPORT_GLOBAL_STYLES   = 'LISTICLE_IMPORT_GLOBAL_STYLES';
export const LISTICLE_CLEAR_BLOCK_GROUPS     = 'LISTICLE_CLEAR_BLOCK_GROUPS';
export const LISTICLE_SAVING_PREVIEW         = 'LISTICLE_SAVING_PREVIEW';

/**
 * @param {*} listicle
 * @returns {*}
 */
const serializeListicle = (listicle) => {
  const now = Math.round((new Date()).getTime() / 1000);

  return {
    id:                 listicle.id,
    title:              listicle.title,
    route:              listicle.route,
    isOnline:           listicle.isOnline,
    anchor:             listicle.anchor,
    header:             listicle.header,
    styles:             listicle.styles,
    pixels:             listicle.pixels,
    bumpedVer:          listicle.bumpedVer,
    template:           listicle.template.id,
    headerScripts:      listicle.headerScripts,
    footerScripts:      listicle.footerScripts,
    footerLinks:        listicle.footerLinks,
    footerHtml:         listicle.footerHtml,
    affiliateUrl:       listicle.affiliateUrl,
    offerSplitting:     listicle.offerSplitting,
    linkTarget:         listicle.linkTarget,
    slideshow:          listicle.slideshow,
    globalStyles:       listicle.globalStyles,
    variationSplitting: listicle.variationSplitting,
    blockGroups:        listicle.blockGroups,
    sidebarBlockGroups: listicle.sidebarBlockGroups,
    templateSettings:   listicle.templateSettings,
    blocksRemovedCount: listicle.blocksRemovedCount,
    blocksAddedCount:   listicle.blocksAddedCount,
    wordCount:          listicle.wordCount,
    timeElapsed:        (now - listicle.timeStarted)
  };
};

let savePreviewEnabled = true;

export const setSavePreviewEnabled = (enabled) => {
  savePreviewEnabled = enabled;
};

/**
 * @param {boolean} isBusy
 * @returns {{type: string, isBusy: *}}
 */
export const busy = (isBusy) => {
  return {
    type: LISTICLE_BUSY,
    isBusy
  };
};

let savePreviewTimeout = 0;

/**
 * @param {Function|boolean} cb
 * @returns {Function}
 */
export const savePreview = (cb = null) => {
  if (!savePreviewEnabled) {
    return {
      type: 'noop'
    };
  }

  return (dispatch, getState) => {
    /**
     *
     */
    const go = () => {
      dispatch({
        type:            LISTICLE_SAVING_PREVIEW,
        isSavingPreview: true,
      });

      const { listicle } = getState();
      const body = serializeListicle(listicle);
      api.post(router.generate('previews_lander_temp_save', { hash: listicle.previewHash }), body)
        .then((resp) => {
          if (cb === true) {
            dispatch({
              type:       LISTICLE_UPDATE_PREVIEW_URL,
              previewURL: resp.url
            });
          } else if (typeof cb === 'function') {
            cb(resp);
          }
        })
        .finally(() => {
          dispatch({
            type:            LISTICLE_SAVING_PREVIEW,
            isSavingPreview: false,
          });
        });
    };

    if (cb) {
      go();
    } else {
      clearTimeout(savePreviewTimeout);
      savePreviewTimeout = setTimeout(go, 1000);
    }
  };
};

/**
 * @returns {{type: string}}
 */
export const clearBlockGroups = () => {
  return {
    type: LISTICLE_CLEAR_BLOCK_GROUPS
  };
};

/**
 * @returns {Function}
 */
export const builderInit = () => {
  return (dispatch, getState) => {
    dispatch({
      type:     LISTICLE_INIT,
      products: getState().products.products
    });
    dispatch(savePreview(true));
  };
};

/**
 * @returns {Function}
 */
export const undo = () => {
  return (dispatch, getState) => {
    const { listicle } = getState();
    dispatch({
      type: LISTICLE_UNDO
    });
    dispatch(appendBlockStyles());
    dispatch(savePreview());

    const { listicle: listicle2 } = getState();
    if (listicle.template.id !== listicle2.template.id) {
      dispatch(loadTemplateStylesheet(listicle2.template.id));
    }
  };
};

/**
 * @param {string} field
 * @returns {{type: string}}
 */
export const listiclePushState = (field) => {
  return {
    type: LISTICLE_PUSH_STATE,
    field
  };
};

/**
 * @param {boolean} isChanged
 *
 * @returns {{type: string}}
 */
export const changed = (isChanged = true) => {
  return (dispatch) => {
    dispatch({
      type: LISTICLE_CHANGED,
      isChanged
    });
    dispatch(savePreview());
  };
};

/**
 * @param {*} draggable
 * @param {Function} cb
 * @returns {{type: string, title: *}}
 */
export const drop = (draggable, cb = null) => {
  return (dispatch, getState) => {
    const { listicle, products, widgets } = getState();

    dispatch({
      type:     LISTICLE_DROP,
      widgets:  widgets.widgets,
      products: products.products,
      draggable,
      cb
    });
    dispatch(appendBlockStyles());
    dispatch(savePreview());

    // Remove the product from the queue if this draggable is a product.
    if (draggable.source.droppableId === 'sidebarProducts' && draggable.draggableId.indexOf('queued-') === 0) {
      const productID = parseInt(draggable.draggableId.replace('queued-', ''), 10);
      api.req(
        'DELETE',
        router.generate('landers_dequeue_products', { id: listicle.anchor.id }),
        { productID }
      );
    }

    if (draggable.source) {
      if (draggable.source.droppableId === 'sidebarLayouts') {
        dispatch(uiLeftSidebarTab('blocks'));
      } else if (draggable.source.droppableId === 'builderBlocks') {
        const { draggableId } = draggable;
        if (blockDependencies[draggableId]) {
          blockDependencies[draggableId].forEach((libraryName) => {
            dispatch(libraryAddPixelByName(libraryName));
          });
        }
      }
    }
  };
};

/**
 * @param {boolean} isDragging
 * @returns {{type: *, isDragging: *}}
 */
export const setDragging = (isDragging) => {
  return {
    type: LISTICLE_SET_DRAGGING,
    isDragging
  };
};

/**
 * @param {*} draggable
 * @returns {{type: string}}
 */
export const dropHeader = (draggable) => {
  return (dispatch, getState) => {
    const { widgets } = getState();

    dispatch({
      type:    LISTICLE_DROP_HEADER,
      widgets: widgets.widgets,
      draggable
    });
    dispatch(savePreview());
  };
};

/**
 * @param {*} block
 * @param {number} index
 * @param {boolean} preview
 * @returns {Function}
 */
export const insertBlock = (block, index, preview = true) => {
  return (dispatch) => {
    dispatch({
      type: LISTICLE_INSERT_BLOCK,
      block,
      index
    });
    if (preview) {
      dispatch(savePreview());
    }
  };
};

/**
 * @param {*} block
 * @returns {{block: *, type: string}}
 */
export const addBuilderBlock = (block) => {
  return {
    type: LISTICLE_ADD_BUILDER_BLOCK,
    block
  };
};

/**
 * @param {number} activeBlockID
 * @param {HTMLElement} activeBlockElement
 * @returns {{block: *, type: string}}
 */
export const activateBlock = (activeBlockID, activeBlockElement) => {
  return (dispatch, getState) => {
    const { listicle } = getState();

    if (activeBlockID !== 0 && listicle.activeBlockID !== 0) {
      dispatch(activateBlock(0, null));
      setTimeout(() => {
        dispatch(activateBlock(activeBlockID, activeBlockElement));
      }, 250);
    } else {
      dispatch({
        type: LISTICLE_ACTIVATE_BLOCK,
        activeBlockID,
        activeBlockElement
      });
    }
  };
};

/**
 * @param {number} hoverBlockID
 * @returns {{block: *, type: string}}
 */
export const hoverBlock = (hoverBlockID) => {
  return {
    type: LISTICLE_HOVER_BLOCK,
    hoverBlockID
  };
};

/**
 * @param {number} blockID
 * @returns {{type: string}}
 */
export const selectBlock = (blockID) => {
  return (dispatch, getState) => {
    const { listicle } = getState();

    if (listicle.activeBlockID !== 0) {
      dispatch(activateBlock(0, null));
    }
    dispatch({
      type: LISTICLE_SELECT_BLOCK,
      blockID
    });
  };
};

/**
 * @returns {{type: string}}
 */
export const copySelectedBlocks = () => {
  return (dispatch) => {
    dispatch({
      type: LISTICLE_COPY_SELECTED_BLOCKS
    });
  };
};

/**
 * @returns {{type: string}}
 */
export const deleteSelectedBlocks = () => {
  return (dispatch) => {
    dispatch({
      type: LISTICLE_DELETE_SELECTED_BLOCKS
    });
    dispatch(savePreview());
  };
};

/**
 * @param {EditorState} editorState
 * @returns {{type: string, editorState: *}}
 */
export const updateEditorState = (editorState) => {
  return {
    type: LISTICLE_UPDATE_EDITOR_STATE,
    editorState
  };
};

let templateSettingsTimeout = null;

/**
 * @param {string} key
 * @param {string|number} value
 * @param {boolean} updatePreview
 * @param {boolean} pushState
 * @returns {{type: string, key: *, value: *}}
 */
export const updateListicle = (key, value, updatePreview = true, pushState = true) => {
  return (dispatch, getState) => {
    const { site, listicle } = getState();

    if (key === 'site') {
      value = arrays.findByID(site.sites, parseInt(value, 10));
    } else if (key === 'headerScripts') {
      builder.injectHeaderScripts(value);
    }

    dispatch({
      type: LISTICLE_UPDATE_LISTICLE,
      pushState,
      key,
      value
    });

    if (updatePreview) {
      dispatch(savePreview());
      if (!listicle.isCanvasView && key === 'templateSettings') {
        clearTimeout(templateSettingsTimeout);
        templateSettingsTimeout = setTimeout(() => {
          dispatch({
            type:         LISTICLE_PREVIEW_COUNT,
            previewCount: listicle.previewCount + 1
          });
        }, 500);
      }
    }

    if (key === 'site') {
      dispatch(loadTemplateStylesheet());
    } else if (key === 'styles' || key === 'templateSettings' || key === 'globalStyles') {
      dispatch(appendBlockStyles());
    }
  };
};

/**
 * @param {string} headlineValue
 * @returns {{type: string, headlineValue: *}}
 */
export const updateHeadlineValue = (headlineValue) => {
  return (dispatch) => {
    dispatch({
      type: LISTICLE_UPDATE_HEADLINE_VALUE,
      headlineValue
    });
    dispatch(savePreview());
  };
};

/**
 * @param {string} key
 * @param {string} value
 * @returns {{type: string, key: *, value: *}}
 */
export const updateOffer = (key, value) => {
  return (dispatch) => {
    dispatch({
      type: LISTICLE_UPDATE_OFFER,
      key,
      value
    });
    dispatch(savePreview());
  };
};

/**
 * @param {*} offer
 * @returns {{offer: *, type: string}}
 */
export const replaceOffer = (offer) => {
  return (dispatch) => {
    dispatch({
      type: LISTICLE_REPLACE_OFFER,
      offer
    });
    dispatch(savePreview());
  };
};

/**
 * @param {number} id
 * @param {number} blockGroupID
 * @param {string} key
 * @param {string} value
 * @returns {{id: *, type: string, value: *, key: *}}
 */
export const updateBlock = (id, blockGroupID, key, value) => {
  return async (dispatch) => {
    dispatch({
      type: LISTICLE_UPDATE_BLOCK,
      id,
      key,
      value,
      blockGroupID
    });

    if (key === 'styles' || key === 'dimensions') {
      setTimeout(() => {
        dispatch({
          type: LISTICLE_ACTIVATE_BLOCK_STYLES,
          blockGroupID
        });
      }, 100);
    } else if (['displayDesktop', 'displayLaptop', 'displayTablet', 'displayMobile'].indexOf(key) !== -1) {
      dispatch(appendBlockStyles());
    }

    dispatch(libraryCallFunction('onChange', blockGroupID));
    dispatch(savePreview());
  };
};

/**
 * @param {number} id
 * @param {number} groupID
 * @param {*} values
 * @returns {Function}
 */
export const updateBlockAll = (id, groupID, values) => {
  return async (dispatch) => {
    dispatch({
      type: LISTICLE_UPDATE_BLOCK_ALL,
      values,
      id,
      groupID
    });

    dispatch(libraryCallFunction('onChange', groupID));
    dispatch(savePreview());
  };
};

/**
 * @param {number} id
 * @param {string} key
 * @param {string} value
 * @returns {Function}
 */
export const updateBlockGroup = (id, key, value) => {
  return (dispatch) => {
    dispatch({
      type: LISTICLE_UPDATE_BLOCK_GROUP,
      id,
      key,
      value
    });

    dispatch(libraryCallFunction('onChange', id));
    dispatch(savePreview());
  };
};

/**
 * @returns {{type: string}}
 */
export const updateSelected = (key, value) => {
  return (dispatch) => {
    dispatch({
      type: LISTICLE_UPDATE_SELECTED,
      key,
      value
    });
    dispatch(savePreview());
  };
};

/**
 * @param {number} blockGroupID
 * @param {string} key
 * @param {string} value
 * @returns {{id: *, type: string, value: *, key: *}}
 */
export const updateTestSettings = (blockGroupID, key, value) => {
  return {
    type: LISTICLE_UPDATE_TEST_SETTINGS,
    blockGroupID,
    key,
    value
  };
};

/**
 * @param {number} blockGroupID
 * @param {number} blockID
 * @returns {Function}
 */
export const setDefaultBlock = (blockGroupID, blockID) => {
  return (dispatch, getState) => {
    const { listicle } = getState();
    const { activeBlockID } = listicle;

    dispatch({
      type: LISTICLE_SET_DEFAULT_BLOCK,
      blockGroupID,
      blockID
    });
    if (activeBlockID !== 0) {
      dispatch(activateBlock(blockGroupID));
    }
    dispatch(savePreview());
  };
};

/**
 * @param {number} id
 * @param {string} direction
 * @returns {{id: *, type: string, direction: *}}
 */
export const blockMove = (id, direction) => {
  return (dispatch) => {
    dispatch(activateBlock(0, null));
    dispatch({
      type: LISTICLE_BLOCK_MOVE,
      direction,
      id
    });
    dispatch(appendBlockStyles());
    dispatch(savePreview());
  };
};

/**
 * @param {string} direction
 * @returns {{type: string}}
 */
export const moveSelected = (direction) => {
  return (dispatch) => {
    dispatch(activateBlock(0, null));
    dispatch({
      type: LISTICLE_MOVE_SELECTED,
      direction
    });
    dispatch(appendBlockStyles());
    dispatch(savePreview());
  };
};

/**
 * @param {number} blockGroupID
 * @returns {{id: *, type: string}}
 */
export const blockClone = (blockGroupID) => {
  return (dispatch) => {
    dispatch({
      type: LISTICLE_BLOCK_CLONE,
      blockGroupID
    });
    dispatch(appendBlockStyles());
    dispatch(savePreview());
  };
};

/**
 * @returns {{type: string}}
 */
export const cloneSelected = () => {
  return (dispatch) => {
    dispatch({
      type: LISTICLE_CLONE_SELECTED
    });
    dispatch(appendBlockStyles());
    dispatch(savePreview());
  };
};

/**
 * @returns {Function}
 */
export const groupSelected = () => {
  return (dispatch) => {
    dispatch({
      type: LISTICLE_GROUP_SELECTED
    });
    dispatch(appendBlockStyles());
    dispatch(savePreview());
  };
};

/**
 * @param {number} blockGroupID
 * @returns {{type: string}}
 */
export const ungroupBlock = (blockGroupID) => {
  return (dispatch) => {
    dispatch({
      type: LISTICLE_UNGROUP_BLOCK,
      blockGroupID
    });
    dispatch(appendBlockStyles());
    dispatch(savePreview());
  };
};

/**
 * @returns {Function}
 */
export const saveListicle = () => {
  return async (dispatch, getState) => {
    const { listicle } = getState();

    if (listicle.isReadOnly) {
      return;
    }

    if (listicle.activeBlockID !== 0) {
      dispatch(activateBlock(0, null));
    }

    const body = serializeListicle(listicle);
    if (body.template === 0) {
      dispatch(uiToggleSection('templateSettings', true));
      await alerts.alert('A template is required.', 'warning');
      return;
    }
    if (body.title === '') {
      dispatch(uiToggleSection('landerSettings', true));
      dispatch(formError('landerSettings', 'Fix the errors below', {
        'title': 'A value is required.'
      }));
      return;
    }
    if (body.anchor.path === '') {
      dispatch(uiToggleSection('anchorSettings', true));
      dispatch(formError('anchorSettings', 'Fix the errors below', {
        'path': 'A value is required.'
      }));
      return;
    }

    const exec = () => {
      dispatch(busy(true));
      api.post(router.generate('landers_save'), body)
        .then(async (resp) => {
          if (resp._error && resp._exception !== undefined) {
            dispatch(uiModal('error', true, resp));
            return;
          }
          if (resp._error) {
            if (resp._field !== undefined) {
              if (resp._field === 'path' || resp._field === 'site') {
                dispatch(uiToggleSection('anchorSettings', true));
                dispatch(formError('anchorSettings', resp._error, {
                  [resp._field]: resp._error
                }));
              } else {
                dispatch(uiToggleSection('landerSettings', true));
                dispatch(formError('landerSettings', resp._error, {
                  [resp._field]: resp._error
                }));
              }
            } else {
              await alerts.alert(resp._error, 'warning');
            }
            return;
          }

          const { lander } = resp;
          dispatch({
            type: LISTICLE_SAVE,
            lander
          });

          window.history.replaceState(null, lander.title, `/landers/builder/${lander.anchor.id}`);
          document.title = lander.title;
          dispatch(savePreview());
        })
        .catch((err) => {
          if (err.response.data._error) {
            alerts.error(err.response.data._error);
            return;
          }
          console.error(err);
        })
        .finally(() => {
          dispatch(busy(false));
        });
    };

    if (listicle.origPath !== ''
      && (body.anchor.path !== listicle.origPath || body.anchor.site.id !== listicle.origSite.id)) {
      alerts.confirm('Changes to URL settings take effect immediately. Do you want to continue?')
        .then(exec);
    } else {
      exec();
    }
  };
};

/**
 * @returns {Function}
 */
export const exportListicle = () => {
  return (dispatch, getState) => {
    const { listicle } = getState();

    const name = listicle.anchor.path.replace('/', '');
    const body = serializeListicle(listicle);
    delete body.anchor;
    delete body.template;

    const data   = `data:text/json;charset=utf-8,${encodeURIComponent(JSON.stringify(body))}`;
    const anchor = document.createElement('a');
    anchor.setAttribute('style', 'opacity: 0;');
    anchor.setAttribute('href', data);
    anchor.setAttribute('download', `${name}.json`);
    document.body.append(anchor);
    anchor.click();
    anchor.remove();
  };
};

/**
 * @returns {{type: string}}
 */
export const importListicle = () => {
  return (dispatch) => {
    const input = document.createElement('input');
    input.setAttribute('style', 'width: 0px; height: 0px;');
    input.setAttribute('type', 'file');
    input.setAttribute('accept', '.json');
    input.addEventListener('change', (e) => {
      const reader = new FileReader();
      reader.onload = (ee) => {
        const data = JSON.parse(ee.target.result);
        dispatch({
          type: LISTICLE_IMPORT_LISTICLE,
          data
        });
      };
      reader.readAsText(e.target.files[0]);
    });
    document.body.append(input);
    input.click();
    input.remove();
  };
};

/**
 * @returns {Function}
 */
export const exportGlobalStyles = () => {
  return (dispatch, getState) => {
    const { listicle } = getState();

    const name   = `${listicle.anchor.path.replace('/', '')}-styles`;
    const data   = `data:text/json;charset=utf-8,${encodeURIComponent(JSON.stringify(listicle.globalStyles))}`;
    const anchor = document.createElement('a');
    anchor.setAttribute('style', 'opacity: 0;');
    anchor.setAttribute('href', data);
    anchor.setAttribute('download', `${name}.json`);
    document.body.append(anchor);
    anchor.click();
    anchor.remove();
  };
};

/**
 * @returns {Function}
 */
export const importGlobalStyles = () => {
  return (dispatch) => {
    const input = document.createElement('input');
    input.setAttribute('style', 'width: 0px; height: 0px;');
    input.setAttribute('type', 'file');
    input.setAttribute('accept', '.json');
    input.addEventListener('change', (e) => {
      const reader = new FileReader();
      reader.onload = (ee) => {
        const globalStyles = JSON.parse(ee.target.result);
        dispatch({
          type: LISTICLE_IMPORT_GLOBAL_STYLES,
          globalStyles
        });
        dispatch(appendBlockStyles());
        dispatch(savePreview());
      };
      reader.readAsText(e.target.files[0]);
    });
    document.body.append(input);
    input.click();
    input.remove();
  };
};

/**
 * @param {boolean} isCompactView
 * @returns {{isCompactView: *, type: string}}
 */
export const setCompactView = (isCompactView) => {
  return {
    type: LISTICLE_SET_COMPACT_VIEW,
    isCompactView
  };
};

/**
 * @param {boolean} isDesignView
 * @returns {{isCompactView: *, type: string}}
 */
export const setDesignView = (isDesignView) => {
  return {
    type: LISTICLE_SET_DESIGN_VIEW,
    isDesignView
  };
};

/**
 * @returns {{type: string}}
 */
export const toggleCanvasView = () => {
  return (dispatch, getState) => {
    const { listicle, ui, forms } = getState();
    const { previewSettings } = forms;

    if (!listicle.isCanvasView) {
      dispatch({
        type:       LISTICLE_TOGGLE_CANVAS_VIEW,
        previewURL: listicle.previewURL
      });
      dispatch(uiChangePreviewSection(false));
      return;
    }
    if (ui.slideUps.pixels) {
      dispatch(uiSlideUp('pixels', false));
    }

    dispatch(busy(true));
    dispatch(activateBlock(0));
    dispatch(savePreview((resp) => {
      const u      = new URL(resp.url);
      const params = objects.keyFilter(previewSettings, commonValues);
      objects.forEach(params, (value, key) => u.searchParams.set(key, value));
      u.searchParams.set('h', Math.random().toString(36).substring(2, 16));

      dispatch(uiChangePreviewSection(true));
      dispatch({
        type:       LISTICLE_TOGGLE_CANVAS_VIEW,
        previewURL: u.toString()
      });
      dispatch(busy(false));
    }, false));
  };
};

/**
 * @param {string} previewURL
 * @returns {{previewURL: *, type: *}}
 */
export const updatePreviewURL = (previewURL) => {
  return (dispatch, getState) => {
    if (!previewURL) {
      const { listicle, forms } = getState();
      const { previewSettings } = forms;

      const u      = new URL(listicle.previewURL);
      const params = objects.keyFilter(previewSettings, commonValues);
      objects.forEach(params, (value, key) => u.searchParams.set(key, value));
      u.searchParams.set('h', Math.random().toString(36).substring(2, 16));

      dispatch({
        type:       LISTICLE_UPDATE_PREVIEW_URL,
        previewURL: u.toString()
      });
    } else {
      dispatch({
        type: LISTICLE_UPDATE_PREVIEW_URL,
        previewURL
      });
    }
  };
};

/**
 * @returns {Function}
 */
export const pageSpeedListicle = () => {
  return async (dispatch, getState) => {
    const { listicle } = getState();

    const body = serializeListicle(listicle);
    if (body.template === 0) {
      await alerts.alert('A template is required.', 'warning');
      return;
    }

    dispatch(busy(true));
    api.post(router.generate('previews_lander_temp_save'), body)
      .then((resp) => {
        window.open(`https://developers.google.com/speed/pagespeed/insights/?url=${encodeURIComponent(resp.url)}`);
      })
      .finally(() => {
        dispatch(busy(false));
      });
  };
};

/**
 * @param {number} blockGroupID
 * @returns {{blockID: *, type: string}}
 */
export const deleteBlockGroup = (blockGroupID) => {
  return (dispatch) => {
    dispatch({
      type: LISTICLE_DELETE_BLOCK_GROUP,
      blockGroupID
    });
    dispatch(savePreview());
  };
};

/**
 * @param {number} id
 * @param {string} name
 * @returns {Function}
 */
export const renameWidget = (id, name) => {
  return (dispatch) => {
    dispatch(busy(true));
    api.post(router.generate('landers_rename_widget', { id }), { name })
      .then((widgets) => {
        dispatch({
          type: LISTICLE_UPDATE_WIDGETS,
          widgets
        });
      })
      .finally(() => {
        dispatch(busy(false));
      });
  };
};

/**
 * @param {number} blockGroupID
 * @returns {Function}
 */
export const addBlockVariation = (blockGroupID) => {
  return (dispatch) => {
    dispatch(activateBlock(0));
    dispatch({
      type: LISTICLE_ADD_BLOCK_VARIATION,
      blockGroupID
    });
    dispatch(savePreview());
    dispatch(activateBlock(blockGroupID));
  };
};

/**
 * @param {number} blockGroupID
 * @param {number} blockID
 * @returns {Function}
 */
export const removeBlockVariation = (blockGroupID, blockID) => {
  return (dispatch) => {
    dispatch(activateBlock(0));
    dispatch({
      type: LISTICLE_REMOVE_BLOCK_VARIATION,
      blockGroupID,
      blockID
    });
    dispatch(savePreview());
  };
};

/**
 * @param {*} template
 * @returns {Function}
 */
export const setTemplate = (template) => {
  return (dispatch) => {
    dispatch({
      type: LISTICLE_SET_TEMPLATE,
      template
    });
    dispatch(savePreview());
  };
};

/**
 * @param {number} value
 * @returns {{type: string}}
 */
export const incrementWordCount = (value = 1) => {
  return {
    type: LISTICLE_INCREMENT_WORD_COUNT,
    value
  };
};

/**
 * @param {boolean} marginHovering
 * @returns {{type: string}}
 */
export const setMarginHovering = (marginHovering) => {
  return {
    type: LISTICLE_SET_MARGIN_HOVERING,
    marginHovering
  };
};

/**
 * @param {boolean} footerEditing
 * @returns {{type: string}}
 */
export const setFooterEditing = (footerEditing) => {
  return {
    type: LISTICLE_SET_FOOTER_EDITING,
    footerEditing
  };
};

/**
 * @param {boolean} headerEditing
 * @returns {{type: string}}
 */
export const setHeaderEditing = (headerEditing) => {
  return {
    type: LISTICLE_SET_HEADER_EDITING,
    headerEditing
  };
};

/**
 * @param {boolean} headerColEditing
 * @returns {{type: string}}
 */
export const setHeaderColEditing = (headerColEditing) => {
  return {
    type: LISTICLE_SET_HEADER_COL_EDITING,
    headerColEditing
  };
};

/**
 * @param {string} key
 * @param {string} value
 * @returns {{type: string}}
 */
export const updateHeader = (key, value) => {
  return {
    type: LISTICLE_UPDATE_HEADER,
    key,
    value
  };
};

/**
 * @param {number} activeColEditing
 * @returns {{type: string}}
 */
export const setActiveColEditing = (activeColEditing) => {
  return {
    type: LISTICLE_SET_ACTIVE_COL_EDITING,
    activeColEditing
  };
};

/**
 * @param {number} blockGroupID
 * @returns {{type: string}}
 */
export const cloneStylesToAll = (blockGroupID) => {
  return (dispatch) => {
    dispatch({
      type: LISTICLE_CLONE_STYLES_TO_ALL,
      blockGroupID
    });
    dispatch(appendBlockStyles());
    dispatch(savePreview());
  };
};
