import * as types from '@builder/actions/listicleActions';
import { objects, builder, arrays } from 'utils';
import builderBlocks, { commonBuilderBlocks, builderLayouts, blocks as allBlocks } from './builderBlocks';
import { LISTICLE_SAVING_PREVIEW } from '@builder/actions/listicleActions';

const hash = (Math.random().toString(36).substring(2, 16) + Math.random().toString(36).substring(2, 16)).toUpperCase();
const initialState = objects.merge({
  isBusy:             false,
  isSaved:            false,
  isReadOnly:         true,
  isCanvasView:       true,
  isCompactView:      false,
  isDesignView:       true,
  isFirstSave:        false,
  isSavingPreview:    false,
  id:                 0,
  undoCount:          0,
  title:              '',
  path:               '',
  route:              '',
  grantUser:          '',
  previewURL:         '',
  previewHash:        hash,
  previewCount:       0,
  wordCount:          0,
  blocksAddedCount:   0,
  blocksRemovedCount: 0,
  site:               {},
  styles:             {},
  pixels:             [],
  footerLinks:        {},
  footerHtml:         '',
  footerEditing:      false,
  headerEditing:      false,
  headerHeight:       60,
  headerColEditing:   -1,
  activeColEditing:   -1,
  activeRowID:        -1,
  isDragging:         false,
  isChanged:          false,
  isOnline:           false,
  isClone:            false,
  isTesting:          false,
  marginHovering:     false,
  template:           null,
  templateSettings:   {},
  globalStyles:       {},
  activeBlockGroup:   null,
  activeBlockStyles:  {},
  activeBlockID:      0,
  hoverBlockID:       0,
  selectedBlockIDs:   [],
  copiedBlockGroups:  [],
  offers:             [],
  widgets:            [],
  blocks:             [],
  sidebarBlocks:      [],
  blockGroups:        [],
  sidebarBlockGroups: [],
  landers:            [],
  queuedProducts:     [],
  headerScripts:      '',
  footerScripts:      '',
  affiliateUrl:       '',
  offerSplitting:     '',
  variationSplitting: '',
  timeStarted:        Math.round((new Date()).getTime() / 1000),
  header:             {},
  builderBlocks
}, window.initialState.listicle);

let idIndex = -1;
let lastDroppedGroup = null;

const prevStates      = [];
let prevStatesBlocked = false;

/**
 * @param {*} state
 * @returns {number}
 */
const pushState = (state) => {
  if (prevStatesBlocked) {
    return state.undoCount;
  }

  prevStatesBlocked = true;
  setTimeout(() => {
    prevStatesBlocked = false;
  }, 500);

  let undoCount = prevStates.push(objects.clone(state));
  if (undoCount > 150) {
    prevStates.shift();
    undoCount = prevStates.length;
  }

  return undoCount;
};

/**
 * @param {*} state
 * @param {*} changes
 * @returns {*}
 */
const pushChanges = (state, changes) => {
  const changeSet = {};
  objects.forEach(state, (value, key) => {
    if (changes[key] !== undefined) {
      changeSet[key] = value;
    }
  });

  const undoCount = pushState(changeSet);

  return {
    ...state,
    ...changes,
    undoCount
  };
};

/**
 * @returns {number}
 */
const genID = () => {
  idIndex -= 1;
  return idIndex;
};

/**
 * @param {*} blockGroup
 * @returns {*}
 */
const cloneBlockGroup = (blockGroup) => {
  const newBlockGroup      = objects.clone(blockGroup);
  newBlockGroup.id         = genID();
  newBlockGroup.parting.id = genID();
  const did                = genID();
  let dBlock               = null;

  for (let i = 0; i < newBlockGroup.blocks.length; i++) {
    const block = newBlockGroup.blocks[i];
    if (block.id === newBlockGroup.defaultBlock.id) {
      block.id = did;
      dBlock = block;
    } else {
      block.id = genID();
    }
    block.parting.id = genID();
    if (block.type === 'row') {
      for (let y = 0; y < block.columnBlockGroups.length; y++) {
        block.columnBlockGroups[y] = cloneBlockGroup(block.columnBlockGroups[y]);
      }
    }
  }

  newBlockGroup.defaultBlock = dBlock;

  // Group blocks
  if (newBlockGroup.defaultBlock.blockGroups) {
    for (let i = 0; i < newBlockGroup.defaultBlock.blockGroups.length; i++) {
      newBlockGroup.defaultBlock.blockGroups[i] = cloneBlockGroup(newBlockGroup.defaultBlock.blockGroups[i]);
    }

    for (let i = 0; i < newBlockGroup.blocks.length; i++) {
      if (newBlockGroup.blocks[i].id === newBlockGroup.defaultBlock.id) {
        newBlockGroup.blocks[i] = newBlockGroup.defaultBlock;
      }
    }
  }

  return newBlockGroup;
};

/**
 * @param {*} bg
 * @param {string} k
 * @param {*} v
 * @returns {*}
 */
const updateDefaultBlock = (bg, k, v) => {
  const { blocks, defaultBlock } = bg;
  defaultBlock[k] = v;
  for (let i = 0; i < blocks.length; i++) {
    if (blocks[i].id === defaultBlock.id) {
      blocks[i][k] = v;
    }
  }

  return bg;
};

/**
 * @param {string} type
 * @param {*} values
 * @returns {*}
 */
const createBlock = (type, values) => {
  return {
    ...commonBuilderBlocks,
    ...values,
    type,
    id:      genID(),
    parting: { id: genID() },
  };
};

/**
 * @param {*} block
 * @param {*} values
 * @returns {*}
 */
const createBlockGroup = (block, values = {}) => {
  block.id        = genID();
  block.parting   = { id: genID() };
  block.variables = '';
  block.type      = block.type.replace('copy-', '');

  if (block.type === 'group') {
    for (let i = 0; i < block.blockGroups.length; i++) {
      const did                                   = block.blockGroups[i].defaultBlock.id;
      block.blockGroups[i].id                     = genID();
      block.blockGroups[i].defaultBlock.id        = genID();
      block.blockGroups[i].defaultBlock.parting   = { id: genID() };
      block.blockGroups[i].defaultBlock.variables = '';

      for (let y = 0; y < block.blockGroups[i].defaultBlock.columnBlockGroups.length; y++) {
        const did2 = block.blockGroups[i].defaultBlock.columnBlockGroups[y].defaultBlock.id;
        block.blockGroups[i].defaultBlock.columnBlockGroups[y].id = genID();
        updateDefaultBlock(block.blockGroups[i].defaultBlock.columnBlockGroups[y], 'id', genID());
        updateDefaultBlock(block.blockGroups[i].defaultBlock.columnBlockGroups[y], 'parting', { id: genID() });
        updateDefaultBlock(block.blockGroups[i].defaultBlock.columnBlockGroups[y], 'variables', '');

        for (let z = 0; z < block.blockGroups[i].defaultBlock.columnBlockGroups[y].blocks.length; z++) {
          if (block.blockGroups[i].defaultBlock.columnBlockGroups[y].blocks[z].id === did2) {
            block.blockGroups[i].defaultBlock.columnBlockGroups[y].blocks[z] = block.blockGroups[i].defaultBlock.columnBlockGroups[y].defaultBlock;
          }
        }
      }

      for (let y = 0; y < block.blockGroups[i].blocks.length; y++) {
        if (block.blockGroups[i].blocks[y].id === did) {
          block.blockGroups[i].blocks[y] = block.blockGroups[i].defaultBlock;
        }
      }
    }
  }

  return {
    id:                 genID(),
    blocks:             [block],
    defaultBlock:       block,
    isHidden:           false,
    sortOrder:          0,
    columnIndex:        0,
    columnSortOrder:    0,
    parting:            { id: genID() },
    variationSplitting: 'layout',
    ...values
  };
};

/**
 * @param {*} bg
 * @param {*} values
 * @returns {*}
 */
const updateDefaultBlockAll = (bg, values) => {
  Object.keys(values).forEach((key) => {
    updateDefaultBlock(bg, key, values[key]);
  });

  return bg;
};

/**
 *
 * @param {number} blockGroupID
 * @param {string} key
 * @param {string} value
 * @param {*} newState
 * @returns {{activeBlockGroup: null}}
 */
const updateBlock = (blockGroupID, key, value, newState) => {
  const { blockGroup, blockGroupIndex, foundAt, groupID, columnKey } = builder.getGroupBlock(
    blockGroupID,
    newState.blockGroups,
    newState.sidebarBlockGroups,
    newState.header
  );

  if (!blockGroup) {
    return null;
  }

  let { countable } = blockGroup.defaultBlock;
  if (key === 'styles') {
    value = objects.merge(blockGroup.defaultBlock.styles, value);
  } else if (key === 'styles:no-merge') {
    key = 'styles';
  } else if (
    key === 'counterVisible'
    && value
    && blockGroup.defaultBlock.type === 'counter-headline'
    && blockGroup.defaultBlock.counterText === ''
  ) {
    countable = true;
  }

  let activeBlockGroup = null;
  if (blockGroupIndex !== -1) {
    activeBlockGroup = blockGroup;

    if (groupID !== null) {
      const groupIndex = arrays.findIndexByID(newState.blockGroups, groupID);
      if (groupIndex !== -1) {
        if (foundAt === 'group') {
          updateDefaultBlock(newState.blockGroups[groupIndex].defaultBlock.blockGroups[blockGroupIndex], key, value);
        } else {
          // Layout inside of a group.
          const index    = arrays.findIndexByID(newState.blockGroups[groupIndex].defaultBlock.blockGroups, foundAt);
          const blockRow = newState.blockGroups[groupIndex].defaultBlock.blockGroups[index];
          const { columnBlockGroups } = blockRow.defaultBlock;
          updateDefaultBlock(columnBlockGroups[blockGroupIndex], key, value);
          updateDefaultBlock(blockRow, 'columnBlockGroups', columnBlockGroups);
        }

        for (let i = 0; i < newState.blockGroups[groupIndex].blocks.length; i++) {
          if (newState.blockGroups[groupIndex].blocks[i].id === newState.blockGroups[groupIndex].defaultBlock.id) {
            newState.blockGroups[groupIndex].blocks[i] = newState.blockGroups[groupIndex].defaultBlock;
          }
        }
      }
    } else if (foundAt === 'header') {
      updateDefaultBlock(newState.header.columnBlockGroups[columnKey][blockGroupIndex], key, value);
    } else if (['blockGroups', 'sidebarBlockGroups'].indexOf(foundAt) !== -1) {
      // Set the affiliate url on child blocks when this is a group block.
      if (key === 'affiliateUrl' && newState[foundAt][blockGroupIndex].defaultBlock.type === 'group') {
        newState[foundAt][blockGroupIndex].defaultBlock.blockGroups.forEach((bg) => {
          updateDefaultBlock(bg, key, value);
          if (bg.defaultBlock.type === 'row') {
            const { columnBlockGroups } = bg.defaultBlock;
            columnBlockGroups.forEach((bg2) => {
              updateDefaultBlock(bg2, key, value);
            });
            updateDefaultBlock(bg, 'columnBlockGroups', columnBlockGroups);
          }
        });
        for (let i = 0; i < newState[foundAt][blockGroupIndex].blocks.length; i++) {
          if (newState[foundAt][blockGroupIndex].blocks[i].id === newState[foundAt][blockGroupIndex].defaultBlock.id) {
            newState[foundAt][blockGroupIndex].blocks[i] = newState[foundAt][blockGroupIndex].defaultBlock;
          }
        }
      } else {
        updateDefaultBlock(newState[foundAt][blockGroupIndex], key, value);
        updateDefaultBlock(newState[foundAt][blockGroupIndex], 'countable', countable);
      }
    } else {
      const blockRowIndex = arrays.findIndexByID(newState.blockGroups, foundAt);
      if (blockRowIndex !== -1) {
        const blockRow = newState.blockGroups[blockRowIndex];
        const { columnBlockGroups } = blockRow.defaultBlock;
        updateDefaultBlock(columnBlockGroups[blockGroupIndex], key, value);
        updateDefaultBlock(blockRow, 'columnBlockGroups', columnBlockGroups);
      }
    }
  }

  return {
    activeBlockGroup
  };
};

/**
 * @param {Array} list
 * @param {number} startIndex
 * @param {number} endIndex
 * @returns {Array}
 */
const reorder = (list, startIndex, endIndex) => {
  const result    = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

/**
 * @param {Array} source
 * @param {Array} destination
 * @param {*} droppableSource
 * @param {*} droppableDestination
 * @param {boolean} widget
 * @returns {*}
 */
const move = (source, destination, droppableSource, droppableDestination, widget = false) => {
  const sourceClone = Array.from(source);
  const clone       = Array.from(destination);
  let sourceBlock   = objects.clone(source[droppableSource.index]);
  sourceClone.splice(droppableSource.index, 1);

  if (widget && sourceBlock.html === '' && sourceBlock.blockGroups.length > 0) {
    lastDroppedGroup = sourceBlock.blockGroups[0]; // eslint-disable-line
    sourceBlock.blockGroups.reverse().forEach((blockGroup) => {
      clone.splice(droppableDestination.index, 0, cloneBlockGroup(blockGroup));
    });

    return { clone, blockGroup: lastDroppedGroup };
  }

  if (sourceBlock.name !== undefined && sourceBlock.html !== undefined) {
    const widgetHtml = sourceBlock.html;
    sourceBlock = objects.clone(commonBuilderBlocks);
    sourceBlock.type             = 'html';
    sourceBlock.html             = widgetHtml;
    sourceBlock.styles.fontSize  = 18;
    sourceBlock.styles.textAlign = 'left';
  }

  const blockGroup = createBlockGroup(sourceBlock);
  if (sourceBlock.type === 'offer' || widget) {
    // sourceBlock.parent = sourceBlock.id;
  }

  clone.splice(droppableDestination.index, 0, blockGroup);
  lastDroppedGroup = blockGroup;

  return { clone, blockGroup };
};

/**
 * @param {*} state
 * @param {*} action
 */
const onBusy = (state, action) => {
  const { isBusy } = action;

  return {
    ...state,
    isBusy
  };
};

/**
 * @param {*} state
 */
const onUndo = (state) => {
  if (prevStates.length === 0) {
    return {
      ...state,
      undoCount: 0
    };
  }

  const changes  = prevStates.pop();

  const newState = {
    ...state,
    ...changes
  };

  if (newState.activeBlockID !== 0) {
    let index = arrays.findIndexByID(newState.blockGroups, newState.activeBlockID);
    if (index === -1) {
      index = arrays.findIndexByID(newState.sidebarBlockGroups, newState.activeBlockID);
    }
    if (index === -1) {
      newState.activeBlockID = 0;
    }
  }

  if (prevStates.length === 0) {
    newState.undoCount = 0;
  }

  return newState;
};

/**
 * @param {*} state
 * @param {*} action
 */
const onPushState = (state, action) => {
  const { field } = action;

  if (field === 'path' || field === 'site') {
    return pushChanges(state, {
      anchor: state.anchor
    });
  }

  return pushChanges(state, {
    [field]: state[field]
  });
};

/**
 * @param {*} state
 */
const onClearBlockGroups = (state) => {
  return {
    ...state,
    blockGroups: []
  };
};

/**
 * @param {*} state
 * @param {*} action
 */
const onInit = (state, action) => {
  const bbb = objects.clone(state.builderBlocks);
  const { header, blockGroups } = state;
  const { products } = action;

  blockGroups.forEach((blockGroup) => {
    if (blockGroup.id < idIndex) {
      idIndex = blockGroup.id;
    }
    blockGroup.blocks.forEach((block) => {
      if (block.id < idIndex) {
        idIndex = block.id;
      }
      if (block.parting.id < idIndex) {
        idIndex = block.parting.id;
      }
    });
  });

  Object.keys(header.columnBlockGroups).forEach((columnBlockGroupKey) => {
    header.columnBlockGroups[columnBlockGroupKey].forEach((columnBlockGroup) => {
      if (columnBlockGroup.id < idIndex) {
        idIndex = columnBlockGroup.id;
      }
      columnBlockGroup.blocks.forEach((block) => {
        if (block.id < idIndex) {
          idIndex = block.id;
        }
        if (block.parting.id < idIndex) {
          idIndex = block.parting.id;
        }
      });
    });
  });

  if (state.queuedProducts.length > 0) {
    state.queuedProducts.forEach((id) => {
      const product = arrays.findByID(products, id);
      if (product) {
        const block = createBlock('product', {
          product
        });
        bbb.push(block);
      }
    });
  }

  idIndex -= 1;

  return {
    ...state,
    builderBlocks: bbb
  };
};

/**
 * @param {*} state
 * @param {*} action
 */
const onChanged = (state, action) => {
  const { isChanged } = action;

  return {
    ...state,
    isChanged
  };
};

/**
 * @param {*} state
 * @param {*} action
 */
const onAddBuilderBlock = (state, action) => {
  const block = objects.clone(action.block);

  const bbb = objects.clone(state.builderBlocks).filter((b) => {
    return !b.isPasted;
  });

  bbb.push(block);

  return {
    ...state,
    builderBlocks: bbb
  };
};

/**
 * @param {*} state
 * @param {*} action
 */
const onDropHeader = (state, action) => {
  const { header }                           = objects.clone(state);
  const widgets                              = objects.clone(action.widgets);
  const { draggable }                        = action;
  const { source, destination, draggableId } = draggable;

  if (source.droppableId.indexOf('col-') === 0 && destination.droppableId.indexOf('col-') === 0) {
    const sourceIndex = parseInt(source.droppableId.replace('col-', ''), 10);
    const destIndex   = parseInt(destination.droppableId.replace('col-', ''), 10);

    const index = arrays.findIndexByID(header.columnBlockGroups[sourceIndex], draggableId);
    if (index !== -1) {
      const blockGroup = header.columnBlockGroups[sourceIndex][index];
      header.columnBlockGroups[sourceIndex].splice(index, 1);
      header.columnBlockGroups[destIndex].push(blockGroup);
    }
  } else if (source.droppableId === 'builderBlocks' && destination.droppableId.indexOf('col-') === 0) {
    const destIndex = parseInt(destination.droppableId.replace('col-', ''), 10);
    const bBlocks   = objects.clone(state.builderBlocks);
    const block     = arrays.findByID(bBlocks, draggableId, 'type');

    block.id        = genID();
    block.parting   = { id: genID() };
    block.styles    = {};
    block.variables = '';
    const blockGroup = {
      id:                 genID(),
      blocks:             [block],
      defaultBlock:       block,
      isHidden:           false,
      sortOrder:          0,
      parting:            { id: genID() },
      variationSplitting: 'layout'
    };
    header.columnBlockGroups[destIndex].push(blockGroup);
  }

  return pushChanges(state, {
    header,
    isChanged: true
  });
};

/**
 * @param {*} state
 * @param {*} action
 */
const onDropBlockRow = (state, action) => {
  const blockGroups                          = objects.clone(state.blockGroups);
  const sidebarBlockGroups                   = objects.clone(state.sidebarBlockGroups);
  const widgets                              = objects.clone(action.widgets);
  const header                               = objects.clone(state.header);
  let { blocksAddedCount }                   = state;
  const { draggable }                        = action;
  const { source, destination, draggableId } = draggable;

  let matches         = destination.droppableId.match(/^col-([\d]+)\.([-\d]+)$/);
  const columnIndex   = parseInt(matches[1], 10);
  let rowBlockGroupID = parseInt(matches[2], 10);

  const { blockGroupIndex } = builder.getGroupBlock(
    rowBlockGroupID,
    blockGroups,
    sidebarBlockGroups,
    header
  );

  let block;
  if ((source.droppableId && destination.droppableId) && source.droppableId === destination.droppableId) {
    // Blocks re-arranged in the same column.
    const { columnBlockGroups } = blockGroups[blockGroupIndex].defaultBlock;
    for (let i = 0; i < columnBlockGroups.length; i++) {
      if (columnBlockGroups[i].columnIndex === columnIndex) {
        if (columnBlockGroups[i].columnSortOrder === source.index) {
          columnBlockGroups[i].columnSortOrder = destination.index;
        } else if (columnBlockGroups[i].columnSortOrder === destination.index) {
          columnBlockGroups[i].columnSortOrder = source.index;
        }
      }
    }
  } else if (source.droppableId === 'builderBlocks') {
    // Block dropped from the sidebar.
    block               = arrays.findByID(objects.clone(state.builderBlocks), draggableId, 'type');
    const newBlock      = createBlock(block.type, block);
    const newBlockGroup = createBlockGroup(newBlock, {
      columnIndex,
      columnSortOrder: destination.index
    });
    blockGroups[blockGroupIndex].defaultBlock.columnBlockGroups.splice(destination.index, 0, newBlockGroup);
  } else if (source.droppableId === 'sidebarWidgets') {
    // Block dropped from widgets.
    const { clone, blockGroup } = move(
      widgets,
      blockGroups[blockGroupIndex].defaultBlock.columnBlockGroups,
      source,
      destination,
      true
    );
    blockGroups[blockGroupIndex].defaultBlock.columnBlockGroups = clone;
  } else if (source.droppableId.indexOf('col-') === 0) {
    // Block dragged from one column to another.
    matches            = source.droppableId.match(/^col-([\d]+)\.([-\d]+)$/);
    const sColumnIndex = parseInt(matches[1], 10);
    rowBlockGroupID    = parseInt(matches[2], 10);

    const { blockGroupIndex: sourceBlockGroupIndex } = builder.getGroupBlock(
      rowBlockGroupID,
      blockGroups,
      sidebarBlockGroups,
      header
    );

    const newBlockGroup = blockGroups[sourceBlockGroupIndex].defaultBlock.columnBlockGroups.splice(sColumnIndex, 1);
    newBlockGroup[0].columnIndex     = columnIndex;
    newBlockGroup[0].columnSortOrder = destination.index;
    blockGroups[blockGroupIndex].defaultBlock.columnBlockGroups.splice(destination.index, 0, newBlockGroup[0]);
  }

  blockGroups[blockGroupIndex].blocks = blockGroups[blockGroupIndex].blocks.map((b) => {
    if (b.id === blockGroups[blockGroupIndex].defaultBlock.id) {
      return blockGroups[blockGroupIndex].defaultBlock;
    }
    return b;
  });

  blocksAddedCount += 1;

  return pushChanges(state, {
    blockGroups,
    sidebarBlockGroups,
    blocksAddedCount,
    selectedBlockIDs: [],
    isChanged:        true
  });
};

/**
 * @param {*} state
 * @param {*} action
 */
const onDropLayoutBlock = (state, action) => {
  const blockGroups             = objects.clone(state.blockGroups);
  const sidebarBlockGroups      = objects.clone(state.sidebarBlockGroups);
  let { blocksAddedCount }      = state;
  const { draggable }           = action;
  const { source, destination } = draggable;

  const layout = builderLayouts[source.index];
  const block  = createBlock('row', {
    columnCount:       layout.columnCount,
    columnWidths:      layout.columnWidths,
    columnStyles:      layout.columnStyles,
    columnBlockGroups: []
  });
  const blockGroup = createBlockGroup(block);
  lastDroppedGroup = blockGroup;

  if (destination.droppableId === 'canvasBlocks') {
    blockGroups.splice(destination.index, 0, blockGroup);
  } else if (destination.droppableId === 'sidebarBlocks') {
    sidebarBlockGroups.splice(destination.index, 0, blockGroup);
  }
  blocksAddedCount += 1;

  return pushChanges(state, {
    blockGroups,
    sidebarBlockGroups,
    blocksAddedCount,
    selectedBlockIDs:  [],
    activeBlockGroup:  blockGroup,
    activeBlockStyles: {},
    activeBlockID:     blockGroup.id,
    isChanged:         true
  });
};

/**
 * @param {*} state
 * @param {*} action
 */
const onDropBlockGroup = (state, action) => {
  const blockGroups = objects.clone(state.blockGroups);
  const { draggable } = action;
  const { source, destination } = draggable;

  if (!source || !destination) {
    return state;
  }

  const groupID    = parseInt(source.droppableId.replace('group-', ''), 10);
  const groupIndex = arrays.findIndexByID(blockGroups, groupID);
  if (groupIndex !== -1) {
    blockGroups[groupIndex].defaultBlock.blockGroups = reorder(
      blockGroups[groupIndex].defaultBlock.blockGroups,
      source.index,
      destination.index
    );

    for (let i = 0; i < blockGroups[groupIndex].blocks.length; i++) {
      if (blockGroups[groupIndex].blocks[i].id === blockGroups[groupIndex].defaultBlock.id) {
        blockGroups[groupIndex].blocks[i] = blockGroups[groupIndex].defaultBlock;
      }
    }
  }

  return pushChanges(state, {
    blockGroups,
    isChanged: true
  });
};

/**
 * @param {*} state
 * @param {*} action
 */
const onDropProduct = (state, action) => {
  const bbb                = objects.clone(state.builderBlocks);
  const blockGroups        = objects.clone(state.blockGroups);
  const sidebarBlockGroups = objects.clone(state.sidebarBlockGroups);
  const queuedProducts     = Array.from(state.queuedProducts);
  const products           = Array.from(action.products);
  const { draggable }      = action;
  const { destination }    = draggable;
  const { index }          = destination;
  const isQueued           = draggable.draggableId.indexOf('queued-') === 0;
  const productID          = parseInt(draggable.draggableId.replace(/product-|queued-/, ''), 10);

  /**
   * Creates a new group block by cloning an existing group block
   *
   * @param {*} product
   * @returns {null|*}
   */
  const createGroupBlock = (product) => {
    let firstBlock = null;
    for (let i = 0; i < blockGroups.length; i++) {
      if (blockGroups[i].defaultBlock.type === 'group') {
        // Save the first found group block in case we don't find a better match.
        if (!firstBlock) {
          firstBlock = blockGroups[i];
        }

        // Try to find an existing block with an image.
        let found = null;
        for (let y = 0; y < blockGroups[i].defaultBlock.blockGroups.length; y++) {
          if (blockGroups[i].defaultBlock.blockGroups[y].defaultBlock.type === 'image') {
            found = blockGroups[i];
            break;
          }
        }

        if (found) {
          const blockGroup   = cloneBlockGroup(found);
          blockGroup.product = product.id;

          return blockGroup;
        }
      }
    }

    // We failed to find a best match. Did we at least find one group block?
    if (firstBlock) {
      const blockGroup   = cloneBlockGroup(firstBlock);
      blockGroup.product = product.id;

      return blockGroup;
    }

    // We didn't find any existing group blocks. Create a generic group block.
    const block = createBlock('group', {
      styles: {
        paddingRight:  10,
        paddingLeft:   10,
        paddingBottom: 0,
        paddingTop:    0,
        marginBottom:  15
      }
    });

    return createBlockGroup(block, {
      product: product.id
    });
  };

  /**
   *
   * @param {*} defaultBlock
   * @param {string} type
   * @param {*} values
   * @param {*} defaults
   */
  const updateBlockByType = (defaultBlock, type, values, defaults) => {
    let found = null;
    for (let i = 0; i < defaultBlock.blockGroups.length; i++) {
      if (defaultBlock.blockGroups[i].defaultBlock.type === type) {
        defaultBlock.blockGroups[i].defaultBlock = Object.assign({}, defaultBlock.blockGroups[i].defaultBlock, values);
        found = defaultBlock.blockGroups[i];
        break;
      }
    }

    if (!found) {
      const block = createBlock(type, {
        ...values,
        ...defaults
      });
      found = createBlockGroup(block);
      defaultBlock.blockGroups.push(found);
      block.blockGroups = [];
    }

    defaultBlock.blockGroups.forEach((bg) => {
      const db = bg.defaultBlock;
      for (let i = 0; i < bg.blocks.length; i++) {
        if (bg.blocks[i].id === db.id) {
          bg.blocks[i] = db;
        }
      }
    });

    return found;
  };

  /**
   * @param {*} product
   * @param {*} blockGroup
   */
  const createHeadline = (product, blockGroup) => {
    if (product.headline) {
      updateBlockByType(blockGroup.defaultBlock, 'counter-headline', {
        html: product.headline
      }, {
        styles:     objects.clone(allBlocks['counter-headline'].defaultValues.styles),
        dimensions: objects.clone(allBlocks['counter-headline'].defaultValues.dimensions)
      });
    }
  };

  /**
   * @param {*} product
   * @param {*} blockGroup
   */
  const createImage = (product, blockGroup) => {
    if (product.screenshotUrl) {
      updateBlockByType(blockGroup.defaultBlock, 'image', {
        imageUrl: product.screenshotUrl
      }, {
        styles:     objects.clone(allBlocks.image.defaultValues.styles),
        dimensions: objects.clone(allBlocks.image.defaultValues.dimensions)
      });
    }
  };

  /**
   * @param {*} product
   * @param {*} blockGroup
   */
  const createText = (product, blockGroup) => {
    if (product.html) {
      updateBlockByType(blockGroup.defaultBlock, 'text', {
        html: product.html
      }, {
        styles: objects.clone(allBlocks.text.defaultValues.styles)
      });
    }
  };

  let blockGroup = null;
  if (isQueued) {
    for (let i = 0; i < bbb.length; i++) {
      if (bbb[i].product && bbb[i].product.id === productID) {
        const { product } = bbb[i];
        blockGroup = createGroupBlock(product);
        createHeadline(product, blockGroup);
        createImage(product, blockGroup);
        createText(product, blockGroup);

        bbb.splice(i, 1);
        const y = queuedProducts.indexOf(productID);
        if (y !== -1) {
          queuedProducts.splice(y, 1);
        }
        break;
      }
    }
  } else {
    for (let i = 0; i < products.length; i++) {
      if (products[i].id === productID) {
        blockGroup = createGroupBlock(products[i]);
        createHeadline(products[i], blockGroup);
        createImage(products[i], blockGroup);
        createText(products[i], blockGroup);

        break;
      }
    }
  }

  if (destination.droppableId === 'canvasBlocks') {
    blockGroups.splice(index, 0, blockGroup);
  } else if (destination.droppableId === 'sidebarBlocks') {
    sidebarBlockGroups.splice(index, 0, blockGroup);
  }

  return pushChanges(state, {
    queuedProducts,
    blockGroups,
    sidebarBlockGroups,
    builderBlocks:    bbb,
    isChanged:        true,
    activeBlockGroup: blockGroup
  });
};

/**
 * @param {*} state
 * @param {*} action
 */
const onDrop = (state, action) => {
  const { selectedBlockIDs, copiedBlockGroups } = state;
  const { draggable, cb } = action;
  const { source, destination } = draggable;

  if (draggable.type === 'blockRow') {
    return onDropBlockRow(state, action);
  }

  if (draggable.type === 'group') {
    return onDropBlockGroup(state, action);
  }

  if (
    (source && source.droppableId.indexOf('col-') === 0)
    || (destination && destination.droppableId.indexOf('col-') === 0)
  ) {
    return onDropHeader(state, action);
  }

  if (source.droppableId === 'sidebarLayouts') {
    return onDropLayoutBlock(state, action);
  }

  if (source.droppableId === 'sidebarProducts') {
    return onDropProduct(state, action);
  }

  // eslint-disable-next-line no-unused-vars
  let { activeBlockID, activeBlockGroup, blocksAddedCount }  = state;
  let blockGroups        = objects.clone(state.blockGroups);
  let sidebarBlockGroups = objects.clone(state.sidebarBlockGroups);

  lastDroppedGroup        = null;
  let newActiveBlockGroup = null;
  const bBlocks = objects.clone(state.builderBlocks);
  const offers  = objects.clone(state.offers);
  const widgets = objects.clone(action.widgets);

  if (draggable.draggableId === 'copiedBlockGroups') {
    let { index } = destination;

    copiedBlockGroups.forEach((bg) => {
      const blockGroup = cloneBlockGroup(bg);
      if (destination.droppableId === 'canvasBlocks') {
        blockGroups.splice(index, 0, blockGroup);
      } else if (destination.droppableId === 'sidebarBlocks') {
        sidebarBlockGroups.splice(index, 0, blockGroup);
      }
      index += 1;
    });

    return pushChanges(state, {
      blockGroups,
      sidebarBlockGroups,
      blocksAddedCount,
      selectedBlockIDs: [],
      isChanged:        true
    });
  }

  if (selectedBlockIDs.length > 0) {
    const { index } = destination;

    selectedBlockIDs.forEach((blockID) => {
      const blockIndex = arrays.findIndexByID(blockGroups, blockID);
      if (blockIndex !== -1) {
        const [removed] = blockGroups.splice(blockIndex, 1);
        blockGroups.splice(index, 0, removed);
      }
    });

    return pushChanges(state, {
      blockGroups,
      sidebarBlockGroups,
      blocksAddedCount,
      selectedBlockIDs: [],
      isChanged:        true
    });
  }

  if (
    destination
    && destination.droppableId !== 'builderBlocks'
    && !(source.droppableId === 'sidebarBlocks' && destination.droppableId === 'canvasBlocks')
  ) {
    // The blocks were re-arranged on the canvas.
    if (source.droppableId === destination.droppableId) {
      if (source.droppableId === 'sidebarBlocks') {
        sidebarBlockGroups = reorder(
          sidebarBlockGroups,
          source.index,
          destination.index
        );
      } else {
        blockGroups = reorder(
          blockGroups,
          source.index,
          destination.index
        );
      }
    } else if (source.droppableId === 'sidebarOffers') {
      // Offer block dropped into the sidebar.
      if (destination.droppableId === 'sidebarBlocks') {
        const { clone, blockGroup } = move(
          offers,
          sidebarBlockGroups,
          source,
          destination
        );
        sidebarBlockGroups  = clone;
        newActiveBlockGroup = blockGroup;
      } else {
        // Offer block dropped onto the canvas.
        const { clone, blockGroup } = move(
          offers,
          blockGroups,
          source,
          destination
        );
        blockGroups         = clone;
        newActiveBlockGroup = blockGroup;
      }
    } else if (source.droppableId === 'sidebarWidgets') {
      // Widget dropped into the sidebar.
      if (destination.droppableId === 'sidebarBlocks') {
        const { clone, blockGroup } = move(
          widgets,
          sidebarBlockGroups,
          source,
          destination,
          true
        );
        sidebarBlockGroups  = clone;
        newActiveBlockGroup = blockGroup;
      } else {
        // Widget dropped onto the canvas.
        const { clone, blockGroup } = move(
          widgets,
          blockGroups,
          source,
          destination,
          true
        );
        blockGroups         = clone;
        newActiveBlockGroup = blockGroup;
      }
    } else if (destination.droppableId === 'sidebarBlocks') {
      const { clone, blockGroup } = move(
        bBlocks,
        sidebarBlockGroups,
        source,
        destination
      );
      sidebarBlockGroups  = clone;
      newActiveBlockGroup = blockGroup;
    } else {
      // A sidebar block was dropped onto the canvas.
      const { clone, blockGroup } = move(
        bBlocks,
        blockGroups,
        source,
        destination
      );
      blockGroups         = clone;
      newActiveBlockGroup = blockGroup;
    }
  }

  if (newActiveBlockGroup !== null && newActiveBlockGroup.defaultBlock.type !== 'html') {
    activeBlockID    = newActiveBlockGroup.id;
    activeBlockGroup = newActiveBlockGroup;
  }

  if (typeof cb === 'function' && lastDroppedGroup !== null) {
    setTimeout(() => {
      cb(lastDroppedGroup);
    }, 100);
  }

  blocksAddedCount += 1;

  return pushChanges(state, {
    blockGroups,
    sidebarBlockGroups,
    // activeBlockID,
    // activeBlockGroup,
    blocksAddedCount,
    isChanged:     true
  });
};

/**
 * @param {*} state
 * @param {*} action
 */
const onSetDragging = (state, action) => {
  const { isDragging } = action;

  return {
    ...state,
    isDragging
  };
};

/**
 * @param {*} state
 * @param {*} action
 */
const onInsertBlock = (state, action) => {
  let { blocksAddedCount } = state;
  const blockGroups        = objects.clone(state.blockGroups);
  const { block, index }   = action;

  const sourceBlock = objects.clone(block);

  sourceBlock.id        = genID();
  sourceBlock.parting   = { id: genID() };
  sourceBlock.styles    = {};
  sourceBlock.variables = '';
  const activeBlockGroup = {
    id:                 genID(),
    blocks:             [sourceBlock],
    defaultBlock:       sourceBlock,
    isHidden:           false,
    sortOrder:          0,
    parting:            { id: genID() },
    variationSplitting: 'layout'
  };

  blockGroups.splice(index, 0, activeBlockGroup);
  const activeBlockID = activeBlockGroup.id;

  blocksAddedCount += 1;

  return pushChanges(state, {
    blockGroups,
    activeBlockID,
    activeBlockGroup,
    blocksAddedCount,
    isChanged: true
  });
};

/**
 * @param {*} state
 * @param {*} action
 */
const onBlockMove = (state, action) => {
  const blockGroups        = objects.clone(state.blockGroups);
  const sidebarBlockGroups = objects.clone(state.sidebarBlockGroups);
  const header             = objects.clone(state.header);
  const { id, direction }  = action;

  const { blockGroup, blockGroupIndex, columnKey, groupID, foundAt } = builder.getGroupBlock(
    id,
    blockGroups,
    sidebarBlockGroups,
    header
  );

  if (blockGroup) {
    const newBlockGroup = cloneBlockGroup(blockGroup);
    let nextBlockIndex  = blockGroupIndex;
    if (direction === 'up' && blockGroupIndex > 0) {
      nextBlockIndex -= 1;
    } else if (direction === 'down') {
      nextBlockIndex += 1;
    }

    if (foundAt === 'blockGroups') {
      blockGroups.splice(blockGroupIndex, 1);
      blockGroups.splice(nextBlockIndex, 0, newBlockGroup);
    } else if (foundAt === 'sidebarBlockGroups') {
      sidebarBlockGroups.splice(blockGroupIndex, 1);
      sidebarBlockGroups.splice(nextBlockIndex, 0, newBlockGroup);
    } else if (foundAt === 'group') {
      const groupIndex = arrays.findIndexByID(blockGroups, groupID);
      if (groupIndex !== -1) {
        blockGroups[groupIndex].defaultBlock.blockGroups.splice(blockGroupIndex, 1);
        blockGroups[groupIndex].defaultBlock.blockGroups.splice(nextBlockIndex, 0, newBlockGroup);
        for (let i = 0; i < blockGroups[groupIndex].blocks.length; i++) {
          if (blockGroups[groupIndex].blocks[i].id === blockGroups[groupIndex].defaultBlock.id) {
            blockGroups[groupIndex].blocks[i] = blockGroups[groupIndex].defaultBlock;
          }
        }
      }
    } else if (foundAt === 'header') {
      header.columnBlockGroups[columnKey][blockGroupIndex].splice(blockGroupIndex, 1);
      header.columnBlockGroups[columnKey][blockGroupIndex].splice(nextBlockIndex, 0, newBlockGroup);
    } else {
      const blockRowIndex = arrays.findIndexByID(blockGroups, foundAt);
      if (blockRowIndex !== -1) {
        const blockRow = blockGroups[blockRowIndex];
        const { columnBlockGroups } = blockRow.defaultBlock;
        // @todo
      }
    }
  }

  return pushChanges(state, {
    header,
    blockGroups,
    sidebarBlockGroups,
    isChanged: true
  });
};

/**
 * @param {*} state
 * @param {*} action
 */
const onMoveSelected = (state, action) => {
  const { selectedBlockIDs } = state;
  const blockGroups        = objects.clone(state.blockGroups);
  const sidebarBlockGroups = objects.clone(state.sidebarBlockGroups);
  const header             = objects.clone(state.header);
  const { direction }      = action;

  let nextBlockGroupIndex = 0;
  selectedBlockIDs.forEach((blockID) => {
    const { blockGroupIndex } = builder.getGroupBlock(
      blockID,
      blockGroups,
      sidebarBlockGroups,
      header
    );
    if (blockGroupIndex > nextBlockGroupIndex) {
      nextBlockGroupIndex = blockGroupIndex;
    }
  });

  selectedBlockIDs.forEach((blockID) => {
    const { blockGroup, columnKey, blockGroupIndex, foundAt } = builder.getGroupBlock(
      blockID,
      blockGroups,
      sidebarBlockGroups,
      header
    );

    if (blockGroup) {
      const newBlockGroup = cloneBlockGroup(blockGroup);
      if (direction === 'up' && nextBlockGroupIndex > 0) {
        nextBlockGroupIndex -= 1;
      } else if (direction === 'down') {
        nextBlockGroupIndex += 1;
      }

      if (foundAt === 'blockGroups') {
        blockGroups.splice(blockGroupIndex, 1);
        blockGroups.splice(nextBlockGroupIndex, 0, newBlockGroup);
      } else if (foundAt === 'sidebarBlockGroups') {
        sidebarBlockGroups.splice(blockGroupIndex, 1);
        sidebarBlockGroups.splice(nextBlockGroupIndex, 0, newBlockGroup);
      } else if (foundAt === 'header') {
        header.columnBlockGroups[columnKey][blockGroupIndex].splice(blockGroupIndex, 1);
        header.columnBlockGroups[columnKey][blockGroupIndex].splice(nextBlockGroupIndex, 0, newBlockGroup);
      } else {
        const blockRowIndex = arrays.findIndexByID(blockGroups, foundAt);
        if (blockRowIndex !== -1) {
          const blockRow = blockGroups[blockRowIndex];
          const { columnBlockGroups } = blockRow.defaultBlock;
          // @todo
        }
      }
    }
  });

  return pushChanges(state, {
    header,
    blockGroups,
    sidebarBlockGroups,
    selectedBlockIDs: [],
    isChanged:        true
  });
};

/**
 * @param {*} state
 * @param {*} action
 */
const onBlockClone = (state, action) => {
  let { blocksAddedCount } = state;
  const blockGroups        = objects.clone(state.blockGroups);
  const sidebarBlockGroups = objects.clone(state.sidebarBlockGroups);
  const header             = objects.clone(state.header);
  const { blockGroupID }   = action;

  const { blockGroup, blockGroupIndex, columnKey, groupID, foundAt } = builder.getGroupBlock(
    blockGroupID,
    blockGroups,
    sidebarBlockGroups,
    header
  );

  if (blockGroup) {
    const newBlockGroup = cloneBlockGroup(blockGroup);

    if (foundAt === 'blockGroups') {
      blockGroups.splice(blockGroupIndex + 1, 0, newBlockGroup);
    } else if (foundAt === 'sidebarBlockGroups') {
      sidebarBlockGroups.splice(blockGroupIndex + 1, 0, newBlockGroup);
    } else if (foundAt === 'group') {
      const groupIndex = arrays.findIndexByID(blockGroups, groupID);
      if (groupIndex !== -1) {
        blockGroups[groupIndex].defaultBlock.blockGroups.splice(blockGroupIndex + 1, 0, newBlockGroup);
        for (let i = 0; i < blockGroups[groupIndex].blocks.length; i++) {
          if (blockGroups[groupIndex].blocks[i].id === blockGroups[groupIndex].defaultBlock.id) {
            blockGroups[groupIndex].blocks[i] = blockGroups[groupIndex].defaultBlock;
          }
        }
      }
    } else if (foundAt === 'header') {
      header.columnBlockGroups[columnKey][blockGroupIndex].splice(blockGroupIndex + 1, 0, newBlockGroup);
    } else {
      const blockRowIndex = arrays.findIndexByID(blockGroups, foundAt);
      if (blockRowIndex !== -1) {
        const blockRow = blockGroups[blockRowIndex];
        const { columnBlockGroups } = blockRow.defaultBlock;
        // @todo
      }
    }

    blocksAddedCount += 1;
  }

  return pushChanges(state, {
    blockGroups,
    blocksAddedCount,
    sidebarBlockGroups,
    activeBlockID:    0,
    activeBlockGroup: null,
    isChanged:        true
  });
};

/**
 * @param {*} state
 */
const onCloneSelected = (state) => {
  const { selectedBlockIDs } = state;
  let { blocksAddedCount } = state;
  const blockGroups        = objects.clone(state.blockGroups);
  const sidebarBlockGroups = objects.clone(state.sidebarBlockGroups);
  const header             = objects.clone(state.header);

  if (selectedBlockIDs.length === 0) {
    return state;
  }

  let lastBlockGroupIndex = 0;
  selectedBlockIDs.forEach((blockID) => {
    const { blockGroupIndex } = builder.getGroupBlock(
      blockID,
      blockGroups,
      sidebarBlockGroups,
      header
    );
    if (blockGroupIndex > lastBlockGroupIndex) {
      lastBlockGroupIndex = blockGroupIndex;
    }
  });

  selectedBlockIDs.forEach((blockID) => {
    const { blockGroup, columnKey, foundAt } = builder.getGroupBlock(
      blockID,
      blockGroups,
      sidebarBlockGroups,
      header
    );

    if (blockGroup) {
      const newBlockGroup = cloneBlockGroup(blockGroup);

      if (foundAt === 'blockGroups') {
        blockGroups.splice(lastBlockGroupIndex + 1, 0, newBlockGroup);
      } else if (foundAt === 'sidebarBlockGroups') {
        sidebarBlockGroups.splice(lastBlockGroupIndex + 1, 0, newBlockGroup);
      } else if (foundAt === 'header') {
        header.columnBlockGroups[columnKey][lastBlockGroupIndex].splice(lastBlockGroupIndex + 1, 0, newBlockGroup);
      } else {
        const blockRowIndex = arrays.findIndexByID(blockGroups, foundAt);
        if (blockRowIndex !== -1) {
          const blockRow = blockGroups[blockRowIndex];
          const { columnBlockGroups } = blockRow.defaultBlock;
          // @todo
        }
      }

      lastBlockGroupIndex += 1;
      blocksAddedCount += 1;
    }
  });

  return pushChanges(state, {
    blockGroups,
    blocksAddedCount,
    sidebarBlockGroups,
    activeBlockID:    0,
    selectedBlockIDs: [],
    activeBlockGroup: null,
    isChanged:        true
  });
};

/**
 * @param {*} state
 */
const onGroupSelected = (state) => {
  const { selectedBlockIDs } = state;
  const blockGroups        = objects.clone(state.blockGroups);
  const sidebarBlockGroups = objects.clone(state.sidebarBlockGroups);
  const header             = objects.clone(state.header);

  if (selectedBlockIDs.length === 0) {
    return state;
  }

  let firstBlockIndex = null;
  const foundBlockGroups = [];
  selectedBlockIDs.forEach((blockID) => {
    const { blockGroup, blockGroupIndex } = builder.getGroupBlock(
      blockID,
      blockGroups,
      sidebarBlockGroups,
      header
    );

    if (blockGroup) {
      blockGroups.splice(blockGroupIndex, 1);
      foundBlockGroups.push(blockGroup);
      if (firstBlockIndex === null) {
        firstBlockIndex = blockGroupIndex;
      }
    }
  });

  const block = createBlock('group', {
    blockGroups: foundBlockGroups,
    styles:      {
      paddingTop:    '0px',
      paddingRight:  '0px',
      paddingBottom: '0px',
      paddingLeft:   '0px'
    }
  });
  const blockGroup = createBlockGroup(block);
  blockGroups.splice(firstBlockIndex, 0, blockGroup);

  return pushChanges(state, {
    blockGroups,
    sidebarBlockGroups,
    activeBlockID:    0,
    selectedBlockIDs: [],
    activeBlockGroup: null,
    isChanged:        true
  });
};

/**
 * @param {*} state
 * @param {*} action
 */
const onUngroupBlock = (state, action) => {
  const blockGroups        = objects.clone(state.blockGroups);
  const sidebarBlockGroups = objects.clone(state.sidebarBlockGroups);
  const header             = objects.clone(state.header);
  const { blockGroupID }   = action;

  const { blockGroup, blockGroupIndex } = builder.getGroupBlock(
    blockGroupID,
    blockGroups,
    sidebarBlockGroups,
    header
  );

  if (blockGroupIndex !== -1) {
    blockGroups.splice(blockGroupIndex, 1);

    blockGroup.defaultBlock.blockGroups.forEach((bg, i) => {
      const newBlockGroup = cloneBlockGroup(bg);
      blockGroups.splice(blockGroupIndex + i, 0, newBlockGroup);
    });
  }

  return pushChanges(state, {
    blockGroups,
    sidebarBlockGroups,
    activeBlockID:    0,
    selectedBlockIDs: [],
    activeBlockGroup: null,
    isChanged:        true
  });
};

/**
 * @param {*} state
 * @param {*} action
 */
const onActivateBlock = (state, action) => {
  const { blockGroups, sidebarBlockGroups, header } = state;
  const { activeBlockID, activeBlockElement } = action;

  if (activeBlockID === 0 && state.activeBlockID === 0) {
    return {
      ...state,
      selectedBlockIDs: []
    };
  }

  let activeRowID       = 0;
  let activeBlockGroup  = null;
  let activeBlockStyles = {};
  if (activeBlockID !== 0) {
    const { blockGroup, blockGroupIndex, defaultBlock, foundAt } = builder.getGroupBlock(
      activeBlockID,
      blockGroups,
      sidebarBlockGroups,
      header
    );

    if (blockGroupIndex === -1) {
      return state;
    }

    if (['blockGroups', 'sidebarBlockGroups', 'header', 'group'].indexOf(foundAt) === -1) {
      activeRowID = foundAt;
    }

    activeBlockGroup  = blockGroup;
    activeBlockStyles = builder.getBlockComputedStyles(activeBlockElement);
    const { styles } = defaultBlock;
    const inherits = {
      marginTop:     'margin-top',
      marginRight:   'margin-right',
      marginBottom:  'margin-bottom',
      marginLeft:    'margin-left',
      paddingTop:    'padding-top',
      paddingRight:  'padding-right',
      paddingBottom: 'padding-bottom',
      paddingLeft:   'padding-left'
    };
    Object.keys(inherits).forEach((key) => {
      if (styles[key] !== undefined && styles[key] !== null) {
        activeBlockStyles[inherits[key]] = styles[key];
      } else {
        styles[key] = activeBlockStyles[inherits[key]];
      }
    });
  }

  return {
    ...state,
    activeBlockGroup,
    activeBlockStyles,
    activeBlockID,
    activeRowID,
    headerEditing:    false,
    headerColEditing: -1,
    selectedBlockIDs: []
  };
};

/**
 * @param {*} state
 * @param {*} action
 */
const onActiveBlockStyles = (state, action) => {
  const { blockGroups, sidebarBlockGroups, header } = state;
  let { activeBlockStyles } = state;
  const { blockGroupID } = action;

  const { blockGroup, defaultBlock } = builder.getGroupBlock(
    blockGroupID,
    blockGroups,
    sidebarBlockGroups,
    header
  );

  if (blockGroup) {
    const element = document.getElementById(`block-${blockGroupID}`);
    if (element) {
      activeBlockStyles = builder.getBlockComputedStyles(element);
      const { styles } = defaultBlock;
      const inherits = {
        marginTop:     'margin-top',
        marginRight:   'margin-right',
        marginBottom:  'margin-bottom',
        marginLeft:    'margin-left',
        paddingTop:    'padding-top',
        paddingRight:  'padding-right',
        paddingBottom: 'padding-bottom',
        paddingLeft:   'padding-left'
      };
      Object.keys(inherits).forEach((key) => {
        if (styles[key] !== undefined && styles[key] !== null) {
          activeBlockStyles[inherits[key]] = styles[key];
        } else {
          styles[key] = activeBlockStyles[inherits[key]];
        }
      });
    }
  }

  return {
    ...state,
    activeBlockStyles,
  };
};

/**
 * @param {*} state
 * @param {*} action
 */
const onHoverBlock = (state, action) => {
  const { hoverBlockID } = action;

  return {
    ...state,
    hoverBlockID
  };
};

/**
 * @param {*} state
 * @param {*} action
 */
const onSelectedBlock = (state, action) => {
  const selectedBlockIDs = objects.clone(state.selectedBlockIDs);
  const { blockID } = action;

  if (Array.isArray(blockID)) {
    return {
      ...state,
      selectedBlockIDs: blockID
    };
  }

  const index = selectedBlockIDs.indexOf(blockID);
  if (index === -1) {
    selectedBlockIDs.push(blockID);
  } else {
    selectedBlockIDs.splice(index, 1);
  }

  return {
    ...state,
    selectedBlockIDs
  };
};

/**
 * @param {*} state
 */
const onCopySelectedBlocks = (state) => {
  const { blockGroups, sidebarBlockGroups, header, selectedBlockIDs } = state;

  const copiedBlockGroups = [];
  selectedBlockIDs.forEach((blockID) => {
    const { blockGroup } = builder.getGroupBlock(blockID, blockGroups, sidebarBlockGroups, header);
    if (blockGroup) {
      copiedBlockGroups.push(blockGroup);
    }
  });

  return {
    ...state,
    copiedBlockGroups
  };
};

/**
 * @param {*} state
 * @param {*} action
 */
const onUpdateEditorState = (state, action) => {
  const { editorState } = action;

  return {
    ...state,
    editorState
  };
};

/**
 * @param {*} state
 * @param {*} action
 */
const onUpdateHeadlineValue = (state, action) => {
  const { headlineValue } = action;

  return {
    ...state,
    headlineValue,
    isChanged: true
  };
};

/**
 * @param {*} state
 * @param {*} action
 */
const onUpdateOffer = (state, action) => {
  const { isChanged } = state;
  const offerValues = objects.clone(state.offerValues);
  const { key, value } = action;

  offerValues[key] = value;

  return {
    ...state,
    offerValues,
    isChanged: isChanged || !objects.isEqual(offerValues, state.offerValues)
  };
};

/**
 * @param {*} state
 * @param {*} action
 */
const onReplaceOffer = (state, action) => {
  const { blockGroups, sidebarBlockGroups, activeBlockID } = state;
  const offer = objects.clone(action.offer);

  let index = arrays.findIndexByID(blockGroups, activeBlockID);
  if (index !== -1) {
    const defaultID = blockGroups[index].defaultBlock.id;

    offer.id                        = genID();
    offer.parent                    = defaultID;
    offer.affiliateUrl              = '';
    offer.parting                   = { id: genID() };
    blockGroups[index].defaultBlock = offer;
    blockGroups[index].blocks       = [offer];
  } else {
    index = arrays.findIndexByID(sidebarBlockGroups, activeBlockID);
    if (index !== -1) {
      const defaultID    = blockGroups[index].defaultBlock.id;

      offer.id                               = genID();
      offer.parent                           = defaultID;
      offer.affiliateUrl                     = '';
      offer.parting                          = { id: genID() };
      sidebarBlockGroups[index].defaultBlock = offer;
      sidebarBlockGroups[index].blocks       = [offer];
    }
  }

  return pushChanges(state, {
    blockGroups,
    sidebarBlockGroups,
    activeBlockID: 0
  });
};

/**
 * @param {*} state
 * @param {*} action
 */
const onUpdateListicle = (state, action) => {
  const newState = objects.clone(state);
  const { key, value, pushState: ps } = action;

  if (key === 'site') {
    newState.anchor.site = value;
  } else if (key === 'path') {
    newState.anchor.path = value;
  } else if (key === 'isOnline') {
    newState.anchor.isOnline = value;
  } else if (key === 'styles') {
    newState.styles = objects.merge(newState.styles, value);
  } else {
    newState[key] = value;
  }

  if (key !== 'headerHeight') {
    newState.isChanged = true;
  }
  if (!ps) {
    return newState;
  }
  return pushChanges(state, newState);
};

/**
 * @param {*} state
 * @param {*} action
 */
const onUpdateBlock = (state, action) => {
  const { isChanged }           = state;
  let { activeBlockGroup }      = state;
  const newState                = objects.clone(state);
  const { key, value, blockGroupID } = action;

  const updated = updateBlock(blockGroupID, key, value, newState);
  if (updated) {
    const { activeBlockGroup: abg } = updated;
    if (abg) {
      activeBlockGroup = abg;
    }
  }

  return pushChanges(state, {
    activeBlockGroup,
    header:             newState.header,
    blockGroups:        newState.blockGroups,
    sidebarBlockGroups: newState.sidebarBlockGroups,
    isChanged:          isChanged || (!objects.isEqual(newState.blockGroups, state.blockGroups)
      || !objects.isEqual(newState.sidebarBlockGroups, state.sidebarBlockGroups))
      || !objects.isEqual(newState.header, state.header)
  });
};

/**
 * @param {*} state
 * @param {*} action
 */
const onUpdateBlockAll = (state, action) => {
  const { isChanged, header }      = state;
  const newState           = objects.clone(state);
  const blockGroups        = objects.clone(state.blockGroups);
  const sidebarBlockGroups = objects.clone(state.sidebarBlockGroups);
  const { values, groupID } = action;

  const { blockGroupIndex, columnKey, foundAt } = builder.getGroupBlock(
    groupID,
    blockGroups,
    sidebarBlockGroups,
    header
  );

  if (blockGroupIndex !== -1) {
    if (['blockGroups', 'sidebarBlockGroups'].indexOf(foundAt) !== -1) {
      updateDefaultBlockAll(newState[foundAt][blockGroupIndex], values);
    } else if (foundAt === 'header') {
      updateDefaultBlockAll(newState.header.columnBlockGroups[columnKey][blockGroupIndex], values);
    } else {
      const blockRowIndex = arrays.findIndexByID(newState.blockGroups, foundAt);
      if (blockRowIndex !== -1) {
        const blockRow = newState.blockGroups[blockRowIndex];
        const { columnBlockGroups } = blockRow.defaultBlock;
        updateDefaultBlockAll(columnBlockGroups[blockGroupIndex], values);
        updateDefaultBlock(blockRow, 'columnBlockGroups', columnBlockGroups);
      }
    }
  }

  return pushChanges(state, {
    header:             newState.header,
    blockGroups:        newState.blockGroups,
    sidebarBlockGroups: newState.sidebarBlockGroups,
    isChanged:          isChanged || (!objects.isEqual(newState.blockGroups, state.blockGroups)
      || !objects.isEqual(newState.sidebarBlockGroups, state.sidebarBlockGroups))
      || !objects.isEqual(newState.header, state.header)
  });
};

/**
 * @param {*} state
 * @param {*} action
 */
const onUpdateBlockGroup = (state, action) => {
  const { isChanged }      = state;
  const blockGroups        = objects.clone(state.blockGroups);
  const sidebarBlockGroups = objects.clone(state.sidebarBlockGroups);
  const { id, key, value } = action;
  const { hoverBlockID, activeBlockID } = state;
  let { activeBlockGroup } = state;

  if (!id && activeBlockID) {
    let index = arrays.findIndexByID(blockGroups, activeBlockID);
    if (index !== -1) {
      blockGroups[index][key] = value;
    } else {
      index = arrays.findIndexByID(sidebarBlockGroups, activeBlockID);
      if (index !== -1) {
        sidebarBlockGroups[index][key] = value;
      }
    }
  } else if (hoverBlockID) {
    let index = arrays.findIndexByID(blockGroups, hoverBlockID);
    if (index !== -1) {
      blockGroups[index][key] = value;
    } else {
      index = arrays.findIndexByID(sidebarBlockGroups, hoverBlockID);
      if (index !== -1) {
        sidebarBlockGroups[index][key] = value;
      }
    }
  } else if (id) {
    let index = arrays.findIndexByID(blockGroups, id);
    if (index !== -1) {
      blockGroups[index][key] = value;
      if (activeBlockID === blockGroups[index].id) {
        activeBlockGroup = blockGroups[index];
      }
    } else {
      index = arrays.findIndexByID(sidebarBlockGroups, id);
      if (index !== -1) {
        sidebarBlockGroups[index][key] = value;
        if (activeBlockID === sidebarBlockGroups[index].id) {
          activeBlockGroup = sidebarBlockGroups[index];
        }
      }
    }
  }

  return pushChanges(state, {
    blockGroups,
    sidebarBlockGroups,
    activeBlockGroup,
    isChanged: isChanged || (!objects.isEqual(blockGroups, state.blockGroups)
      || !objects.isEqual(sidebarBlockGroups, state.sidebarBlockGroups))
  });
};

/**
 * @param {*} state
 * @param {*} action
 */
const onUpdateSelected = (state, action) => {
  const { selectedBlockIDs, isChanged } = state;
  const { key, value } = action;

  const newState = objects.clone(state);
  selectedBlockIDs.forEach((blockID) => {
    updateBlock(blockID, key, value, newState);
  });

  return pushChanges(state, {
    header:             newState.header,
    blockGroups:        newState.blockGroups,
    sidebarBlockGroups: newState.sidebarBlockGroups,
    isChanged:          isChanged || (!objects.isEqual(newState.blockGroups, state.blockGroups)
      || !objects.isEqual(newState.sidebarBlockGroups, state.sidebarBlockGroups))
                          || !objects.isEqual(newState.header, state.header)
  });
};

/**
 * @param {*} state
 * @param {*} action
 */
const onAddBlockVariation = (state, action) => {
  const blockGroups        = objects.clone(state.blockGroups);
  const sidebarBlockGroups = objects.clone(state.sidebarBlockGroups);
  const { blockGroupID }   = action;

  let index = arrays.findIndexByID(blockGroups, blockGroupID);
  if (index !== -1) {
    const blockGroup = blockGroups[index];
    const variation  = objects.clone(blockGroup.defaultBlock);

    variation.id         = genID();
    variation.parting.id = genID();
    blockGroup.blocks.push(variation);
    blockGroup.defaultBlock = variation;
  } else {
    index = arrays.findIndexByID(sidebarBlockGroups, blockGroupID);
    if (index !== -1) {
      const blockGroup = sidebarBlockGroups[index];
      const variation  = objects.clone(blockGroup.defaultBlock);

      variation.id         = genID();
      variation.parting.id = genID();
      blockGroup.blocks.push(variation);
      blockGroup.defaultBlock = variation;
    }
  }

  return pushChanges(state, {
    blockGroups,
    sidebarBlockGroups,
    isChanged: true
  });
};

/**
 * @param {*} state
 * @param {*} action
 */
const onRemoveBlockVariation = (state, action) => {
  const blockGroups        = objects.clone(state.blockGroups);
  const sidebarBlockGroups = objects.clone(state.sidebarBlockGroups);
  const { blockGroupID, blockID } = action;

  let index = arrays.findIndexByID(blockGroups, blockGroupID);
  if (index !== -1) {
    const blockGroup = blockGroups[index];
    const bIndex = arrays.findIndexByID(blockGroup.blocks, blockID);
    if (bIndex !== -1) {
      blockGroup.blocks.splice(bIndex, 1);
    }

    if (blockGroup.defaultBlock.id === blockID) {
      if (blockGroup.blocks.length === 0) {
        blockGroups.splice(index, 1);
      } else {
        blockGroup.defaultBlock = blockGroup.blocks[0]; // eslint-disable-line
      }
    }
  } else {
    index = arrays.findIndexByID(sidebarBlockGroups, blockGroupID);
    if (index !== -1) {
      const blockGroup = sidebarBlockGroups[index];
      const bIndex = arrays.findIndexByID(blockGroup.blocks, blockID);
      if (bIndex !== -1) {
        blockGroup.blocks.splice(bIndex, 1);
      }

      if (blockGroup.defaultBlock.id === blockID) {
        if (blockGroup.blocks.length === 0) {
          sidebarBlockGroups.splice(index, 1);
        } else {
          blockGroup.defaultBlock = blockGroup.blocks[0]; // eslint-disable-line
        }
      }
    }
  }

  return pushChanges(state, {
    blockGroups,
    sidebarBlockGroups,
    isChanged: true
  });
};

/**
 * @param {*} state
 * @param {*} action
 */
const onSetDefaultBlock = (state, action) => {
  const blockGroups = objects.clone(state.blockGroups);
  const sidebarBlockGroups = objects.clone(state.sidebarBlockGroups);
  const { blockGroupID, blockID } = action;
  let { isChanged } = state;

  let index = arrays.findIndexByID(blockGroups, blockGroupID);
  if (index !== -1) {
    if (blockGroups[index].defaultBlock.id !== blockID) {
      blockGroups[index].defaultBlock = objects.clone(
        arrays.findByID(blockGroups[index].blocks, blockID)
      );
      isChanged = true;
    }
  } else {
    index = arrays.findIndexByID(sidebarBlockGroups, blockGroupID);
    if (index !== -1) {
      if (sidebarBlockGroups[index].defaultBlock.id !== blockID) {
        sidebarBlockGroups[index].defaultBlock = objects.clone(
          arrays.findByID(sidebarBlockGroups[index].blocks, blockID)
        );
        isChanged = true;
      }
    }
  }

  return pushChanges(state, {
    blockGroups,
    sidebarBlockGroups,
    isChanged
  });
};

/**
 * @param {*} state
 * @param {*} action
 */
const onUpdateTestSettings = (state, action) => {
  const blockGroups        = objects.clone(state.blockGroups);
  const sidebarBlockGroups = objects.clone(state.sidebarBlockGroups);
  let { activeBlockGroup } = state;
  const { blockGroupID, key, value } = action;

  let index = arrays.findIndexByID(blockGroups, blockGroupID);
  if (index !== -1) {
    const { defaultBlock } = blockGroups[index];
    const bIndex = arrays.findIndexByID(blockGroups[index].blocks, defaultBlock.id);
    blockGroups[index].defaultBlock.parting[key]   = value;
    blockGroups[index].blocks[bIndex].parting[key] = value;
    activeBlockGroup = blockGroups[index];
  } else {
    index = arrays.findIndexByID(sidebarBlockGroups, blockGroupID);
    if (index !== -1) {
      const { defaultBlock } = sidebarBlockGroups[index];
      const bIndex = arrays.findIndexByID(sidebarBlockGroups[index].blocks, defaultBlock.id);
      sidebarBlockGroups[index].defaultBlock.parting[key]   = value;
      sidebarBlockGroups[index].blocks[bIndex].parting[key] = value;
      activeBlockGroup = sidebarBlockGroups[index];
    }
  }

  return {
    ...state,
    blockGroups,
    activeBlockGroup,
    sidebarBlockGroups,
    isChanged: true
  };
};

/**
 * @param {*} state
 * @param {*} action
 */
const onDeleteBlockGroup = (state, action) => {
  let { blocksRemovedCount } = state;
  const { blockGroupID }     = action;
  const newState             = objects.clone(state);
  const header               = objects.clone(state.header);
  const blockGroups          = objects.clone(state.blockGroups);
  const sidebarBlockGroups   = objects.clone(state.sidebarBlockGroups);

  const { blockGroupIndex, foundAt, groupID, columnKey } = builder.getGroupBlock(
    blockGroupID,
    blockGroups,
    sidebarBlockGroups,
    header
  );

  if (blockGroupIndex !== -1) {
    if (['blockGroups', 'sidebarBlockGroups'].indexOf(foundAt) !== -1) {
      newState[foundAt].splice(blockGroupIndex, 1);
    } else if (groupID !== null) {
      const groupIndex = arrays.findIndexByID(newState.blockGroups, groupID);
      if (groupIndex !== -1) {
        if (foundAt === 'group') {
          newState.blockGroups[groupIndex].defaultBlock.blockGroups.splice(blockGroupIndex, 1);
        } else {
          // Layout inside of a group.
          const index    = arrays.findIndexByID(newState.blockGroups[groupIndex].defaultBlock.blockGroups, foundAt);
          const blockRow = newState.blockGroups[groupIndex].defaultBlock.blockGroups[index];
          const { columnBlockGroups } = blockRow.defaultBlock;
          columnBlockGroups.splice(blockGroupIndex, 1);
          updateDefaultBlock(blockRow, 'columnBlockGroups', columnBlockGroups);
        }
        for (let i = 0; i < newState.blockGroups[groupIndex].blocks.length; i++) {
          if (newState.blockGroups[groupIndex].blocks[i].id === newState.blockGroups[groupIndex].defaultBlock.id) {
            newState.blockGroups[groupIndex].blocks[i] = newState.blockGroups[groupIndex].defaultBlock;
          }
        }
      }
    } else if (foundAt === 'header') {
      newState.header.columnBlockGroups[columnKey].splice(blockGroupIndex, 1);
    } else {
      const blockRowIndex = arrays.findIndexByID(newState.blockGroups, foundAt);
      if (blockRowIndex !== -1) {
        const blockRow = newState.blockGroups[blockRowIndex];
        const { columnBlockGroups } = blockRow.defaultBlock;

        columnBlockGroups.splice(blockGroupIndex, 1);
        updateDefaultBlock(blockRow, 'columnBlockGroups', columnBlockGroups);
      }
    }
  }

  blocksRemovedCount += 1;

  return pushChanges(state, {
    header:             newState.header,
    blockGroups:        newState.blockGroups,
    sidebarBlockGroups: newState.sidebarBlockGroups,
    isChanged:          true,
    activeBlockID:      0,
    activeRowID:        0,
    blocksRemovedCount,
  });
};

/**
 * @param {*} state
 */
const onDeleteSelectedBlocks = (state) => {
  let { blocksRemovedCount } = state;
  const { selectedBlockIDs } = state;
  const newState             = objects.clone(state);

  for (let i = 0; i < selectedBlockIDs.length; i++) {
    const blockID = selectedBlockIDs[i];
    const { blockGroupIndex, foundAt, columnKey } = builder.getGroupBlock(
      blockID,
      newState.blockGroups,
      newState.sidebarBlockGroups,
      newState.header
    );

    if (blockGroupIndex !== -1) {
      if (foundAt !== 'header') {
        newState[foundAt].splice(blockGroupIndex, 1);
      } else {
        newState.header.columnBlockGroups[columnKey].splice(blockGroupIndex, 1);
      }
    }

    blocksRemovedCount += 1;
  }

  return pushChanges(state, {
    header:             newState.header,
    blockGroups:        newState.blockGroups,
    sidebarBlockGroups: newState.sidebarBlockGroups,
    selectedBlockIDs:   [],
    isChanged:          true,
    activeBlockID:      0,
    blocksRemovedCount,
  });
};

/**
 * @param {*} state
 * @param {*} action
 */
const onSave = (state, action) => {
  const lander = objects.clone(action.lander);

  return {
    ...state,
    id:        lander.id,
    anchor:    lander.anchor,
    origSite:  lander.origSite,
    origPath:  lander.origPath,
    isSaved:   true,
    isChanged: false
  };
};

/**
 * @param {*} state
 * @param {*} action
 */
const onToggleCanvasView = (state, action) => {
  const { isCanvasView } = state;
  const { previewURL } = action;

  return {
    ...state,
    previewURL:   previewURL || '',
    isCanvasView: !isCanvasView
  };
};

/**
 * @param {*} state
 * @param {*} action
 */
const onUpdatePreviewURL = (state, action) => {
  const { previewURL } = action;

  return {
    ...state,
    previewURL
  };
};

/**
 * @param {*} state
 * @param {*} action
 */
const onSetCompactView = (state, action) => {
  const { isCompactView } = action;

  return {
    ...state,
    isCompactView,
    isDesignView: false
  };
};

/**
 * @param {*} state
 * @param {*} action
 */
const onSetDesignView = (state, action) => {
  const { isDesignView } = action;

  return {
    ...state,
    isDesignView,
    isCompactView: false
  };
};

/**
 * @param {*} state
 * @param {*} action
 */
const onPreviewCount = (state, action) => {
  const { previewCount } = action;

  return {
    ...state,
    previewCount
  };
};

/**
 * @param {*} state
 * @param {*} action
 */
const onUpdateWidgets = (state, action) => {
  const widgets = objects.clone(action.widgets);

  return {
    ...state,
    widgets
  };
};

/**
 * @param {*} state
 * @param {*} action
 */
const onSetTemplate = (state, action) => {
  const { template } = action;

  if (state.template !== null && state.template.id !== template.id) {
    return pushChanges(state, {
      template,
      isChanged: true
    });
  }
  if (state.template === null && template !== null) {
    return pushChanges(state, {
      template,
      isChanged: true
    });
  }

  return state;
};

/**
 * @param {*} state
 * @param {*} action
 */
const onIncrementWordCount = (state, action) => {
  let { wordCount } = state;
  const { value } = action;

  wordCount += value;

  return {
    ...state,
    wordCount
  };
};

/**
 * @param {*} state
 * @param {*} action
 */
const onMarginHovering = (state, action) => {
  const { marginHovering } = action;

  return {
    ...state,
    marginHovering
  };
};

/**
 * @param {*} state
 * @param {*} action
 */
const onFooterEditing = (state, action) => {
  const { footerEditing } = action;

  return {
    ...state,
    footerEditing
  };
};

/**
 * @param {*} state
 * @param {*} action
 */
const onHeaderEditing = (state, action) => {
  const { headerEditing } = action;

  return {
    ...state,
    headerEditing,
    headerColEditing: -1
  };
};

/**
 * @param {*} state
 * @param {*} action
 */
const onHeaderColEditing = (state, action) => {
  const { headerColEditing } = action;

  return {
    ...state,
    activeBlockID: 0,
    headerColEditing
  };
};

/**
 * @param {*} state
 * @param {*} action
 */
const onActiveColEditing = (state, action) => {
  const { activeColEditing } = action;

  return {
    ...state,
    activeColEditing
  };
};

/**
 * @param {*} state
 * @param {*} action
 */
const onUpdateHeader = (state, action) => {
  const { header, headerColEditing } = state;
  const { key, value } = action;
  const newHeader = objects.clone(header);

  if (key === 'columnCount') {
    const count           = parseInt(value, 10);
    newHeader.columnCount = count;
    for (let i = 0; i < count; i++) {
      if (!header.columnWidths[i]) {
        newHeader.columnWidths[i] = 2;
      }
      if (!header.columnBlockGroups[i]) {
        newHeader.columnBlockGroups[i] = [];
      }
    }
  } else if (key === 'columnWidth') {
    newHeader.columnWidths[headerColEditing] = parseInt(value, 10);
  } else if (key === 'columnJustify') {
    newHeader.columnJustifies[headerColEditing] = value;
  } else if (key === 'columnAlign') {
    newHeader.columnAligns[headerColEditing] = value;
  } else {
    newHeader[key] = value;
  }

  return pushChanges(state, {
    header:    newHeader,
    isChanged: true
  });
};

/**
 * @param {*} state
 * @param {*} action
 */
const onCloneStylesToAll = (state, action) => {
  const { blockGroups, sidebarBlockGroups, header } = state;
  const { blockGroupID } = action;
  const newBlockGroups = objects.clone(blockGroups);

  const { blockGroup, blockGroupIndex, groupID, foundAt, defaultBlock } = builder.getGroupBlock(
    blockGroupID,
    blockGroups,
    sidebarBlockGroups,
    header
  );
  if (blockGroupIndex === -1) {
    return state;
  }

  let { styles } = defaultBlock;

  if (groupID !== null) {
    const groupIndex = arrays.findIndexByID(newBlockGroups, groupID);
    if (groupIndex !== -1) {
      if (foundAt === 'group') {
        // eslint-disable-next-line prefer-destructuring
        styles = newBlockGroups[groupIndex].defaultBlock.blockGroups[blockGroupIndex].defaultBlock.styles;
      } else {
        const index = arrays.findIndexByID(newBlockGroups[groupIndex].defaultBlock.blockGroups, foundAt);
        // eslint-disable-next-line prefer-destructuring
        styles = newBlockGroups[groupIndex].defaultBlock.blockGroups[index].defaultBlock.styles;
      }
    }
  }

  newBlockGroups.forEach((bg) => {
    if (bg.id !== blockGroup.id && defaultBlock.type === bg.defaultBlock.type) {
      updateDefaultBlock(bg, 'styles', styles);
    }
    bg.defaultBlock.blockGroups.forEach((bg2) => {
      if (defaultBlock.type === bg2.defaultBlock.type) {
        updateDefaultBlock(bg2, 'styles', styles);
      }
      bg2.defaultBlock.columnBlockGroups.forEach((cbg) => {
        if (defaultBlock.type === cbg.defaultBlock.type) {
          updateDefaultBlock(cbg, 'styles', styles);
        }
      });
      updateDefaultBlock(bg2, 'columnBlockGroups', bg2.defaultBlock.columnBlockGroups);
    });
  });

  const newSidebarBlockGroups = objects.clone(sidebarBlockGroups);
  newSidebarBlockGroups.forEach((bg) => {
    if (bg.id !== blockGroup.id && defaultBlock.type === bg.defaultBlock.type) {
      bg.defaultBlock.styles = defaultBlock.styles;
      bg.blocks.forEach((block) => {
        if (block.id === bg.defaultBlock.id) {
          block.styles = defaultBlock.styles;
        }
      });
    }
  });

  return pushChanges(state, {
    blockGroups:        newBlockGroups,
    sidebarBlockGroups: newSidebarBlockGroups
  });
};

/**
 * @param {*} state
 * @param {*} action
 */
const onImportListicle = (state, action) => {
  const { data } = action;

  return {
    ...state,
    ...data
  };
};

/**
 * @param {*} state
 * @param {*} action
 */
const onImportGlobalStyles = (state, action) => {
  const { globalStyles } = action;

  return pushChanges(state, {
    globalStyles
  });
};

/**
 * @param {*} state
 * @param {*} action
 */
const onSavingPreview = (state, action) => {
  return {
    ...state,
    isSavingPreview: action.isSavingPreview
  };
};

const handlers = {
  [types.LISTICLE_BUSY]:                   onBusy,
  [types.LISTICLE_DROP]:                   onDrop,
  [types.LISTICLE_DROP_HEADER]:            onDropHeader,
  [types.LISTICLE_SAVE]:                   onSave,
  [types.LISTICLE_UNDO]:                   onUndo,
  [types.LISTICLE_INIT]:                   onInit,
  [types.LISTICLE_CLEAR_BLOCK_GROUPS]:     onClearBlockGroups,
  [types.LISTICLE_SET_DRAGGING]:           onSetDragging,
  [types.LISTICLE_INSERT_BLOCK]:           onInsertBlock,
  [types.LISTICLE_PUSH_STATE]:             onPushState,
  [types.LISTICLE_BLOCK_CLONE]:            onBlockClone,
  [types.LISTICLE_BLOCK_MOVE]:             onBlockMove,
  [types.LISTICLE_MOVE_SELECTED]:          onMoveSelected,
  [types.LISTICLE_CHANGED]:                onChanged,
  [types.LISTICLE_ADD_BUILDER_BLOCK]:      onAddBuilderBlock,
  [types.LISTICLE_UPDATE_WIDGETS]:         onUpdateWidgets,
  [types.LISTICLE_PREVIEW_COUNT]:          onPreviewCount,
  [types.LISTICLE_DELETE_BLOCK_GROUP]:     onDeleteBlockGroup,
  [types.LISTICLE_UPDATE_BLOCK]:           onUpdateBlock,
  [types.LISTICLE_UPDATE_BLOCK_ALL]:       onUpdateBlockAll,
  [types.LISTICLE_UPDATE_BLOCK_GROUP]:     onUpdateBlockGroup,
  [types.LISTICLE_UPDATE_SELECTED]:        onUpdateSelected,
  [types.LISTICLE_UPDATE_TEST_SETTINGS]:   onUpdateTestSettings,
  [types.LISTICLE_UPDATE_LISTICLE]:        onUpdateListicle,
  [types.LISTICLE_ADD_BLOCK_VARIATION]:    onAddBlockVariation,
  [types.LISTICLE_REMOVE_BLOCK_VARIATION]: onRemoveBlockVariation,
  [types.LISTICLE_SET_DEFAULT_BLOCK]:      onSetDefaultBlock,
  [types.LISTICLE_ACTIVATE_BLOCK]:         onActivateBlock,
  [types.LISTICLE_ACTIVATE_BLOCK_STYLES]:  onActiveBlockStyles,
  [types.LISTICLE_HOVER_BLOCK]:            onHoverBlock,
  [types.LISTICLE_SELECT_BLOCK]:           onSelectedBlock,
  [types.LISTICLE_UPDATE_OFFER]:           onUpdateOffer,
  [types.LISTICLE_REPLACE_OFFER]:          onReplaceOffer,
  [types.LISTICLE_UPDATE_EDITOR_STATE]:    onUpdateEditorState,
  [types.LISTICLE_UPDATE_HEADLINE_VALUE]:  onUpdateHeadlineValue,
  [types.LISTICLE_TOGGLE_CANVAS_VIEW]:     onToggleCanvasView,
  [types.LISTICLE_UPDATE_PREVIEW_URL]:     onUpdatePreviewURL,
  [types.LISTICLE_SET_COMPACT_VIEW]:       onSetCompactView,
  [types.LISTICLE_SET_DESIGN_VIEW]:        onSetDesignView,
  [types.LISTICLE_SET_TEMPLATE]:           onSetTemplate,
  [types.LISTICLE_INCREMENT_WORD_COUNT]:   onIncrementWordCount,
  [types.LISTICLE_SET_MARGIN_HOVERING]:    onMarginHovering,
  [types.LISTICLE_SET_FOOTER_EDITING]:     onFooterEditing,
  [types.LISTICLE_SET_HEADER_EDITING]:     onHeaderEditing,
  [types.LISTICLE_SET_HEADER_COL_EDITING]: onHeaderColEditing,
  [types.LISTICLE_SET_ACTIVE_COL_EDITING]: onActiveColEditing,
  [types.LISTICLE_UPDATE_HEADER]:          onUpdateHeader,
  [types.LISTICLE_CLONE_STYLES_TO_ALL]:    onCloneStylesToAll,
  [types.LISTICLE_COPY_SELECTED_BLOCKS]:   onCopySelectedBlocks,
  [types.LISTICLE_DELETE_SELECTED_BLOCKS]: onDeleteSelectedBlocks,
  [types.LISTICLE_CLONE_SELECTED]:         onCloneSelected,
  [types.LISTICLE_GROUP_SELECTED]:         onGroupSelected,
  [types.LISTICLE_UNGROUP_BLOCK]:          onUngroupBlock,
  [types.LISTICLE_IMPORT_LISTICLE]:        onImportListicle,
  [types.LISTICLE_IMPORT_GLOBAL_STYLES]:   onImportGlobalStyles,
  [types.LISTICLE_SAVING_PREVIEW]:         onSavingPreview
};

/**
 * @param {*} state
 * @param {*} action
 * @returns {*}
 */
export default function listicleReducer(state = objects.clone(initialState), action = {}) {
  if (handlers[action.type]) {
    return handlers[action.type].call(null, state, action);
  }

  return state;
}
