import * as types from '@builder/actions/formActions';
import { objects } from 'utils';

/**
 *
 */
export const commonValues = {
  errorMessage:   '',
  successMessage: '',
  errorFields:    {},
  isChanged:      false,
  isSubmitting:   false,
  isSubmitted:    false,
  isSuccess:      false,
  isComplete:     false,
  isInitialized:  false
};

const initialState = {
  previewSettings: {
    userAgent: navigator.userAgent,
    ...commonValues
  },
  headerSettings: {
    columnCount: 3,
    ...commonValues
  },
  colorEditor: {
    color:           '#FFFFFF',
    backgroundColor: '#000000',
    ...commonValues
  }
};

/**
 * @param {*} state
 * @param {*} action
 * @returns {*}
 */
function formInit(state, action) {
  const { formName } = action;
  const newState = objects.clone(state);

  if (newState[formName] === undefined) {
    newState[formName] = objects.clone(commonValues);
  }
  newState[formName].isInitialized = true;

  return newState;
}

/**
 * @param {*} state
 * @param {*} action
 * @returns {*}
 */
function formChange(state, action) {
  if (state[action.formName] === undefined) {
    throw new Error(`Form ${action.formName} not found.`);
  }

  const form = objects.clone(state[action.formName]);
  form[action.name] = action.value;
  form.isChanged    = true;

  return {
    ...state,
    [action.formName]: form
  };
}

/**
 * @param {*} state
 * @param {*} action
 * @returns {*}
 */
function formChanges(state, action) {
  if (state[action.formName] === undefined) {
    throw new Error(`Form ${action.formName} not found.`);
  }

  const form   = objects.clone(state[action.formName]);
  const values = objects.clone(action.values);

  Object.keys(values).forEach((key) => {
    form[key] = values[key];
  });

  return {
    ...state,
    [action.formName]: form
  };
}

/**
 * @param {*} state
 * @param {*} action
 * @returns {*}
 */
function formSubmitting(state, action) {
  const form = objects.merge(state[action.formName], {
    isSubmitting: action.isSubmitting
  });
  if (form.isSubmitting) {
    form.isSubmitted = false;
  }

  return {
    ...state,
    [action.formName]: form
  };
}

/**
 * @param {*} state
 * @param {*} action
 * @returns {*}
 */
function formSubmitted(state, action) {
  const form = objects.merge(state[action.formName], {
    isSubmitted:  action.isSubmitted,
    isSubmitting: false
  });

  return {
    ...state,
    [action.formName]: form
  };
}

/**
 * @param {*} state
 * @param {*} action
 * @returns {*}
 */
function formError(state, action) {
  const form = objects.merge(state[action.formName], {
    errorMessage:   action.errorMessage,
    errorFields:    objects.clone(action.errorFields),
    successMessage: ''
  });

  form.successMessage = '';
  if (form.errors && action.errors) {
    form.errors = action.errors;
  }
  if (form.successes) {
    form.successes = [];
  }

  return {
    ...state,
    [action.formName]: form
  };
}

/**
 * @param {*} state
 * @param {*} action
 * @returns {*}
 */
function formSuccess(state, action) {
  const form = objects.merge(state[action.formName], {
    successMessage: action.successMessage,
    errorMessage:   '',
    errorFields:    {}
  });
  if (form.errors) {
    form.errors = [];
  }
  if (form.successes && action.successes) {
    form.successes = action.successes;
  }

  return {
    ...state,
    [action.formName]: form
  };
}

/**
 * @param {*} state
 * @param {*} action
 * @returns {*}
 */
function formReset(state, action) {
  let form = objects.clone(state[action.formName]);

  if (initialState[action.formName]) {
    form = objects.clone(initialState[action.formName]);
  } else {
    Object.keys(form).forEach((key) => {
      switch (typeof form[key]) {
        case 'string':
          form[key] = '';
          break;
        case 'boolean':
          form[key] = false;
          break;
      }
    });
  }

  form.isInitialized = true;

  return {
    ...state,
    [action.formName]: form
  };
}

/**
 * @param {*} state
 * @param {*} action
 * @returns {*}
 */
function formComplete(state, action) {
  const form = objects.merge(state[action.formName], {
    isComplete: action.isComplete
  });

  return {
    ...state,
    [action.formName]: form
  };
}

/**
 * @param {*} state
 * @param {*} action
 * @returns {*}
 */
export default function formsReducer(state = initialState, action = {}) {
  switch (action.type) {
    case types.FORMS_INIT:
      return formInit(state, action);
    case types.FORMS_CHANGE:
      return formChange(state, action);
    case types.FORMS_CHANGES:
      return formChanges(state, action);
    case types.FORMS_SUBMITTING:
      return formSubmitting(state, action);
    case types.FORMS_SUBMITTED:
      return formSubmitted(state, action);
    case types.FORMS_ERROR:
      return formError(state, action);
    case types.FORMS_SUCCESS:
      return formSuccess(state, action);
    case types.FORMS_RESET:
      return formReset(state, action);
    case types.FORMS_COMPLETE:
      return formComplete(state, action);
    default: return state;
  }
}
