import * as types from '@builder/actions/mediaActions';
import { objects } from 'utils';

const initialState = {
  library:       [],
  libraryTotal:  0,
  libraryOffset: 0,
  selected:      null
};

/**
 * @param {*} state
 * @param {*} action
 * @returns {*}
 */
const onSelect = (state, action) => {
  const selected = objects.clone(action.asset);

  return {
    ...state,
    selected
  };
};

/**
 * @param {*} state
 * @param {*} action
 * @returns {*}
 */
const onLibraryFetch = (state, action) => {
  const { library, libraryTotal, libraryOffset } = action;

  let newLibrary;
  if (libraryOffset !== 0) {
    newLibrary = objects.clone(state.library).concat(library);
  } else {
    newLibrary = objects.clone(library);
  }

  return {
    ...state,
    libraryTotal,
    libraryOffset,
    library: newLibrary
  };
};

const handlers = {
  [types.MEDIA_SELECT]:        onSelect,
  [types.MEDIA_LIBRARY_FETCH]: onLibraryFetch
};

/**
 * @param {*} state
 * @param {*} action
 * @returns {*}
 */
export default function mediaReducer(state = objects.clone(initialState), action = {}) {
  if (handlers[action.type]) {
    return handlers[action.type].call(null, state, action);
  }

  return state;
}
