import { clipboard } from 'utils';
import BlockHtml from '@builder/components/blocks/BlockHtml';
import BlockText from '@builder/components/blocks/BlockText';
import BlockStars from '@builder/components/blocks/BlockStars';
// import BlockOffer from '@builder/components/blocks/BlockOffer';
import BlockLogo from '@builder/components/blocks/BlockLogo';
import BlockImage from '@builder/components/blocks/BlockImage';
import BlockYoutube from '@builder/components/blocks/BlockYoutube';
import BlockButton from '@builder/components/blocks/BlockButton';
import BlockSlideshow from '@builder/components/blocks/BlockSlideshow';
// import BlockHeadline from '@builder/components/blocks/BlockHeadline';
import BlockCounterHeadline from '@builder/components/blocks/BlockCounterHeadline';
// import BlockRow from '@builder/components/blocks/BlockRow';
import BlockForm from '@builder/components/blocks/BlockForm';
import BlockQuote from '@builder/components/blocks/BlockQuote';
import BlockSocial from '@builder/components/blocks/BlockSocial';
import BlockFlow from '@builder/components/blocks/BlockFlow';

export const commonBuilderBlocks = {
  headline:          '',
  shortHeadline:     '',
  imageUrl:          '',
  imageBytes:        0,
  videoUrl:          '',
  slideshow:         [],
  affiliateUrl:      '',
  testAffiliateUrl:  '',
  testSettings:      {},
  html:              '',
  button:            '',
  isBonus:           false,
  isHidden:          false,
  testPercent:       0,
  classes:           '',
  scripts:           '',
  footer:            '',
  counterText:       '',
  counterVisible:    false,
  countable:         false,
  variables:         '',
  parting:           {},
  product:           null,
  headlineSize:      '1',
  ratings:           0,
  stars:             0,
  form:              {},
  displayDesktop:    true,
  displayLaptop:     true,
  displayTablet:     true,
  displayMobile:     true,
  blockGroups:       [],
  columnCount:       0,
  columnWidths:      {},
  columnStyles:      {},
  columnSettings:    {},
  columnBlockGroups: [],
  styles:            {
    marginTop:     '0px',
    marginRight:   '0px',
    marginBottom:  '15px',
    marginLeft:    '0px',
    paddingTop:    '15px',
    paddingRight:  '15px',
    paddingBottom: '15px',
    paddingLeft:   '15px'
  },
  dimensions: {
    widthXl:  '100%',
    heightXl: 'auto',
    alignXl:  'center',
    widthMd:  '100%',
    heightMd: 'auto',
    alignMd:  'center',
    widthSm:  '100%',
    heightSm: 'auto',
    alignSm:  'center',
    widthXs:  '100%',
    heightXs: 'auto',
    alignXs:  'center'
  }
};

let idIndex = 1;
/**
 * @returns {number}
 */
const genID = () => {
  idIndex += 1;
  return idIndex;
};

export const blocks = {
  [BlockText.typeName]:            BlockText,
  [BlockImage.typeName]:           BlockImage,
  [BlockQuote.typeName]:           BlockQuote,
  [BlockButton.typeName]:          BlockButton,
  [BlockCounterHeadline.typeName]: BlockCounterHeadline,
  [BlockStars.typeName]:           BlockStars,
  [BlockYoutube.typeName]:         BlockYoutube,
  [BlockSocial.typeName]:          BlockSocial,
  // [BlockHeadline.typeName]:        BlockHeadline,
  [BlockSlideshow.typeName]:       BlockSlideshow,
  // [BlockRow.typeName]:             BlockRow,
  [BlockForm.typeName]:            BlockForm,
  [BlockFlow.typeName]:            BlockFlow,
  // [BlockOffer.typeName]:           BlockOffer,
  [BlockLogo.typeName]:            BlockLogo,
  [BlockHtml.typeName]:            BlockHtml,
};

const builderBlocks = [];
Object.keys(blocks).forEach((key) => {
  builderBlocks.push({
    ...commonBuilderBlocks,
    ...blocks[key].defaultValues,
    type: blocks[key].typeName,
    id:   genID(),
  });
});

if (clipboard.has('block')) {
  const block = clipboard.get('block');
  block.isPasted = true;
  builderBlocks.push(block);
}

export default builderBlocks;

const columnStyle = {
  display:        'flex',
  flexDirection:  'column',
  justifyContent: 'center',
  height:         '100%'
};
const columnSettings = {
  widthXs: '12',
  widthSm: '12'
};
const createColumnStyles = (num) => {
  const styles = [];
  for (let i = 0; i < num; i++) {
    styles.push(Object.assign({}, columnStyle));
  }

  return styles;
};

export const builderLayouts = [
  {
    id:             1,
    name:           'Single Column',
    columnCount:    1,
    columnWidths:   [12],
    columnSettings: Object.assign({}, columnSettings),
    columnStyles:   createColumnStyles(1)
  },
  {
    id:             2,
    name:           'Two Columns',
    columnCount:    2,
    columnWidths:   [6, 6],
    columnSettings: Object.assign({}, columnSettings),
    columnStyles:   createColumnStyles(2)
  },
  {
    id:             3,
    name:           'Three Columns',
    columnCount:    3,
    columnWidths:   [4, 4, 4],
    columnSettings: Object.assign({}, columnSettings),
    columnStyles:   createColumnStyles(4)
  },
  {
    id:             4,
    name:           'Four Columns',
    columnCount:    4,
    columnWidths:   [3, 3, 3, 3],
    columnSettings: Object.assign({}, columnSettings),
    columnStyles:   createColumnStyles(4)
  },
  {
    id:             5,
    name:           'Double Flank',
    columnCount:    3,
    columnWidths:   [2, 8, 2],
    columnSettings: Object.assign({}, columnSettings),
    columnStyles:   createColumnStyles(3)
  },
  {
    id:             6,
    name:           'Heavy Right',
    columnCount:    2,
    columnWidths:   [5, 7],
    columnSettings: Object.assign({}, columnSettings),
    columnStyles:   createColumnStyles(2)
  },
  {
    id:             7,
    name:           'Heavy Left',
    columnCount:    2,
    columnWidths:   [7, 5],
    columnSettings: Object.assign({}, columnSettings),
    columnStyles:   createColumnStyles(2)
  }
];
