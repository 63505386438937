import React from 'react';
import PropTypes from 'prop-types';
import { objects, connect, mapDispatchToProps } from 'utils';
import { Icon } from 'components';
import { uiActions } from 'apps/builder/actions';
import Checkbox from './Checkbox';
import Input from './Input';
import Select from './Select';
import Textarea from './Textarea';
import ColorPicker from './ColorPicker';

@connect(
  null,
  mapDispatchToProps({ uiModal: uiActions.uiModal })
)
export default class FormBuilder extends React.PureComponent {
  static propTypes = {
    fields:  PropTypes.object.isRequired,
    uiModal: PropTypes.func.isRequired
  };

  /**
   * @param {*} props
   */
  constructor(props) {
    super(props);

    this.html = React.createRef();
  }

  /**
   *
   */
  handleBigEditorClick = () => {
    const { uiModal } = this.props;

    uiModal('htmlEditor', true, {
      html: this.html.current.getValue(),
      cb:   (html) => {
        this.html.current.setValue(html);
      }
    });
  };

  /**
   * @returns {*}
   */
  render() {
    const { fields } = this.props;

    const children = [];
    objects.forEach(fields, (values, key) => {
      switch (values.type) {
        case 'file':
          children.push(
            <Input
              key={key}
              name={key}
              type="file"
              accept={values.accept || undefined}
              label={values.label || ''}
              id={`form-builder-${key}-input`}
            />
          );
          break;
        case 'checkbox':
          children.push(
            <Checkbox
              key={key}
              name={key}
              label={values.label || ''}
              id={`form-builder-${key}-input`}
            />
          );
          break;
        case 'textarea':
          children.push(
            <Textarea
              key={key}
              name={key}
              label={values.label || ''}
              id={`form-builder-${key}-input`}
              style={{ height: 150 }}
            />
          );
          break;
        case 'html':
          children.push(
            <Textarea
              key={key}
              name={key}
              ref={this.html}
              id={`form-builder-${key}-input`}
              style={{ height: 100 }}
              label={(
                <>
                  {values.label || ''}&nbsp;
                  <Icon
                    name="window-maximize"
                    title="Open in big editor"
                    className="pointer float-right"
                    onClick={this.handleBigEditorClick}
                  />
                </>
              )}
            />
          );
          break;
        case 'select':
          children.push(
            <Select
              key={key}
              name={key}
              label={values.label || ''}
              id={`form-builder-${key}-input`}
              values={values.values}
            />
          );
          break;
        case 'color':
          children.push(
            <ColorPicker
              key={key}
              name={key}
              label={values.label || ''}
              defaultValue={values.default || ''}
              id={`form-builder-${key}-input`}
            />
          );
          break;
        default:
          children.push(
            <Input
              key={key}
              name={key}
              type={values.type}
              label={values.label || ''}
              id={`form-builder-${key}-input`}
            />
          );
          break;
      }
    });

    return children;
  }
}
