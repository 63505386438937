import styles from '../../css/_vars.scss';

export const breakpoints = {
  xs: parseInt(styles.breakXs, 10),
  sm: parseInt(styles.breakSm, 10),
  md: parseInt(styles.breakMd, 10),
  lg: parseInt(styles.breakLg, 10),
  xl: parseInt(styles.breakXl, 10)
};

export const deviceHeights = {
  xs: 480,
  sm: 960,
  md: 540,
  lg: 720,
  xl: 1200
};

export const editor = {
  headerHeight:  parseInt(styles.headerHeight, 10),
  menuBarHeight: 24
};

export const defaultArbStyles = {
  '!container:@media-xs:width': '100%',
  '!container:@media-sm:width': '100%',
  '!container:@media-md:width': '60%',
  '!container:@media-xl:width': '60%',
  '!sidebar:@media-xs:width':   '100%',
  '!sidebar:@media-sm:width':   '100%',
  '!sidebar:@media-md:width':   '25%',
  '!sidebar:@media-xl:width':   '25%'
};

export const globalStyleSelectors = {
  textColor:              '.arb-text:color',
  textSecondaryColor:     '!.arb-text:secondaryColor',
  textFontSize:           '.arb-text:fontSize',
  textMarginBottom:       '.arb-text:marginBottom',
  headlineColor:          'h1,h2,h3:color',
  headlineSecondaryColor: '!h1,h2,h3:secondaryColor',
  headlineMarginBottom:   'h1,h2,h3:marginBottom',
  imageMarginBottom:      'img:marginBottom',
  buttonColor:            '.arb-button:color',
  buttonSecondaryColor:   '!.arb-button:secondaryColor',
  buttonMarginBottom:     '.arb-button:marginBottom',
  quoteColor:             '.arb-quote:color',
  quoteSecondaryColor:    '!.arb-quote:secondaryColor',
  quoteMarginBottom:      '.arb-quote:marginBottom'
};

export const popupOffset = 40;
