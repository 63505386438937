import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import YouTube from 'react-youtube';
import Vimeo from '@u-wave/react-vimeo';
import { connect, video, mapDispatchToProps } from 'utils';
import { listicleActions } from '@builder/actions';
import BlockWrapper from '@builder/components/BlockWrapper';
import PopupTools from '@builder/components/PopupTools';

const BlockYoutube = ({ blockGroup, updateBlock }) => {
  const element = useRef(null);

  const { defaultBlock } = blockGroup;

  return (
    <BlockWrapper blockGroup={blockGroup} className="arb-youtube-video">
      {(provided) => {
        if (provided.isActive) {
          return (
            <>
              <PopupTools
                position="left"
                className="builder-canvas-video-popup"
                getTarget={() => element.current}
                visible
              >
                <label>Youtube/Vimeo URL</label>
                <input
                  value={defaultBlock.videoUrl}
                  className="form-control builder-canvas-block-youtube-input arb-block"
                  placeholder="YouTube/Vimeo URL"
                  onChange={e => updateBlock(defaultBlock.id, blockGroup.id, 'videoUrl', e.target.value)}
                />
              </PopupTools>
              <div ref={element}>
                {video.getProvider(defaultBlock.videoUrl) === 'youtube' ? (
                  <YouTube
                    containerClassName="builder-canvas-block-youtube-iframe-wrapper"
                    videoId={video.extractYoutubeId(defaultBlock.videoUrl)}
                  />
                ) : (
                  <Vimeo
                    video={defaultBlock.videoUrl}
                    height={380}
                  />
                )}
              </div>
            </>
          );
        }

        return (
          <>
            <div className="builder-canvas-block-youtube-cover" />
            {video.getProvider(defaultBlock.videoUrl) === 'youtube' ? (
              <YouTube
                containerClassName="builder-canvas-block-youtube-iframe-wrapper"
                videoId={video.extractYoutubeId(defaultBlock.videoUrl)}
              />
            ) : (
              <Vimeo
                video={defaultBlock.videoUrl}
                height={380}
              />
            )}
          </>
        );
      }}
    </BlockWrapper>
  );
};

BlockYoutube.propTypes = {
  active:      PropTypes.bool.isRequired,
  blockGroup:  PropTypes.object.isRequired,
  updateBlock: PropTypes.func.isRequired
};

BlockYoutube.typeName      = 'youtube';
BlockYoutube.icon          = 'youtube';
BlockYoutube.label         = 'Video';
BlockYoutube.defaultValues = {
  videoUrl: 'https://www.youtube.com/watch?v=glcqRnCXIUE',
  styles:   { marginBottom: 15 }
};

export default connect(
  null,
  mapDispatchToProps(listicleActions)
)(BlockYoutube);
