import * as types from '@builder/actions/widgetsActions';
import { objects } from 'utils';

const initialState = objects.merge({
  widgets: []
}, window.initialState.widgets);

/**
 * @param {*} state
 * @param {*} action
 */
const onSave = (state, action) => {
  const widgets = objects.clone(action.widgets);

  return {
    ...state,
    widgets
  };
};

/**
 * @param {*} state
 * @param {*} action
 */
const onUpdate = (state, action) => {
  const widgets = objects.clone(action.widgets);

  return {
    ...state,
    widgets
  };
};

/**
 * @param {*} state
 * @param {*} action
 */
const onDelete = (state, action) => {
  const widgets = objects.clone(action.widgets);

  return {
    ...state,
    widgets
  };
};

/**
 * @param {*} state
 * @param {*} action
 */
const onFetch = (state, action) => {
  const widgets = objects.clone(action.widgets);

  return {
    ...state,
    widgets
  };
};

const handlers = {
  [types.WIDGETS_SAVE]:   onSave,
  [types.WIDGETS_UPDATE]: onUpdate,
  [types.WIDGETS_DELETE]: onDelete,
  [types.WIDGETS_FETCH]:  onFetch
};

/**
 * @param {*} state
 * @param {*} action
 * @returns {*}
 */
export default function widgetsReducer(state = objects.clone(initialState), action = {}) {
  if (handlers[action.type]) {
    return handlers[action.type].call(null, state, action);
  }

  return state;
}
