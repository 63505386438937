/**
 * @param {string} colorCode
 * @param {number} amount
 * @returns {string}
 */
const lightenDarkenColor = (colorCode, amount) => {
  let usePound = false;

  if (colorCode[0] === '#') {
    colorCode = colorCode.slice(1);
    usePound = true;
  }

  const num = parseInt(colorCode, 16);
  let r     = (num >> 16) + amount;
  let b     = ((num >> 8) & 0x00FF) + amount;
  let g     = (num & 0x0000FF) + amount;

  if (r > 255) {
    r = 255;
  } else if (r < 0) {
    r = 0;
  }

  if (b > 255) {
    b = 255;
  } else if (b < 0) {
    b = 0;
  }

  if (g > 255) {
    g = 255;
  } else if (g < 0) {
    g = 0;
  }

  return (usePound ? '#' : '') + (g | (b << 8) | (r << 16)).toString(16);
};

/**
 * @param {string} numStr
 * @param {number} percent
 * @returns {*}
 */
export const componentFromStr = (numStr, percent) => {
  const num = Math.max(0, parseInt(numStr, 10));
  return percent ? Math.floor(255 * Math.min(100, num) / 100) : Math.min(255, num);
};

/**
 * @param {string} rgb
 * @returns {string}
 */
export const rgbToHex = (rgb) => {
  const rgbRegex = /^rgba?\(\s*(-?\d+)(%?)\s*,\s*(-?\d+)(%?)\s*,\s*(-?\d+)(%?)\s*(,\s*(-?\d+)(%?)\s*)?\)$/;
  let result;
  let r;
  let g;
  let b;
  let hex = '';
  if ((result = rgbRegex.exec(rgb))) { // eslint-disable-line
    r = componentFromStr(result[1], result[2]);
    g = componentFromStr(result[3], result[4]);
    b = componentFromStr(result[5], result[6]);

    hex = `#${(0x1000000 + (r << 16) + (g << 8) + b).toString(16).slice(1)}`; // eslint-disable-line
  }

  return hex;
};

/**
 * @param {string} hex
 * @returns {*}
 */
export const hexToRgb = (hex) => {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result ? {
    r: parseInt(result[1], 16),
    g: parseInt(result[2], 16),
    b: parseInt(result[3], 16)
  } : null;
};

/**
 * @param {string} hex
 * @param {number} opacity
 * @returns {string}
 */
export const hexToRgbA = (hex, opacity = 1) => {
  let c;
  if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
    c = hex.substring(1).split('');
    if (c.length === 3) {
      c = [c[0], c[0], c[1], c[1], c[2], c[2]];
    }
    c = '0x' + c.join(''); // eslint-disable-line
    return 'rgba(' + [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',') + ',' + opacity + ')'; // eslint-disable-line
  }
  throw new Error('Bad Hex');
};

export default {
  rgbToHex,
  hexToRgb,
  hexToRgbA,
  lightenDarkenColor,
  componentFromStr
};
