import * as types from '@builder/actions/uiActions';
import { objects, browser } from 'utils';

const initialState = {
  meta: {
    help:         {},
    error:        {},
    cropper:      {},
    testSettings: {},
    htmlEditor:   {},
    createWidget: { id: 0 },
    geoTemplates: {}
  },
  modals: {
    help:          false,
    error:         false,
    offers:        false,
    cropper:       false,
    blockSettings: false,
    testSettings:  false,
    htmlEditor:    false,
    geoTemplates:  false,
    pageSettings:  false
  },
  slideUps: {
    pixels: false
  },
  sidebarSections: {
    previewSettings:     browser.storage.getItem('cms:ui:sidebarSections:previewSettings', true),
    blockSettings:       browser.storage.getItem('cms:ui:sidebarSections:blockSettings', true),
    imageSettings:       browser.storage.getItem('cms:ui:sidebarSections:imageSettings', true),
    blockStyles:         browser.storage.getItem('cms:ui:sidebarSections:blockStyles', true),
    blockGeo:            browser.storage.getItem('cms:ui:sidebarSections:blockGeo', false),
    blockTime:           browser.storage.getItem('cms:ui:sidebarSections:blockTime', false),
    blockPlacement:      browser.storage.getItem('cms:ui:sidebarSections:blockPlacement', false),
    layoutSettings:      browser.storage.getItem('cms:ui:sidebarSections:layoutSettings', false),
    landerSettings:      browser.storage.getItem('cms:ui:sidebarSections:landerSettings', true),
    templateSettings:    browser.storage.getItem('cms:ui:sidebarSections:templateSettings', false),
    linkSettings:        browser.storage.getItem('cms:ui:sidebarSections:linkSettings', false),
    blockRequestParting: browser.storage.getItem('cms:ui:sidebarSections:blockRequestParting', false),
    footerSettings:      browser.storage.getItem('cms:ui:sidebarSections:footerSettings', false),
    headerSettings:      browser.storage.getItem('cms:ui:sidebarSections:headerSettings', false),
    sidebarSettings:     browser.storage.getItem('cms:ui:sidebarSections:sidebarSettings', false),
    selectedSettings:    browser.storage.getItem('cms:ui:sidebarSections:selectedSettings', false),
    libraries:           browser.storage.getItem('cms:ui:sidebarSections:libraries', false),
    pageStyles:          browser.storage.getItem('cms:ui:sidebarSections:pageStyles', false),
  },
  selectionVisible:    false,
  prevSidebarSections: {},
  previewDevice:       'xl',
  leftSidebarTab:      'blocks',
  blockStyleTool:      'margin',
  quillToolbarExtras:  ''
};

/**
 * @param {*} state
 * @param {*} action
 */
const onModal = (state, action) => {
  const modals = objects.clone(state.modals);
  const meta   = objects.clone(state.meta);
  const { name, opened, modalClose } = action;

  modals[name] = opened;
  meta[name]   = objects.clone(action.meta);
  meta[name].modalClose = modalClose;

  return {
    ...state,
    meta,
    modals
  };
};

/**
 * @param {*} state
 * @param {*} action
 */
const onSlideUp = (state, action) => {
  const slideUps = objects.clone(state.slideUps);
  const { name, opened } = action;

  if (opened === undefined) {
    slideUps[name] = !slideUps[name];
  } else {
    slideUps[name] = opened;
  }

  return {
    ...state,
    slideUps
  };
};

/**
 * @param {*} state
 * @param {*} action
 */
const onPreviewDevice = (state, action) => {
  const { previewDevice } = action;

  return {
    ...state,
    previewDevice
  };
};

/**
 * @param {*} state
 * @param {*} action
 * @returns {*}
 */
const onToggleSection = (state, action) => {
  const { section, open } = action;
  const sidebarSections = objects.clone(state.sidebarSections);

  if (open !== null) {
    sidebarSections[section] = open;
  } else {
    sidebarSections[section] = !sidebarSections[section];
  }
  browser.storage.setItem(`cms:ui:sidebarSections:${section}`, sidebarSections[section]);

  return {
    ...state,
    sidebarSections
  };
};

/**
 * @param {*} state
 * @param {*} action
 * @returns {*}
 */
const onChangePreviewSection = (state, action) => {
  const { open } = action;
  const sidebarSections     = objects.clone(state.sidebarSections);
  const prevSidebarSections = objects.clone(state.prevSidebarSections);

  sidebarSections.previewSettings = open;

  const actionable = ['landerSettings', 'templateSettings', 'linkSettings'];
  if (open) {
    actionable.forEach((key) => {
      prevSidebarSections[key] = sidebarSections[key];
      sidebarSections[key]     = false;
    });
  } else {
    actionable.forEach((key) => {
      sidebarSections[key]     = prevSidebarSections[key];
    });
  }

  return {
    ...state,
    sidebarSections,
    prevSidebarSections
  };
};

/**
 * @param {*} state
 * @param {*} action
 */
const onBlockStyleTool = (state, action) => {
  const { blockStyleTool } = action;

  return {
    ...state,
    blockStyleTool
  };
};

/**
 * @param {*} state
 * @param {*} action
 */
const onSelectionVisible = (state, action) => {
  const { selectionVisible } = action;

  return {
    ...state,
    selectionVisible
  };
};

/**
 * @param {*} state
 * @param {*} action
 */
const onLeftSidebarTab = (state, action) => {
  const { leftSidebarTab } = action;

  return {
    ...state,
    leftSidebarTab
  };
};

/**
 * @param {*} state
 * @param {*} action
 */
const onQuillToolbarExtras = (state, action) => {
  const { quillToolbarExtras } = action;

  return {
    ...state,
    quillToolbarExtras
  };
};

const handlers = {
  [types.UI_MODAL]:                  onModal,
  [types.UI_SLIDEUP]:                onSlideUp,
  [types.UI_TOGGLE_SECTION]:         onToggleSection,
  [types.UI_PREVIEW_DEVICE]:         onPreviewDevice,
  [types.UI_CHANGE_PREVIEW_SECTION]: onChangePreviewSection,
  [types.UI_QUILL_TOOLBAR_EXTRAS]:   onQuillToolbarExtras,
  [types.UI_SELECTION_VISIBLE]:      onSelectionVisible,
  [types.UI_SET_BLOCK_STYLE_TOOL]:   onBlockStyleTool,
  [types.UI_LEFT_SIDEBAR_TAB]:       onLeftSidebarTab
};

/**
 * @param {*} state
 * @param {*} action
 * @returns {*}
 */
export default function uiReducer(state = objects.clone(initialState), action = {}) {
  if (handlers[action.type]) {
    return handlers[action.type].call(null, state, action);
  }

  return state;
}
