import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Icon } from 'components';

/**
 *
 */
export default class ButtonLink extends React.PureComponent {
  static propTypes = {
    href:      PropTypes.string.isRequired,
    target:    PropTypes.string,
    sm:        PropTypes.bool,
    lg:        PropTypes.bool,
    far:       PropTypes.bool,
    fas:       PropTypes.bool,
    fab:       PropTypes.bool,
    icon:      PropTypes.string,
    active:    PropTypes.bool,
    className: PropTypes.string,
    children:  PropTypes.node,
    onClick:   PropTypes.func
  };

  static defaultProps = {
    sm:        false,
    lg:        false,
    far:       false,
    fas:       false,
    fab:       false,
    active:    false,
    target:    '_self',
    icon:      '',
    className: '',
    children:  '',
    onClick:   () => {}
  };

  /**
   * @returns {*}
   */
  render() {
    const { href, target, sm, lg, far, fas, fab, icon, active, className, children, onClick, ...props } = this.props;

    const classes = classNames('btn-editor', className, {
      'btn-editor-sm':     sm,
      'btn-editor-lg':     lg,
      'btn-editor-active': active
    });

    if (icon) {
      return (
        <a href={href} target={target} className={classes} onClick={onClick} {...props}>
          <Icon name={icon} far={far} fas={fas} fab={fab} />
          {children && (
            <span className="btn-editor-label">
              {children}
            </span>
          )}
        </a>
      );
    }

    return (
      <a href={href} target={target} className={classes} onClick={onClick} {...props}>
        {children}
      </a>
    );
  }
}
