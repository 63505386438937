import React from 'react';
import PropTypes from 'prop-types';
import { connect, mapDispatchToProps } from 'utils';
import { SimpleForm, Checkbox, Input } from 'components/forms';
import { listicleActions } from '@builder/actions';
import BlockWrapper from '@builder/components/BlockWrapper';
import QuillEditor from '@builder/components/QuillEditor';

const BlockCounterHeadline = ({ blockGroup, offerNumber, updateBlock }) => {
  const { defaultBlock } = blockGroup;

  const counterStyles = {
    color:           defaultBlock.styles['!counter:color'],
    backgroundColor: defaultBlock.styles['!counter:backgroundColor']
  };

  return (
    <BlockWrapper blockGroup={blockGroup} className="arb-counter-headline w-100">
      {(provided) => {
        if (provided.isActive) {
          const toolbarExtras = (
            <SimpleForm
              values={defaultBlock}
              onChange={(e, v, k) => updateBlock(defaultBlock.id, blockGroup.id, k, v)}
            >
              <Checkbox
                name="counterVisible"
                label="Counter Visible"
                id="builder-counter-headline-counter-visible"
              />
              <Input
                name="counterText"
                placeholder="Counter Text"
                id="builder-counter-headline-counter-text"
                formGroupClassName="mb-0 pb-0"
              />
            </SimpleForm>
          );

          return (
            <div className="arb-counter-headline">
              {defaultBlock.counterVisible && (
                <div className="arb-counter" style={counterStyles}>
                  {defaultBlock.counterText || offerNumber}
                </div>
              )}
              <QuillEditor
                html={defaultBlock.html}
                canChangeFontSize={false}
                getElement={provided.getElement}
                toolbarExtras={toolbarExtras}
                onUpdate={html => updateBlock(blockGroup.defaultBlock.id, blockGroup.id, 'html', html)}
              />
            </div>
          );
        }

        return (
          <div className="arb-counter-headline">
            {defaultBlock.counterVisible && (
              <div className="arb-counter" style={counterStyles}>
                {defaultBlock.counterText || offerNumber}
              </div>
            )}
            <span dangerouslySetInnerHTML={{ __html: defaultBlock.html }} />
          </div>
        );
      }}
    </BlockWrapper>
  );
};

BlockCounterHeadline.propTypes = {
  blockGroup:  PropTypes.object.isRequired,
  offerNumber: PropTypes.number,
  updateBlock: PropTypes.func.isRequired
};

BlockCounterHeadline.defaultProps = {
  offerNumber: 0
};

BlockCounterHeadline.typeName      = 'counter-headline';
BlockCounterHeadline.icon          = 'heading';
BlockCounterHeadline.label         = 'Headline';
BlockCounterHeadline.defaultValues = {
  html:   '<h2>Lorem ipsum dolor sit amet, consectetur adipiscing elit</h2>',
  styles: {
    marginBottom:               15,
    textAlign:                  'left',
    '!counter:color':           '#FFFFFF',
    '!counter:backgroundColor': '#000000'
  },
  dimensions: {
    '@media-xl:width':          '100%',
    '@media-md:width':          '100%',
    '@media-sm:width':          '100%',
    '@media-xs:width':          '100%',
    '@media-xl:height':         'auto',
    '@media-md:height':         'auto',
    '@media-sm:height':         'auto',
    '@media-xs:height':         'auto',
    '@media-xl:justifyContent': 'left',
    '@media-md:justifyContent': 'left',
    '@media-sm:justifyContent': 'left',
    '@media-xs:justifyContent': 'left',
  }
};

export default connect(
  null,
  mapDispatchToProps(listicleActions)
)(BlockCounterHeadline);
