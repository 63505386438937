import React from 'react';
import PropTypes from 'prop-types';
import ContentEditable from 'react-contenteditable';
import { browser, strings, objects } from 'utils';
import { Icon } from 'components';
import PopupTools from 'apps/builder/components/PopupTools';

export default class LinkableContentEditable extends React.PureComponent {
  static propTypes = {
    id:               PropTypes.string,
    url:              PropTypes.string.isRequired,
    html:             PropTypes.string.isRequired,
    style:            PropTypes.object,
    className:        PropTypes.string,
    wrapperClassName: PropTypes.string,
    onBlur:           PropTypes.func,
    onChange:         PropTypes.func
  };

  static defaultProps = {
    id:               '',
    style:            {},
    className:        '',
    wrapperClassName: '',
    detachedToolbar:  false,
    onBlur:           () => {},
    onChange:         () => {}
  };

  /**
   * @param {*} props
   */
  constructor(props) {
    super(props);

    this.editable = React.createRef();
    this.state = {
      cmds: {
        link:   false,
        bold:   false,
        italic: false,
      }
    };
  }

  /**
   *
   */
  componentDidMount() {
    browser.placeCaretAtEnd(this.editable.current);
    setTimeout(() => {
      this.handleClick();
    }, 100);

    $(document).on('paste', '[contenteditable]', this.handlePaste);
  }

  /**
   *
   */
  componentWillUnmount() {
    $(document).off('paste', '[contenteditable]', this.handlePaste);
  }

  /**
   *
   */
  focus = () => {
    this.editable.current.focus();
  };

  /**
   * @returns {string}
   */
  getSelection = () => {
    let text = '';
    if (window.getSelection) {
      text = window.getSelection().toString();
    } else if (document.selection && document.selection.type !== 'Control') {
      text = document.selection.createRange().text; // eslint-disable-line
    }

    return text;
  };

  /**
   * @param {Event} e
   */
  handlePaste = (e) => {
    e.preventDefault();

    let text = '';
    if (e.clipboardData || e.originalEvent.clipboardData) {
      text = (e.originalEvent || e).clipboardData.getData('text/plain');
    } else if (window.clipboardData) {
      text = window.clipboardData.getData('Text');
    }

    if (document.queryCommandSupported('insertText')) {
      document.execCommand('insertText', false, text);
    } else {
      document.execCommand('paste', false, text);
    }
  };

  /**
   *
   */
  handleLinkClick = () => {
    const { url } = this.props;
    const { cmds } = this.state;

    const newCmds = objects.clone(cmds);
    if (newCmds.link) {
      document.execCommand('unlink', false, '');
      newCmds.link = false;
    } else {
      const text = this.getSelection();
      if (text !== '') {
        document.execCommand('createLink', false, url);
        newCmds.link = true;
      } else {
        newCmds.link = false;
      }
    }

    this.setState({ cmds: newCmds });
  };

  /**
   * @param {string} cmd
   * @param {string} value
   */
  handleCommandClick = (cmd, value = '') => {
    const { html, onChange } = this.props;

    if (cmd === 'clear') {
      onChange({
        target: {
          value: strings.stripTags(html)
        }
      });
      this.setState({
        cmds: {
          link:   false,
          bold:   false,
          italic: false,
        }
      });
    } else {
      const text = this.getSelection();
      if (text === '') {
        document.execCommand('selectAll', false, null);
      }
      document.execCommand(cmd, false, value);
      this.handleClick();
    }
  };

  /**
   *
   */
  handleClick = () => {
    const { cmds } = this.state;

    const newCmds = objects.clone(cmds);
    Object.keys(newCmds).forEach((key) => {
      newCmds[key] = document.queryCommandState(key);
    });

    const node = browser.getSelectedNode();
    if (node) {
      newCmds.link = browser.hasParentTag(node, 'A');
    }

    this.setState({ cmds: newCmds });
  };

  /**
   * @returns {*}
   */
  render() {
    const { id, html, style, wrapperClassName, className, onChange, onBlur } = this.props;
    const { cmds } = this.state;

    const newStyles = objects.clone(style);
    delete newStyles.paddingTop;
    delete newStyles.paddingRight;
    delete newStyles.paddingBottom;
    delete newStyles.paddingLeft;

    return (
      <div id={id} className={`form-content-editable ${wrapperClassName}`}>
        <PopupTools position="left" getTarget={() => this.editable.current} visible>
          <Icon
            name="bold"
            title="Bold"
            className={cmds.bold ? 'active' : ''}
            onClick={() => this.handleCommandClick('bold')}
          />
          <Icon
            name="italic"
            title="Italic"
            className={cmds.italic ? 'active' : ''}
            onClick={() => this.handleCommandClick('italic')}
          />
          <Icon
            name="eraser"
            title="Remove text formatting"
            onClick={() => this.handleCommandClick('clear')}
          />
          <Icon
            name="dollar-sign"
            title="Affiliate URL Link"
            className={cmds.link ? 'active affiliateurl' : 'affiliateurl'}
            onClick={this.handleLinkClick}
          />
        </PopupTools>
        <ContentEditable
          innerRef={this.editable}
          html={html}
          style={newStyles}
          className={className}
          onChange={onChange}
          onClick={this.handleClick}
          onBlur={onBlur}
        />
      </div>
    );
  }
}
