import React from 'react';
import PropTypes from 'prop-types';
import FormContext from './formContext';

export default class SimpleForm extends React.PureComponent {
  static propTypes = {
    onChange:  PropTypes.func,
    children:  PropTypes.node,
    className: PropTypes.string,
    values:    PropTypes.object,
    style:     PropTypes.object
  };

  static defaultProps = {
    style:     {},
    values:    {},
    className: '',
    onChange:  () => {}
  };

  /**
   * @param {Event} e
   * @param {string} value
   * @param {string} name
   */
  handleChange = (e, value, name) => {
    const { onChange } = this.props;

    const event = {
      target: {}
    };
    if (e.target) {
      event.target = e.target;
    }
    event.target.name  = name;
    event.target.value = value;

    onChange(event, value, name);
  };

  /**
   * @returns {*}
   */
  render() {
    const { values, style, className, children } = this.props;

    const contextValue = {
      values,
      onChange:    this.handleChange,
      errorFields: {}
    };

    return (
      <form className={className} style={style}>
        <FormContext.Provider value={contextValue}>
          {children}
        </FormContext.Provider>
      </form>
    );
  }
}
