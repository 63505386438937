import React from 'react';
import PropTypes from 'prop-types';
import Quill from 'quill';
import 'quill-emoji';
import { browser, connect, mapDispatchToProps } from 'utils';
import { listicleActions, uiActions, mediaActions, libraryActions } from '@builder/actions';
import { popupOffset } from 'utils/styles';

window.Quill = Quill; // Libraries need this.
const FontStyle     = Quill.import('attributors/style/font');
FontStyle.whitelist = null;
Quill.register(FontStyle, true);

const AlignStyle = Quill.import('attributors/style/align');
AlignStyle.whitelist = null;
Quill.register(AlignStyle, true);

const Inline = Quill.import('blots/inline');
class AffiliateUrlBlock extends Inline {
  static create(value) {
    const node = super.create(value);
    node.setAttribute('href', '#affiliateUrl');
    return node;
  }
}

AffiliateUrlBlock.blotName = 'affiliateurl';
AffiliateUrlBlock.tagName  = 'a';

// Fixes browser spell check from deleting words.
// @see https://github.com/quilljs/quill/issues/2096
class CustomColor extends Inline {
  constructor(domNode, value) {
    super(domNode, value);
    domNode.style.color = domNode.color;
    const span = this.replaceWith(new Inline(Inline.create()));
    span.children.forEach((child) => {
      if (child.attributes) child.attributes.copy(span);
      if (child.unwrap) child.unwrap();
    });
    this.remove();
    return span;
  }
}
CustomColor.blotName = 'customColor';
CustomColor.tagName  = 'FONT';
Quill.register(CustomColor, true);

class EmojiBlot extends Inline {
  static create(value) {
    const node = super.create();
    if (typeof value === 'object') {
      node.innerHTML = value.code_decimal;
    }

    return node;
  }

  static value(node) {
    return node.dataset.name;
  }
}

EmojiBlot.blotName = 'emoji';
EmojiBlot.tagName  = 'span';
Quill.register({
  'formats/emoji': EmojiBlot
}, true);

// Prevent quill from removing "emoji" class from images inserted by twemoji
const Token = Quill.import('formats/image');
Token.className = 'emoji';
Token.blotName = 'emoji-image';
Quill.register(Token);

const Parchment = Quill.import('parchment');
const levels = [1, 2, 3, 4, 5];
const multiplier = 2;

// Replace indent class with inline styles.
class IndentAttributor extends Parchment.Attributor.Style {
  add(node, value) {
    return super.add(node, `${value * multiplier}em`);
  }

  value(node) {
    return parseFloat(super.value(node)) / multiplier || undefined; // Don't return NaN
  }
}

const IndentStyle = new IndentAttributor('indent', 'margin-left', {
  scope:     Parchment.Scope.BLOCK,
  whitelist: levels.map(value => `${value * multiplier}em`)
});

Quill.register(IndentStyle);

const icons = Quill.import('ui/icons');
icons.bold          = '<i class="fa fa-bold" aria-hidden="true" />';
icons.italic        = '<i class="fa fa-italic" aria-hidden="true" />';
icons.underline     = '<i class="fa fa-underline" aria-hidden="true" />';
icons.strike        = '<i class="fa fa-strikethrough" aria-hidden="true" />';
icons.blockquote    = '<i class="fa fa-quote-right" aria-hidden="true" />';
icons.align['']     = '<i class="fa fa-align-left" aria-hidden="true" />';
icons.align.left    = '<i class="fa fa-align-left" aria-hidden="true" />';
icons.align.center  = '<i class="fa fa-align-center" aria-hidden="true" />';
icons.align.right   = '<i class="fa fa-align-right" aria-hidden="true" />';
icons.align.justify = '<i class="fa fa-align-justify" aria-hidden="true" />';
icons.background    = '<i class="fa fa-highlighter" aria-hidden="true" />';
icons.clean         = '<i class="fa fa-eraser" aria-hidden="true" />';
icons.color         = '<i class="fa fa-font" aria-hidden="true" />';
icons.image         = '<i class="fa fa-image" aria-hidden="true" />';
icons.indent['+1']  = '<i class="fa fa-indent" aria-hidden="true" />';
icons.indent['-1']  = '<i class="fa fa-outdent" aria-hidden="true" />';
icons.link          = '<i class="fa fa-link" aria-hidden="true" />';
icons.list          = '<i class="fa fa-list" aria-hidden="true" />';
icons.script        = '<i class="fa fa-subscript" aria-hidden="true" />';
icons.video         = '<i class="fa fa-video" aria-hidden="true" />';
icons.affiliateurl  = '<i class="fa fa-dollar-sign" aria-hidden="true" />';
icons.htmleditor    = '<i class="fa fa-code" aria-hidden="true" />';
icons.emoji         = '<i class="fa fa-emoji" aria-hidden="true" />';

@connect(
  null,
  mapDispatchToProps(listicleActions, uiActions, mediaActions, libraryActions)
)
export default class QuillEditor extends React.PureComponent {
  static propTypes = {
    html:                 PropTypes.string.isRequired,
    style:                PropTypes.object,
    autoFocus:            PropTypes.bool,
    getElement:           PropTypes.func.isRequired,
    toolbarOffsetTop:     PropTypes.number,
    toolbarExtras:        PropTypes.node,
    canChangeFontSize:    PropTypes.bool,
    affiliateOnly:        PropTypes.bool,
    onUpdate:             PropTypes.func.isRequired,
    className:            PropTypes.string,
    uploadFile:           PropTypes.func.isRequired,
    replaceFile:          PropTypes.func.isRequired,
    setFooterEditing:     PropTypes.func.isRequired,
    activateBlock:        PropTypes.func.isRequired,
    uiModal:              PropTypes.func.isRequired,
    uiQuillToolbarExtras: PropTypes.func.isRequired,
    libraryCallFunction:  PropTypes.func.isRequired
  };

  static defaultProps = {
    style:             {},
    autoFocus:         true,
    canChangeFontSize: true,
    affiliateOnly:     true,
    toolbarExtras:     '',
    toolbarOffsetTop:  0,
    className:         ''
  };

  /**
   * @param {*} props
   */
  constructor(props) {
    super(props);

    this.quill          = null;
    this.toolbar        = null;
    this.element        = React.createRef();
    this.colorPicker    = React.createRef();
    this.fileInput      = React.createRef();
    this.interval       = null;
    this.resizeObserver = null;
    this.currentColor   = browser.storage.getItem('custom-color', '#000000');
  }

  /**
   *
   */
  componentDidMount() {
    const {
      autoFocus,
      activateBlock,
      setFooterEditing,
      toolbarExtras,
      uiQuillToolbarExtras,
      canChangeFontSize,
      onUpdate,
      uiModal,
      affiliateOnly,
      libraryCallFunction
    } = this.props;

    if (canChangeFontSize) {
      const fontSize = document.querySelector('.ql-formats-font-size');
      if (fontSize) {
        fontSize.style.display = 'inline-block';
      }

      const Size     = Quill.import('attributors/style/size');
      Size.whitelist = null;
      Quill.register(Size, true);
    } else {
      const fontSize = document.querySelector('.ql-formats-font-size');
      if (fontSize) {
        fontSize.style.display = 'none';
      }
      const Size     = Quill.import('attributors/style/size');
      Size.whitelist = [];
      Quill.register(Size, true);
    }

    if (affiliateOnly) {
      Quill.register(AffiliateUrlBlock);
    } else {
      const Link = Quill.import('formats/link');
      Quill.register(Link);
    }

    try {
      this.toolbar = document.getElementById('quill-toolbar');
      this.quill   = new Quill(this.element.current, {
        theme:   'snow',
        modules: {
          toolbar:         this.toolbar,
          'emoji-toolbar': {
            buttonIcon: '<i class="fa fa-laugh" aria-hidden="true" />'
          }
        }
      });
      this.quill.getModule('toolbar').addHandler('image', this.handleImageUpload);
      libraryCallFunction('onTextEditor', this.quill, this.toolbar);
    } catch (error) {
      console.error(error);
      return;
    }

    this.quill.getModule('toolbar').addHandler('color', (value) => {
      if (value === 'custom-color-swatch') {
        this.quill.format('color', this.currentColor);
      } else {
        this.quill.format('color', value);
      }
    });

    const affiliateUrlButton = document.querySelector('.ql-affiliateurl');
    affiliateUrlButton.addEventListener('click', () => {
      const range = this.quill.getSelection();
      if (range) {
        if (!this.quill.getFormat().affiliateurl) {
          this.quill.formatText(range, 'affiliateurl', false);
        } else {
          this.quill.formatText(range, 'affiliateurl', true);
        }
      }
    });

    this.quill.on('editor-change', () => {
      libraryCallFunction('onChange', 0, this.element.current);
    });

    const htmlEditorButton = document.querySelector('.ql-htmleditor');
    htmlEditorButton.addEventListener('click', () => {
      activateBlock(0);
      setFooterEditing(false);
      uiModal('htmlEditor', true, {
        html: this.quill.container.firstChild.innerHTML,
        cb:   (h) => {
          if (h !== null) {
            onUpdate(h);
          }
        }
      });
    });

    this.initToolbar();
    uiQuillToolbarExtras(toolbarExtras);
    this.toolbar.style.opacity = 0;
    this.toolbar.style.display = 'block';

    document.querySelector('#builder-canvas-container')
      .addEventListener('scroll', this.updateToolbarPosition, false);
    this.resizeObserver = new ResizeObserver(this.updateToolbarPosition);
    this.resizeObserver.observe(this.element.current);
    this.updateToolbarPosition();

    if (autoFocus) {
      this.quill.focus();
    }
  }

  /**
   *
   */
  componentDidUpdate() {
    const { toolbarExtras, uiQuillToolbarExtras } = this.props;

    if (toolbarExtras) {
      uiQuillToolbarExtras(toolbarExtras);
    }
  }

  /**
   *
   */
  componentWillUnmount() {
    const { onUpdate, uiQuillToolbarExtras } = this.props;

    this.toolbar.style.display = 'none';
    uiQuillToolbarExtras('');
    if (this.quill && this.quill.container && this.quill.container.firstChild) {
      onUpdate(this.quill.container.firstChild.innerHTML);
    }
    document.querySelector('#builder-canvas-container')
      .removeEventListener('scroll', this.updateToolbarPosition, false);
    this.resizeObserver.disconnect();
  }

  /**
   *
   */
  updateToolbarPosition = () => {
    const { getElement, toolbarOffsetTop } = this.props;

    if (getElement && this.toolbar && this.toolbar.getAttribute('data-moved') !== 'true') {
      const element = getElement();
      if (!element) {
        clearTimeout(this.interval);
        setTimeout(this.updateToolbarPosition, 50);
      } else {
        setTimeout(() => {
          const rect                 = element.getBoundingClientRect();
          this.toolbar.style.top     = `${rect.top - toolbarOffsetTop}px`;
          this.toolbar.style.left    = `${rect.left - (200 + popupOffset)}px`;
          this.toolbar.style.opacity = 1;
        }, 50);
      }
    }
  };

  /**
   *
   */
  initToolbar = () => {
    const colorSwatch = this.toolbar.querySelector('[data-label="custom-color-swatch"]');
    if (colorSwatch) {
      colorSwatch.style.backgroundColor = this.currentColor;
    }

    const picker = this.toolbar.querySelector('[data-value="custom-color"]');
    if (picker) {
      const newEl = document.createElement('span');
      newEl.setAttribute('class', 'ql-picker-item fa fa-eye-dropper');
      newEl.setAttribute('style', 'font-size: 14px; color: #000; background-color: #FFF; border-color: #FFF;');
      newEl.setAttribute('data-value', 'custom-color');
      picker.parentNode.replaceChild(newEl, picker);
      newEl.addEventListener('click', () => {
        this.colorPicker.current.click();
      });
    }
  };

  /**
   * @param {Event} e
   */
  handleColorChange = (e) => {
    this.quill.format('color', e.target.value);
    // this.toolbar.querySelector('[data-value="custom-color"]').style.backgroundColor = e.target.value;
    this.toolbar.querySelector('[data-label="custom-color-swatch"]').style.backgroundColor = e.target.value;
    this.toolbar.querySelector('[data-label="custom-color-swatch"]').setAttribute('data-value', e.target.value);
    this.currentColor = e.target.value;

    browser.storage.setItem('custom-color', e.target.value);
  };

  /**
   * @param {Event} e
   */
  handleFileChange = (e) => {
    const { uiModal, uploadFile, replaceFile } = this.props;
    const { files } = e.target;

    const handleUpdate = (resp) => {
      const range = this.quill.getSelection();
      this.quill.insertEmbed(range.index, 'image', resp.url, Quill.sources.USER);
    };

    uploadFile(files[0], (resp) => {
      uiModal('cropper', true, {
        url:       resp.url,
        onCropped: (data) => {
          replaceFile(resp.url, data, (r) => {
            handleUpdate(r);
          });
        },
        onSkip: () => {
          handleUpdate(resp);
        }
      });
    });
  };

  /**
   *
   */
  handleImageUpload = () => {
    this.fileInput.current.click();
  };

  /**
   * @returns {*}
   */
  render() {
    const { html, style, className } = this.props;

    return (
      <>
        <div
          style={style}
          ref={this.element}
          className={className}
          dangerouslySetInnerHTML={{ __html: html }}
        />
        <input
          type="color"
          ref={this.colorPicker}
          style={{ display: 'none' }}
          onChange={this.handleColorChange}
        />
        <input
          type="file"
          ref={this.fileInput}
          style={{ display: 'none' }}
          onChange={this.handleFileChange}
        />
      </>
    );
  }
}
