import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { strings } from 'utils';

const BuilderImage = React.forwardRef(({ id, src, alt, bytes, dataID, showBytes, style, innerRef, className, onClick }, ref) => {
  const [error, setError] = useState(false);

  const realID = (typeof id === 'number') ? `block-${id}` : id;
  if (ref && !innerRef) {
    innerRef = ref;
  }

  if (error) {
    return (
      <div className="arb-block-wrap" id={`block-wrap-${dataID}`}>
        <img
          alt={alt}
          id={realID}
          data-id={dataID}
          style={style}
          ref={innerRef}
          src="/images/image-placeholder.jpg"
          className={`arb-block arb-image ${className}`}
          onClick={onClick}
        />
      </div>
    );
  }

  let classes = '';
  if (bytes > 1024 * 1024) {
    classes = 'bg-danger';
  } else if (bytes > 1024 * 150) {
    classes = 'bg-warning';
  }

  return (
    <div className="builder-block-image-container">
      <div className="arb-block-wrap" id={`block-wrap-${dataID}`}>
        <img
          alt={alt}
          src={src}
          id={realID}
          data-id={dataID}
          style={style}
          ref={innerRef}
          className={`arb-block arb-image ${className}`}
          onError={() => setError(true)}
          onClick={onClick}
        />
      </div>
      {(bytes !== 0 && showBytes) && (
        <div className="builder-block-image-bytes">
          <span className={classes}>
            {strings.humanBytes(bytes)}
          </span>
        </div>
      )}
    </div>
  );
});

BuilderImage.propTypes = {
  id:        PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  src:       PropTypes.string.isRequired,
  dataID:    PropTypes.number.isRequired,
  alt:       PropTypes.string,
  style:     PropTypes.object,
  bytes:     PropTypes.number,
  showBytes: PropTypes.bool,
  className: PropTypes.string,
  onClick:   PropTypes.func
};

BuilderImage.defaultProps = {
  alt:       '',
  bytes:     0,
  style:     {},
  showBytes: true,
  className: '',
  onClick:   () => {}
};

export default BuilderImage;
