import React from 'react';
import { toast } from 'react-toastify';
import ToastBody from 'components/ToastBody';
import { appendBlockStyles } from './templateActions';

export const UI_MODAL                  = 'UI_MODAL';
export const UI_TOAST                  = 'UI_TOAST';
export const UI_SLIDEUP                = 'UI_SLIDEUP';
export const UI_TOGGLE_SECTION         = 'UI_TOGGLE_SECTION';
export const UI_PREVIEW_DEVICE         = 'UI_PREVIEW_DEVICE';
export const UI_CHANGE_PREVIEW_SECTION = 'UI_CHANGE_PREVIEW_SECTION';
export const UI_SET_BLOCK_STYLE_TOOL   = 'UI_SET_BLOCK_STYLE_TOOL';
export const UI_SELECTION_VISIBLE      = 'UI_SELECTION_VISIBLE';
export const UI_LEFT_SIDEBAR_TAB       = 'UI_LEFT_SIDEBAR_TAB';
export const UI_QUILL_TOOLBAR_EXTRAS   = 'UI_QUILL_TOOLBAR_EXTRAS';

/**
 * @param {string} name
 * @param {boolean} opened
 * @param {*} meta
 * @returns {{name: *, opened: *, type: string}}
 */
export const uiModal = (name, opened, meta = {}) => {
  return (dispatch) => {
    dispatch({
      name,
      meta,
      opened,
      type:       UI_MODAL,
      modalClose: () => {
        dispatch(uiModal(name, false));
      }
    });
  };
};

/**
 * @param {string} name
 * @param {boolean} opened
 * @returns {{type: string}}
 */
export const uiSlideUp = (name, opened) => {
  return {
    type: UI_SLIDEUP,
    opened,
    name
  };
};

/**
 * @param {string} payload
 * @param {*} meta
 * @param {number} autoClose
 * @returns {{payload: *, type: *}}
 */
export const uiToast = (payload, meta = {}, autoClose = 2000) => {
  toast(<ToastBody type={meta.type || 'default'} message={payload} />, {
    position:        'bottom-center',
    autoClose,
    hideProgressBar: true,
    closeOnClick:    true,
    pauseOnHover:    true,
    draggable:       true,
    type:            meta.type
  });

  return {
    type: UI_TOAST,
    payload,
    meta
  };
};

/**
 * @param {string} previewDevice
 * @returns {function(*): void}
 */
export const uiSetPreviewDevice = (previewDevice) => {
  return (dispatch) => {
    dispatch({
      type: UI_PREVIEW_DEVICE,
      previewDevice
    });
    dispatch(appendBlockStyles());
  };
};

/**
 * @param {string} section
 * @param {boolean|null} open
 * @returns {{section: *, type: string}}
 */
export const uiToggleSection = (section, open = null) => {
  return {
    type: UI_TOGGLE_SECTION,
    section,
    open
  };
};

/**
 * @param {boolean} open
 * @returns {{type: string, open: *}}
 */
export const uiChangePreviewSection = (open) => {
  return {
    type: UI_CHANGE_PREVIEW_SECTION,
    open
  };
};

/**
 * @param {boolean} selectionVisible
 * @returns {{type: string}}
 */
export const uiSelectionVisible = (selectionVisible) => {
  return {
    type: UI_SELECTION_VISIBLE,
    selectionVisible
  };
};

/**
 * @param {string} blockStyleTool
 * @returns {{type: string}}
 */
export const uiSetBlockStyleTool = (blockStyleTool) => {
  return {
    type: UI_SET_BLOCK_STYLE_TOOL,
    blockStyleTool
  };
};

/**
 * @param {string} leftSidebarTab
 * @returns {{type: string}}
 */
export const uiLeftSidebarTab = (leftSidebarTab) => {
  return {
    type: UI_LEFT_SIDEBAR_TAB,
    leftSidebarTab
  };
};

/**
 * @param {*} quillToolbarExtras
 * @returns {{type: string}}
 */
export const uiQuillToolbarExtras = (quillToolbarExtras) => {
  return {
    type: UI_QUILL_TOOLBAR_EXTRAS,
    quillToolbarExtras
  };
};
