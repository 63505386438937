import * as types from '@builder/actions/productsActions';
import { objects, arrays } from 'utils';

const initialState = objects.merge({
  products: []
}, window.initialState.product);

/**
 * @param {*} state
 * @param {*} action
 */
const onList = (state, action) => {
  const products = objects.clone(action.products);

  return {
    ...state,
    products
  };
};

/**
 * @param {*} state
 * @param {*} action
 */
const onCreate = (state, action) => {
  const products = Array.from(state.products);
  const { product } = action;

  products.push(product);
  products.sort((a, b) => {
    if (a.name < b.name) return -1;
    if (a.name > b.name) return 1;
    return 0;
  });

  return {
    ...state,
    products
  };
};

/**
 * @param {*} state
 * @param {*} action
 */
const onUpdate = (state, action) => {
  const products = objects.clone(state.products);
  const { product } = action;

  const index = arrays.findIndexByID(products, product.id);
  if (index !== -1) {
    products[index] = product;
  }

  return {
    ...state,
    products
  };
};

/**
 * @param {*} state
 * @param {*} action
 */
const onDelete = (state, action) => {
  const products = objects.clone(state.products);
  const { id } = action;

  const index = arrays.findIndexByID(products, id);
  if (index !== -1) {
    products.splice(index, 1);
  }

  return {
    ...state,
    products
  };
};

/**
 * @param {*} state
 * @param {*} action
 */
const onDestroy = (state, action) => {
  const products = objects.clone(state.products);
  const { id } = action;

  const index = arrays.findIndexByID(products, id);
  if (index !== -1) {
    products[index].destroying = 1;
  }

  return {
    ...state,
    products
  };
};

const handlers = {
  [types.PRODUCTS_LIST]:    onList,
  [types.PRODUCTS_CREATE]:  onCreate,
  [types.PRODUCTS_UPDATE]:  onUpdate,
  [types.PRODUCTS_DELETE]:  onDelete,
  [types.PRODUCTS_DESTROY]: onDestroy
};

/**
 * @param {*} state
 * @param {*} action
 * @returns {*}
 */
export default function productsReducer(state = objects.clone(initialState), action = {}) {
  if (handlers[action.type]) {
    return handlers[action.type].call(null, state, action);
  }

  return state;
}
