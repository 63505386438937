import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { objects } from 'utils';
import { FormGroup, Label } from 'components/forms';
import FormContext from './formContext';

/**
 *
 */
class Input extends React.PureComponent {
  static contextType = FormContext;

  static propTypes = {
    id:                 PropTypes.string.isRequired,
    sm:                 PropTypes.bool,
    help:               PropTypes.string,
    name:               PropTypes.string,
    type:               PropTypes.string,
    label:              PropTypes.node,
    maxLength:          PropTypes.number,
    numeric:            PropTypes.bool,
    increment:          PropTypes.number,
    minValue:           PropTypes.number,
    maxValue:           PropTypes.number,
    value:              PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    errorMessage:       PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    formGroupClassName: PropTypes.string,
    formGroupStyle:     PropTypes.object,
    labelClassName:     PropTypes.string,
    placeholder:        PropTypes.string,
    className:          PropTypes.string,
    button:             PropTypes.element,
    focused:            PropTypes.bool,
    disabled:           PropTypes.bool,
    required:           PropTypes.bool,
    last:               PropTypes.bool,
    light:              PropTypes.bool,
    counter:            PropTypes.bool,
    format:             PropTypes.func,
    parse:              PropTypes.func,
    onChange:           PropTypes.func,
    forms:              PropTypes.object
  };

  static defaultProps = {
    help:               '',
    name:               '',
    type:               'text',
    value:              '',
    label:              '',
    placeholder:        '',
    className:          '',
    errorMessage:       '',
    labelClassName:     '',
    formGroupClassName: '',
    formGroupStyle:     {},
    maxLength:          0,
    increment:          1,
    light:              false,
    numeric:            false,
    counter:            false,
    sm:                 false,
    focused:            false,
    disabled:           false,
    required:           false,
    last:               false,
    forms:              {},
    format:             v => v,
    parse:              v => v,
    onChange:           null
  };

  static unityFormType = 'input';

  /**
   * Called when the component mounts
   */
  componentDidMount() {
    const { focused, numeric } = this.props;

    if (numeric) {
      this.input.addEventListener('wheel', this.handleWheelCancel, { passive: false });
    }
    if (focused) {
      setTimeout(() => {
        this.focus();
      }, 500);
    }
  }

  /**
   *
   */
  componentWillUnmount() {
    const { numeric } = this.props;

    if (numeric) {
      this.input.removeEventListener('wheel', this.handleWheelCancel, { passive: false });
    }
  }

  /**
   * @returns {string}
   */
  getValue = () => {
    return this.input.value;
  };

  /**
   * Gives focus to the element
   */
  focus = () => {
    this.input.focus();
  };

  /**
   * @param {Event} e
   */
  handleWheelCancel = (e) => {
    e.preventDefault();
  };

  /**
   * @param {Event} e
   * @param {*} context
   */
  handleChange = (e, context) => {
    const { id, name, type, parse, onChange } = this.props;

    let { value } = this.input;
    if (type === 'file') {
      value = this.input.files;
    }
    value = parse(value);

    if (context.onChange) {
      context.onChange(e, value, name || id);
    }
    if (onChange) {
      onChange(e, value, name || id);
    }
  };

  /**
   * @param {MouseWheelEvent} e
   * @param {*} context
   */
  handleWheel = (e, context) => {
    const { id, name, parse, numeric, increment, minValue, maxValue, onChange } = this.props;
    const { value } = e.target;

    if (!numeric) {
      return;
    }

    const parts = value.match(/^([\d-]+)([\w%]+)?$/);
    if (!parts || parts[1].match(/[^\w-]/i)) {
      return;
    }
    let newValue = parseInt(parts[1], 10);
    const unit   = parts[2];

    if (e.deltaY < 0) {
      newValue += increment;
    } else if (e.deltaY > 0) {
      newValue -= increment;
    }
    if (minValue !== undefined && newValue < minValue) {
      newValue = minValue;
    }
    if (maxValue !== undefined && newValue > maxValue) {
      newValue = maxValue;
    }
    if (unit) {
      newValue = `${newValue}${unit}`;
    }
    newValue = parse(newValue);

    if (context.onChange) {
      context.onChange(e, newValue, name || id);
    }
    if (onChange) {
      onChange(e, newValue, name || id);
    }
  };

  /**
   * @param {Event} e
   * @param {*} context
   */
  handleKeyUp = (e, context) => {
    const { id, name, parse, numeric, increment, minValue, maxValue, onChange } = this.props;
    const { value } = e.target;

    if (!numeric) {
      return;
    }

    const parts  = value.match(/^([\d-]+)([\w%]+)?$/);
    if (!parts || parts[1].match(/[^\w-]/i)) {
      return;
    }
    let newValue = parseInt(parts[1], 10);
    const unit   = parts[2];

    if (e.keyCode === 38) {
      newValue += increment;
    } else if (e.keyCode === 40) {
      newValue -= increment;
    }

    if (minValue !== undefined && newValue < minValue) {
      newValue = minValue;
    }
    if (maxValue !== undefined && newValue > maxValue) {
      newValue = maxValue;
    }
    if (unit) {
      newValue = `${newValue}${unit}`;
    }
    newValue = parse(newValue);

    if (context.onChange) {
      context.onChange(e, newValue, name || id);
    }
    if (onChange) {
      onChange(e, newValue, name || id);
    }
  };

  /**
   * @returns {*}
   */
  render() {
    const {
      id,
      sm,
      help,
      name,
      type,
      last,
      light,
      value,
      label,
      format,
      button,
      counter,
      numeric,
      maxLength,
      placeholder,
      errorMessage,
      labelClassName,
      className,
      disabled,
      required,
      formGroupStyle,
      formGroupClassName,
      ...props
    } = this.props;
    const { context } = this;

    const inputName = name || id;
    const classes = classNames(className, 'form-control', {
      'form-control-sm':    context.sm || sm,
      'form-control-light': light
    });
    const inputValue  = format(context.values[inputName] !== undefined ? context.values[inputName] : value);
    const valueLength = inputValue.length;

    let LabelComponent = '';
    if (counter && maxLength) {
      LabelComponent = (
        <Label className={labelClassName} htmlFor={id}>
          <div className="form-counter">
            {valueLength} / {maxLength}
          </div>
          {label}
        </Label>
      );
    } else {
      LabelComponent = label;
    }

    return (
      <FormGroup
        help={help}
        htmlFor={id}
        label={LabelComponent}
        style={formGroupStyle}
        labelClassName={labelClassName}
        className={last ? 'form-group-last' : formGroupClassName}
        required={context.required || required}
        errorMessage={context.errorFields[inputName] || errorMessage}
      >
        <div className="d-flex">
          <input
            id={id}
            type={type}
            name={inputName}
            className={classes}
            placeholder={placeholder}
            ref={ref => this.input = ref}
            required={context.required || required}
            disabled={context.disabled || disabled}
            onChange={e => this.handleChange(e, context)}
            {...objects.keyFilter(props, Input.propTypes)}
            maxLength={maxLength !== 0 ? maxLength : undefined}
            value={type === 'file' ? undefined : inputValue}
            onWheel={e => this.handleWheel(e, context)}
            onKeyUp={e => this.handleKeyUp(e, context)}
          />
          {button}
        </div>
      </FormGroup>
    );
  }
}

export default Input;
