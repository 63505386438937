import React from 'react';
import PropTypes from 'prop-types';
import { connect, mapDispatchToProps } from 'utils';
import { listicleActions } from '@builder/actions';
import BlockWrapper from '@builder/components/BlockWrapper';
import QuillEditor from '@builder/components/QuillEditor';

const BlockText = ({ blockGroup, updateBlock }) => {
  const { defaultBlock } = blockGroup;

  return (
    <BlockWrapper blockGroup={blockGroup} className="arb-text">
      {(provided) => {
        if (provided.isActive) {
          return (
            <QuillEditor
              html={defaultBlock.html}
              getElement={provided.getElement}
              onUpdate={html => updateBlock(blockGroup.defaultBlock.id, blockGroup.id, 'html', html)}
            />
          );
        }

        return (
          <div dangerouslySetInnerHTML={{ __html: blockGroup.defaultBlock.html }} />
        );
      }}
    </BlockWrapper>
  );
};

BlockText.propTypes = {
  blockGroup:  PropTypes.object.isRequired,
  updateBlock: PropTypes.func.isRequired
};

BlockText.typeName      = 'text';
BlockText.icon          = 'align-center';
BlockText.label         = 'Text';
BlockText.defaultValues = {
  html:   '<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor...</p>',
  styles: {
    marginBottom: 15,
    fontSize:     18,
    textAlign:    'left'
  }
};

export default connect(
  null,
  mapDispatchToProps(listicleActions)
)(BlockText);
