import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from 'components';

const PopupDragHandle = ({ isDown, onMouseDown }) => {
  return (
    <div
      onMouseDown={onMouseDown}
      className={isDown ? 'ql-toolbar-handle ql-toolbar-handle-dragging' : 'ql-toolbar-handle'}
    >
      <Icon name="ellipsis-h" className="drag-handle" />
    </div>
  );
};

PopupDragHandle.propTypes = {
  isDown:      PropTypes.bool.isRequired,
  onMouseDown: PropTypes.func.isRequired
};

export default PopupDragHandle;
