import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
// import DragHandle from './DragHandle';

export default class DragImage extends React.PureComponent {
  static propTypes = {
    src:       PropTypes.string.isRequired,
    innerRef:  PropTypes.object.isRequired,
    enabled:   PropTypes.bool,
    className: PropTypes.string.isRequired,
    onError:   PropTypes.func.isRequired,
    onResize:  PropTypes.func.isRequired
  };

  /**
   * @param {*} props
   */
  constructor(props) {
    super(props);

    this.state = {
      isDragging: false,
      position:   ''
    };
  }

  /**
   * @param {MouseEvent} e
   * @param {string} position
   */
  handleMouseDown = (e, position) => {
    const { innerRef } = this.props;
    const { current } = innerRef;

    this.originalWidth  = parseFloat(getComputedStyle(current, null).getPropertyValue('width').replace('px', ''));
    this.originalHeight = parseFloat(getComputedStyle(current, null).getPropertyValue('height').replace('px', ''));
    this.originalMouseX = e.pageX;
    this.originalMouseY = e.pageY;

    this.setState({ isDragging: true, position });
  };

  /**
   *
   */
  handleMouseUp = () => {
    this.setState({ isDragging: false });
  };

  /**
   * @param {MouseEvent} e
   * @param {string} position
   */
  handleResize = (e, position) => {
    const { onResize } = this.props;

    let width;
    let height;
    if (position === 'right-bottom') {
      width  = this.originalWidth + (e.pageX - this.originalMouseX);
      height = this.originalHeight + (e.pageY - this.originalMouseY);
      onResize(e, width, height);
    } else if (position === 'left-bottom') {
      height = this.originalHeight + (e.pageY - this.originalMouseY);
      width  = this.originalWidth - (e.pageX - this.originalMouseX);
      onResize(e, width, height);
    } else if (position === 'right-top') {
      width  = this.originalWidth + (e.pageX - this.originalMouseX);
      height = this.originalHeight - (e.pageY - this.originalMouseY);
      onResize(e, width, height);
    } else {
      width  = this.originalWidth - (e.pageX - this.originalMouseX);
      height = this.originalHeight - (e.pageY - this.originalMouseY);
    }

    width  = Math.floor(width);
    height = Math.floor(height);
    if (!e.shiftKey) {
      height = 'auto';
    }
    onResize(e, width, height);
  };

  /**
   * @returns {*}
   */
  render() {
    const { src, innerRef, className, onError, onResize, enabled, ...props } = this.props;
    const { isDragging, position } = this.state;

    if (!enabled) {
      return (
        <img
          {...props}
          src={src}
          ref={innerRef}
          onError={onError}
          className={className}
          alt=""
        />
      );
    }

    const classes = classNames(`builder-image-wrapper builder-image-wrapper-${position}`, {
      'builder-image-wrapper-dragging': isDragging
    });

    return (
      <span className={classes}>
        <img
          {...props}
          src={src}
          ref={innerRef}
          onError={onError}
          className={className}
          alt=""
        />
        {/* <DragHandle
          position="left-top"
          onResize={this.handleResize}
          onMouseUp={this.handleMouseUp}
          onMouseDown={this.handleMouseDown}
        />
        <DragHandle
          position="right-top"
          onResize={this.handleResize}
          onMouseUp={this.handleMouseUp}
          onMouseDown={this.handleMouseDown}
        />
        <DragHandle
          position="right-bottom"
          onResize={this.handleResize}
          onMouseUp={this.handleMouseUp}
          onMouseDown={this.handleMouseDown}
        />
        <DragHandle
          position="left-bottom"
          onResize={this.handleResize}
          onMouseUp={this.handleMouseUp}
          onMouseDown={this.handleMouseDown}
        /> */}
      </span>
    );
  }
}
