/**
 * Returns a random number between min and max
 *
 * @param {number} min
 * @param {number} max
 */
function random(min, max) {
  return Math.floor(Math.random() * (max - min)) + min;
}

const themes = ['success', 'error', 'warning'];

/**
 * @typedef {Object} Prompt
 * @property {Element} closeIcon
 * @property {Element} header
 * @property {Element} footer
 * @property {Element} body
 * @property {Element} container
 * @property {Element} mask
 * @property {function} close
 * @property {function} setContent
 */

class Alerts {
  /**
   * @type {Prompt[]}
   */
  prompts = [];

  /**
   * @type {Element}
   */
  mount = null;

  /**
   * @param mount
   */
  constructor(mount) {
    this.mount = mount || document.body;
  }

  /**
   * @param {string|Element|array} content
   * @param {string} theme
   * @param {function(Prompt)|MouseEvent} cb
   * @param {array} buttons
   * @returns {Promise<boolean>}
   */
  confirm = (content, theme = 'success', cb = null, buttons = ['Yes', 'No']) => {
    return new Promise(async (resolve, reject) => {
      if (buttons.length === 0) {
        reject(new Error('Alerts.confirm buttons empty.'));
        return;
      }

      const prompt = await this.createPrompt(theme, content, false);
      prompt.header.remove();

      const btnYes = this.createElement('button', {
        'className': 'btn btn-primary mr-1',
        'type':      'button',
        'html':      buttons[0],
        'onClick':   () => {
          prompt.close();
          resolve(true);
        },
      });
      prompt.footer.appendChild(btnYes);

      if (buttons[1]) {
        const btnNo = this.createElement('button', {
          'className': 'btn btn-primary',
          'type':      'button',
          'html':      buttons[1],
          'onClick':   () => {
            prompt.close();
          },
        });
        prompt.footer.appendChild(btnNo);
      }

      if (cb && typeof cb === 'object' && cb.clientY !== undefined) {
        prompt.container.style.position = 'absolute';
        prompt.container.style.top      = `${cb.clientY + 150}px`;
      }

      this.appendPrompt(prompt, cb);
    });
  };

  /**
   * @param {string|Element|array} content
   * @param {string} value
   * @param {string} theme
   * @param {function(Prompt)|MouseEvent} cb
   * @param {array} buttons
   * @returns {Promise<*>}
   */
  ask = (content, value = '', theme = 'success', cb = null, buttons = ['Okay', 'Cancel']) => {
    return new Promise(async (resolve) => {
      const prompt = await this.createPrompt(theme, '', false);
      prompt.header.remove();

      const rand  = random(0, 10000);
      const label = this.createElement('label', {
        'html': content,
        'for':  `input-ask-${rand}`,
        'className': 'mb-3',
      });
      const input = this.createElement('input', {
        'className': 'form-control form-control-light',
        'value':     value,
        'id':        `input-ask-${rand}`,
      });
      const group = this.createElement('div', {
        'className': 'form-group mb-0',
        'html':      [label, input],
      });
      prompt.setContent(group);

      const btnYes = this.createElement('button', {
        'className': 'btn btn-primary mr-1',
        'type':      'button',
        'html':      'Okay',
        'onClick':   () => {
          prompt.close();
          resolve(input.value);
        },
      });
      prompt.footer.appendChild(btnYes);

      const btnNo = this.createElement('button', {
        'className': 'btn btn-primary',
        'type':      'button',
        'html':      buttons[1],
        'onClick':   () => {
          prompt.close();
          resolve(undefined);
        },
      });
      prompt.footer.appendChild(btnNo);

      input.addEventListener('keydown', (e) => {
        if (e.key === 'Enter') {
          btnYes.click();
        }
      }, false);

      if (cb && typeof cb === 'object' && cb.clientY !== undefined) {
        prompt.container.style.position = 'absolute';
        prompt.container.style.top      = `${cb.clientY + 150}px`;
      }

      this.appendPrompt(prompt, cb);
      setTimeout(() => {
        input.focus();
        input.setSelectionRange(0, value.length);
      }, 500);
    });
  };

  /**
   * @param content
   * @param items
   * @param value
   * @param theme
   * @param cb
   * @param buttons
   * @returns {Promise<unknown>}
   */
  choose = (content, items, value = '', theme = 'success', cb = null, buttons = ['Okay', 'Cancel']) => {
    return new Promise(async (resolve) => {
      const prompt = await this.createPrompt(theme, '', false);
      prompt.header.remove();

      const rand  = random(0, 10000);
      const label = this.createElement('label', {
        'html': content,
        'for':  `input-ask-${rand}`,
      });
      const input = this.createElement('select', {
        'className': 'form-control form-control-light',
        'id':        `input-ask-${rand}`,
      });
      items.forEach((item) => {
        const option = this.createElement('option', {
          value: item.value,
          html:  item.label,
        });
        input.appendChild(option);
        if (value === item.value) {
          option.setAttribute('selected', 'selected');
        }
      });
      const group = this.createElement('div', {
        'className': 'form-group mb-0',
        'html':      [label, input],
      });
      prompt.setContent(group);

      const btnYes = this.createElement('button', {
        'className': 'btn btn-primary mr-1',
        'type':      'button',
        'html':      'Okay',
        'onClick':   () => {
          prompt.close();
          resolve(input.options[input.selectedIndex].value);
        },
      });
      prompt.footer.appendChild(btnYes);

      const btnNo = this.createElement('button', {
        'className': 'btn btn-default',
        'type':      'button',
        'html':      buttons[1],
        'onClick':   () => {
          prompt.close();
          resolve(undefined);
        },
      });
      prompt.footer.appendChild(btnNo);

      if (cb && typeof cb === 'object' && cb.clientY !== undefined) {
        prompt.container.style.position = 'absolute';
        prompt.container.style.top      = `${cb.clientY + 150}px`;
      }

      this.appendPrompt(prompt, cb);
    });
  };

  /**
   * @param {string|Element} content
   * @param {string} theme
   * @param {function(Prompt)} cb
   * @returns {Promise<boolean>}
   */
  alert = (content, theme = 'success', cb = () => {}) => {
    return this.confirm(content, theme, cb, ['Okay']);
  };

  /**
   * @param {string|Element} content
   * @param {boolean} isCloseable
   * @param {function(Prompt)} cb
   * @returns {Promise}
   */
  success = (content, isCloseable = true, cb = null) => {
    return new Promise(async (resolve) => {
      const handleClose = this.handleClose.bind(this, isCloseable, resolve);
      const prompt      = await this.createPrompt('success', content, isCloseable, handleClose);
      prompt.footer.remove();
      if (!isCloseable) {
        prompt.header.remove();
      }

      this.appendPrompt(prompt, cb);
    });
  };

  /**
   * @param {string|Element} content
   * @param {boolean} isCloseable
   * @param {function(Prompt)} cb
   * @returns {Promise}
   */
  error = (content, isCloseable = true, cb = null) => {
    return new Promise(async (resolve) => {
      const handleClose = this.handleClose.bind(this, isCloseable, resolve);
      const prompt      = await this.createPrompt('error', content, isCloseable, handleClose);
      prompt.footer.remove();
      if (!isCloseable) {
        prompt.header.remove();
      }

      this.appendPrompt(prompt, cb);
    });
  };

  /**
   * @param {string} content
   * @param {function} cb
   * @returns {Promise<Prompt>}
   */
  loading = (content, cb = null) => {
    return new Promise(async (resolve) => {
      const prompt = await this.createPrompt('success', content, false);
      prompt.header.remove();
      prompt.body.classList.add('text-center');
      prompt.body.innerHTML = `
        <div class="text-center mb-3">${content}</div>
      `;
      prompt.footer.classList.remove('justify-content-end');
      prompt.footer.classList.add('justify-content-center');
      prompt.footer.innerHTML = `
        <div class="text-center">
          <div class="loading loading-inline"></div>
        </div>
      `;

      this.appendPrompt(prompt, cb);
      resolve(prompt);
    });
  };

  /**
   * @param {Prompt} prompt
   * @param {function} cb
   */
  appendPrompt = (prompt, cb) => {
    this.mount.appendChild(prompt.mask);
    if (typeof cb === 'function') {
      cb(prompt);
    }
  };

  /**
   * @private
   *
   * @param {string} theme
   * @param {string|array|Element} content
   * @param {boolean} isCloseable
   * @param {function} handleClose
   * @returns {Promise<Prompt>}
   */
  createPrompt = (theme, content, isCloseable, handleClose = null) => {
    return new Promise(async (resolve, reject) => {
      if (themes.indexOf(theme) === -1) {
        reject(new Error(`Alert theme "${theme}" is not valid.`));
        return;
      }

      const prompt = this.createEmptyPrompt();
      const len    = this.prompts.push(prompt);
      prompt.close = () => {
        this.unmountMask(len - 1);
        if (handleClose) {
          handleClose();
        }
      };

      prompt.closeIcon = this.createElement('i', {
        'className': 'fas fa-times prompts-icon-close pointer',
        'onClick':   prompt.close,
      });
      prompt.header = this.createElement('div', {
        'className': 'prompts-header text-right',
        'html':      prompt.closeIcon,
      });

      prompt.body = this.createElement('div', {
        'className': 'prompts-body pt-2 pb-2',
        'html':      content,
      });
      prompt.setContent = (c) => {
        this.setContent(prompt.body, c);
      };

      prompt.footer = this.createElement('div', {
        'className': 'prompts-footer pt-2 pb-2 d-flex justify-content-end',
      });
      prompt.container = this.createElement('div', {
        'className': `prompts-container prompts-container-type-${theme} fade-in pt-2 pb-2 pl-3 pr-3`,
        'html':      [prompt.header, prompt.body, prompt.footer],
        'onClick':   e => e.stopPropagation(),
      });

      prompt.mask = this.createElement('div', {
        'className': 'mask mounted visible d-flex align-items-center justify-content-center',
      });
      prompt.mask.appendChild(prompt.container);
      if (!isCloseable) {
        prompt.mask.addEventListener('click', this.shakeContainer, false);
      } else {
        prompt.mask.addEventListener('click', prompt.close, false);
      }
      resolve(prompt);
    });
  };

  /**
   * @private
   *
   * @returns {Prompt}
   */
  createEmptyPrompt = () => {
    return {
      closeIcon:  null,
      header:     null,
      footer:     null,
      body:       null,
      container:  null,
      mask:       null,
      close:      () => {},
      setContent: () => {},
    };
  };

  /**
   * @private
   *
   * @param {string} tag
   * @param {*} opts
   * @returns {Element}
   */
  createElement = (tag, opts = {}) => {
    const el = document.createElement(tag);
    if (opts.className) {
      el.setAttribute('class', opts.className);
      delete opts.className;
    }

    if (opts.html !== undefined) {
      this.setContent(el, opts.html);
      delete opts.html;
    }

    Object.keys(opts).forEach((key) => {
      if (key.indexOf('on') === 0) {
        el.addEventListener(key.substr(2).toLowerCase(), opts[key], false);
      } else {
        el.setAttribute(key, opts[key]);
      }
    });

    return el;
  };

  /**
   * @private
   *
   * @param {Element} el
   * @param {string|array|Element} content
   */
  setContent = (el, content) => {
    if (typeof content === 'string') {
      el.innerHTML = content;
    } else if (Array.isArray(content)) {
      content.forEach((child) => {
        if (child) {
          el.appendChild(child);
        }
      });
    } else if (content) {
      el.appendChild(content);
    }
  };

  /**
   * @private
   */
  unmountMask = (index = 0) => {
    if (this.prompts[index]) {
      this.prompts[index].mask.remove();
      this.prompts.splice(index, 1);
    }
  };

  /**
   * @private
   *
   * @param {boolean} isCloseable
   * @param {function} resolve
   */
  handleClose = (isCloseable, resolve) => {
    if (isCloseable) {
      this.unmountMask();
      resolve();
    } else {
      this.shakeContainer();
    }
  };

  /**
   * @private
   */
  shakeContainer = () => {
    if (this.prompts.length > 0) {
      const { container } = this.prompts[0];
      if (container) {
        container.classList.add('shake');
        setTimeout(() => {
          container.classList.remove('shake');
        }, 1000);
      }
    }
  };
}

export default new Alerts();
export {
  Alerts,
};
