import React from 'react';
import PropTypes from 'prop-types';
import { connect, mapDispatchToProps } from 'utils';
import { listicleActions } from '@builder/actions';
import BlockWrapper from '@builder/components/BlockWrapper';
import PopupTools from '@builder/components/PopupTools';
import PopupDragHandle from '@builder/components/PopupDragHandle';
import { Input, Checkbox, SimpleForm } from 'components/forms';

@connect(
  null,
  mapDispatchToProps(listicleActions)
)
export default class BlockForm extends React.PureComponent {
  static propTypes = {
    updateBlock: PropTypes.func.isRequired
  };

  static defaultProps = {};

  static typeName = 'form';

  static icon = 'keyboard';

  static label = 'Form';

  static defaultValues = {
    headline: 'Email address',
    button:   'Submit',
    styles:   {
      color:        '#000000',
      textAlign:    'left',
      marginBottom: 15
    }
  };

  form   = React.createRef();

  button = React.createRef();

  state = {
    buttonWidth: 6
  };

  /**
   * @param {Event} e
   */
  handleButtonChange = (e) => {
    const { blockGroup, updateBlock } = this.props;
    const { target } = e;

    updateBlock(blockGroup.defaultBlock.id, blockGroup.id, 'button', target.value);
    this.setState({
      buttonWidth: target.value.length > 0 ? target.value.length : 1
    });
  };

  /**
   * @param {Event} e
   */
  handleLabelChange = (e) => {
    const { blockGroup, updateBlock } = this.props;
    const { target } = e;

    updateBlock(blockGroup.defaultBlock.id, blockGroup.id, 'headline', target.value);
  };

  /**
   * @returns {*}
   */
  renderInactive = () => {
    const { blockGroup } = this.props;
    const { defaultBlock } = blockGroup;

    const buttonStyles = {};
    if (defaultBlock.styles['!button:color']) {
      buttonStyles.color = defaultBlock.styles['!button:color'];
    }
    if (defaultBlock.styles['!button:backgroundColor']) {
      buttonStyles.backgroundColor = defaultBlock.styles['!button:backgroundColor'];
    }

    return (
      <div className="form-group">
        <label className="arb-form-label-bootstrap" htmlFor={`form-inout-${blockGroup.id}`}>
          {defaultBlock.headline}
        </label>
        <div className="d-flex">
          <input id={`form-inout-${blockGroup.id}`} className="arb-form-control-bootstrap mr-2" />
          <button className="arb-btn-bootstrap" style={buttonStyles}>
            {defaultBlock.button}
          </button>
        </div>
      </div>
    );
  };

  /**
   * @returns {*}
   */
  renderActive = () => {
    const { blockGroup } = this.props;
    const { buttonWidth } = this.state;
    const { defaultBlock } = blockGroup;

    const buttonStyles = {};
    if (defaultBlock.styles['!button:color']) {
      buttonStyles.color = defaultBlock.styles['!button:color'];
    }
    if (defaultBlock.styles['!button:backgroundColor']) {
      buttonStyles.backgroundColor = defaultBlock.styles['!button:backgroundColor'];
    }

    return (
      <form ref={this.form}>
        <div className="form-group">
          <label className="arb-form-label-bootstrap w-100" htmlFor={`form-inout-${blockGroup.id}`}>
            <input
              style={{ width: '100%' }}
              className="input-no-styles"
              value={defaultBlock.headline}
              onChange={this.handleLabelChange}
            />
          </label>
          <div className="d-flex">
            <input id={`form-inout-${blockGroup.id}`} className="arb-form-control-bootstrap mr-2" />
            <span className="arb-btn-bootstrap" style={buttonStyles}>
              <input
                ref={this.button}
                value={defaultBlock.button}
                className="input-no-styles"
                style={{ width: `${buttonWidth}ch` }}
                onChange={this.handleButtonChange}
              />
            </span>
          </div>
        </div>
      </form>
    );
  };

  /**
   * @returns {*}
   */
  renderMenu = () => {
    const { blockGroup, updateBlock } = this.props;
    const { defaultBlock } = blockGroup;
    const { form } = defaultBlock;

    const formValues = {
      url:        form.url !== undefined ? form.url : 'https://{{ site("domain") }}/mailchimp.php',
      tags:       form.tags !== undefined ? form.tags : '{{ site }}',
      noRedirect: form.noRedirect !== undefined ? form.noRedirect : true
    };

    /**
     * @param {Event} e
     * @param {string} value
     * @param {string} name
     */
    const handleChange = (e, value, name) => {
      const newForm = { ...form, [name]: value };
      updateBlock(defaultBlock.id, blockGroup.id, 'form', newForm);
    };

    return (
      <PopupTools
        position="bottom"
        getTarget={() => this.form.current}
        className="builder-block-menu-form wide"
        visible
        dragHandle={(isDown, onMouseDown) => (
          <PopupDragHandle isDown={isDown} onMouseDown={onMouseDown} />
        )}
      >
        <SimpleForm values={formValues} onChange={handleChange}>
          <div className="mb-1">
            <Input
              name="url"
              id="builder-block-form-url-input"
              label="Post"
            />
            <Input
              name="tags"
              id="builder-block-form-tags-input"
              label="Tags"
            />
          </div>
          <Checkbox
            name="noRedirect"
            id="builder-block-form-no-redirect-input"
            label="No Redirect"
          />
        </SimpleForm>
      </PopupTools>
    );
  };

  /**
   * @returns {*}
   */
  render() {
    const { blockGroup } = this.props;

    return (
      <BlockWrapper blockGroup={blockGroup} menu={this.renderMenu} className="arb-form">
        {(provided) => {
          if (provided.isActive) {
            return this.renderActive();
          }

          return this.renderInactive();
        }}
      </BlockWrapper>
    );
  }
}
