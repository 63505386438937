import { api, router } from 'utils';

export const SITE_LOAD = 'SITE_LOAD';

/**
 * @returns {Function}
 */
export const sitesLoad = () => {
  return (dispatch) => {
    api.get(router.generate('sites_list'))
      .then((sites) => {
        dispatch({
          type: SITE_LOAD,
          sites
        });
      });
  };
};
