import routes from '../../../public/js/routes.json';
// import '../../../public/js/router.js';

if (window.Routing) {
  window.Routing.setRoutingData(routes);
}

/**
 * @property {Function} generate
 */
export default window.Routing;
