/**
 * @param {string} url
 * @returns {boolean|string}
 */
export function videoExtractYoutubeId(url) {
  const regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#\&\?]*).*/;
  const match = url.match(regExp);

  return (match && match[7].length === 11) ? match[7] : false;
}

/**
 * @param {string} url
 * @returns {boolean|string}
 */
export function videoExtractVimeoId(url) {
  const regExp = /vimeo\.com\/([\d]+)\/?$/;
  const match  = url.match(regExp);

  return match ? match[1] : false;
}

/**
 * @param {string} url
 * @returns {string|boolean}
 */
export function videoYoutubeShortUrl(url) {
  const youtubeId = videoExtractYoutubeId(url);
  if (!youtubeId) {
    return false;
  }

  return `https://youtu.be/${youtubeId}`;
}

/**
 * @param {string} url
 * @returns {Promise}
 */
export function videoVimeoPosterUrl(url) {
  return new Promise((resolve) => {
    const id = videoExtractVimeoId(url);
    $.getJSON(`https://vimeo.com/api/v2/video/${id}.json`)
      .then((resp) => {
        resolve(resp[0].thumbnail_large);
      });
  });
}

/**
 * @param {string} url
 * @returns {string}
 */
export function videoGetProvider(url) {
  if (url.indexOf('vimeo.com') !== -1) {
    return 'vimeo';
  }
  return 'youtube';
}

export default {
  extractYoutubeId: videoExtractYoutubeId,
  extractVimeoId:   videoExtractVimeoId,
  youtubeShortUrl:  videoYoutubeShortUrl,
  vimeoPosterUrl:   videoVimeoPosterUrl,
  getProvider:      videoGetProvider
};
