import React from 'react';
import PropTypes from 'prop-types';
import { connect, mapDispatchToProps } from 'utils';

const PaddingHighlight = ({ blockGroup, activeBlockID, marginHovering, children }) => {
  const { styles } = blockGroup.defaultBlock;

  const highlightStyles = {};
  highlightStyles.borderTopWidth    = styles.paddingTop || 0;
  highlightStyles.borderRightWidth  = styles.paddingRight || 0;
  highlightStyles.borderBottomWidth = styles.paddingBottom || 0;
  highlightStyles.borderLeftWidth   = styles.paddingLeft || 0;

  return (
    <div
      style={highlightStyles}
      className={(marginHovering && blockGroup.id === activeBlockID)
        ? 'builder-canvas-block-padding highlight' : 'builder-canvas-block-padding'}
    >
      {children}
    </div>
  );
};

PaddingHighlight.propTypes = {
  blockGroup:     PropTypes.object.isRequired,
  activeBlockID:  PropTypes.number.isRequired,
  marginHovering: PropTypes.bool.isRequired,
  children:       PropTypes.node
};

PaddingHighlight.defaultProps = {
  children: ''
};

const mapStateToProps = state => ({
  activeBlockID:  state.listicle.activeBlockID,
  marginHovering: state.listicle.marginHovering,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps()
)(PaddingHighlight);
