import { api, builder, router, alerts } from 'utils';
import { busy } from './listicleActions';
import { uiToast } from './uiActions';

export const WIDGETS_SAVE   = 'WIDGETS_SAVE';
export const WIDGETS_UPDATE = 'WIDGETS_UPDATE';
export const WIDGETS_DELETE = 'WIDGETS_DELETE';
export const WIDGETS_FETCH  = 'WIDGETS_FETCH';

/**
 * @returns {{type: string}}
 */
export const widgetsFetch = () => {
  return (dispatch) => {
    api.get(router.generate('widgets_fetch'))
      .then((widgets) => {
        dispatch({
          type: WIDGETS_FETCH,
          widgets
        });
      });
  };
};

/**
 * @param {string} name
 * @param {string|array} html
 * @param {Function} cb
 * @returns {Function}
 */
export const widgetsSave = (name, html, cb = () => {}) => {
  return (dispatch, getState) => {
    dispatch(busy(true));

    const body = {
      name,
      html
    };

    if (Array.isArray(html)) {
      const { listicle } = getState();
      const { blockGroups, sidebarBlockGroups, header } = listicle;
      const found = [];
      html.forEach((blockGroupID) => {
        const { blockGroup } = builder.getGroupBlock(
          blockGroupID,
          blockGroups,
          sidebarBlockGroups,
          header
        );
        if (blockGroup) {
          found.push(blockGroup);
        } else {
          console.error(`Block group ${blockGroupID} not found.`);
        }
      });

      body.html = found;
    }

    api.post(router.generate('widgets_save'), body)
      .then((widgets) => {
        if (widgets._error !== undefined) {
          alerts.error(widgets._error);
          return;
        }

        dispatch({
          type: WIDGETS_SAVE,
          widgets
        });
        cb(widgets);
        dispatch(uiToast('Widget saved!'));
      })
      .finally(() => {
        dispatch(busy(false));
      });
  };
};

/**
 * @param {number} id
 * @param {string} name
 * @param {string} html
 * @param {Function} cb
 * @returns {Function}
 */
export const widgetsUpdate = (id, name, html, cb = () => {}) => {
  return (dispatch) => {
    const body = {
      name,
      html
    };

    dispatch(busy(true));
    api.post(router.generate('widgets_update', { id }), body)
      .then((widgets) => {
        dispatch({
          type: WIDGETS_UPDATE,
          widgets
        });
        cb(widgets);
        dispatch(uiToast('Widget updated!'));
      })
      .finally(() => {
        dispatch(busy(false));
      });
  };
};

/**
 * @param {number} id
 * @param {Function} cb
 * @returns {Function}
 */
export const widgetsDelete = (id, cb = () => {}) => {
  return (dispatch) => {
    dispatch(busy(true));
    api.req('DELETE', router.generate('widgets_delete', { id }))
      .then((widgets) => {
        dispatch({
          type: WIDGETS_DELETE,
          widgets
        });
        cb(widgets);
        dispatch(uiToast('Widget deleted!'));
      })
      .finally(() => {
        dispatch(busy(false));
      });
  };
};
