import { api, router } from 'utils';
import { busy } from './listicleActions';

export const MEDIA_LIBRARY_FETCH = 'MEDIA_LIBRARY_FETCH';
export const MEDIA_SELECT        = 'MEDIA_SELECT';

/**
 * @param {*} asset
 * @returns {{type: string, asset: *}}
 */
export const mediaSelect = (asset) => {
  return {
    type: MEDIA_SELECT,
    asset
  };
};

/**
 * @param {number} offset
 * @returns {Function}
 */
export const mediaLibraryFetch = (offset = 0) => {
  return (dispatch) => {
    api.get(router.generate('media_library', { offset }))
      .then((resp) => {
        dispatch({
          type:          MEDIA_LIBRARY_FETCH,
          library:       resp.media,
          libraryTotal:  parseInt(resp.total, 10),
          libraryOffset: parseInt(resp.offset, 10)
        });
      });
  };
};

/**
 * @returns {function(...[*]=)}
 */
export const mediaLibraryFetchNext = () => {
  return (dispatch, getState) => {
    const { media } = getState();
    const { libraryOffset } = media;

    dispatch(mediaLibraryFetch(libraryOffset + 100));
  };
};

/**
 * @param {File} file
 * @param {Function} onComplete
 * @returns {Function}
 */
export const uploadFile = (file, onComplete = (() => {})) => {
  return (dispatch) => {
    const body = new FormData();
    if (typeof file === 'string') {
      body.append('url', file);
    } else {
      body.append('file', file);
    }
    body.append('system', 'landers');

    dispatch(busy(true));
    api.post(router.generate('media_upload'), body)
      .then((resp) => {
        // Speeds up the image loading in the cropper.
        const ext = resp.url.split('.').pop().toLowerCase();
        if (['jpg', 'jpeg', 'gif', 'png', 'webp'].indexOf(ext) !== -1) {
          const img = new Image();
          img.addEventListener('load', () => {
            onComplete(resp);
            dispatch(mediaLibraryFetch());
          });
          img.src = resp.url;
        } else {
          onComplete(resp);
          dispatch(mediaLibraryFetch());
        }
      })
      .finally(() => {
        dispatch(busy(false));
      });
  };
};

/**
 * @param {string} url
 * @param {File} data
 * @param {Function} onComplete
 * @returns {Function}
 */
export const replaceFile = (url, data, onComplete = (() => {})) => {
  return (dispatch) => {
    const body = {
      url,
      data,
      system: 'landers'
    };

    dispatch(busy(true));
    api.post(router.generate('media_replace'), body)
      .then((resp) => {
        onComplete(resp);
        dispatch(mediaLibraryFetch());
      })
      .finally(() => {
        dispatch(busy(false));
      });
  };
};
