import * as types from '@builder/actions/userActions';
import { objects } from 'utils';

const initialState = objects.merge({}, window.initialState.user);
const roles = [];
objects.forEach(initialState.roles, (role) => {
  roles.push(role);
});
initialState.roles = roles;

const handlers = {};

/**
 * @param {*} state
 * @param {*} action
 * @returns {*}
 */
export default function userReducer(state = objects.clone(initialState), action = {}) {
  if (handlers[action.type]) {
    return handlers[action.type].call(null, state, action);
  }

  return state;
}
