import React from 'react';
import PropTypes from 'prop-types';
import colors from 'utils/colors';

export default class StarRating extends React.PureComponent {
  static propTypes = {
    value:     PropTypes.number.isRequired,
    fontSize:  PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    className: PropTypes.string,
    style:     PropTypes.object,
    onChange:  PropTypes.func
  };

  static defaultProps = {
    className: '',
    onChange:  () => {}
  };

  /**
   * @param {*} props
   */
  constructor(props) {
    super(props);

    this.state = {
      labelValue: 0
    };
  }

  /**
   * @param {number} v
   * @returns {string|undefined}
   */
  getLabelColor = (v) => {
    const { style, value } = this.props;
    const { labelValue } = this.state;

    if (v <= labelValue) {
      return colors.lightenDarkenColor(style.color, 25);
    }

    if (value >= v) {
      return style.color;
    }

    return undefined;
  };

  /**
   * @param {Event} e
   */
  handleChange = (e) => {
    const { onChange } = this.props;

    onChange(e, parseFloat(e.target.value));
  };

  /**
   * @param {Event} e
   */
  handleEmptyClick = (e) => {
    const { onChange } = this.props;

    onChange(e, 0.0);
  };

  /**
   * @param {Event} e
   * @param {number} labelValue
   */
  handleLabelMouseEnter = (e, labelValue) => {
    this.setState({ labelValue });
  };

  /**
   *
   */
  handleLabelMouseLeave = () => {
    this.setState({ labelValue: 0 });
  };

  /**
   * @returns {*}
   */
  render() {
    const { value, fontSize, className } = this.props;

    // For some reason (bug?) react isn't automatically adding 'px' to the line height value.
    const styles = {
      fontSize,
      lineHeight: `${fontSize}px`
    };

    return (
      <fieldset className={`star-rating ${className}`} style={styles}>
        <input
          type="radio"
          id="rating10"
          name="rating"
          value="5"
          onChange={this.handleChange}
          checked={value === 5}
        />
        <label
          htmlFor="rating10"
          title="5 stars"
          style={{ color: this.getLabelColor(5) }}
          onMouseEnter={e => this.handleLabelMouseEnter(e, 5)}
          onMouseLeave={this.handleLabelMouseLeave}
        />

        <input
          type="radio"
          id="rating9"
          name="rating"
          value="4.5"
          onChange={this.handleChange}
          checked={value === 4.5}
        />
        <label
          className="half"
          htmlFor="rating9"
          title="4 1/2 stars"
          style={{ color: this.getLabelColor(4.5) }}
          onMouseEnter={e => this.handleLabelMouseEnter(e, 4.5)}
          onMouseLeave={this.handleLabelMouseLeave}
        />

        <input
          type="radio"
          id="rating8"
          name="rating"
          value="4"
          onChange={this.handleChange}
          checked={value === 4}
        />
        <label
          htmlFor="rating8"
          title="4 stars"
          style={{ color: this.getLabelColor(4) }}
          onMouseEnter={e => this.handleLabelMouseEnter(e, 4)}
          onMouseLeave={this.handleLabelMouseLeave}
        />

        <input
          type="radio"
          id="rating7"
          name="rating"
          value="3.5"
          onChange={this.handleChange}
          checked={value === 3.5}
        />
        <label
          className="half"
          htmlFor="rating7"
          title="3 1/2 stars"
          style={{ color: this.getLabelColor(3.5) }}
          onMouseEnter={e => this.handleLabelMouseEnter(e, 3.5)}
          onMouseLeave={this.handleLabelMouseLeave}
        />

        <input
          type="radio"
          id="rating6"
          name="rating"
          value="3"
          onChange={this.handleChange}
          checked={value === 3}
        />
        <label
          htmlFor="rating6"
          title="3 stars"
          style={{ color: this.getLabelColor(3) }}
          onMouseEnter={e => this.handleLabelMouseEnter(e, 3)}
          onMouseLeave={this.handleLabelMouseLeave}
        />

        <input
          type="radio"
          id="rating5"
          name="rating"
          value="2.5"
          onChange={this.handleChange}
          checked={value === 2.5}
        />
        <label
          className="half"
          htmlFor="rating5"
          title="2 1/2 stars"
          style={{ color: this.getLabelColor(2.5) }}
          onMouseEnter={e => this.handleLabelMouseEnter(e, 2.5)}
          onMouseLeave={this.handleLabelMouseLeave}
        />

        <input
          type="radio"
          id="rating4"
          name="rating"
          value="2"
          onChange={this.handleChange}
          checked={value === 2}
        />
        <label
          htmlFor="rating4"
          title="2 stars"
          style={{ color: this.getLabelColor(2) }}
          onMouseEnter={e => this.handleLabelMouseEnter(e, 2)}
          onMouseLeave={this.handleLabelMouseLeave}
        />

        <input
          type="radio"
          id="rating3"
          name="rating"
          value="1.5"
          onChange={this.handleChange}
          checked={value === 1.5}
        />
        <label
          className="half"
          htmlFor="rating3"
          title="1 1/2 stars"
          style={{ color: this.getLabelColor(1.5) }}
          onMouseEnter={e => this.handleLabelMouseEnter(e, 1.5)}
          onMouseLeave={this.handleLabelMouseLeave}
        />

        <input
          type="radio"
          id="rating2"
          name="rating"
          value="1"
          onChange={this.handleChange}
          checked={value === 1}
        />
        <label
          htmlFor="rating2"
          title="1 star"
          style={{ color: this.getLabelColor(1) }}
          onMouseEnter={e => this.handleLabelMouseEnter(e, 1)}
          onMouseLeave={this.handleLabelMouseLeave}
        />

        <input
          type="radio"
          id="rating1"
          name="rating"
          value="0.5"
          onChange={this.handleChange}
          checked={value === 0.5}
        />
        <label
          className="half"
          htmlFor="rating1"
          title="1/2 star"
          style={{ color: this.getLabelColor(0.5) }}
          onMouseEnter={e => this.handleLabelMouseEnter(e, 0.5)}
          onMouseLeave={this.handleLabelMouseLeave}
        />

        <div className="empty" onClick={this.handleEmptyClick} />
      </fieldset>
    );
  }
}
