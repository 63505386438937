import { api, router, objects, arrays, alerts } from 'utils';
import blockDependencies from '@builder/store/blockDependencies';
import { uiToast } from './uiActions';
import { updateListicle } from './listicleActions';

export const LIBRARY_FETCH = 'LIBRARY_FETCH';

const compiledFunctions = {};

/**
 * @param {*} pixel
 * @param {string} functionName
 * @param {Array} args
 */
const callLibraryFunction = (pixel, functionName, args = []) => {
  if (!compiledFunctions[pixel.name]) {
    compiledFunctions[pixel.name] = {};

    const scope = {};
    try {
      eval(pixel.updateFunction); // eslint-disable-line
      compiledFunctions[pixel.name] = scope;
    } catch (error) {
      console.error(error);
    }
  }

  if (compiledFunctions[pixel.name][functionName]) {
    try {
      compiledFunctions[pixel.name][functionName].apply(this, args);
    } catch (error) {
      console.warn(error);
    }
  }
};

/**
 * @param {string} dependencies
 * @param {Array} libraries
 * @param {Array} pixels
 * @returns {[]}
 */
const resolveDependencies = (dependencies, libraries, pixels) => {
  const resolved = [];
  const deps = dependencies.split(/,/g).map(i => i.trim());
  deps.forEach((d) => {
    libraries.forEach((l) => {
      if (l.name === d) {
        let found = false;
        for (let i = 0; i < pixels.length; i++) {
          if (pixels[i].name === l.name) {
            found = true;
            break;
          }
        }
        if (!found) {
          resolved.push(objects.clone(l));
        }
      }
    });
  });

  return resolved;
};

/**
 * @param {string} functionName
 * @param {...} args
 * @returns {function(...[*]=)}
 */
export const libraryCallFunction = (functionName, ...args) => {
  return (dispatch, getState) => {
    const { listicle } = getState();
    const { pixels } = listicle;

    pixels.forEach((pixel) => {
      if (pixel.updateFunction) {
        callLibraryFunction(pixel, functionName, args);
      }
    });
  };
};

/**
 * @returns {{type: string}}
 */
export const libraryFetch = () => {
  return (dispatch, getState) => {
    api.get(router.generate('libraries_fetch'))
      .then((libraries) => {
        dispatch({
          type: LIBRARY_FETCH,
          libraries
        });
      });

    getState().listicle.pixels.forEach((pixel) => {
      if (pixel.headerInject) {
        $('head').append(pixel.headerScripts);
      }
      if (pixel.footerInject) {
        $('head').append(pixel.footerScripts);
      }
    });
    dispatch(libraryCallFunction('onInitialize'));
  };
};

/**
 * @param {*} body
 * @param {Function} cb
 * @returns {{type: string}}
 */
export const libraryCreate = (body, cb = (() => {})) => {
  return (dispatch) => {
    api.post(router.generate('libraries_create'), body)
      .then((libraries) => {
        dispatch({
          type: LIBRARY_FETCH,
          libraries
        });
        cb();
        dispatch(uiToast('Library created.'));
      });
  };
};

/**
 * @param {*} pixel
 * @returns {{type: string}}
 */
export const libraryAddPixel = (pixel) => {
  return (dispatch, getState) => {
    const { listicle, library } = getState();
    const { pixels } = listicle;
    const { libraries } = library;

    for (let i = 0; i < pixels.length; i++) {
      if (pixels[i].name === pixel.name) {
        return;
      }
    }

    const newPixels = objects.clone(pixels);

    if (pixel.dependencies) {
      const deps = resolveDependencies(pixel.dependencies, libraries, pixels);
      deps.forEach((d) => {
        newPixels.push(d);
        if (d.headerInject) {
          $('head').append(d.headerScripts);
        }
        if (d.footerInject) {
          $('head').append(d.footerScripts);
        }
      });
    }

    newPixels.push(pixel);
    dispatch(updateListicle('pixels', newPixels));

    if (pixel.headerInject) {
      $('head').append(pixel.headerScripts);
    }
    if (pixel.footerInject) {
      $('head').append(pixel.footerScripts);
    }
    dispatch(libraryCallFunction('onInitialize'));
    dispatch(libraryCallFunction('onChange'));
  };
};

/**
 * @param {string} libraryName
 * @returns {function(...[*]=)}
 */
export const libraryAddPixelByName = (libraryName) => {
  return (dispatch, getState) => {
    const { listicle, library } = getState();
    const { pixels } = listicle;
    const { libraries } = library;

    for (let i = 0; i < libraries.length; i++) {
      if (libraries[i].name === libraryName) {
        const found = arrays.findByID(pixels, libraryName, 'name');
        if (!found) {
          const pixel = objects.clone(libraries[i]);
          dispatch(libraryAddPixel(pixel));
        }
      }
    }
  };
};

/**
 * @param {number} index
 * @returns {{type: string}}
 */
export const libraryRemovePixel = (index) => {
  return async (dispatch, getState) => {
    const { listicle } = getState();
    const { blockGroups, pixels } = listicle;

    const { name } = pixels[index];
    for (let i = 0; i < blockGroups.length; i++) {
      const { type } = blockGroups[i].defaultBlock;
      if (blockDependencies[type]) {
        if (blockDependencies[type].indexOf(name) !== -1) {
          // eslint-disable-next-line no-await-in-loop
          await alerts.alert('Library cannot be removed because it\'s used by a block on the page.', 'warning');
          return;
        }
      }
    }

    for (let i = 0; i < pixels.length; i++) {
      if (pixels[i].dependencies.indexOf(name) !== -1) {
        // eslint-disable-next-line no-await-in-loop
        await alerts.alert(`Library cannot be removed because it's used by the ${pixels[i].name} library.`, 'warning');
        return;
      }
    }

    const newPixels = objects.clone(pixels);
    newPixels.splice(index, 1);
    dispatch(updateListicle('pixels', newPixels));
  };
};

/**
 * @param {number} index
 * @param {*} body
 * @param {Function} cb
 * @returns {{type: string}}
 */
export const libraryUpdatePixel = (index, body, cb = (() => {})) => {
  return (dispatch, getState) => {
    const { listicle, library } = getState();
    const { pixels } = listicle;
    const { libraries } = library;

    const newPixels = objects.clone(pixels);
    newPixels.splice(index, 1);
    newPixels.splice(index, 0, body);

    if (body.dependencies) {
      const deps = resolveDependencies(body.dependencies, libraries, pixels);
      deps.forEach((d) => {
        newPixels.push(d);
      });
    }

    dispatch(updateListicle('pixels', newPixels));
    delete compiledFunctions[body.name];
    if (body.headerInject) {
      $('head').append(body.headerScripts);
    }
    if (body.footerInject) {
      $('head').append(body.footerScripts);
    }
    cb();
  };
};

/**
 * @param {number} id
 * @param {*} body
 * @param {Function} cb
 * @returns {{type: string}}
 */
export const libraryUpdateLibrary = (id, body, cb = (() => {})) => {
  return (dispatch) => {
    api.post(router.generate('libraries_update', { id }), body)
      .then((libraries) => {
        dispatch({
          type: LIBRARY_FETCH,
          libraries
        });
        cb();
        dispatch(uiToast('Library updated.'));
      });
  };
};

/**
 * @param {number} id
 * @returns {{type: string}}
 */
export const libraryRemoveLibrary = (id) => {
  return (dispatch) => {
    api.req('DELETE', router.generate('libraries_delete', { id }))
      .then((libraries) => {
        dispatch({
          type: LIBRARY_FETCH,
          libraries
        });
        dispatch(uiToast('Library deleted.'));
      });
  };
};
