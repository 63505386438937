export const codes = [
  {
    'name':         'United States of America',
    'iso':          'US',
    'country-code': '840'
  },
  {
    'name':         'Australia',
    'iso':          'AU',
    'country-code': '036'
  },
  {
    'name':         'Canada',
    'iso':          'CA',
    'country-code': '124'
  },
  {
    'name':         'Great Britain',
    'iso':          'GB',
    'country-code': '826'
  },
  {
    'name':         'United Kingdom',
    'iso':          'UK',
    'country-code': '826'
  },
  {
    'name':         'Afghanistan',
    'iso':          'AF',
    'country-code': '004'
  },
  {
    'name':         'Åland Islands',
    'iso':          'AX',
    'country-code': '248'
  },
  {
    'name':         'Albania',
    'iso':          'AL',
    'country-code': '008'
  },
  {
    'name':         'Algeria',
    'iso':          'DZ',
    'country-code': '012'
  },
  {
    'name':         'American Samoa',
    'iso':          'AS',
    'country-code': '016'
  },
  {
    'name':         'Andorra',
    'iso':          'AD',
    'country-code': '020'
  },
  {
    'name':         'Angola',
    'iso':          'AO',
    'country-code': '024'
  },
  {
    'name':         'Anguilla',
    'iso':          'AI',
    'country-code': '660'
  },
  {
    'name':         'Antarctica',
    'iso':          'AQ',
    'country-code': '010'
  },
  {
    'name':         'Antigua and Barbuda',
    'iso':          'AG',
    'country-code': '028'
  },
  {
    'name':         'Argentina',
    'iso':          'AR',
    'country-code': '032'
  },
  {
    'name':         'Armenia',
    'iso':          'AM',
    'country-code': '051'
  },
  {
    'name':         'Aruba',
    'iso':          'AW',
    'country-code': '533'
  },
  {
    'name':         'Austria',
    'iso':          'AT',
    'country-code': '040'
  },
  {
    'name':         'Azerbaijan',
    'iso':          'AZ',
    'country-code': '031'
  },
  {
    'name':         'Bahamas',
    'iso':          'BS',
    'country-code': '044'
  },
  {
    'name':         'Bahrain',
    'iso':          'BH',
    'country-code': '048'
  },
  {
    'name':         'Bangladesh',
    'iso':          'BD',
    'country-code': '050'
  },
  {
    'name':         'Barbados',
    'iso':          'BB',
    'country-code': '052'
  },
  {
    'name':         'Belarus',
    'iso':          'BY',
    'country-code': '112'
  },
  {
    'name':         'Belgium',
    'iso':          'BE',
    'country-code': '056'
  },
  {
    'name':         'Belize',
    'iso':          'BZ',
    'country-code': '084'
  },
  {
    'name':         'Benin',
    'iso':          'BJ',
    'country-code': '204'
  },
  {
    'name':         'Bermuda',
    'iso':          'BM',
    'country-code': '060'
  },
  {
    'name':         'Bhutan',
    'iso':          'BT',
    'country-code': '064'
  },
  {
    'name':         'Bolivia (Plurinational State of)',
    'iso':          'BO',
    'country-code': '068'
  },
  {
    'name':         'Bonaire, Sint Eustatius and Saba',
    'iso':          'BQ',
    'country-code': '535'
  },
  {
    'name':         'Bosnia and Herzegovina',
    'iso':          'BA',
    'country-code': '070'
  },
  {
    'name':         'Botswana',
    'iso':          'BW',
    'country-code': '072'
  },
  {
    'name':         'Bouvet Island',
    'iso':          'BV',
    'country-code': '074'
  },
  {
    'name':         'Brazil',
    'iso':          'BR',
    'country-code': '076'
  },
  {
    'name':         'British Indian Ocean Territory',
    'iso':          'IO',
    'country-code': '086'
  },
  {
    'name':         'Brunei Darussalam',
    'iso':          'BN',
    'country-code': '096'
  },
  {
    'name':         'Bulgaria',
    'iso':          'BG',
    'country-code': '100'
  },
  {
    'name':         'Burkina Faso',
    'iso':          'BF',
    'country-code': '854'
  },
  {
    'name':         'Burundi',
    'iso':          'BI',
    'country-code': '108'
  },
  {
    'name':         'Cabo Verde',
    'iso':          'CV',
    'country-code': '132'
  },
  {
    'name':         'Cambodia',
    'iso':          'KH',
    'country-code': '116'
  },
  {
    'name':         'Cameroon',
    'iso':          'CM',
    'country-code': '120'
  },
  {
    'name':         'Cayman Islands',
    'iso':          'KY',
    'country-code': '136'
  },
  {
    'name':         'Central African Republic',
    'iso':          'CF',
    'country-code': '140'
  },
  {
    'name':         'Chad',
    'iso':          'TD',
    'country-code': '148'
  },
  {
    'name':         'Chile',
    'iso':          'CL',
    'country-code': '152'
  },
  {
    'name':         'China',
    'iso':          'CN',
    'country-code': '156'
  },
  {
    'name':         'Christmas Island',
    'iso':          'CX',
    'country-code': '162'
  },
  {
    'name':         'Cocos (Keeling) Islands',
    'iso':          'CC',
    'country-code': '166'
  },
  {
    'name':         'Colombia',
    'iso':          'CO',
    'country-code': '170'
  },
  {
    'name':         'Comoros',
    'iso':          'KM',
    'country-code': '174'
  },
  {
    'name':         'Congo',
    'iso':          'CG',
    'country-code': '178'
  },
  {
    'name':         'Congo, Democratic Republic of the',
    'iso':          'CD',
    'country-code': '180'
  },
  {
    'name':         'Cook Islands',
    'iso':          'CK',
    'country-code': '184'
  },
  {
    'name':         'Costa Rica',
    'iso':          'CR',
    'country-code': '188'
  },
  {
    'name':         'Côte d\'Ivoire',
    'iso':          'CI',
    'country-code': '384'
  },
  {
    'name':         'Croatia',
    'iso':          'HR',
    'country-code': '191'
  },
  {
    'name':         'Cuba',
    'iso':          'CU',
    'country-code': '192'
  },
  {
    'name':         'Curaçao',
    'iso':          'CW',
    'country-code': '531'
  },
  {
    'name':         'Cyprus',
    'iso':          'CY',
    'country-code': '196'
  },
  {
    'name':         'Czechia',
    'iso':          'CZ',
    'country-code': '203'
  },
  {
    'name':         'Denmark',
    'iso':          'DK',
    'country-code': '208'
  },
  {
    'name':         'Djibouti',
    'iso':          'DJ',
    'country-code': '262'
  },
  {
    'name':         'Dominica',
    'iso':          'DM',
    'country-code': '212'
  },
  {
    'name':         'Dominican Republic',
    'iso':          'DO',
    'country-code': '214'
  },
  {
    'name':         'Ecuador',
    'iso':          'EC',
    'country-code': '218'
  },
  {
    'name':         'Egypt',
    'iso':          'EG',
    'country-code': '818'
  },
  {
    'name':         'El Salvador',
    'iso':          'SV',
    'country-code': '222'
  },
  {
    'name':         'Equatorial Guinea',
    'iso':          'GQ',
    'country-code': '226'
  },
  {
    'name':         'Eritrea',
    'iso':          'ER',
    'country-code': '232'
  },
  {
    'name':         'Estonia',
    'iso':          'EE',
    'country-code': '233'
  },
  {
    'name':         'Eswatini',
    'iso':          'SZ',
    'country-code': '748'
  },
  {
    'name':         'Ethiopia',
    'iso':          'ET',
    'country-code': '231'
  },
  {
    'name':         'Falkland Islands (Malvinas)',
    'iso':          'FK',
    'country-code': '238'
  },
  {
    'name':         'Faroe Islands',
    'iso':          'FO',
    'country-code': '234'
  },
  {
    'name':         'Fiji',
    'iso':          'FJ',
    'country-code': '242'
  },
  {
    'name':         'Finland',
    'iso':          'FI',
    'country-code': '246'
  },
  {
    'name':         'France',
    'iso':          'FR',
    'country-code': '250'
  },
  {
    'name':         'French Guiana',
    'iso':          'GF',
    'country-code': '254'
  },
  {
    'name':         'French Polynesia',
    'iso':          'PF',
    'country-code': '258'
  },
  {
    'name':         'French Southern Territories',
    'iso':          'TF',
    'country-code': '260'
  },
  {
    'name':         'Gabon',
    'iso':          'GA',
    'country-code': '266'
  },
  {
    'name':         'Gambia',
    'iso':          'GM',
    'country-code': '270'
  },
  {
    'name':         'Georgia',
    'iso':          'GE',
    'country-code': '268'
  },
  {
    'name':         'Germany',
    'iso':          'DE',
    'country-code': '276'
  },
  {
    'name':         'Ghana',
    'iso':          'GH',
    'country-code': '288'
  },
  {
    'name':         'Gibraltar',
    'iso':          'GI',
    'country-code': '292'
  },
  {
    'name':         'Greece',
    'iso':          'GR',
    'country-code': '300'
  },
  {
    'name':         'Greenland',
    'iso':          'GL',
    'country-code': '304'
  },
  {
    'name':         'Grenada',
    'iso':          'GD',
    'country-code': '308'
  },
  {
    'name':         'Guadeloupe',
    'iso':          'GP',
    'country-code': '312'
  },
  {
    'name':         'Guam',
    'iso':          'GU',
    'country-code': '316'
  },
  {
    'name':         'Guatemala',
    'iso':          'GT',
    'country-code': '320'
  },
  {
    'name':         'Guernsey',
    'iso':          'GG',
    'country-code': '831'
  },
  {
    'name':         'Guinea',
    'iso':          'GN',
    'country-code': '324'
  },
  {
    'name':         'Guinea-Bissau',
    'iso':          'GW',
    'country-code': '624'
  },
  {
    'name':         'Guyana',
    'iso':          'GY',
    'country-code': '328'
  },
  {
    'name':         'Haiti',
    'iso':          'HT',
    'country-code': '332'
  },
  {
    'name':         'Heard Island and McDonald Islands',
    'iso':          'HM',
    'country-code': '334'
  },
  {
    'name':         'Holy See',
    'iso':          'VA',
    'country-code': '336'
  },
  {
    'name':         'Honduras',
    'iso':          'HN',
    'country-code': '340'
  },
  {
    'name':         'Hong Kong',
    'iso':          'HK',
    'country-code': '344'
  },
  {
    'name':         'Hungary',
    'iso':          'HU',
    'country-code': '348'
  },
  {
    'name':         'Iceland',
    'iso':          'IS',
    'country-code': '352'
  },
  {
    'name':         'India',
    'iso':          'IN',
    'country-code': '356'
  },
  {
    'name':         'Indonesia',
    'iso':          'ID',
    'country-code': '360'
  },
  {
    'name':         'Iran (Islamic Republic of)',
    'iso':          'IR',
    'country-code': '364'
  },
  {
    'name':         'Iraq',
    'iso':          'IQ',
    'country-code': '368'
  },
  {
    'name':         'Ireland',
    'iso':          'IE',
    'country-code': '372'
  },
  {
    'name':         'Isle of Man',
    'iso':          'IM',
    'country-code': '833'
  },
  {
    'name':         'Israel',
    'iso':          'IL',
    'country-code': '376'
  },
  {
    'name':         'Italy',
    'iso':          'IT',
    'country-code': '380'
  },
  {
    'name':         'Jamaica',
    'iso':          'JM',
    'country-code': '388'
  },
  {
    'name':         'Japan',
    'iso':          'JP',
    'country-code': '392'
  },
  {
    'name':         'Jersey',
    'iso':          'JE',
    'country-code': '832'
  },
  {
    'name':         'Jordan',
    'iso':          'JO',
    'country-code': '400'
  },
  {
    'name':         'Kazakhstan',
    'iso':          'KZ',
    'country-code': '398'
  },
  {
    'name':         'Kenya',
    'iso':          'KE',
    'country-code': '404'
  },
  {
    'name':         'Kiribati',
    'iso':          'KI',
    'country-code': '296'
  },
  {
    'name':         'Korea (Democratic People\'s Republic of)',
    'iso':          'KP',
    'country-code': '408'
  },
  {
    'name':         'Korea, Republic of',
    'iso':          'KR',
    'country-code': '410'
  },
  {
    'name':         'Kuwait',
    'iso':          'KW',
    'country-code': '414'
  },
  {
    'name':         'Kyrgyzstan',
    'iso':          'KG',
    'country-code': '417'
  },
  {
    'name':         'Lao People\'s Democratic Republic',
    'iso':          'LA',
    'country-code': '418'
  },
  {
    'name':         'Latvia',
    'iso':          'LV',
    'country-code': '428'
  },
  {
    'name':         'Lebanon',
    'iso':          'LB',
    'country-code': '422'
  },
  {
    'name':         'Lesotho',
    'iso':          'LS',
    'country-code': '426'
  },
  {
    'name':         'Liberia',
    'iso':          'LR',
    'country-code': '430'
  },
  {
    'name':         'Libya',
    'iso':          'LY',
    'country-code': '434'
  },
  {
    'name':         'Liechtenstein',
    'iso':          'LI',
    'country-code': '438'
  },
  {
    'name':         'Lithuania',
    'iso':          'LT',
    'country-code': '440'
  },
  {
    'name':         'Luxembourg',
    'iso':          'LU',
    'country-code': '442'
  },
  {
    'name':         'Macao',
    'iso':          'MO',
    'country-code': '446'
  },
  {
    'name':         'Madagascar',
    'iso':          'MG',
    'country-code': '450'
  },
  {
    'name':         'Malawi',
    'iso':          'MW',
    'country-code': '454'
  },
  {
    'name':         'Malaysia',
    'iso':          'MY',
    'country-code': '458'
  },
  {
    'name':         'Maldives',
    'iso':          'MV',
    'country-code': '462'
  },
  {
    'name':         'Mali',
    'iso':          'ML',
    'country-code': '466'
  },
  {
    'name':         'Malta',
    'iso':          'MT',
    'country-code': '470'
  },
  {
    'name':         'Marshall Islands',
    'iso':          'MH',
    'country-code': '584'
  },
  {
    'name':         'Martinique',
    'iso':          'MQ',
    'country-code': '474'
  },
  {
    'name':         'Mauritania',
    'iso':          'MR',
    'country-code': '478'
  },
  {
    'name':         'Mauritius',
    'iso':          'MU',
    'country-code': '480'
  },
  {
    'name':         'Mayotte',
    'iso':          'YT',
    'country-code': '175'
  },
  {
    'name':         'Mexico',
    'iso':          'MX',
    'country-code': '484'
  },
  {
    'name':         'Micronesia (Federated States of)',
    'iso':          'FM',
    'country-code': '583'
  },
  {
    'name':         'Moldova, Republic of',
    'iso':          'MD',
    'country-code': '498'
  },
  {
    'name':         'Monaco',
    'iso':          'MC',
    'country-code': '492'
  },
  {
    'name':         'Mongolia',
    'iso':          'MN',
    'country-code': '496'
  },
  {
    'name':         'Montenegro',
    'iso':          'ME',
    'country-code': '499'
  },
  {
    'name':         'Montserrat',
    'iso':          'MS',
    'country-code': '500'
  },
  {
    'name':         'Morocco',
    'iso':          'MA',
    'country-code': '504'
  },
  {
    'name':         'Mozambique',
    'iso':          'MZ',
    'country-code': '508'
  },
  {
    'name':         'Myanmar',
    'iso':          'MM',
    'country-code': '104'
  },
  {
    'name':         'Namibia',
    'iso':          'NA',
    'country-code': '516'
  },
  {
    'name':         'Nauru',
    'iso':          'NR',
    'country-code': '520'
  },
  {
    'name':         'Nepal',
    'iso':          'NP',
    'country-code': '524'
  },
  {
    'name':         'Netherlands',
    'iso':          'NL',
    'country-code': '528'
  },
  {
    'name':         'New Caledonia',
    'iso':          'NC',
    'country-code': '540'
  },
  {
    'name':         'New Zealand',
    'iso':          'NZ',
    'country-code': '554'
  },
  {
    'name':         'Nicaragua',
    'iso':          'NI',
    'country-code': '558'
  },
  {
    'name':         'Niger',
    'iso':          'NE',
    'country-code': '562'
  },
  {
    'name':         'Nigeria',
    'iso':          'NG',
    'country-code': '566'
  },
  {
    'name':         'Niue',
    'iso':          'NU',
    'country-code': '570'
  },
  {
    'name':         'Norfolk Island',
    'iso':          'NF',
    'country-code': '574'
  },
  {
    'name':         'North Macedonia',
    'iso':          'MK',
    'country-code': '807'
  },
  {
    'name':         'Northern Mariana Islands',
    'iso':          'MP',
    'country-code': '580'
  },
  {
    'name':         'Norway',
    'iso':          'NO',
    'country-code': '578'
  },
  {
    'name':         'Oman',
    'iso':          'OM',
    'country-code': '512'
  },
  {
    'name':         'Pakistan',
    'iso':          'PK',
    'country-code': '586'
  },
  {
    'name':         'Palau',
    'iso':          'PW',
    'country-code': '585'
  },
  {
    'name':         'Palestine, State of',
    'iso':          'PS',
    'country-code': '275'
  },
  {
    'name':         'Panama',
    'iso':          'PA',
    'country-code': '591'
  },
  {
    'name':         'Papua New Guinea',
    'iso':          'PG',
    'country-code': '598'
  },
  {
    'name':         'Paraguay',
    'iso':          'PY',
    'country-code': '600'
  },
  {
    'name':         'Peru',
    'iso':          'PE',
    'country-code': '604'
  },
  {
    'name':         'Philippines',
    'iso':          'PH',
    'country-code': '608'
  },
  {
    'name':         'Pitcairn',
    'iso':          'PN',
    'country-code': '612'
  },
  {
    'name':         'Poland',
    'iso':          'PL',
    'country-code': '616'
  },
  {
    'name':         'Portugal',
    'iso':          'PT',
    'country-code': '620'
  },
  {
    'name':         'Puerto Rico',
    'iso':          'PR',
    'country-code': '630'
  },
  {
    'name':         'Qatar',
    'iso':          'QA',
    'country-code': '634'
  },
  {
    'name':         'Réunion',
    'iso':          'RE',
    'country-code': '638'
  },
  {
    'name':         'Romania',
    'iso':          'RO',
    'country-code': '642'
  },
  {
    'name':         'Russian Federation',
    'iso':          'RU',
    'country-code': '643'
  },
  {
    'name':         'Rwanda',
    'iso':          'RW',
    'country-code': '646'
  },
  {
    'name':         'Saint Barthélemy',
    'iso':          'BL',
    'country-code': '652'
  },
  {
    'name':         'Saint Helena, Ascension and Tristan da Cunha',
    'iso':          'SH',
    'country-code': '654'
  },
  {
    'name':         'Saint Kitts and Nevis',
    'iso':          'KN',
    'country-code': '659'
  },
  {
    'name':         'Saint Lucia',
    'iso':          'LC',
    'country-code': '662'
  },
  {
    'name':         'Saint Martin (French part)',
    'iso':          'MF',
    'country-code': '663'
  },
  {
    'name':         'Saint Pierre and Miquelon',
    'iso':          'PM',
    'country-code': '666'
  },
  {
    'name':         'Saint Vincent and the Grenadines',
    'iso':          'VC',
    'country-code': '670'
  },
  {
    'name':         'Samoa',
    'iso':          'WS',
    'country-code': '882'
  },
  {
    'name':         'San Marino',
    'iso':          'SM',
    'country-code': '674'
  },
  {
    'name':         'Sao Tome and Principe',
    'iso':          'ST',
    'country-code': '678'
  },
  {
    'name':         'Saudi Arabia',
    'iso':          'SA',
    'country-code': '682'
  },
  {
    'name':         'Senegal',
    'iso':          'SN',
    'country-code': '686'
  },
  {
    'name':         'Serbia',
    'iso':          'RS',
    'country-code': '688'
  },
  {
    'name':         'Seychelles',
    'iso':          'SC',
    'country-code': '690'
  },
  {
    'name':         'Sierra Leone',
    'iso':          'SL',
    'country-code': '694'
  },
  {
    'name':         'Singapore',
    'iso':          'SG',
    'country-code': '702'
  },
  {
    'name':         'Sint Maarten (Dutch part)',
    'iso':          'SX',
    'country-code': '534'
  },
  {
    'name':         'Slovakia',
    'iso':          'SK',
    'country-code': '703'
  },
  {
    'name':         'Slovenia',
    'iso':          'SI',
    'country-code': '705'
  },
  {
    'name':         'Solomon Islands',
    'iso':          'SB',
    'country-code': '090'
  },
  {
    'name':         'Somalia',
    'iso':          'SO',
    'country-code': '706'
  },
  {
    'name':         'South Africa',
    'iso':          'ZA',
    'country-code': '710'
  },
  {
    'name':         'South Georgia and the South Sandwich Islands',
    'iso':          'GS',
    'country-code': '239'
  },
  {
    'name':         'South Sudan',
    'iso':          'SS',
    'country-code': '728'
  },
  {
    'name':         'Spain',
    'iso':          'ES',
    'country-code': '724'
  },
  {
    'name':         'Sri Lanka',
    'iso':          'LK',
    'country-code': '144'
  },
  {
    'name':         'Sudan',
    'iso':          'SD',
    'country-code': '729'
  },
  {
    'name':         'Suriname',
    'iso':          'SR',
    'country-code': '740'
  },
  {
    'name':         'Svalbard and Jan Mayen',
    'iso':          'SJ',
    'country-code': '744'
  },
  {
    'name':         'Sweden',
    'iso':          'SE',
    'country-code': '752'
  },
  {
    'name':         'Switzerland',
    'iso':          'CH',
    'country-code': '756'
  },
  {
    'name':         'Syrian Arab Republic',
    'iso':          'SY',
    'country-code': '760'
  },
  {
    'name':         'Taiwan, Province of China',
    'iso':          'TW',
    'country-code': '158'
  },
  {
    'name':         'Tajikistan',
    'iso':          'TJ',
    'country-code': '762'
  },
  {
    'name':         'Tanzania, United Republic of',
    'iso':          'TZ',
    'country-code': '834'
  },
  {
    'name':         'Thailand',
    'iso':          'TH',
    'country-code': '764'
  },
  {
    'name':         'Timor-Leste',
    'iso':          'TL',
    'country-code': '626'
  },
  {
    'name':         'Togo',
    'iso':          'TG',
    'country-code': '768'
  },
  {
    'name':         'Tokelau',
    'iso':          'TK',
    'country-code': '772'
  },
  {
    'name':         'Tonga',
    'iso':          'TO',
    'country-code': '776'
  },
  {
    'name':         'Trinidad and Tobago',
    'iso':          'TT',
    'country-code': '780'
  },
  {
    'name':         'Tunisia',
    'iso':          'TN',
    'country-code': '788'
  },
  {
    'name':         'Turkey',
    'iso':          'TR',
    'country-code': '792'
  },
  {
    'name':         'Turkmenistan',
    'iso':          'TM',
    'country-code': '795'
  },
  {
    'name':         'Turks and Caicos Islands',
    'iso':          'TC',
    'country-code': '796'
  },
  {
    'name':         'Tuvalu',
    'iso':          'TV',
    'country-code': '798'
  },
  {
    'name':         'Uganda',
    'iso':          'UG',
    'country-code': '800'
  },
  {
    'name':         'Ukraine',
    'iso':          'UA',
    'country-code': '804'
  },
  {
    'name':         'United Arab Emirates',
    'iso':          'AE',
    'country-code': '784'
  },
  {
    'name':         'United States Minor Outlying Islands',
    'iso':          'UM',
    'country-code': '581'
  },
  {
    'name':         'Uruguay',
    'iso':          'UY',
    'country-code': '858'
  },
  {
    'name':         'Uzbekistan',
    'iso':          'UZ',
    'country-code': '860'
  },
  {
    'name':         'Vanuatu',
    'iso':          'VU',
    'country-code': '548'
  },
  {
    'name':         'Venezuela (Bolivarian Republic of)',
    'iso':          'VE',
    'country-code': '862'
  },
  {
    'name':         'Viet Nam',
    'iso':          'VN',
    'country-code': '704'
  },
  {
    'name':         'Virgin Islands (British)',
    'iso':          'VG',
    'country-code': '092'
  },
  {
    'name':         'Virgin Islands (U.S.)',
    'iso':          'VI',
    'country-code': '850'
  },
  {
    'name':         'Wallis and Futuna',
    'iso':          'WF',
    'country-code': '876'
  },
  {
    'name':         'Western Sahara',
    'iso':          'EH',
    'country-code': '732'
  },
  {
    'name':         'Yemen',
    'iso':          'YE',
    'country-code': '887'
  },
  {
    'name':         'Zambia',
    'iso':          'ZM',
    'country-code': '894'
  },
  {
    'name':         'Zimbabwe',
    'iso':          'ZW',
    'country-code': '716'
  }
];

/**
 * @param {string} code
 * @returns {string}
 */
const fromCode = (code) => {
  return codes[code].name;
};

export const countries = codes;

export default {
  codes,
  fromCode
};
