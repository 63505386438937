import React from 'react';
import PropTypes from 'prop-types';
import { connect, mapDispatchToProps } from 'utils';
import { listicleActions } from '@builder/actions';
import QuillEditor from '@builder/components/QuillEditor';
import BlockWrapper from '@builder/components/BlockWrapper';

const BlockButton = ({ blockGroup, updateBlock }) => {
  const { defaultBlock } = blockGroup;

  return (
    <BlockWrapper blockGroup={blockGroup} className="arb-button">
      {(provided) => {
        if (provided.isActive) {
          return (
            <QuillEditor
              html={defaultBlock.html}
              getElement={provided.getElement}
              onUpdate={html => updateBlock(blockGroup.defaultBlock.id, blockGroup.id, 'html', html)}
            />
          );
        }

        return <span dangerouslySetInnerHTML={{ __html: defaultBlock.html }} />;
      }}
    </BlockWrapper>
  );
};

BlockButton.propTypes = {
  blockGroup:  PropTypes.object.isRequired,
  updateBlock: PropTypes.func.isRequired
};

BlockButton.typeName      = 'button';
BlockButton.icon          = 'link';
BlockButton.label         = 'Button';
BlockButton.defaultValues = {
  html:   'Click Me',
  styles: { marginBottom: 15 }
};

export default connect(
  null,
  mapDispatchToProps(listicleActions)
)(BlockButton);
