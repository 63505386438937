import React from 'react';
import PropTypes from 'prop-types';
import { connect, mapDispatchToProps } from 'utils';
import BlockWrapper from '@builder/components/BlockWrapper';

const BlockLogo = ({ blockGroup, anchor }) => {
  return (
    <BlockWrapper
      tag="img"
      blockGroup={blockGroup}
      className="arb-logo"
      src={anchor.site.logoUrl}
    />
  );
};

BlockLogo.propTypes = {
  blockGroup: PropTypes.object.isRequired,
  anchor:     PropTypes.object.isRequired
};

BlockLogo.defaultProps = {};

BlockLogo.typeName      = 'logo';
BlockLogo.icon          = 'bolt';
BlockLogo.label         = 'Logo';
BlockLogo.defaultValues = {
  styles: { marginBottom: 15 }
};

const mapStateToProps = state => ({
  anchor: state.listicle.anchor
});

export default connect(
  mapStateToProps,
  mapDispatchToProps()
)(BlockLogo);
