import React from 'react';
import PropTypes from 'prop-types';
import { ToastContainer } from 'react-toastify';
import { connect, mapDispatchToProps } from 'utils';
import { uiActions } from '@builder/actions';

@connect(
  null,
  mapDispatchToProps(uiActions)
)
export default class App extends React.PureComponent {
  static propTypes = {
    uiToast: PropTypes.func.isRequired
  };

  static defaultProps = {};

  /**
   *
   */
  componentDidMount() {
    const { uiToast } = this.props;

    window.jToast = uiToast;
    if (window.jToastQueue !== undefined) {
      window.jToastQueue.forEach((item) => {
        uiToast(decodeURIComponent(item.message), {
          type: item.type
        });
      });
      window.jToastQueue = [];
    }
  }

  /**
   * @returns {*}
   */
  render() {
    return (
      <div>
        <ToastContainer />
      </div>
    );
  }
}
