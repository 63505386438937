import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import ErrorBoundary from 'react-error-boundary';
import { builder, connect, mapDispatchToProps } from 'utils';
import { listicleActions } from '@builder/actions';
import ErrorComponent from './ErrorComponent';
import PaddingHighlight from './PaddingHighlight';
import MarginHighlight from './MarginHighlight';

const BlockWrapper = ({
  tag,
  menu,
  blockGroup,
  activeBlockID,
  singleElement,
  selectedBlockIDs,
  className,
  children,
  clickable,
  activateBlock,
  selectBlock,
  ...rest
}) => {
  const element  = useRef(null);
  const body     = useRef(null);
  const isActive = blockGroup.id === activeBlockID;

  /**
   * @param {MouseEvent} e
   */
  const handleClick = (e) => {
    /* if (e.button === 2) {
      return;
    } */

    if (e.ctrlKey) {
      e.stopPropagation();
      selectBlock(blockGroup.id);
      return;
    }

    e.stopPropagation();
    if (clickable && !isActive) {
      activateBlock(blockGroup.id, body.current);
    }
  };

  let styles;
  if (!isActive) {
    styles = builder.buildStyles(blockGroup.defaultBlock.styles);
  } else {
    styles = builder.buildStyles(blockGroup.defaultBlock.styles, [], [
      'marginTop', 'marginRight', 'marginBottom', 'marginLeft',
      'paddingTop', 'paddingRight', 'paddingBottom', 'paddingLeft'
    ]);
    styles.margin  = 0;
    styles.padding = 0;
  }

  const props = {
    ref:       body,
    style:     styles,
    id:        `block-${blockGroup.id}`,
    'data-id': blockGroup.id,
    className: `arb-block ${className} ${blockGroup.defaultBlock.classes}`,
    ...rest
  };

  let inner = null;
  if (children) {
    inner = children({
      getElement: () => document.getElementById(`block-${blockGroup.id}`),
      isActive,
      props
    });
  }

  if (!isActive) {
    return (
      <ErrorBoundary FallbackComponent={ErrorComponent}>
        <div
          ref={element}
          onClick={handleClick}
          className="arb-block-wrap"
          id={`block-wrap-${blockGroup.id}`}
        >
          {singleElement ? inner : React.createElement(tag, props, inner)}
          {selectedBlockIDs.indexOf(blockGroup.id) !== -1 && (
            <div className="builder-canvas-block-wrap-selected-highlight" />
          )}
        </div>
      </ErrorBoundary>
    );
  }

  let elChildren;
  if (inner) {
    elChildren = (
      <PaddingHighlight blockGroup={blockGroup}>
        {inner}
      </PaddingHighlight>
    );
  }

  return (
    <ErrorBoundary FallbackComponent={ErrorComponent}>
      {(typeof menu === 'function') ? menu() : menu}
      <div
        ref={element}
        onClick={handleClick}
        className="arb-block-wrap"
        id={`block-wrap-${blockGroup.id}`}
      >
        <MarginHighlight blockGroup={blockGroup}>
          {singleElement ? inner : React.createElement(tag, props, elChildren)}
        </MarginHighlight>
        {selectedBlockIDs.indexOf(blockGroup.id) !== -1 && (
          <div className="builder-canvas-block-wrap-selected-highlight" />
        )}
      </div>
    </ErrorBoundary>
  );
};

BlockWrapper.propTypes = {
  blockGroup:    PropTypes.object.isRequired,
  tag:           PropTypes.string,
  menu:          PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  singleElement: PropTypes.bool,
  clickable:     PropTypes.bool,
  className:     PropTypes.string,
  children:      PropTypes.func
};

BlockWrapper.defaultProps = {
  tag:           'div',
  menu:          '',
  singleElement: false,
  clickable:     true,
  className:     ''
};

const mapStateToProps = state => ({
  activeBlockID:    state.listicle.activeBlockID,
  selectedBlockIDs: state.listicle.selectedBlockIDs
});

export default connect(
  mapStateToProps,
  mapDispatchToProps({
    activateBlock: listicleActions.activateBlock,
    selectBlock:   listicleActions.selectBlock
  })
)(BlockWrapper);
