import browser from './browser';

const values = {};

/**
 * @param {string} namespace
 * @param {string} value
 */
const copy = (namespace, value) => {
  if (values[namespace] === undefined) {
    values[namespace] = '';
  }

  values[namespace] = value;
  browser.storage.setItem(`clipboard.${namespace}`, value);
  window.jToast('Copied!', {}, 1000);
};

/**
 * @param {string} namespace
 * @returns {string|*}
 */
const get = (namespace) => {
  if (!values[namespace]) {
    const stored = browser.storage.getItem(`clipboard.${namespace}`);
    if (stored) {
      if (values[namespace] === undefined) {
        values[namespace] = '';
      }
      values[namespace] = stored;

      return stored;
    }

    return null;
  }

  return values[namespace];
};

/**
 * @param {string} namespace
 * @returns {boolean}
 */
const has = (namespace) => {
  return !!get(namespace);
};

const eventSubscribers = [];

/**
 * @param {Function} cb
 */
const subscribe = (cb) => {
  eventSubscribers.push(cb);
};

window.addEventListener('storage', (e) => {
  if (e.key.indexOf('clipboard.') === 0) {
    const namespace = e.key.replace('clipboard.', '');
    delete values[namespace];

    eventSubscribers.forEach((cb) => {
      cb(e, namespace, get(namespace));
    });
  }
});

export default {
  subscribe,
  get,
  has,
  copy
};
