import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { classNames } from 'utils';
import Icon from 'components/Icon';

const Tabs = ({ items, value, onChange, className }) => {
  const [tab, setTab] = useState(value);

  useEffect(() => {
    setTab(value);
  }, [value]);
  /**
   * @param {Event} e
   * @param {*} i
   */
  const handleChange = (e, i) => {
    setTab(i.value);
    onChange(e, i);
  };

  return (
    <ul className={classNames('nav nav-pills', className)}>
      {items.map(item => (
        <li key={item.value} className="nav-item">
          <span
            className={classNames('nav-link', { 'active': tab === item.value })}
            onClick={e => handleChange(e, item)}
          >
            {item.icon ? (
              <Icon name={item.icon} title={item.label} />
            ) : (
              item.label
            )}
          </span>
        </li>
      ))}
    </ul>
  );
};

Tabs.propTypes = {
  items:     PropTypes.array.isRequired,
  value:     PropTypes.string.isRequired,
  className: PropTypes.string,
  onChange:  PropTypes.func
};

Tabs.defaultProps = {
  className: '',
  onChange:  () => {}
};

export default Tabs;
