import { api, router } from 'utils';

export const PRODUCTS_LIST    = 'PRODUCTS_LIST';
export const PRODUCTS_CREATE  = 'PRODUCTS_CREATE';
export const PRODUCTS_UPDATE  = 'PRODUCTS_UPDATE';
export const PRODUCTS_DELETE  = 'PRODUCTS_DELETE';
export const PRODUCTS_DESTROY = 'PRODUCTS_DESTROY';

/**
 * @param {function|null} cb
 * @returns {function(*): void}
 */
export const productsList = (cb = null) => {
  return (dispatch) => {
    api.get(router.generate('products_list'))
      .then((products) => {
        dispatch({
          type: PRODUCTS_LIST,
          products
        });
        if (cb) {
          cb(products);
        }
      });
  };
};

/**
 * @param {*} values
 * @param {function} cb
 *
 * @returns {function(*): void}
 */
export const productsCreate = (values, cb = null) => {
  return (dispatch) => {
    api.post(router.generate('products_create'), values)
      .then((product) => {
        if (product.error) {
          if (cb) {
            cb(product.error);
          }
          return;
        }

        dispatch({
          type: PRODUCTS_CREATE,
          product
        });
        if (cb) {
          cb(product);
        }
      });
  };
};

/**
 * @param {number} id
 * @param {*} values
 * @param {function|null} cb
 * @returns {function(*): void}
 */
export const productsUpdate = (id, values, cb = null) => {
  return (dispatch) => {
    api.post(router.generate('products_update', { id }), values)
      .then((product) => {
        dispatch({
          type: PRODUCTS_UPDATE,
          product
        });
        if (cb) {
          cb();
        }
      });
  };
};

/**
 * @param {number} id
 * @param {function|null} cb
 * @returns {function(*): void}
 */
export const productsDelete = (id, cb = null) => {
  return (dispatch) => {
    api.req('DELETE', router.generate('products_delete', { id }))
      .then(() => {
        dispatch({
          type: PRODUCTS_DELETE,
          id
        });
        if (cb) {
          cb();
        }
      });
  };
};

/**
 * @param {number} id
 * @param {function|null} cb
 * @returns {function(*): void}
 */
export const productsDestroy = (id, cb = null) => {
  return (dispatch) => {
    api.req('DELETE', router.generate('products_destroy', { id }))
      .then(() => {
        dispatch({
          type: PRODUCTS_DESTROY,
          id
        });
        if (cb) {
          cb();
        }
      });
  };
};
