import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { connect, mapDispatchToProps } from 'utils';
import { Icon } from 'components';
import { uiActions } from 'apps/builder/actions';

const mapStateToProps = state => ({
  sidebarSections: state.ui.sidebarSections
});

@connect(
  mapStateToProps,
  mapDispatchToProps(uiActions)
)
export default class Section extends React.PureComponent {
  static propTypes = {
    name:            PropTypes.string.isRequired,
    title:           PropTypes.string.isRequired,
    badge:           PropTypes.number,
    open:            PropTypes.bool,
    className:       PropTypes.string,
    children:        PropTypes.node,
    sidebarSections: PropTypes.object.isRequired,
    uiToggleSection: PropTypes.func.isRequired
  };

  static defaultProps = {
    badge:     0,
    className: '',
    children:  ''
  };

  /**
   *
   */
  handleArrowClick = () => {
    const { name, uiToggleSection } = this.props;

    uiToggleSection(name);
  };

  /**
   * @returns {*}
   */
  render() {
    const { open, name, title, badge, sidebarSections, className, children } = this.props;
    const visible = open !== undefined ? open : sidebarSections[name];

    const icon    = visible ? 'caret-down' : 'caret-up';
    const classes = classNames('section', className, {
      'section-closed': !visible
    });

    return (
      <section className={classes}>
        <h2 className="section-title" onClick={this.handleArrowClick}>
          {title}
          <Icon name={icon} className="section-arrow" />
          {badge !== 0 && (
            <span className="section-badge" title={badge}>
              {badge}
            </span>
          )}
        </h2>
        <div className="section-wrap">
          <div className="section-body">
            {children}
          </div>
        </div>
      </section>
    );
  }
}
