import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FormGroup } from 'components/forms';
import FormContext from './formContext';

/**
 *
 */
export default class ColorPicker extends React.PureComponent {
  static contextType = FormContext;

  static propTypes = {
    id:                 PropTypes.string.isRequired,
    name:               PropTypes.string,
    label:              PropTypes.string,
    help:               PropTypes.string,
    defaultValue:       PropTypes.string,
    required:           PropTypes.bool,
    disabled:           PropTypes.bool,
    formGroupClassName: PropTypes.string,
    className:          PropTypes.string,
    errorMessage:       PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    value:              PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    onChange:           PropTypes.func,
    onMouseOver:        PropTypes.func,
    onMouseLeave:       PropTypes.func,
    format:             PropTypes.func,
    parse:              PropTypes.func,
  };

  static defaultProps = {
    help:               '',
    name:               '',
    label:              '',
    formGroupClassName: '',
    errorMessage:       '',
    defaultValue:       '',
    className:          '',
    required:           false,
    disabled:           false,
    onChange:           null,
    format:             v => v,
    parse:              v => v,
    onMouseOver:        () => {},
    onMouseLeave:       () => {}
  };

  static unityFormType = 'color';

  /**
   * @param {*} props
   */
  constructor(props) {
    super(props);

    this.picker   = React.createRef();
    this.interval = 0;
  }

  /**
   * @param {*} color
   * @param {*} context
   */
  handleChange = (color, context) => {
    const { id, name, parse, disabled, onChange } = this.props;

    if (!disabled) {
      clearTimeout(this.interval);
      this.interval = setTimeout(() => {
        const value = parse(color.hex);
        if (context.onChange) {
          context.onChange(new CustomEvent('change'), value, name || id);
        }
        if (onChange) {
          onChange(new CustomEvent('change'), value, name || id);
        }
      }, 100);
    }
  };

  /**
   * @returns {*}
   */
  render() {
    const {
      id,
      help,
      name,
      label,
      required,
      disabled,
      format,
      value,
      className,
      onMouseOver,
      onMouseLeave,
      defaultValue,
      errorMessage,
      formGroupClassName
    } = this.props;
    const { context } = this;

    const inputName  = name || id;
    const inputValue = format(context.values[inputName] !== undefined ?
      context.values[inputName]
      : (value || defaultValue));

    return (
      <FormGroup
        help={help}
        htmlFor={id}
        label={label}
        className={formGroupClassName}
        required={context.required || required}
        errorMessage={context.errorFields[inputName] || errorMessage}
        onMouseOver={onMouseOver}
        onMouseLeave={onMouseLeave}
      >
        <div className="d-flex align-items-stretch" style={{ marginTop: 1 }}>
          <input
            type="color"
            value={inputValue}
            className={classNames('form-control flex-grow-1', className)}
            onChange={e => this.handleChange({ hex: e.target.value }, context)}
            disabled={disabled}
            style={{ width: 40, padding: 2, border: 0 }}
          />
          <input
            type="text"
            value={inputValue}
            className={classNames('form-control flex-grow-1', className)}
            onChange={e => this.handleChange({ hex: e.target.value }, context)}
            disabled={disabled}
            style={{ width: 100 }}
          />
          {defaultValue && (
            <button
              type="button"
              disabled={disabled}
              className="btn btn-editor flex-grow-1"
              onClick={() => this.handleChange(defaultValue, context)}
            >
              Reset
            </button>
          )}
        </div>
      </FormGroup>
    );
  }
}
