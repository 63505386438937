import { api, router } from 'utils';
import { uiToast } from './uiActions';

export const GEO_SET_ZONES     = 'GEO_SET_ZONES';
export const GEO_ERROR_MESSAGE = 'GEO_ERROR_MESSAGE';

/**
 * @param {string} errorMessage
 * @returns {{type: string}}
 */
export const geoErrorMessage = (errorMessage) => {
  return {
    type: GEO_ERROR_MESSAGE,
    errorMessage
  };
};

/**
 * @param {number} id
 * @param {Function} cb
 * @returns {{type: string}}
 */
export const geoDeleteZone = (id, cb) => {
  return (dispatch) => {
    api.req('DELETE', router.generate('geo_delete_zone', { id }))
      .then((zones) => {
        dispatch({
          type: GEO_SET_ZONES,
          zones
        });
        dispatch(geoErrorMessage(''));
        dispatch(uiToast('Zone deleted.'));
        cb();
      });
  };
};

/**
 * @param {number} id
 * @param {Array} items
 * @param {Function} cb
 * @returns {{type: string}}
 */
export const geoUpdateZone = (id, items, cb) => {
  return (dispatch) => {
    const body = { items };

    api.post(router.generate('geo_update_zone', { id }), body)
      .then((zones) => {
        dispatch({
          type: GEO_SET_ZONES,
          zones
        });
        dispatch(geoErrorMessage(''));
        dispatch(uiToast('Zone updated.'));
        cb();
      });
  };
};
/**
 * @param {string} name
 * @param {string} type
 * @param {Array} items
 * @param {Function} cb
 * @returns {{type: string}}
 */
export const geoAddZone = (name, type, items, cb) => {
  return (dispatch, getState) => {
    const { geo } = getState();

    let valid = true;
    geo.zones.forEach((item) => {
      if (item.name === name) {
        dispatch(geoErrorMessage('Zone name exists.'));
        valid = false;
      }
    });

    if (!valid) {
      return;
    }

    const body = { name, type, items };

    api.post(router.generate('geo_add_zone'), body)
      .then((zones) => {
        dispatch({
          type: GEO_SET_ZONES,
          zones
        });
        dispatch(geoErrorMessage(''));
        dispatch(uiToast('Zone added.'));
        cb();
      });
  };
};
