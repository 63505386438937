import { api, router, alerts } from 'utils';
import { changed } from './listicleActions';

let lastDateMoved     = new Date();
let lastRefresh       = new Date();
let refreshErrorCount = 0;

/** @type {Prompt|null} */
let prompt = null;

/**
 * @param {number} id
 * @returns {Function}
 */
export const refreshLanderLock = (id) => {
  return (dispatch, getState) => {
    api.post(router.generate('locks_lander_refresh', { id }))
      .then((resp) => {
        if (!resp || resp === 0) {
          refreshErrorCount += 1;
          if (refreshErrorCount === 3) {
            alerts.error('Unable to refresh session lock.')
              .then(() => {
                dispatch(changed(false));

                const { listicle } = getState();
                document.location = `/landers/details/${listicle.anchor.id}`;
              });
          }
        }
      })
      .catch((err) => {
        console.error(err);
        refreshErrorCount += 1;
        if (refreshErrorCount === 3) {
          alerts.error('Unable to refresh session lock.')
            .then(() => {
              dispatch(changed(false));

              const { listicle } = getState();
              document.location = `/landers/details/${listicle.anchor.id}`;
            });
        }
      });
  };
};

/**
 * @returns {Function}
 */
export const unlockLander = () => {
  return (dispatch, getState) => {
    const { listicle } = getState();

    api.get(router.generate('locks_lander_unlock', { id: listicle.anchor.id }));
  };
};

/**
 * @returns {Function}
 */
export const runLockChecks = () => {
  return (dispatch, getState) => {
    document.addEventListener('mousemove', () => {
      lastDateMoved = new Date();
      if (prompt) {
        prompt.close();
      }
    });

    setInterval(() => {
      const { listicle } = getState();

      const now  = new Date();
      const diff = Math.floor((now.getTime() - lastDateMoved.getTime()) / 1000);

      if (diff < 30) {
        if (prompt) {
          prompt.close();
        }
        dispatch(refreshLanderLock(listicle.anchor.id));
        lastRefresh = new Date();
      } else {
        const remaining = Math.floor(900 - ((now.getTime() - lastRefresh.getTime()) / 1000));
        if (remaining < 0) {
          dispatch(changed(false));
          document.location = `/landers/details/${listicle.anchor.id}`;
        }

        if (remaining < 60) {
          const msg = `Your session will expire in ${remaining} seconds. Unsaved work with be lost.`;
          if (prompt) {
            prompt.setContent(msg);
          } else {
            alerts.alert(msg, 'warning', (p) => {
              prompt = p;
            });
          }
        }
      }
    }, 10000);
  };
};
