import React from 'react';
import PropTypes from 'prop-types';
import { connect, mapDispatchToProps } from 'utils';
import { listicleActions } from '@builder/actions';
import QuillEditor from '@builder/components/QuillEditor';
import BlockWrapper from '@builder/components/BlockWrapper';

const BlockQuote = ({ blockGroup, updateBlock }) => {
  const { defaultBlock } = blockGroup;

  return (
    <BlockWrapper blockGroup={blockGroup} className="builder-canvas-block-quote arb-quote">
      {(provided) => {
        if (provided.isActive) {
          return (
            <QuillEditor
              html={defaultBlock.html}
              className="arb-quote-body"
              getElement={provided.getElement}
              onUpdate={html => updateBlock(blockGroup.defaultBlock.id, blockGroup.id, 'html', html)}
            />
          );
        }

        return (
          <div
            className="arb-quote-body"
            dangerouslySetInnerHTML={{ __html: defaultBlock.html }}
          />
        );
      }}
    </BlockWrapper>
  );
};

BlockQuote.propTypes = {
  blockGroup:  PropTypes.object.isRequired,
  updateBlock: PropTypes.func.isRequired
};

BlockQuote.typeName      = 'quote';
BlockQuote.icon          = 'quote-left';
BlockQuote.label         = 'Quote';
BlockQuote.defaultValues = {
  html:   '<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor...</p>',
  styles: {
    width:        '100%',
    marginBottom: 15
  }
};

export default connect(
  null,
  mapDispatchToProps(listicleActions)
)(BlockQuote);
