import React from 'react';
import PropTypes from 'prop-types';
import Dropzone from 'react-dropzone';
import { classNames, objects, connect, mapDispatchToProps, alerts } from 'utils';
import { listicleActions, mediaActions, uiActions } from '@builder/actions';
import { Icon } from 'components';
import BlockWrapper from '@builder/components/BlockWrapper';
import PopupTools from '@builder/components/PopupTools';
import { imagePlaceholder } from './BlockImage';

@connect(
  null,
  mapDispatchToProps(listicleActions, mediaActions, uiActions)
)
export default class BlockSlideshow extends React.PureComponent {
  static propTypes = {
    active:      PropTypes.bool.isRequired,
    blockGroup:  PropTypes.object.isRequired,
    updateBlock: PropTypes.func.isRequired,
    uploadFile:  PropTypes.func.isRequired,
    mediaSelect: PropTypes.func.isRequired,
    replaceFile: PropTypes.func.isRequired,
    uiModal:     PropTypes.func.isRequired
  };

  static defaultProps = {};

  static typeName = 'slideshow';

  static icon = 'images';

  static label = 'Slideshow';

  static defaultValues = {
    styles:    { marginBottom: 15 },
    slideshow: [
      {
        isActive: true,
        imageUrl: imagePlaceholder,
        bytes:    0
      }
    ]
  };

  /**
   * @param {*} props
   */
  constructor(props) {
    super(props);

    this.image = React.createRef();
    this.popup = React.createRef();
  }

  /**
   * @param {*} data
   */
  updateActiveImage = (data) => {
    const { blockGroup, updateBlock } = this.props;
    const { defaultBlock } = blockGroup;

    const newSlideshow = objects.clone(defaultBlock.slideshow);
    for (let i = 0; i < newSlideshow.length; i++) {
      if (newSlideshow[i].isActive) {
        newSlideshow[i].imageUrl = data.url;
        newSlideshow[i].bytes    = data.bytes;
      }
    }
    updateBlock(defaultBlock.id, blockGroup.id, 'slideshow', newSlideshow);
    setTimeout(() => {
      this.popup.current.updatePosition();
    }, 50);
  };

  /**
   * @param {Event} e
   */
  handleControlClick = (e) => {
    const { blockGroup, active, updateBlock } = this.props;
    const { defaultBlock } = blockGroup;
    const { currentTarget } = e;

    if (!active) {
      e.stopPropagation();
    }

    const newSlideshow = objects.clone(defaultBlock.slideshow);
    const slideshowMax = newSlideshow.length - 1;
    const direction    = currentTarget.getAttribute('data-slide');
    const slideTo      = currentTarget.getAttribute('data-slide-to');

    let activeIndex = 0;
    for (let i = 0; i < newSlideshow.length; i++) {
      if (newSlideshow[i].isActive) {
        activeIndex = i;
      }
    }

    if (slideTo !== null) {
      activeIndex = parseInt(slideTo, 10);
    } else if (direction === 'prev') {
      activeIndex -= 1;
      if (activeIndex < 0) {
        activeIndex = slideshowMax;
      }
    } else {
      activeIndex += 1;
      if (activeIndex > slideshowMax) {
        activeIndex = 0;
      }
    }

    for (let i = 0; i < newSlideshow.length; i++) {
      newSlideshow[i].isActive = (activeIndex === i);
    }
    updateBlock(defaultBlock.id, blockGroup.id, 'slideshow', newSlideshow);
  };

  /**
   * @param {Event} e
   */
  handleClickLibrary = (e) => {
    const { uiModal } = this.props;

    e.stopPropagation();
    uiModal('mediaLibrary', true, {
      onSelect: (selected) => {
        if (selected) {
          this.updateActiveImage(selected);
        }
      }
    });
  };

  /**
   * @param {Event} e
   */
  handleClickURL = (e) => {
    const { blockGroup, uploadFile, uiModal, replaceFile } = this.props;
    const { defaultBlock } = blockGroup;

    e.stopPropagation();

    let imageUrl = '';
    const newSlideshow = objects.clone(defaultBlock.slideshow);
    newSlideshow.forEach((slide) => {
      if (slide.isActive) {
        ({ imageUrl } = slide);
      }
    });

    alerts.ask('Image URL', imageUrl)
      .then((value) => {
        if (value && value !== imageUrl) {
          uploadFile(value, (resp) => {
            uiModal('cropper', true, {
              url:       resp.url,
              onCropped: (data) => {
                replaceFile(resp.url, data, (r) => {
                  this.updateActiveImage(r);
                });
              },
              onSkip: () => {
                this.updateActiveImage(resp);
              }
            });
          });
        }
      });
  };

  /**
   * @param {Event} e
   */
  handleAddClick = (e) => {
    const { blockGroup, updateBlock } = this.props;
    const { defaultBlock } = blockGroup;

    e.stopPropagation();

    let activeIndex    = -1;
    const newSlideshow = objects.clone(defaultBlock.slideshow);
    for (let i = 0; i < newSlideshow.length; i++) {
      if (newSlideshow[i].isActive) {
        activeIndex = i;
        newSlideshow[i].isActive = false;
        break;
      }
    }

    if (activeIndex === -1) {
      return;
    }

    const newSlide = {
      isActive: true,
      imageUrl: imagePlaceholder,
      bytes:    0
    };
    if (activeIndex === newSlideshow.length - 1) {
      newSlideshow.push(newSlide);
    } else {
      newSlideshow.splice(activeIndex + 1, 0, newSlide);
    }
    updateBlock(defaultBlock.id, blockGroup.id, 'slideshow', newSlideshow);
  };

  /**
   * @param {Event} e
   */
  handleRemoveClick = (e) => {
    const { blockGroup, updateBlock } = this.props;
    const { defaultBlock } = blockGroup;

    e.stopPropagation();

    let activeIndex    = -1;
    const newSlideshow = objects.clone(defaultBlock.slideshow);
    for (let i = 0; i < newSlideshow.length; i++) {
      if (newSlideshow[i].isActive) {
        activeIndex = i;
        break;
      }
    }

    if (activeIndex === -1) {
      return;
    }

    const removed = newSlideshow.splice(activeIndex, 1);
    if (newSlideshow.length === 0) {
      const newSlide = {
        isActive: true,
        imageUrl: imagePlaceholder,
        bytes:    0
      };
      newSlideshow.push(newSlide);
    } else if (removed[0].isActive) {
      newSlideshow[0].isActive = true;
    }

    updateBlock(defaultBlock.id, blockGroup.id, 'slideshow', newSlideshow);
  };

  /**
   * @param {File[]} files
   */
  handleDrop = (files) => {
    const { uiModal, uploadFile, replaceFile } = this.props;

    uploadFile(files[0], (resp) => {
      uiModal('cropper', true, {
        url:       resp.url,
        onCropped: (data) => {
          replaceFile(resp.url, data, (r) => {
            this.updateActiveImage(r);
          });
        },
        onSkip: () => {
          this.updateActiveImage(resp);
        }
      });
    });
  };

  /**
   * @returns {*}
   */
  renderImage = (slide) => {
    const { active } = this.props;

    if (!active || (active && !slide.isActive)) {
      return (
        <img className="d-block w-100" src={slide.imageUrl} alt="" />
      );
    }

    return (
      <Dropzone
        accept="image/*"
        maxSize={10 * 1024 * 1024}
        onDrop={acceptedFiles => this.handleDrop(acceptedFiles)}
        multiple={false}
      >
        {({ getRootProps, getInputProps, isDragActive }) => {
          const classes = classNames('d-block w-100', {
            'hover': isDragActive
          });

          const inputProps = getInputProps();

          return (
            <div className={classes} {...getRootProps()}>
              <input {...inputProps} />
              <PopupTools ref={this.popup} position="left" getTarget={() => this.image.current} visible>
                <Icon name="images" title="Library" onClick={this.handleClickLibrary} />
                <Icon name="external-link-alt" title="URL" onClick={this.handleClickURL} />
                <Icon name="plus" title="Add slide" onClick={this.handleAddClick} />
                <Icon name="minus" title="Remove slide" onClick={this.handleRemoveClick} />
              </PopupTools>
              <div
                className="builder-canvas-block-offer-drop-placeholder"
              >
                Click or drop image
              </div>
              <img
                ref={this.image}
                onError={this.handleImageError}
                src={slide.imageUrl}
                className={classNames({
                  'builder-block-image-placeholder': slide.imageUrl === imagePlaceholder
                })}
                alt=""
              />
            </div>
          );
        }}
      </Dropzone>
    );
  };

  /**
   * @returns {*}
   */
  renderSlideshow = (props) => {
    const { blockGroup } = this.props;
    const { defaultBlock } = blockGroup;

    const controlStyles = {};
    if (defaultBlock.styles['!controls:backgroundColor']) {
      controlStyles.backgroundColor = defaultBlock.styles['!controls:backgroundColor'];
    }

    return (
      <div
        id={`arb-slideshow-${defaultBlock.id}`}
        className="carousel slide"
        data-ride="carousel"
        data-interval={false}
        data-keyboard={false}
      >
        {/* <ol className="carousel-indicators">
          {defaultBlock.slideshow.map((slide, i) => (
            <li
              key={i}
              data-slide-to={i}
              style={controlStyles}
              className={slide.isActive ? 'active' : ''}
              data-target={`#arb-slideshow-${defaultBlock.id}`}
              onClick={this.handleControlClick}
            />
          ))}
        </ol> */}
        <div className="carousel-inner">
          {defaultBlock.slideshow.map((slide, i) => (
            <div key={i} className={classNames('carousel-item', { active: slide.isActive })}>
              {this.renderImage(slide, props)}
            </div>
          ))}
        </div>
        <a
          data-slide="prev"
          role="button"
          className="carousel-control-prev"
          href={`#arb-slideshow-${defaultBlock.id}`}
          onClick={this.handleControlClick}
        >
          <span className="carousel-control-prev-icon" aria-hidden="true" style={controlStyles} />
          <span className="sr-only">Previous</span>
        </a>
        <a
          role="button"
          data-slide="next"
          className="carousel-control-next"
          href={`#arb-slideshow-${defaultBlock.id}`}
          onClick={this.handleControlClick}
        >
          <span className="carousel-control-next-icon" aria-hidden="true" style={controlStyles} />
          <span className="sr-only">Next</span>
        </a>
      </div>
    );
  };

  /**
   * @returns {*}
   */
  render() {
    const { blockGroup } = this.props;

    return (
      <BlockWrapper blockGroup={blockGroup} className="arb-slideshow">
        {(provided) => {
          if (provided.isActive) {
            return this.renderSlideshow(provided.props);
          }

          return this.renderSlideshow({});
        }}
      </BlockWrapper>
    );
  }
}
