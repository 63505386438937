import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

/**
 *
 */
class Icon extends React.PureComponent {
  static propTypes = {
    fas:        PropTypes.bool,
    far:        PropTypes.bool,
    fab:        PropTypes.bool,
    fixed:      PropTypes.bool,
    solid:      PropTypes.bool,
    name:       PropTypes.string.isRequired,
    size:       PropTypes.number,
    isSpinning: PropTypes.bool,
    className:  PropTypes.string
  };

  static defaultProps = {
    fas:        false,
    far:        false,
    fab:        false,
    fixed:      true,
    solid:      false,
    size:       1,
    isSpinning: false,
    className:  ''
  };

  /**
   * @returns {*}
   */
  render() {
    const { fas, far, fab, fixed, name, size, solid, isSpinning, className, ...props } = this.props;

    return (
      <span
        className={classNames(
          className,
          `icon fa-${size}x fa-${name}`,
          {
            'fa':      !far && !fas && !fab && !solid,
            'fa-spin': isSpinning,
            'fa-solid': solid,
            'fas':     fas,
            'far':     far,
            'fab':     fab,
            'fa-fw':   fixed
          }
        )}
        aria-hidden="true"
        {...props}
      />
    );
  }
}

export default Icon;
