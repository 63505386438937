import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Dropzone from 'react-dropzone';
import { connect, alerts, mapDispatchToProps } from 'utils';
import { listicleActions, uiActions, mediaActions, templateActions } from '@builder/actions';
import { Icon } from 'components';
import BlockWrapper from '@builder/components/BlockWrapper';
import BuilderImage from '@builder/components/BuilderImage';
import PopupTools from '@builder/components/PopupTools';
import DragImage from '@builder/components/DragImage';

export const imagePlaceholder = '/images/image-placeholder.jpg';

const mapStateToProps = state => ({
  selectedAsset: state.media.selected,
  isDesignView:  state.listicle.isDesignView
});

/**
 * For some reason the Dropzone in this component does not work correctly
 * when the component is inside of the header. There are some hacks that
 * rely on detachedToolbar to fix the drop bug.
 */
@connect(
  mapStateToProps,
  mapDispatchToProps(listicleActions, uiActions, mediaActions, templateActions)
)
export default class BlockImage extends React.PureComponent {
  static propTypes = {
    active:            PropTypes.bool.isRequired,
    blockGroup:        PropTypes.object.isRequired,
    selectedAsset:     PropTypes.object,
    dragEnabled:       PropTypes.bool,
    isDesignView:      PropTypes.bool.isRequired,
    detachedToolbar:   PropTypes.bool.isRequired,
    appendBlockStyles: PropTypes.func.isRequired,
    uploadFile:        PropTypes.func.isRequired,
    updateBlock:       PropTypes.func.isRequired,
    mediaSelect:       PropTypes.func.isRequired,
    replaceFile:       PropTypes.func.isRequired,
    uiModal:           PropTypes.func.isRequired,
    onUploaded:        PropTypes.func
  };

  static defaultProps = {
    dragEnabled: true,
    onUploaded:  () => {}
  };

  static typeName = 'image';

  static icon = 'image';

  static label = 'Image';

  static defaultValues = {
    imageUrl:   imagePlaceholder,
    styles:     { marginBottom: 15 },
    dimensions: {
      widthXl:  '100%',
      heightXl: 'auto',
      alignXl:  'center',
      widthMd:  '100%',
      heightMd: 'auto',
      alignMd:  'center',
      widthSm:  '100%',
      heightSm: 'auto',
      alignSm:  'center',
      widthXs:  '100%',
      heightXs: 'auto',
      alignXs:  'center'
    }
  };

  /**
   * @param {*} props
   */
  constructor(props) {
    super(props);

    this.element = React.createRef();
    this.image   = React.createRef();
    this.input   = null;
    this.state   = {
      error: false
    };
  }

  /**
   *
   */
  componentDidMount() {
    const { detachedToolbar } = this.props;

    if (detachedToolbar && this.input) {
      this.input.current.addEventListener('change', this.handleInputChange, false);
    }
  }

  /**
   * @param {*} prevProps
   */
  componentDidUpdate(prevProps) {
    const { blockGroup, active, selectedAsset, mediaSelect, updateBlock } = this.props;
    const { selectedAsset: prevSelectedAsset } = prevProps;
    const { defaultBlock } = blockGroup;

    if (prevSelectedAsset === null && selectedAsset !== null && active) {
      mediaSelect(null);
      updateBlock(defaultBlock.id, blockGroup.id, 'imageUrl', selectedAsset.url);
      updateBlock(defaultBlock.id, blockGroup.id, 'imageBytes', selectedAsset.bytes);
      this.setState({ error: false });
    }
  }

  /**
   *
   */
  componentWillUnmount() {
    const { detachedToolbar } = this.props;

    if (detachedToolbar && this.input && this.input.current) {
      this.input.current.removeEventListener('change', this.handleInputChange, false);
    }
  }

  /**
   * @param {Event} e
   */
  handleInputChange = (e) => {
    this.handleDrop(e.target.files);
  };

  /**
   * @param {File[]} files
   */
  handleDrop = (files) => {
    const { blockGroup, uploadFile, updateBlock, replaceFile, uiModal, onUploaded } = this.props;
    const { defaultBlock } = blockGroup;

    uploadFile(files[0], (resp) => {
      uiModal('cropper', true, {
        url:       resp.url,
        onCropped: (data) => {
          replaceFile(resp.url, data, (r) => {
            updateBlock(defaultBlock.id, blockGroup.id, 'imageUrl', r.url);
            updateBlock(defaultBlock.id, blockGroup.id, 'imageBytes', r.bytes);
            onUploaded(r.url);
          });
        },
        onSkip: () => {
          updateBlock(defaultBlock.id, blockGroup.id, 'imageUrl', resp.url);
          updateBlock(defaultBlock.id, blockGroup.id, 'imageBytes', resp.bytes);
          onUploaded(resp.url);
        }
      });
    });
  };

  /**
   * @param {Event} e
   */
  handleClick = (e) => {
    const { detachedToolbar } = this.props;

    if (detachedToolbar) {
      e.stopPropagation();
      this.input.current.click();
    }
  };

  /**
   * @param {Event} e
   */
  handleClickLibrary = (e) => {
    const { uiModal } = this.props;

    e.stopPropagation();
    uiModal('mediaLibrary', true);
  };

  /**
   * @param {Event} e
   */
  handleClickURL = (e) => {
    const { blockGroup, updateBlock, uploadFile, uiModal, replaceFile, onUploaded } = this.props;
    const { defaultBlock } = blockGroup;

    e.stopPropagation();

    alerts.ask('Image URL', defaultBlock.imageUrl)
      .then((value) => {
        if (value && value !== defaultBlock.imageUrl) {
          uploadFile(value, (resp) => {
            uiModal('cropper', true, {
              url:       resp.url,
              onCropped: (data) => {
                replaceFile(resp.url, data, (r) => {
                  updateBlock(defaultBlock.id, blockGroup.id, 'imageUrl', r.url);
                  updateBlock(defaultBlock.id, blockGroup.id, 'imageBytes', r.bytes);
                  onUploaded(r.url);
                });
              },
              onSkip: () => {
                updateBlock(defaultBlock.id, blockGroup.id, 'imageUrl', resp.url);
                updateBlock(defaultBlock.id, blockGroup.id, 'imageBytes', resp.bytes);
                onUploaded(resp.url);
              }
            });
          });
        }
      });
  };

  /**
   *
   */
  handleImageError = () => {
    this.setState({ error: true });
  };

  /**
   * @param {Event} e
   * @param {number|string} width
   * @param {number|string} height
   */
  handleImageResize = (e, width, height) => {
    const { blockGroup, updateBlock, appendBlockStyles } = this.props;
    const { defaultBlock } = blockGroup;
    const { dimensions } = defaultBlock;

    const newDimensions    = { ...dimensions };
    newDimensions.widthXl  = `${width}px`;
    newDimensions.heightXl = height === 'auto' ? 'auto' : `${height}px`;
    newDimensions.widthMd  = `${width}px`;
    newDimensions.heightMd = height === 'auto' ? 'auto' : `${height}px`;
    updateBlock(defaultBlock.id, blockGroup.id, 'dimensions', newDimensions);
    appendBlockStyles();
  };

  /**
   * @param {*} props
   * @returns {*}
   */
  renderDropzone = (props) => {
    const { blockGroup, detachedToolbar, dragEnabled } = this.props;
    const { error } = this.state;
    const { defaultBlock } = blockGroup;
    const { dimensions } = defaultBlock;

    return (
      <Dropzone
        accept="image/*"
        maxSize={10 * 1024 * 1024}
        onDrop={acceptedFiles => this.handleDrop(acceptedFiles)}
        multiple={false}
        noClick={detachedToolbar}
      >
        {({ getRootProps, getInputProps, isDragActive }) => {
          const classes = classNames('builder-canvas-block-offer-dropzone', {
            'hover': isDragActive
          });

          // Using mousedown instead of click prevents accidental clicking
          // which resizing the image by the drag handles.
          const outerProps = getRootProps();
          outerProps.onMouseDown = outerProps.onClick;
          delete outerProps.onClick;

          const inputProps = getInputProps();
          this.input = inputProps.ref;

          return (
            <div className={classes} {...outerProps} style={{ width: dimensions.widthXl }}>
              <input {...inputProps} />
              <PopupTools position="left" getTarget={() => this.image.current} visible>
                <Icon
                  name="folder-open"
                  title="Library"
                  onClick={this.handleClickLibrary}
                  onMouseDown={e => e.stopPropagation()}
                />
                <Icon
                  name="external-link-alt"
                  title="URL"
                  onClick={this.handleClickURL}
                  onMouseDown={e => e.stopPropagation()}
                />
              </PopupTools>
              <div
                id={`builder-canvas-block-offer-drop-placeholder-${blockGroup.id}`}
                className="builder-canvas-block-offer-drop-placeholder"
              >
                <span>Click or drop image</span>
              </div>
              <DragImage
                enabled={dragEnabled}
                innerRef={this.image}
                onError={this.handleImageError}
                onResize={this.handleImageResize}
                src={error ? imagePlaceholder : defaultBlock.imageUrl}
                className={classNames(props.className, {
                  'builder-block-image-placeholder': defaultBlock.imageUrl === imagePlaceholder
                })}
                {...props}
              />
            </div>
          );
        }}
      </Dropzone>
    );
  };

  /**
   * @returns {*}
   */
  render() {
    const { blockGroup, isDesignView } = this.props;
    const { defaultBlock } = blockGroup;

    return (
      <BlockWrapper
        blockGroup={blockGroup}
        className={classNames('arb-image', {
          'arb-image-placeholder': defaultBlock.imageUrl === imagePlaceholder
        })}
        singleElement
      >
        {(provided) => {
          if (provided.isActive) {
            return this.renderDropzone(provided.props);
          }

          return (
            <BuilderImage
              {...provided.props}
              dataID={blockGroup.id}
              showBytes={!isDesignView}
              src={defaultBlock.imageUrl}
              bytes={defaultBlock.imageBytes}
            />
          );
        }}
      </BlockWrapper>
    );
  }
}
