import 'utils/polyfills';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import textFit from 'textfit';
import configureStore from '@builder/store/configureStore';
import { alerts, loading, router, api } from 'utils';
import ClipboardJS from 'clipboard';
import App from 'apps/site/App';

window.alerts  = alerts;
window.loading = loading;
window.router  = router;
window.api     = api;

$(() => {
  const clipboard = new ClipboardJS('.btn-copy');
  clipboard.on('success', () => {
    jToast('Copied!', true);
  });

  textFit(document.getElementsByClassName('card-lander-meta-pathname'));

  ReactDOM.render(
    // eslint-disable-next-line react/jsx-filename-extension
    <Provider store={configureStore(window.initialState)}>
      <App />
    </Provider>,
    document.getElementById('app-mount')
  );
});

/**
 * Clickable online/offline badge on lander details page.
 */
$(() => {
  const $badge = $('#card-title-badge');
  if ($badge.length === 0) {
    return;
  }

  const type    = $badge.data('type');
  const $status = $('.card-lg-lander-status');
  const $text   = $('.card-lander-status-text');

  $badge.on('click', () => {
    const current = $badge.data('status');

    const action = () => {
      const id   = $('.card:first').data('anchor-id');
      const body = {
        status: (current === 'online') ? 'offline' : 'online'
      };

      let route = 'landers_status';
      if (type === 'route') {
        route = 'routes_status';
      } else if (type === 'landing_page') {
        route = 'landing_pages_status';
      } else if (type !== 'lander') {
        route = 'sites_status';
      }

      api.post(router.generate(route, { id }), body)
        .then(() => {
          $badge.data('status', body.status);
          if (body.status === 'online') {
            $text.text('Online');
            $status
              .removeClass('card-lander-status-offline')
              .addClass('card-lander-status-online');
          } else {
            $text.text('Offline');
            $status
              .removeClass('card-lander-status-online')
              .addClass('card-lander-status-offline');
          }
        });
    };

    if (current === 'online') {
      let message = 'Are you sure you want to deactivate this lander?';
      if (type === 'route') {
        message = 'Are you sure you want to deactivate this route?';
      } else if (type === 'landing_page') {
        message = 'Are you sure you want to deactivate this landing page?';
      } else if (type !== 'lander') {
        // eslint-disable-next-line max-len
        message = 'Are you sure you want to take this site offline? Landers served by the site will no longer be viewable.';
      }
      alerts.confirm(message)
        .then((yes) => {
          if (yes) {
            action();
          }
        });
      return;
    }

    action();
  });
});

/**
 * Handles utils forms
 */
$(() => {
  const $inputRipUrl = $('#input-rip-url');
  if ($inputRipUrl.length === 0) {
    return;
  }

  const $form      = $('#form-quick-rip');
  const $inputPath = $('#input-rip-cdn-path');

  $inputRipUrl.on('input', () => {
    try {
      const u = new URL($inputRipUrl.val());
      if (u.pathname && u.pathname !== '/') {
        let { pathname } = u;
        if (pathname.indexOf('.') !== -1) {
          pathname = pathname.substring(0, pathname.lastIndexOf('/'));
        }
        $inputPath.val(pathname);
      }
    } catch (error) {
      console.error(error);
    }
  });

  $form.on('submit', async (e) => {
    e.preventDefault();

    const prompt = await alerts.loading('Copying assets to the CDN.');
    api.post(router.generate('utils_rip'), $form.serialize())
      .then((resp) => {
        document.location = resp.url;
      })
      .catch((error) => {
        alerts.error(error.toString());
      })
      .finally(() => {
        prompt.close();
      });
  });
});

/**
 * Handles select.placeholder elements
 */
$(() => {
  const setPlaceholder = (e) => {
    const $target = $(e.target);
    const value   = $target.val();

    if (value === '-1') {
      $target.addClass('placeholder');
    } else {
      $target.removeClass('placeholder');
    }
  };

  $('.placeholder').on('change', setPlaceholder).trigger('change');
});

/**
 * Expands and collapses card bodies when the collapse handle is clicked.
 */
window.wireupCollapsableCards = () => {
  /**
   * @param {string} id
   * @param {boolean} collapsing
   */
  const updateStorage = (id, collapsing) => {
    const values = (localStorage.getItem('data-collapsable') || '').split(',').filter(v => v);
    const index  = values.indexOf(id);
    if (collapsing && index === -1) {
      values.push(id);
    } else if (!collapsing && index !== -1) {
      values.splice(index, 1);
    }
    localStorage.setItem('data-collapsable', values.join(','));
  };

  const values = (localStorage.getItem('data-collapsable') || '').split(',').filter(v => v);
  $('.card[data-collapsable-id]').each((i, item) => {
    const id = item.getAttribute('data-collapsable-id');
    if (values.indexOf(id) !== -1) {
      item.classList.add('collapsed');
    }
  });

  $('.card[data-collapsable-id] .card-collapsable-handle').on('click', (e) => {
    const $handle = $(e.currentTarget);
    const $card   = $handle.parents('.card');
    const id      = $card.data('collapsable-id');

    if ($card.is('.collapsed')) {
      $card.removeClass('collapsed');
      updateStorage(id, false);
      $card[0].dispatchEvent(new CustomEvent('arb-card-expanded'));
    } else {
      $card.addClass('collapsed');
      updateStorage(id, true);
      $card[0].dispatchEvent(new CustomEvent('arb-card-collapsed'));
    }
  });
};

$(window.wireupCollapsableCards);
