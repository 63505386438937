import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

/**
 *
 */
class CardImage extends React.PureComponent {
  static propTypes = {
    data: PropTypes.shape({
      alt: PropTypes.string,
      src: PropTypes.string
    }).isRequired,
    className: PropTypes.string
  };

  static defaultProps = {
    className: ''
  };

  /**
   * @returns {*}
   */
  render() {
    const { data, className, ...props } = this.props;

    return (
      <img src={data.src} alt={data.alt} className={classNames('card-img-top', className)} {...props} />
    );
  }
}

export default CardImage;
