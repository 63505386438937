import { combineReducers } from 'redux';
import ui from './uiReducer';
import geo from './geoReducer';
import forms from './formsReducer';
import site from './siteReducer';
import listicle from './listicleReducer';
import template from './templateReducer';
import library from './libraryReducer';
import products from './productsReducer';
import widgets from './widgetsReducer';
import media from './mediaReducer';
import user from './userReducer';

const logReducer = (reducer) => {
  return (state, action) => {
    console.log(action);
    return reducer(state, action);
  };
};

/**
 * @returns {Reducer}
 */
export default function createRootReducer() {
  return combineReducers({
    ui,
    geo,
    site,
    user,
    forms,
    media,
    library,
    widgets,
    products,
    listicle,
    template
  });
}
