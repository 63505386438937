import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Jodit } from 'jodit';
import { connect, mapDispatchToProps, router } from 'utils';
import { listicleActions } from '@builder/actions';
import BlockWrapper from '@builder/components/BlockWrapper';

let editor = null;

const BlockHtml = ({ blockGroup, active, updateBlock }) => {
  const element = useRef(null);
  const { defaultBlock } = blockGroup;

  useEffect(() => {
    if (active && !editor) {
      editor = new Jodit(element.current, {
        uploader: {
          url:       router.generate('pages_upload'),
          isSuccess: (resp) => {
            return resp;
          },
          process: (resp) => {
            return {
              files:   resp.files,
              error:   resp.error,
              message: resp.message,
              path:    '',
              baseurl: ''
            };
          }
        }
      });
      editor.value = defaultBlock.html;
    } else if (editor) {
      updateBlock(defaultBlock.id, blockGroup.id, 'html', editor.value);
      editor = null;
    }
  }, [active]);

  useEffect(() => {
    const blockElement = document.getElementById(`block-${blockGroup.id}`);
    if (blockElement) {
      blockElement.querySelectorAll('a').forEach((a) => {
        a.addEventListener('click', e => e.preventDefault(), false);
      });
    }
  });

  return (
    <BlockWrapper blockGroup={blockGroup} className="arb-html builder-canvas-block-html-headline">
      {(provided) => {
        if (provided.isActive) {
          return (
            <textarea
              ref={element}
              id="jodit-editor"
            />
          );
        }

        return <div dangerouslySetInnerHTML={{ __html: blockGroup.defaultBlock.html }} />;
      }}
    </BlockWrapper>
  );
};

BlockHtml.propTypes = {
  blockGroup:  PropTypes.object.isRequired,
  updateBlock: PropTypes.func.isRequired
};

BlockHtml.typeName      = 'html';
BlockHtml.icon          = 'code';
BlockHtml.label         = 'HTML';
BlockHtml.defaultValues = {
  html:   '<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do...</p>',
  styles: { marginBottom: 15 }
};

export default connect(
  null,
  mapDispatchToProps(listicleActions)
)(BlockHtml);
