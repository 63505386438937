import React from 'react';
import PropTypes from 'prop-types';
import { classNames, connect, mapDispatchToProps } from 'utils';
import { SimpleForm, Input, Checkbox } from 'components/forms';
import { listicleActions } from '@builder/actions';
import BlockWrapper from '@builder/components/BlockWrapper';
import PopupDragHandle from '@builder/components/PopupDragHandle';
import PopupTools from '@builder/components/PopupTools';

@connect(
  null,
  mapDispatchToProps(listicleActions)
)
export default class BlockSocial extends React.PureComponent {
  static propTypes = {
    blockGroup:  PropTypes.object.isRequired,
    updateBlock: PropTypes.func.isRequired
  };

  static defaultProps = {};

  static typeName      = 'social';

  static icon          = 'hashtag';

  static label         = 'Social';

  static defaultValues = {
    styles:    { marginBottom: 15 },
    slideshow: {
      facebook:  { url: 'https://www.facebook.com/sharer/sharer.php?u=#pageUrl', count: '' },
      twitter:   { url: 'https://twitter.com/share?url=#pageUrl&amp;text=#pageTitle', count: '' },
      reddit:    { url: 'https://www.reddit.com/submit?url=#pageUrl&title=#pageTitle', count: '' },
      instagram: { url: '' },
      pinterest: { url: '' }
    }
  };

  static colors = {
    facebook:  '#3A579D',
    twitter:   '#00AAF0',
    reddit:    '#FF4500',
    instagram: '#833AB4',
    pinterest: '#E60024'
  };

  static icons = {
    facebook:  'fab fa-facebook',
    twitter:   'fab fa-twitter',
    reddit:    'fab fa-reddit-alien',
    instagram: 'fab fa-instagram',
    pinterest: 'fab fa-pinterest'
  };

  element = React.createRef();

  /**
   * @param {Event} e
   * @param {string} v
   * @param {string} k
   */
  handleFormChange = (e, v, k) => {
    const { blockGroup, updateBlock } = this.props;
    const { defaultBlock } = blockGroup;

    if (k === 'float') {
      const newStyles = Object.assign({}, defaultBlock.styles);
      newStyles['!social:float'] = v ? 1 : 0;
      updateBlock(defaultBlock.id, blockGroup.id, 'styles', newStyles);
    } else if (k.indexOf('-count') !== -1) {
      const key = k.split('-', 1).shift();
      const newSlideshow = Object.assign({}, defaultBlock.slideshow);
      newSlideshow[key].count = v;
      updateBlock(defaultBlock.id, blockGroup.id, 'slideshow', newSlideshow);
    } else {
      const newSlideshow = Object.assign({}, defaultBlock.slideshow);
      newSlideshow[k].url = v;
      updateBlock(defaultBlock.id, blockGroup.id, 'slideshow', newSlideshow);
    }
  };

  /**
   * @returns {*}
   */
  renderBlock = () => {
    const { blockGroup } = this.props;
    const { defaultBlock } = blockGroup;
    const { slideshow } = defaultBlock;

    const isFloat = defaultBlock.styles['!social:float'] || false;

    return (
      <div ref={this.element} className={classNames('arb-social', { 'arb-social-float': isFloat })}>
        {Object.keys(slideshow).filter(k => slideshow[k].url).map(key => (
          <div key={key} className="arb-social-box">
            {slideshow[key].count && (
              <div className="arb-social-counter">
                <span>{slideshow[key].count}</span>
              </div>
            )}
            <span className="arb-social-link" style={{ backgroundColor: BlockSocial.colors[key] }}>
              <i className={BlockSocial.icons[key]} />
            </span>
          </div>
        ))}
      </div>
    );
  };

  /**
   * @returns {*}
   */
  render() {
    const { blockGroup } = this.props;
    const { defaultBlock } = blockGroup;
    const { slideshow } = defaultBlock;

    return (
      <BlockWrapper blockGroup={blockGroup}>
        {(provided) => {
          if (provided.isActive) {
            const formValues = {
              facebook:          slideshow.facebook.url || '',
              'facebook-count':  slideshow.facebook.count || '',
              twitter:           slideshow.twitter.url || '',
              'twitter-count':   slideshow.twitter.count || '',
              reddit:            slideshow.reddit.url || '',
              'reddit-count':    slideshow.reddit.count || '',
              instagram:         slideshow.instagram.url || '',
              'instagram-count': slideshow.instagram.count || '',
              pinterest:         slideshow.pinterest.url || '',
              'pinterest-count': slideshow.pinterest.count || '',
              float:             defaultBlock.styles['!social:float'] || false
            };

            return (
              <>
                {this.renderBlock()}
                <PopupTools
                  position="left"
                  className="builder-block-menu-form"
                  getTarget={() => this.element.current}
                  visible
                  dragHandle={(isDown, onMouseDown) => (
                    <PopupDragHandle isDown={isDown} onMouseDown={onMouseDown} />
                  )}
                >
                  <SimpleForm values={formValues} onChange={this.handleFormChange}>
                    <div className="d-flex">
                      <Input
                        name="facebook"
                        id="builder-block-social-facebook-url"
                        placeholder="Facebook"
                        formGroupStyle={{ width: '75%' }}
                      />
                      <Input
                        name="facebook-count"
                        id="builder-block-social-facebook-count"
                        placeholder="#"
                        formGroupStyle={{ width: '25%' }}
                      />
                    </div>
                    <div className="d-flex">
                      <Input
                        name="twitter"
                        id="builder-block-social-twitter-url"
                        placeholder="Twitter"
                        formGroupStyle={{ width: '75%' }}
                      />
                      <Input
                        name="twitter-count"
                        id="builder-block-social-twitter-count"
                        placeholder="#"
                        formGroupStyle={{ width: '25%' }}
                      />
                    </div>
                    <div className="d-flex">
                      <Input
                        name="reddit"
                        id="builder-block-social-reddit-url"
                        placeholder="Reddit"
                        formGroupStyle={{ width: '75%' }}
                      />
                      <Input
                        name="reddit-count"
                        id="builder-block-social-reddit-count"
                        placeholder="#"
                        formGroupStyle={{ width: '25%' }}
                      />
                    </div>
                    <div className="d-flex">
                      <Input
                        name="instagram"
                        id="builder-block-social-instagram-url"
                        placeholder="Instagram"
                        formGroupStyle={{ width: '75%' }}
                      />
                      <Input
                        name="instagram-count"
                        id="builder-block-social-instagram-count"
                        placeholder="#"
                        formGroupStyle={{ width: '25%' }}
                      />
                    </div>
                    <div className="d-flex">
                      <Input
                        name="pinterest"
                        id="builder-block-social-pinterest-url"
                        placeholder="Pinterest"
                        formGroupStyle={{ width: '75%' }}
                      />
                      <Input
                        name="pinterest-count"
                        id="builder-block-social-pinterest-count"
                        placeholder="#"
                        formGroupStyle={{ width: '25%' }}
                      />
                    </div>
                    <div className="text-left">
                      <Checkbox
                        name="float"
                        label="Float"
                        id="builder-block-social-float"
                      />
                    </div>
                  </SimpleForm>
                </PopupTools>
              </>
            );
          }

          return this.renderBlock();
        }}
      </BlockWrapper>
    );
  }
}
