import React from 'react';
import PropTypes from 'prop-types';
import Tooltip from 'rc-tooltip';
import { default as RCSlider, Handle } from 'rc-slider'; // eslint-disable-line

const handle = (props) => {
  const { value, dragging, index, ...restProps } = props;

  return (
    <Tooltip
      prefixCls="rc-slider-tooltip"
      overlay={value}
      visible={dragging}
      placement="top"
      key={index}
    >
      <Handle value={value} {...restProps} />
    </Tooltip>
  );
};

const Slider = ({ min, max, ...rest }) => {
  return (
    <RCSlider
      min={min}
      max={max}
      handle={handle}
      {...rest}
    />
  );
};

Slider.propTypes = {
  min: PropTypes.number,
  max: PropTypes.number
};

export default Slider;
