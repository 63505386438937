import arrays from './arrays';
import strings from './strings';

/**
 * @param {number} activeBlockID
 * @param {Array} blockGroups
 * @returns {{blockGroupIndex: *, foundAt: *, blockGroup: *}}
 */
const getGroupBlockColumn = (activeBlockID, blockGroups) => {
  let blockGroupIndex = -1;
  let blockGroup      = null;
  let foundAt         = null;

  for (let i = 0; i < blockGroups.length; i++) {
    if (blockGroups[i].defaultBlock.type === 'row') {
      blockGroupIndex = arrays.findIndexByID(blockGroups[i].defaultBlock.columnBlockGroups, activeBlockID);
      if (blockGroupIndex !== -1) {
        blockGroup = blockGroups[i].defaultBlock.columnBlockGroups[blockGroupIndex];
        foundAt    = blockGroups[i].id;
        break;
      }
    }
  }

  return {
    blockGroupIndex,
    blockGroup,
    foundAt
  };
};

/**
 * @param {number} blockID
 * @param {Array} blockGroups
 * @param {Array} sidebarBlockGroups
 * @param {object} header
 * @returns {{ blockGroup: *, defaultBlock: * }}
 */
const getGroupBlock = (blockID, blockGroups, sidebarBlockGroups, header) => {
  let blockGroup      = null;
  let foundAt         = '';
  let columnKey       = null;
  let groupID         = null;
  let blockGroupIndex = arrays.findIndexByID(blockGroups, blockID);

  if (blockGroupIndex !== -1) {
    blockGroup = blockGroups[blockGroupIndex];
    foundAt    = 'blockGroups';
  } else {
    let index;
    for (let i = 0; i < blockGroups.length; i++) {
      if (blockGroups[i].defaultBlock.type === 'group') {
        blockGroupIndex = arrays.findIndexByID(blockGroups[i].defaultBlock.blockGroups, blockID);
        if (blockGroupIndex !== -1) {
          groupID = blockGroups[i].id;
          index   = i;
          break;
        }
      }
    }

    if (blockGroupIndex !== -1) {
      blockGroup = blockGroups[index].defaultBlock.blockGroups[blockGroupIndex];
      foundAt    = 'group';
    } else {
      for (let i = 0; i < blockGroups.length; i++) {
        if (blockGroups[i].defaultBlock.type === 'group') {
          // eslint-disable-next-line max-len
          ({ blockGroupIndex, blockGroup, foundAt } = getGroupBlockColumn(blockID, blockGroups[i].defaultBlock.blockGroups));
          if (blockGroupIndex !== -1) {
            groupID = blockGroups[i].id;
            break;
          }
        }
      }

      if (blockGroupIndex === -1) {
        blockGroupIndex = arrays.findIndexByID(sidebarBlockGroups, blockID);
        if (blockGroupIndex !== -1) {
          blockGroup = sidebarBlockGroups[blockGroupIndex];
          foundAt    = 'sidebarBlockGroups';
        } else {
          ({ blockGroupIndex, blockGroup, foundAt } = getGroupBlockColumn(blockID, blockGroups));

          if (!blockGroup && header.columnBlockGroups) {
            const keys = Object.keys(header.columnBlockGroups);
            for (let i = 0; i < keys.length; i++) {
              columnKey       = keys[i];
              blockGroupIndex = arrays.findIndexByID(header.columnBlockGroups[columnKey], blockID);
              if (blockGroupIndex !== -1) {
                blockGroup = header.columnBlockGroups[columnKey][blockGroupIndex];
                foundAt    = 'header';
                break;
              }
            }
          }
        }
      }
    }
  }

  if (blockGroupIndex === -1 || blockGroup === null) {
    return {
      defaultBlock: null,
      foundAt,
      groupID,
      columnKey,
      blockGroup,
      blockGroupIndex
    };
  }

  return {
    foundAt,
    groupID,
    columnKey,
    blockGroup,
    blockGroupIndex,
    defaultBlock: blockGroup.defaultBlock
  };
};

/**
 * @param {string} headerScripts
 */
const injectHeaderScripts = (headerScripts) => {
  $('.arb-header-stylesheets').remove();

  if (headerScripts) {
    const $head   = $('head');
    const regex   = new RegExp('<style>(.*?)</style>', 'gms');
    const matches = strings.matchAll(regex, headerScripts);
    matches.forEach((match) => {
      $('<style class="arb-header-stylesheets">')
        .text(match[1])
        .appendTo($head);
    });
  }
};

/**
 * @param {*} styles
 * @param {Array} include
 * @param {Array} exclude
 * @returns {*}
 */
export function buildStyles(styles, include = [], exclude = []) {
  const newStyles = {};
  if (styles) {
    Object.keys(styles).forEach((key) => {
      if (
        styles[key] !== ''
        && styles[key] !== null
        && (include.includes(key) || include.length === 0)
        && !exclude.includes(key)
      ) {
        newStyles[key] = styles[key];
      }
    });
  }

  return newStyles;
}

/**
 * @param {number|HTMLElement} blockGroupID
 * @returns {*}
 */
export function getBlockComputedStyles(blockGroupID) {
  let element;
  if (typeof blockGroupID === 'object') {
    element = blockGroupID;
  } else {
    element = document.querySelector(`#block-${blockGroupID}`);
  }
  if (element) {
    const computed = {};
    const styles   = window.getComputedStyle(element);
    for (let i = 0; i < styles.length; i++) {
      const key     = styles[i];
      computed[key] = styles.getPropertyValue(key);
      if (computed[key] === 'rgba(0, 0, 0, 0)') {
        computed[key] = '';
      }
    }

    return computed;
  }

  return {};
}

export default {
  getGroupBlock,
  buildStyles,
  injectHeaderScripts,
  getBlockComputedStyles
};
