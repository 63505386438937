import React from 'react';
import PropTypes from 'prop-types';
import { classNames, connect, mapDispatchToProps } from 'utils';
import { StarRating, Slider } from 'components';
import { listicleActions } from '@builder/actions';
import BlockWrapper from '@builder/components/BlockWrapper';
import QuillEditor from '@builder/components/QuillEditor';
import { FormGroup, ColorPicker } from 'components/forms';

const defaultFontSize  = 24;
const defaultStarColor = '#FFD700';

@connect(
  null,
  mapDispatchToProps(listicleActions)
)
export default class BlockStars extends React.PureComponent {
  static propTypes = {
    blockGroup:  PropTypes.object.isRequired,
    updateBlock: PropTypes.func.isRequired
  };

  static typeName = 'stars';

  static icon = 'star';

  static label = 'Stars';

  static defaultValues = {
    stars:   5,
    ratings: '<p><span style="font-size: 20px; color: #000;">({{ rand(0, 3000) }} ratings)</span></p>',
    styles:  {
      textAlign:    'left',
      marginBottom: 15,
      starColor:    defaultStarColor,
      starFontSize: defaultFontSize
    }
  };

  /**
   * @param {*} props
   */
  constructor(props) {
    super(props);

    this.element    = React.createRef();
    this.toolbar    = React.createRef();
    this.ratingsRef = React.createRef();
  }

  /**
   * @returns {*}
   */
  renderInactive = () => {
    const { blockGroup } = this.props;
    const { defaultBlock } = blockGroup;

    let isHalf = false;
    let justifyContent = '';
    const stars = [];
    if (defaultBlock.stars) {
      const starStyles = {
        fontSize: defaultBlock.styles.starFontSize || defaultFontSize
      };
      if (defaultBlock.styles.starColor) {
        starStyles.color = defaultBlock.styles.starColor;
      }

      switch (defaultBlock.styles.textAlign) {
        case 'center':
          justifyContent = 'center';
          break;
        case 'right':
          justifyContent = 'flex-end';
          break;
      }

      const [left, right] = defaultBlock.stars.toString().split('.');
      for (let i = 0; i < left; i++) {
        stars.push(
          <div key={i} className="arb-star fa fa-star" style={starStyles} />
        );
      }
      if (right) {
        isHalf = true;
        stars.push(
          <div key="half" className="arb-star fa fa-star-half" style={starStyles} />
        );
      }
    }

    if (stars.length === 0) {
      return null;
    }

    return (
      <div className="arb-rating" style={{ justifyContent }}>
        {stars.length > 0 && (
          <span className={classNames('arb-stars', { 'mr-3': !isHalf })}>
            {stars}
          </span>
        )}
        <span className="arb-ratings" dangerouslySetInnerHTML={{ __html: defaultBlock.ratings || '' }} />
      </div>
    );
  };

  /**
   * @returns {*}
   */
  renderActive = () => {
    const { blockGroup, updateBlock } = this.props;
    const { defaultBlock } = blockGroup;

    const handleUpdate = (ratings) => {
      if (ratings !== '<p>(empty)</p>') {
        updateBlock(defaultBlock.id, blockGroup.id, 'ratings', ratings);
      }
    };

    let justifyContent = 'flex-start';
    switch (defaultBlock.styles.textAlign) {
      case 'center':
        justifyContent = 'center';
        break;
      case 'right':
        justifyContent = 'flex-end';
        break;
    }

    let html = (defaultBlock.ratings || '').toString().replace('<br>', '').replace('<p></p>', '');
    if (!html) {
      html = '(empty)';
    }

    return (
      <div className="arb-rating" style={{ justifyContent }} ref={this.ratingsRef}>
        <StarRating
          value={defaultBlock.stars}
          className="arb-stars builder-canvas-block-rating"
          style={{ color: defaultBlock.styles.starColor || defaultStarColor }}
          fontSize={defaultBlock.styles.starFontSize || defaultFontSize}
          onChange={(e, v) => updateBlock(defaultBlock.id, blockGroup.id, 'stars', v)}
        />
        <span className="ml-3">
          <QuillEditor
            className="arb-ratings"
            toolbarExtras={this.renderMenu()}
            html={html}
            getElement={() => this.ratingsRef.current}
            onUpdate={handleUpdate}
          />
        </span>
      </div>
    );
  };

  /**
   * @returns {*}
   */
  renderMenu = () => {
    const { blockGroup, updateBlock } = this.props;
    const { defaultBlock } = blockGroup;

    /**
     * @param {string} value
     */
    const handleSizeChange = (value) => {
      const starFontSize = parseInt(value, 10);
      updateBlock(defaultBlock.id, blockGroup.id, 'styles', { starFontSize });
    };

    /**
     * @param {Event} e
     * @param {string} starColor
     */
    const handleColorChange = (e, starColor) => {
      updateBlock(defaultBlock.id, blockGroup.id, 'styles', { starColor });
    };

    return (
      <>
        <FormGroup
          className="mb-0"
          htmlFor="block-settings-star-font-size-input"
          label="Star Size"
        >
          <Slider
            min={12}
            max={60}
            step={4}
            value={defaultBlock.styles.starFontSize || defaultFontSize}
            onChange={handleSizeChange}
          />
        </FormGroup>
        <ColorPicker
          name="starColor"
          label="Star Color"
          id="block-settings-star-color-input"
          className="form-control-sm"
          onChange={handleColorChange}
          formGroupClassName="mb-0"
          value={defaultBlock.styles.starColor || defaultStarColor}
        />
      </>
    );
  };

  /**
   * @returns {*}
   */
  render() {
    const { blockGroup } = this.props;

    return (
      <BlockWrapper
        blockGroup={blockGroup}
      >
        {(provided) => {
          if (provided.isActive) {
            return this.renderActive();
          }

          return this.renderInactive();
        }}
      </BlockWrapper>
    );
  }
}
