import React from 'react';
import PropTypes from 'prop-types';
import { connect, mapDispatchToProps } from 'utils';

const MarginHighlight = ({ blockGroup, activeBlockID, marginHovering, children }) => {
  const { styles } = blockGroup.defaultBlock;

  const newStyles        = {};
  newStyles.marginTop    = styles.marginTop || 0;
  newStyles.marginRight  = styles.marginRight || 0;
  newStyles.marginBottom = styles.marginBottom || 0;
  newStyles.marginLeft   = styles.marginLeft || 0;
  newStyles.padding      = 0;

  if (styles.marginTop > 0) {
    newStyles.marginTop      = 0;
    newStyles.borderTopWidth = styles.marginTop;
  }
  if (styles.marginRight > 0) {
    newStyles.marginRight      = 0;
    newStyles.borderRightWidth = styles.marginRight;
  }
  if (styles.marginBottom > 0) {
    newStyles.marginBottom      = 0;
    newStyles.borderBottomWidth = styles.marginBottom;
  }
  if (styles.marginLeft > 0) {
    newStyles.marginLeft      = 0;
    newStyles.borderLeftWidth = styles.marginLeft;
  }

  return (
    <div
      style={newStyles}
      className={(marginHovering && blockGroup.id === activeBlockID)
        ? 'builder-canvas-block-margin highlight' : 'builder-canvas-block-margin'}
    >
      {children}
    </div>
  );
};

MarginHighlight.propTypes = {
  blockGroup:     PropTypes.object.isRequired,
  activeBlockID:  PropTypes.number.isRequired,
  marginHovering: PropTypes.bool.isRequired,
  children:       PropTypes.node
};

MarginHighlight.defaultProps = {
  children: ''
};

const mapStateToProps = state => ({
  activeBlockID:  state.listicle.activeBlockID,
  marginHovering: state.listicle.marginHovering,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps()
)(MarginHighlight);
