import { arrays, api, objects, colors, builder, router } from 'utils';
import { defaultArbStyles } from 'utils/styles';
import { setTemplate } from './listicleActions';

export const TEMPLATE_LOAD   = 'TEMPLATE_LOAD';
export const TEMPLATE_SELECT = 'TEMPLATE_SELECT';

/**
 * @param {string} styles
 * @param {string} id
 */
const appendStyles = (styles, id = 'arb-stylesheet-link') => {
  let $link = $(`#${id}`);
  if ($link.length !== 0) {
    $link.remove();
  }

  $link = $(`<style id="${id}" type="text/css" />`);
  $link.text(styles);
  $('head').append($link);
};

/**
 * @param {string} str
 * @returns {string|*}
 */
const dimension = (str) => {
  if (!str) {
    return str;
  }
  if (str.toString().match(/^[\d]+$/)) {
    return `${str}px`;
  }
  return str;
};

/**
 * @param {string} str
 * @returns {string|*}
 */
const camelCaseToDash = (str) => {
  return str.replace(/([a-z])([A-Z])/g, '$1-$2').toLowerCase();
};

/**
 * @returns {Function}
 */
export const loadTemplates = () => {
  return (dispatch) => {
    api.get(router.generate('templates_list'))
      .then((templates) => {
        dispatch({
          type: TEMPLATE_LOAD,
          templates
        });
      });
  };
};

/**
 * @param {number} selectedTemplate
 * @returns {Function}
 */
export const loadTemplateStylesheet = (selectedTemplate = 0) => {
  return (dispatch, getState) => {
    const { template, listicle } = getState();
    const { templates } = template;
    const { id, anchor, templateSettings } = listicle;
    const { site } = anchor;

    if (selectedTemplate === 0) {
      selectedTemplate = listicle.template.id;
    }

    const t = arrays.findByID(templates, selectedTemplate);
    if (!t) {
      console.error('Template not found.');
      return;
    }

    const body = {
      site:     site.id,
      lander:   id,
      settings: templateSettings
    };

    api.post(router.generate('templates_styles_builder', { id: selectedTemplate }), body)
      .then(appendStyles);
  };
};

/**
 * @returns {Function}
 */
export const appendBlockStyles = () => {
  return (dispatch, getState) => {
    const { listicle, ui } = getState();
    const { blockGroups, sidebarBlockGroups, styles, globalStyles, header, template } = listicle;
    const { previewDevice } = ui;

    const justify = (dir) => {
      switch (dir) {
        case 'left':
          return 'flex-start';
        case 'center':
          return 'center';
        case 'right':
          return 'flex-end';
        default:
          return 'initial';
      }
    };

    let stylesToAppend = '';
    if (template.version === '2') {
      let backgroundColor = styles.containerBackgroundColor || '#FFFFFF';
      if (styles.containerOpacity !== undefined && parseInt(styles.containerOpacity, 10) !== 0) {
        backgroundColor = colors.hexToRgbA(backgroundColor, parseInt(styles.containerOpacity, 10) / 100);
      } else {
        backgroundColor = colors.hexToRgbA(backgroundColor, 0);
      }

      stylesToAppend = `${stylesToAppend}
      .arb-columns {
        width:            ${styles['!container:@media-xs:width'] || defaultArbStyles['!container:@media-xs:width']};
        padding-left:     ${styles['!container:padding'] || '15px'};
        padding-right:    ${styles['!container:padding'] || '15px'};
        background-color: ${backgroundColor};
      }
      @media (min-width: 768px) {
        .arb-columns {
          width: ${styles['!container:@media-sm:width'] || defaultArbStyles['!container:@media-sm:width']};
        }
      }
      @media (min-width: 992px) {
        .arb-columns {
          width: ${styles['!container:@media-md:width'] || defaultArbStyles['!container:@media-md:width']};
        }
      }
      @media (min-width: 1200px) {
        .arb-columns {
          width: ${styles['!container:@media-xl:width'] || defaultArbStyles['!container:@media-xl:width']};
        }
      }
    `;

      if (previewDevice !== 'xl') {
        switch (previewDevice) {
          case 'xs':
            stylesToAppend = `${stylesToAppend}
              .arb-columns {
                width: ${styles['!container:@media-xs:width'] || defaultArbStyles['!container:@media-xs:width']};
              }
            `;
            break;
          case 'sm':
            stylesToAppend = `${stylesToAppend}
              .arb-columns {
                width: ${styles['!container:@media-sm:width'] || defaultArbStyles['!container:@media-sm:width']};
              }
            `;
            break;
          case 'md':
            stylesToAppend = `${stylesToAppend}
              .arb-columns {
                width: ${styles['!container:@media-md:width'] || defaultArbStyles['!container:@media-md:width']};
              }
            `;
            break;
        }
      }

      if (listicle.templateSettings.withSidebar) {
        const xsW = styles['!sidebar:@media-xs:width'] || defaultArbStyles['!sidebar:@media-xs:width'];
        const smW = styles['!sidebar:@media-sm:width'] || defaultArbStyles['!sidebar:@media-sm:width'];
        const mdW = styles['!sidebar:@media-md:width'] || defaultArbStyles['!sidebar:@media-md:width'];
        const xlW = styles['!sidebar:@media-xl:width'] || defaultArbStyles['!sidebar:@media-xl:width'];
        const padding = listicle.templateSettings.sidebarPadding || '15px';

        stylesToAppend = `${stylesToAppend}
          .arb-blocks {
            width: calc(100% - ${xsW === '100%' ? '0' : xsW});
          }
          @media (min-width: 768px) {
            .arb-blocks {
              width: calc(100% - ${smW === '100%' ? '0' : smW});
            }
          }
          @media (min-width: 992px) {
            .arb-blocks {
              width: calc(100% - ${mdW === '100%' ? '0' : mdW});
            }
          }
          @media (min-width: 1200px) {
            .arb-blocks {
              width: calc(100% - ${xlW === '100%' ? '0' : xlW});
            }
          }

          .arb-sidebar {
            width: ${xsW};
          }
          @media (min-width: 768px) {
            .arb-sidebar {
              width: ${smW};
            }
          }
          @media (min-width: 992px) {
            .arb-sidebar {
              width: ${mdW};
              padding-left: ${padding};
            }
          }
          @media (min-width: 1200px) {
            .arb-sidebar {
              width: ${xlW};
              padding-left: ${padding};
            }
          }
        `;

        if (previewDevice !== 'xl') {
          switch (previewDevice) {
            case 'xs':
              stylesToAppend = `${stylesToAppend}
              .arb-blocks {
                width: calc(100% - ${xsW === '100%' ? '0px' : xsW});
              }
              .arb-sidebar {
                width: ${xsW};
              }
              `;
              break;
            case 'sm':
              stylesToAppend = `${stylesToAppend}
              .arb-blocks {
                width: calc(100% - ${smW === '100%' ? '0px' : smW});
              }
              .arb-sidebar {
                width: ${smW};
              }
              `;
              break;
            case 'md':
              stylesToAppend = `${stylesToAppend}
              .arb-blocks {
                width: calc(100% - ${mdW === '100%' ? '0px' : mdW});
              }
              .arb-sidebar {
                width: ${mdW};
                padding-left: ${padding};
              }
              `;
              break;
          }
        }
      }
    }

    const handleBlockGroup = (type, { id, defaultBlock }) => {
      const { dimensions, displayDesktop, displayLaptop, displayTablet, displayMobile, styles: s } = defaultBlock;
      const {
        widthXl,
        heightXl,
        alignXl,
        widthMd,
        heightMd,
        alignMd,
        widthSm,
        heightSm,
        alignSm,
        widthXs,
        heightXs,
        alignXs
      } = dimensions;

      stylesToAppend = `${stylesToAppend}
        #block-wrap-${id} {
          text-align: ${alignXs || 'center'} !important;
          justify-content: ${justify(alignXs)};
        }
      `;
      if (s['!wrapper:backgroundColor']) {
        stylesToAppend = `${stylesToAppend}
          #block-wrap-${id} {
            background-color: ${s['!wrapper:backgroundColor']};
          }
        `;
      }
      if (s['!wrapper:backgroundImage']) {
        stylesToAppend = `${stylesToAppend}
          #block-wrap-${id} {
            background-image: url(${s['!wrapper:backgroundImage']});
            background-position: center center;
            background-size: cover;
          }
        `;
      }
      if (type === 'image' || (type === 'header' && !(widthXs === '100%' && heightXs === 'auto'))) {
        stylesToAppend = `${stylesToAppend}
        #block-${id} {
          width: ${dimension(widthXs)} !important;
          height: ${dimension(heightXs)} !important;
        }
        `;
      }

      if (s['!paragraph:marginBottom']) {
        stylesToAppend = `${stylesToAppend}
        #block-${id} p {
          margin-bottom: ${dimension(s['!paragraph:marginBottom'])} !important;
        }
        #block-${id} p:last-child {
          margin-bottom: 0 !important;
        }
        `;
      }

      stylesToAppend = `${stylesToAppend}
        @media (min-width: 768px) {
          #block-wrap-${id} {
            text-align: ${alignSm || 'center'} !important;
            justify-content: ${justify(alignSm)};
          }
        }
      `;
      if (type === 'image' || (type === 'header' && !(widthSm === '100%' && heightSm === 'auto'))) {
        stylesToAppend = `${stylesToAppend}
        @media (min-width: 768px) {
          #block-${id} {
            width: ${dimension(widthSm)} !important;
            height: ${dimension(heightSm)} !important;
          }
        }
        `;
      }

      stylesToAppend = `${stylesToAppend}
        @media (min-width: 992px) {
          #block-wrap-${id} {
            text-align: ${alignMd || 'center'} !important;
            justify-content: ${justify(alignMd)};
          }
        }
      `;
      if (type === 'image' || (type === 'header' && !(widthMd === '100%' && heightMd === 'auto'))) {
        stylesToAppend = `${stylesToAppend}
        @media (min-width: 992px) {
          #block-${id} {
            width: ${dimension(widthMd)} !important;
            height: ${dimension(heightMd)} !important;
          }
        }
        `;
      }

      stylesToAppend = `${stylesToAppend}
        @media (min-width: 1200px) {
          #block-wrap-${id} {
            text-align: ${alignXl || 'center'} !important;
            justify-content: ${justify(alignXl)};
          }
        }
      `;
      if (type === 'image' || (type === 'header' && !(widthXl === '100%' && heightXl === 'auto'))) {
        stylesToAppend = `${stylesToAppend}
        @media (min-width: 1200px) {
          #block-${id} {
            width: ${dimension(widthXl)} !important;
            height: ${dimension(heightXl)} !important;
          }
        }
        `;
      }

      switch (previewDevice) {
        case 'xs':
          if (displayMobile || displayMobile === undefined) {
            stylesToAppend = `${stylesToAppend}
            #block-${id} {
              width: ${dimension(widthXs)} !important;
              height: ${dimension(heightXs)} !important;
            }
            `;
          } else {
            stylesToAppend = `${stylesToAppend}
            #block-${id} {
              display: none;
            }
            `;
          }
          break;
        case 'sm':
          if (displayTablet || displayMobile === undefined) {
            stylesToAppend = `${stylesToAppend}
            #block-${id} {
              width: ${dimension(widthSm)} !important;
              height: ${dimension(heightSm)} !important;
            }
            `;
          } else {
            stylesToAppend = `${stylesToAppend}
            #block-${id} {
              display: none;
            }
            `;
          }
          break;
        case 'md':
          if (displayLaptop || displayMobile === undefined) {
            stylesToAppend = `${stylesToAppend}
            #block-${id} {
              width: ${dimension(widthMd)} !important;
              height: ${dimension(heightMd)} !important;
            }
            `;
          } else {
            stylesToAppend = `${stylesToAppend}
            #block-${id} {
              display: none;
            }
            `;
          }
          break;
        case 'xl':
          if (displayDesktop === false) {
            stylesToAppend = `${stylesToAppend}
            #block-${id} {
              display: none;
            }
            `;
          }
          break;
      }

      const hoverStyles = {};
      Object.keys(defaultBlock.styles).forEach((key) => {
        if (key.indexOf('hover:') === 0) {
          hoverStyles[key.replace('hover:', '')] = defaultBlock.styles[key];
        }
      });
      if (!objects.isEmpty(hoverStyles)) {
        Object.keys(hoverStyles).forEach((key) => {
          stylesToAppend = `${stylesToAppend}
            #block-${id}:hover {
              ${camelCaseToDash(key)}: ${hoverStyles[key]} !important;
            }
          `;
        });
      }
    };

    blockGroups.forEach(handleBlockGroup.bind(this, 'image'));
    blockGroups.forEach((blockGroup) => {
      if (blockGroup.defaultBlock.type === 'row') {
        blockGroup.defaultBlock.columnBlockGroups.forEach((bg) => {
          handleBlockGroup('image', bg);
        });
      } else if (blockGroup.defaultBlock.type === 'group') {
        blockGroup.defaultBlock.blockGroups.forEach((bg) => {
          handleBlockGroup('image', bg);
          bg.defaultBlock.columnBlockGroups.forEach((bg2) => {
            handleBlockGroup('image', bg2);
          });
        });
      }
    });
    sidebarBlockGroups.forEach(handleBlockGroup.bind(this, 'image'));
    Object.keys(header.columnBlockGroups).forEach((key) => {
      header.columnBlockGroups[key].forEach(handleBlockGroup.bind(this, 'header'));
    });

    // Global styles
    if (globalStyles.fontFamily) {
      const family = globalStyles.fontFamily.split(',').shift();
      const link   = document.createElement('link');
      link.setAttribute('rel', 'stylesheet');
      link.setAttribute('href', `https://fonts.googleapis.com/css2?family=${family}:wght@300;400;500;700;900&display=swap`);
      document.querySelector('head').appendChild(link);
    }
    Object.keys(globalStyles).forEach((key) => {
      if (globalStyles[key] && key[0] !== '!') {
        const keyValue = key.split(':');
        const parts    = keyValue[0].split(',');
        parts.forEach((part) => {
          if (keyValue.length === 1) {
            stylesToAppend = `${stylesToAppend}
                .arb-columns {
                  ${camelCaseToDash(keyValue[0])}: ${globalStyles[key]} !important;
                }
              `;
          } else {
            let selector;
            if (keyValue.length > 2) {
              selector = camelCaseToDash(keyValue.pop());
              keyValue.shift();
              part = `${part} ${keyValue.join(' ')}`;
            } else {
              selector = camelCaseToDash(keyValue[1]);
            }
            stylesToAppend = `${stylesToAppend}
                .arb-columns ${part} {
                  ${selector}: ${globalStyles[key]} !important;
                }
              `;
          }
        });
      }
    });

    appendStyles(stylesToAppend, 'arb-block-styles');
  };
};

/**
 * @param {number} id
 * @returns {{id: *, type: string}}
 */
export const selectTemplate = (id) => {
  return (dispatch, getState) => {
    const { template, listicle } = getState();

    dispatch({
      type: TEMPLATE_SELECT,
      id
    });
    dispatch(loadTemplateStylesheet(id));

    const selected = arrays.findByID(template.templates, id);
    dispatch(setTemplate(selected));

    const { headerScripts } = listicle;
    if (headerScripts) {
      builder.injectHeaderScripts(headerScripts);
    }

    setTimeout(() => {
      dispatch(appendBlockStyles());
    }, 1000);
  };
};
