import * as types from '@builder/actions/libraryActions';
import { objects } from 'utils';

const initialState = objects.merge({
  libraries: []
}, window.initialState.library);

/**
 * @param {*} state
 * @param {*} action
 */
const onFetch = (state, action) => {
  const libraries = objects.clone(action.libraries);

  return {
    ...state,
    libraries
  };
};

const handlers = {
  [types.LIBRARY_FETCH]: onFetch
};

/**
 * @param {*} state
 * @param {*} action
 * @returns {*}
 */
export default function libraryReducer(state = objects.clone(initialState), action = {}) {
  if (handlers[action.type]) {
    return handlers[action.type].call(null, state, action);
  }

  return state;
}
