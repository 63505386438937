import * as types from '@builder/actions/geoActions';
import { objects } from 'utils';

const initialState = objects.merge({
  zones:        [],
  errorMessage: ''
}, window.initialState.geo);

/**
 * @param {*} state
 * @param {*} action
 */
const onSetZones = (state, action) => {
  const zones = objects.clone(action.zones);

  return {
    ...state,
    zones
  };
};

/**
 * @param {*} state
 * @param {*} action
 */
const onErrorMessage = (state, action) => {
  const { errorMessage } = action;

  return {
    ...state,
    errorMessage
  };
};

const handlers = {
  [types.GEO_SET_ZONES]:     onSetZones,
  [types.GEO_ERROR_MESSAGE]: onErrorMessage
};

/**
 * @param {*} state
 * @param {*} action
 * @returns {*}
 */
export default function geoReducer(state = objects.clone(initialState), action = {}) {
  if (handlers[action.type]) {
    return handlers[action.type].call(null, state, action);
  }

  return state;
}
