import React from 'react';
import PropTypes from 'prop-types';
import { classNames } from 'utils';

const SectionTool = ({ className, children }) => {
  return (
    <div className={classNames('section-tool', className)}>
      {children}
    </div>
  );
};

SectionTool.propTypes = {
  className: PropTypes.string,
  children:  PropTypes.node
};

SectionTool.defaultProps = {
  className: '',
  children:  ''
};

export default SectionTool;
