import * as types from '@builder/actions/siteActions';
import { objects } from 'utils';

const initialState = objects.merge({
  sites: [],
  site:  null
}, window.initialState.site);

/**
 * @param {*} state
 * @param {*} action
 */
const onLoad = (state, action) => {
  const sites = Array.from(action.sites);

  return {
    ...state,
    sites
  };
};

const handlers = {
  [types.SITE_LOAD]: onLoad
};

/**
 * @param {*} state
 * @param {*} action
 * @returns {*}
 */
export default function siteReducer(state = objects.clone(initialState), action = {}) {
  if (handlers[action.type]) {
    return handlers[action.type].call(null, state, action);
  }

  return state;
}
