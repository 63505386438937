import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { objects } from 'utils';
import { FormGroup } from 'components/forms';
import FormContext from './formContext';

/**
 *
 */
class Textarea extends React.PureComponent {
  static contextType = FormContext;

  static propTypes = {
    id:                 PropTypes.string,
    sm:                 PropTypes.bool,
    help:               PropTypes.string,
    name:               PropTypes.string,
    value:              PropTypes.string,
    label:              PropTypes.node,
    light:              PropTypes.bool,
    innerRef:           PropTypes.object,
    formGroupClassName: PropTypes.string,
    errorMessage:       PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    placeholder:        PropTypes.string,
    className:          PropTypes.string,
    disabled:           PropTypes.bool,
    required:           PropTypes.bool,
    format:             PropTypes.func,
    parse:              PropTypes.func,
    onChange:           PropTypes.func
  };

  static defaultProps = {
    id:                 '',
    help:               '',
    name:               '',
    value:              '',
    label:              '',
    errorMessage:       '',
    placeholder:        '',
    className:          '',
    formGroupClassName: '',
    innerRef:           null,
    light:              false,
    sm:                 false,
    disabled:           false,
    required:           false,
    format:             v => v,
    parse:              v => v,
    onChange:           null
  };

  static unityFormType = 'textarea';

  /**
   * @param {*} props
   */
  constructor(props) {
    super(props);

    this.textarea = React.createRef();
  }

  /**
   * @param {string} value
   */
  setValue = (value) => {
    this.textarea.current.value = value;
    this.handleChange(new Event('change'), this.context);
  };

  /**
   * @returns {*}
   */
  getValue = () => {
    return this.textarea.current.value;
  };

  /**
   * @param {Event} e
   * @param {*} context
   */
  handleChange = (e, context) => {
    const { id, name, parse, innerRef, onChange } = this.props;

    const ref = innerRef || this.textarea;
    const value = parse(ref.current.value);
    if (context.onChange) {
      context.onChange(e, value, name || id);
    }
    if (onChange) {
      onChange(e, parse(ref.current.value), name || id);
    }
  };

  /**
   * @returns {*}
   */
  render() {
    const {
      id,
      sm,
      help,
      name,
      value,
      label,
      light,
      format,
      placeholder,
      errorMessage,
      formGroupClassName,
      className,
      disabled,
      required,
      ...props
    } = this.props;
    const { context } = this;
    const inputName = name || id;
    const classes = classNames(className, 'form-control', {
      'form-control-sm':    context.sm || sm,
      'form-control-light': light
    });

    let { innerRef } = this.props; // eslint-disable-line
    const ref = innerRef || this.textarea;

    return (
      <FormGroup
        help={help}
        htmlFor={id}
        label={label}
        className={formGroupClassName}
        required={context.required || required}
        errorMessage={context.errorFields[inputName] || errorMessage}
      >
        <textarea
          id={id}
          name={inputName}
          className={classes}
          placeholder={placeholder}
          ref={ref}
          required={context.required || required}
          disabled={context.disabled || disabled}
          onChange={e => this.handleChange(e, context)}
          {...objects.keyFilter(props, Textarea.propTypes)}
          value={format(context.values[inputName] || value)}
        />
      </FormGroup>
    );
  }
}

export default Textarea;
