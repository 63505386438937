import React from 'react';
import PropTypes from 'prop-types';
import { router, connect, mapDispatchToProps } from 'utils';
import { Icon } from 'components';
import { listicleActions, uiActions } from '@builder/actions';
import PopupTools from '@builder/components/PopupTools';
import BlockWrapper from '@builder/components/BlockWrapper';

const mapStateToProps = state => ({
  anchor: state.listicle.anchor
});

@connect(
  mapStateToProps,
  mapDispatchToProps(listicleActions, uiActions)
)
export default class BlockFlow extends React.PureComponent {
  static propTypes = {
    anchor:      PropTypes.object.isRequired,
    blockGroup:  PropTypes.object.isRequired,
    updateBlock: PropTypes.func.isRequired,
    uiModal:     PropTypes.func.isRequired
  };

  static defaultProps = {};

  static typeName = 'flow';

  static icon = 'project-diagram';

  static label = 'Flow';

  static defaultValues = {
    imageUrl: '',
    styles:   {
      marginBottom: 15
    }
  };

  /**
   * @param {*} props
   */
  constructor(props) {
    super(props);

    this.mount    = React.createRef();
    this.progress = React.createRef();
    this.title    = React.createRef();
    this.button   = React.createRef();
  }

  /**
   *
   */
  componentDidMount() {
    const { anchor, blockGroup, uiModal, updateBlock } = this.props;
    const { defaultBlock } = blockGroup;

    const script = document.createElement('SCRIPT');
    script.addEventListener('load', this.handleFlowEngineLoad, false);
    script.src = `https://${anchor.site.domain}/api/flow.js`;
    document.querySelector('head').appendChild(script);

    uiModal('flows', true, {
      onSelect: (url) => {
        updateBlock(defaultBlock.id, blockGroup.id, 'imageUrl', url);
        setTimeout(this.handleFlowEngineLoad, 200);
      }
    });
  }

  /**
   * @param {*} prevProps
   */
  componentDidUpdate(prevProps) {
    const { active } = this.props;

    if (!active && prevProps.active || active && !prevProps.active) {
      this.handleFlowEngineLoad();
    }
  }

  /**
   *
   */
  handleFlowEngineLoad = () => {
    const { blockGroup } = this.props;
    const { defaultBlock } = blockGroup;

    if (!defaultBlock.imageUrl) {
      return;
    }

    this.flow = new Flow(defaultBlock.imageUrl); // eslint-disable-line

    // Set the page title once the flow document is loaded.
    this.flow.on('start', (doc) => {
      this.title.current.innerText = doc.title;
    });
    this.flow.on('step', (step, hasPrev) => {
      this.button.current.style.display = hasPrev ? 'inline-block' : 'none';
    });
    this.button.current.addEventListener('click', () => {
      this.flow.goto('prev');
    }, false);


    this.flow.start(this.mount.current);
  };

  /**
   * @param {Event} e
   */
  handleUrlClick = (e) => {
    const { blockGroup, updateBlock, uiModal } = this.props;
    const { defaultBlock } = blockGroup;

    e.stopPropagation();

    uiModal('flows', true, {
      onSelect: (url) => {
        updateBlock(defaultBlock.id, blockGroup.id, 'imageUrl', url);
        setTimeout(this.handleFlowEngineLoad, 200);
      }
    });
  };

  /**
   *
   */
  handleEditClick = () => {
    const { blockGroup } = this.props;
    const { defaultBlock } = blockGroup;

    const match = defaultBlock.imageUrl.match(/([\d]+)\.json$/);
    window.open(router.generate('flows_details', { id: match[1] }));
  };

  /**
   * @returns {*}
   */
  renderFlow = () => {
    return (
      <>
        <div className="progress flow-progress mb-4">
          <div
            ref={this.progress}
            className="progress-bar flow-progress-bar"
            role="progressbar"
            aria-valuenow="0"
            aria-valuemin="0"
            aria-valuemax="100"
          />
        </div>
        <h1 ref={this.title} className="flow-title mb-4">Title</h1>
        <div ref={this.mount} />
        <div className="text-center mt-4">
          <button ref={this.button} className="flow-btn-prev-step" type="button" style={{ display: 'none' }}>
            <span className="small">
              <i className="fa fa-arrow-left" /> back
            </span>
          </button>
        </div>
      </>
    );
  };

  /**
   * @returns {*}
   */
  render() {
    const { blockGroup } = this.props;

    return (
      <BlockWrapper blockGroup={blockGroup} className="arb-flow">
        {(provided) => {
          if (provided.isActive) {
            return (
              <>
                {this.renderFlow()}
                <PopupTools position="left" getTarget={() => this.mount.current} visible>
                  <Icon name="project-diagram" title="Flow URL" onClick={this.handleUrlClick} />
                  <Icon name="edit" title="Edit Flow" onClick={this.handleEditClick} />
                  <Icon name="sync-alt" title="Refresh" onClick={this.handleFlowEngineLoad} />
                </PopupTools>
              </>
            );
          }

          return this.renderFlow();
        }}
      </BlockWrapper>
    );
  }
}
