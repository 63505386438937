import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

/**
 *
 */
export default class Avatar extends React.PureComponent {
  static propTypes = {
    src:       PropTypes.string,
    user:      PropTypes.object,
    sm:        PropTypes.bool,
    lg:        PropTypes.bool,
    className: PropTypes.string
  };

  static defaultProps = {
    src:       '',
    user:      {},
    sm:        false,
    lg:        false,
    className: ''
  };

  /**
   * @returns {*}
   */
  render() {
    const { src, user, sm, lg, className, ...props } = this.props;

    const classes = classNames('avatar', {
      'avatar-sm': sm,
      'avatar-lg': lg
    }, className);

    if (src) {
      return (
        <img src={src} alt="Avatar" className={classes} {...props} />
      );
    }

    return (
      <span className={classNames(classes, 'avatar-default')} style={{ backgroundColor: user.avatarColor }}>
        {user.avatarInitials}
      </span>
    );
  }
}
