import axios, { CancelToken } from 'axios';

const postBusy = [];

/**
 * @param {string} url
 * @returns {Promise<any> | Promise | Promise}
 */
const get = (url) => {
  return axios.get(url, {
    headers: {
      'X-Requested-With': 'XMLHttpRequest',
    },
  })
    .then(resp => resp.data);
};

/**
 * @param {string} url
 * @param {*} body
 * @param {*} headers
 * @param {*} cancelToken
 * @param {string} name
 * @returns {Promise<any> | Promise | Promise}
 */
const post = (url, body = {}, headers = {}, cancelToken = null, name = '') => {
  const options = {
    headers: Object.assign({}, {
      'X-Requested-With': 'XMLHttpRequest',
    }, headers),
  };
  if (cancelToken) {
    options.cancelToken = cancelToken.token;
  }

  postBusy.push(name || url);

  return axios.post(url, body, options)
    .then((resp) => {
      const i = postBusy.indexOf(name || url);
      if (i !== -1) {
        postBusy.splice(i, 1);
      }
      return resp.data;
    })
    .catch((error) => {
      const i = postBusy.indexOf(name || url);
      if (i !== -1) {
        postBusy.splice(i, 1);
      }
      if (!axios.isCancel(error)) {
        throw error;
      }
    });
};

/**
 * @param {string} method
 * @param {string} url
 * @param {*} data
 * @returns {Promise<any> | Promise | Promise}
 */
const req = (method, url, data = {}) => {
  return axios.request({
    url,
    data,
    method,
    headers: {
      'X-Requested-With': 'XMLHttpRequest',
    },
  }).then(resp => resp.data);
};

/**
 * @returns {*}
 */
const getCancelToken = () => {
  return CancelToken.source();
};

/**
 * @param name
 * @returns {boolean}
 */
const isPostBusy = (name) => {
  return postBusy.indexOf(name) !== -1;
};

export default {
  get,
  req,
  post,
  isPostBusy,
  getCancelToken,
};
