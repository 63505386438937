import React from 'react';
import PropTypes from 'prop-types';
import { classNames } from 'utils';
import { popupOffset } from 'utils/styles';

export default class PopupTools extends React.PureComponent {
  static propTypes = {
    visible:    PropTypes.bool,
    position:   PropTypes.oneOf(['left', 'right', 'bottom']),
    getTarget:  PropTypes.func.isRequired,
    dragHandle: PropTypes.func,
    className:  PropTypes.string,
    children:   PropTypes.node
  };

  static defaultProps = {
    visible:   false,
    position:  'right',
    className: '',
    children:  ''
  };

  /**
   * @param {*} props
   */
  constructor(props) {
    super(props);

    this.menu    = React.createRef();
    this.timeout = 0;
    this.unmount = false;
    this.state   = {
      vertical: false,
      styles:   { display: 'none' },
      isDown:   false,
      isMoved:  false,
      mousePos: { clientX: 0, clientY: 0 }
    };
  }

  /**
   *
   */
  componentDidMount() {
    const { visible } = this.props;

    const container = document.getElementById('builder-canvas-container');
    if (container) {
      container.addEventListener('scroll', this.updatePosition, false);
    }

    if (visible) {
      this.setState({
        styles: {
          opacity: 0,
          display: 'block'
        }
      });
      this.timeout = setTimeout(this.updatePosition, 50);
    } else {
      this.setState({
        styles: {
          display: 'none'
        }
      });
    }
  }

  /**
   * @param {*} prevProps
   */
  componentDidUpdate(prevProps) {
    const { visible } = this.props;

    if (visible && !prevProps.visible) {
      this.setState({
        styles: {
          opacity: 0,
          display: 'block'
        }
      });
      clearTimeout(this.timeout);
      this.timeout = setTimeout(this.updatePosition, 50);
    } else if (!visible && prevProps.visible) {
      this.setState({
        styles: {
          display: 'none'
        }
      });
    }
  }

  /**
   *
   */
  componentWillUnmount() {
    this.unmount = true;
    clearTimeout(this.timeout);
    const container = document.getElementById('builder-canvas-container');
    if (container) {
      container.removeEventListener('scroll', this.updatePosition, false);
    }
  }

  /**
   *
   */
  updatePosition = () => {
    const { visible, position, getTarget } = this.props;
    const { isMoved } = this.state;

    if (isMoved || this.unmount) {
      return;
    }

    const container = document.getElementById('builder-canvas-container');
    const element   = getTarget();

    if (element && container && this.menu.current) {
      const display       = visible ? 'block' : 'none';
      const blockRect     = element.getBoundingClientRect();
      const containerRect = container.getBoundingClientRect();
      const menuWidth     = this.menu.current.offsetWidth;

      if (position === 'right') {
        if ((blockRect.right + popupOffset + menuWidth) > containerRect.right) {
          this.setState({ vertical: true });
          clearTimeout(this.timeout);
          this.timeout = setTimeout(() => {
            if (!this.unmount) {
              this.setState({
                styles: {
                  top:     blockRect.top - this.menu.current.offsetHeight,
                  left:    blockRect.right - (popupOffset + this.menu.current.offsetWidth),
                  opacity: 1,
                  display
                }
              });
            }
          }, 25);
        } else {
          this.setState({ vertical: false });
          clearTimeout(this.timeout);
          this.timeout = setTimeout(() => {
            if (!this.unmount) {
              this.setState({
                styles: {
                  top:     blockRect.top,
                  left:    blockRect.right + popupOffset,
                  opacity: 1,
                  display
                }
              });
            }
          }, 25);
        }
      } else if (position === 'bottom') {
        this.setState({ vertical: false });
        clearTimeout(this.timeout);
        this.timeout = setTimeout(() => {
          if (!this.unmount) {
            this.setState({
              styles: {
                top:     blockRect.bottom + 25,
                left:    blockRect.left + (blockRect.width / 2) - (menuWidth / 2),
                opacity: 1,
                display
              }
            });
          }
        }, 25);
      } else {
        this.setState({ vertical: false });
        clearTimeout(this.timeout);
        this.timeout = setTimeout(() => {
          if (!this.unmount) {
            this.setState({
              styles: {
                top:     blockRect.top,
                left:    blockRect.left - menuWidth - popupOffset,
                opacity: 1,
                display
              }
            });
          }
        }, 25);
      }
    }
  };

  /**
   * @param {MouseEvent} e
   */
  handleMouseMove = (e) => {
    const { isDown, mousePos, styles } = this.state;

    if (isDown) {
      e.preventDefault();
      const { clientX, clientY } = e;

      const dX = mousePos.clientX - clientX;
      const dY = mousePos.clientY - clientY;
      this.setState({
        isMoved:  true,
        styles:   Object.assign({}, styles, { top: styles.top - dY, left: styles.left - dX }),
        mousePos: { clientX, clientY }
      });
    }
  };

  /**
   *
   */
  handleMouseUp = () => {
    window.removeEventListener('mousemove', this.handleMouseMove);
    window.removeEventListener('mouseup', this.handleMouseUp);
    this.setState({ isDown: false });
  };

  /**
   * @param {MouseEvent} e
   */
  handleMouseDown = (e) => {
    const { styles } = this.state;
    const { clientX, clientY } = e;

    const rect = this.menu.current.getBoundingClientRect();
    this.setState({
      isDown:   true,
      styles:   Object.assign({}, styles, { top: rect.top, left: rect.left }),
      mousePos: { clientX, clientY }
    });
    window.addEventListener('mousemove', this.handleMouseMove);
    window.addEventListener('mouseup', this.handleMouseUp);
  };

  /**
   * @returns {*}
   */
  render() {
    const { visible, className, dragHandle, children } = this.props;
    const { vertical, styles, isDown } = this.state;

    const classes = classNames('builder-block-menu-mini', className, {
      'builder-block-menu-hidden': !visible,
      vertical
    });

    return (
      <div ref={this.menu} className={classes} style={styles}>
        {dragHandle && dragHandle(isDown, this.handleMouseDown)}
        {children}
      </div>
    );
  }
}
