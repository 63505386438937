import React from 'react';
import PropTypes from 'prop-types';
import { connect, mapDispatchToProps } from 'utils';
import { Modal as BootstrapModal, ModalHeader, ModalBody, ModalFooter, Button } from 'components/bootstrap';
import { Icon, Avatar } from 'components';
import { uiActions } from '../apps/builder/actions';

@connect(
  null,
  mapDispatchToProps(uiActions)
)
export default class Modal extends React.PureComponent {
  static propTypes = {
    name:        PropTypes.string.isRequired,
    title:       PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    icon:        PropTypes.string,
    avatar:      PropTypes.string,
    buttons:     PropTypes.node,
    lg:          PropTypes.bool,
    id:          PropTypes.string,
    role:        PropTypes.string,
    label:       PropTypes.string,
    closeText:   PropTypes.string,
    footer:      PropTypes.bool,
    fixedHeight: PropTypes.bool,
    fullPage:    PropTypes.bool,
    centered:    PropTypes.bool,
    header:      PropTypes.node,
    closeable:   PropTypes.bool,
    uiModal:     PropTypes.func.isRequired,
    onBodyClick: PropTypes.func,
    onShow:      PropTypes.func,
    onHide:      PropTypes.func,
    onOpened:    PropTypes.func,
    onClosed:    PropTypes.func,
    children:    PropTypes.node,
    className:   PropTypes.string
  };

  static defaultProps = {
    lg:          false,
    footer:      true,
    fixedHeight: false,
    fullPage:    false,
    centered:    false,
    closeable:   true,
    id:          '',
    icon:        '',
    avatar:      '',
    label:       '',
    header:      '',
    role:        'dialog',
    closeText:   'Close',
    buttons:     '',
    children:    '',
    className:   '',
    onShow:      () => {},
    onHide:      () => {},
    onOpened:    () => {},
    onClosed:    () => {},
    onBodyClick: () => {}
  };

  /**
   *
   */
  close = () => {
    const { name, uiModal, onClosed } = this.props;

    onClosed();
    uiModal(name, false);
  };

  /**
   * @returns {*}
   */
  render() {
    const {
      title,
      icon,
      avatar,
      role,
      header,
      buttons,
      centered,
      closeable,
      fixedHeight,
      fullPage,
      closeText,
      footer,
      id,
      lg,
      label,
      className,
      onOpened,
      onShow,
      onHide,
      children,
      onBodyClick
    } = this.props;

    const modalHeader = header || (
      <ModalHeader>
        {icon && (
          <Icon name={icon} />
        )}
        {avatar && (
          <Avatar src={avatar} sm />
        )}
        {title}
      </ModalHeader>
    );

    return (
      <BootstrapModal
        open
        closeable={closeable}
        onOpened={onOpened}
        onClosed={this.close}
        onShow={onShow}
        onHide={onHide}
        role={role}
        lg={lg}
        id={id}
        fullPage={fullPage}
        className={className}
        centered={centered}
      >
        {modalHeader}
        <ModalBody className={fixedHeight ? 'modal-body-fixed-height modal-body-overflow' : ''} onClick={onBodyClick}>
          {children}
        </ModalBody>
        {footer && (
          <ModalFooter>
            {buttons}
            {closeText && (
              <Button onClick={this.close}>
                {closeText}
              </Button>
            )}
            {label && (
              <div className="modal-footer-label">
                {label}
              </div>
            )}
          </ModalFooter>
        )}
      </BootstrapModal>
    );
  }
}
